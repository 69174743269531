import { useState, useEffect } from 'react'

const OPTIONS = [
  //{ label: '10', value: 10, id: 1 },
  { label: '25', value: 25, id: 2 }
]

export const usePaymentDate = (goToNextScreen, setDataModal, incomeStatementPath) => {
  const [value, setValue] = useState()

  useEffect(() => {
    setDataModal(null)
  }, [setDataModal])

  useEffect(() => {
    if (incomeStatementPath && incomeStatementPath.ReadIncomeDeclarationPaymentDate) {
      const find = OPTIONS.find(item => item.value === incomeStatementPath.ReadIncomeDeclarationPaymentDate)
      if (find) {
        onSelectOption(find)
      }
    }
  }, [incomeStatementPath])

  const onSelectOption = item => {
    setValue(item)
  }

  const onSubmit = e => {
    e.preventDefault()
    if (value?.value) {
      goToNextScreen(value.value)
    }
  }

  return [onSelectOption, onSubmit, value, OPTIONS]
}

import { useEffect } from 'react'
import { getDataScreenCurrent } from '../../../services/isa'
import { LOCALSTORAGE_TOKENS } from '../../../constants'

export const useIntro = (setIncomeStatementPath, setIsLoading) => {
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getDataScreenCurrent(crid_isa)
        setIncomeStatementPath(response.data)
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    const crid_isa = localStorage.getItem(LOCALSTORAGE_TOKENS.crid_isa)
    if (crid_isa) {
      getData()
    }
  }, [setIncomeStatementPath, setIsLoading])
}

import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'

export const Option = styled.nav`
  display: flex;
  height: 48px;
  justify-content: space-between;
  cursor: pointer;

  align-items: center;
  padding: 0 16px;
  transition: background 0.2s ease;
  background: ${props => (props.isSubMenuToggled ? theme.colors.blueSecundaryLight : 'transparent')};

  &:hover {
    background: ${theme.colors.bluePrimaryLight};
  }
`

const rotateSubMenuToggleArrow = css`
  transform: rotate(180deg);
`

export const ToggleSubMenu = styled.div`
  margin: 0;
  padding: 0;
  cursor: pointer;

  > svg {
    ${props => props.isSubMenuToggled && rotateSubMenuToggleArrow}
  }
`

const subOptionDefaultStyles = css`
  border-left: 2px solid rgb(224, 224, 224, 0.8);
  color: #414141;
`

const subOptionActiveStyles = css`
  border-left: 4px solid ${theme.colors.pink900};
  color: ${theme.colors.blue900};
`
export const SubOption = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 30px;
`

export const LiOption = styled.li`
  ${props => (props.active ? subOptionActiveStyles : subOptionDefaultStyles)}
  padding: 12px 16px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
`

const proviBlueOptionTitleColor = css`
  color: ${theme.colors.blue900};
`
const defaultOptionTitleColor = css`
  color: #414141;
`

export const OptionTitle = styled.strong`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
  text-decoration: none;
  ${props => (props?.color === 'blue' ? proviBlueOptionTitleColor : defaultOptionTitleColor)};
`
export const LinkedInValidationAlert = styled.div`
  font-family: 'Montserrat', sans-serif;
  width: 20px;
  height: 20px;
  margin-left: 180px;
  background-color: ${theme.colors.pink900};
  border-radius: 50%;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 14px;
`

import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  ${theme.breakpoints.down('md')} {
    align-items: flex-start;
  }
`

export const WrapperForm = styled.div`
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${theme.breakpoints.down('md')} {
    align-items: flex-start;
  }
`

export const WrapperInput = styled.div`
  margin: 20px 0;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

import React from 'react'

export const House = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9993 25.9982V19.9981C18.9993 19.7329 18.8939 19.4785 18.7064 19.291C18.5188 19.1034 18.2645 18.9981 17.9993 18.9981H13.9993C13.7341 18.9981 13.4797 19.1034 13.2922 19.291C13.1046 19.4785 12.9993 19.7329 12.9993 19.9981V25.9982C12.9993 26.2634 12.8939 26.5177 12.7064 26.7053C12.5189 26.8928 12.2646 26.9982 11.9994 26.9982L6.00012 26.999C5.86879 26.999 5.73874 26.9732 5.6174 26.9229C5.49606 26.8727 5.38581 26.799 5.29294 26.7062C5.20007 26.6133 5.12639 26.5031 5.07613 26.3817C5.02587 26.2604 5 26.1303 5 25.999V14.4415C5 14.3021 5.02911 14.1644 5.08547 14.0369C5.14183 13.9095 5.22418 13.7953 5.32726 13.7016L15.3266 4.60973C15.5106 4.44236 15.7505 4.34962 15.9993 4.34961C16.2481 4.3496 16.4879 4.44233 16.672 4.60968L26.6727 13.7016C26.7758 13.7953 26.8582 13.9095 26.9145 14.037C26.9709 14.1644 27 14.3022 27 14.4415V25.999C27 26.1303 26.9741 26.2604 26.9239 26.3817C26.8736 26.5031 26.7999 26.6133 26.7071 26.7062C26.6142 26.799 26.5039 26.8727 26.3826 26.9229C26.2613 26.9732 26.1312 26.999 25.9999 26.999L19.9991 26.9982C19.7339 26.9982 19.4796 26.8928 19.2921 26.7053C19.1046 26.5177 18.9993 26.2634 18.9993 25.9982V25.9982Z"
      stroke="var(--brandPrimaryDark)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

import React from 'react'
import { CardAvailable } from './Cards/available'
import { CardBlocked } from './Cards/blocked'
import { CardPaid } from './Cards/paid'
import { CardProcessingPayment } from './Cards/processing-payment'
import { CardRequested } from './Cards/requested'
import { CardScheduled } from './Cards/scheduled'

export const RenderAllowance = ({ allowance, key }) => {
  const statuses = {
    blocked: <CardBlocked allowance={allowance} key={key} />,
    paid: <CardPaid allowance={allowance} key={key} />,
    paid_with_delay: <CardPaid allowance={allowance} key={key} />,
    processing_payment: <CardProcessingPayment allowance={allowance} key={key} />,
    requested: allowance.userSigned ? (
      <CardRequested allowance={allowance} key={key} />
    ) : (
      <CardAvailable allowance={allowance} key={key} />
    ),
    scheduled: <CardScheduled allowance={allowance} key={key} />
  }

  return statuses[allowance.status] || <CardAvailable allowance={allowance} key={key} />
}

import React from 'react'

export const IconCheck = () => (
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.68421 8.55556L9.52632 11.3889L19 1.94444M18.0526 9.5V16.1111C18.0526 16.6121 17.853 17.0925 17.4977 17.4468C17.1423 17.801 16.6604 18 16.1579 18H2.89474C2.39222 18 1.91029 17.801 1.55496 17.4468C1.19962 17.0925 1 16.6121 1 16.1111V2.88889C1 2.38792 1.19962 1.90748 1.55496 1.55324C1.91029 1.19901 2.39222 1 2.89474 1H13.3158"
      stroke="var(--brandPrimaryDark)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const IconCheckUpload = () => (
  <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 7.37V8C15 11.85 11.85 15 8 15C4.15 15 1 11.85 1 8C1 4.15 4.15 1 8 1C8.98 1 9.96 1.21 10.87 1.63"
      stroke="#89D747"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0004 2.3999L8.00039 9.3999L5.90039 7.2999"
      stroke="#89D747"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

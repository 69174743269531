import { PortalModal, theme } from '@provi/provi-components'
import styled from 'styled-components'

export const StyledModal = styled(PortalModal)`
  width: 496px;

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    display: block;
    width: 100%;
  }
`

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: ${theme.colors.bluePrimary};
  max-width: 427px;
  @media (max-width: 800px) {
    font-size: 16px;
  }
`

export const IconContainer = styled.div`
  cursor: pointer;
`

export const Button = styled.div`
  background: ${theme.colors.bluePrimaryLight};
  border-radius: 4px;

  margin-top: 20px;

  cursor: pointer;

  flex: none;
  order: 0;
  flex-grow: 0;

  width: 227px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;

  color: #00198a;

  flex: none;
  order: 0;
  flex-grow: 0;

  svg {
    margin-right: 8px;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  a {
    color: ${theme.colors.bluePrimary};
    font-weight: 600;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;

    a {
      order: 1;
      margin-bottom: 24px;
    }
  }
`

export const ModalBody = styled.div`
  p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13.2px;
    line-height: 20px;

    letter-spacing: 0.005em;
  }
`

import { useState, useCallback } from 'react'

export const useOption = () => {
  const [optionDescriptionsVisible, setOptionDescriptionsVisible] = useState(false)

  const handleOptionsDescription = useCallback(() => setOptionDescriptionsVisible(!optionDescriptionsVisible), [
    optionDescriptionsVisible
  ])

  return {
    optionDescriptionsVisible,
    handleOptionsDescription
  }
}

import { useEffect, useState } from 'react'

import { getProfileData, getExtraInfoCrid, getNotifications } from '../../services/api'
import { LOCALSTORAGE_TOKENS, menuRender } from '../../constants'
import { history } from '../../navigation/history'
import { setLogger } from '../../utils/logger'

/* eslint-disable react-hooks/exhaustive-deps */

export const useMenu = () => {
  const [menuActive, setMenuActive] = useState()
  const [isBack, setIsBack] = useState(false)
  const [isMenuDesktopOpened, setIsMenuDesktopOpened] = useState(true)
  return [menuActive, setMenuActive, isBack, setIsBack, isMenuDesktopOpened, setIsMenuDesktopOpened]
}

export const useProfile = (setIsLoading, setDataProfile, dataProfile, token, setTypeDash) => {
  useEffect(() => {
    const getProfile = async () => {
      try {
        setIsLoading(true)
        const storage = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
        if (storage) {
          const crid = JSON.parse(storage)
          setTypeDash(crid.productType)
        }
        const response = await getProfileData(token)
        localStorage.setItem(LOCALSTORAGE_TOKENS.email, response.email)
        localStorage.setItem(LOCALSTORAGE_TOKENS.userId, response.id)
        setIsLoading(false)
        setDataProfile(response)
      } catch (_) {}
    }
    const pathname = history.location.pathname
    const split = pathname.split('/')
    const isDashboard = split[1] === 'dashboard'
    if ((!dataProfile && isDashboard) || ['perfil', 'cancelamento'].includes(split[1])) {
      getProfile()
    }
  }, [])
}

export const useScreenMeasure = () => {
  const [dimensions, setDimensions] = useState([window.innerWidth, window.innerHeight])

  useEffect(() => {
    window.addEventListener('resize', () => setDimensions([window.innerWidth, window.innerHeight]))
    return () => {
      window.removeEventListener('resize', () => setDimensions([window.innerWidth, window.innerHeight]))
    }
  }, [])

  return [dimensions[0] <= 800]
}

export const useCrid = (setSelectCrid, setShowExtraInfo, setExtraInfos) => {
  const getExtraInfo = async crid => {
    try {
      const { data } = await getExtraInfoCrid(crid)
      setShowExtraInfo(data.showDetailedParts)
      setExtraInfos(data)
    } catch (error) {
      setLogger(error)
    }
  }
  useEffect(() => {
    const storage = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
    const crid = JSON.parse(storage)
    crid && getExtraInfo(crid.id)

    setSelectCrid(crid)
  }, [])
}

export const useMenuOptions = (typeDash, showExtraInfo, selectCrid) => {
  const [menuOptions, setMenuOptions] = useState([])

  useEffect(() => {
    setMenuOptions(
      menuRender(typeDash, selectCrid && selectCrid.showPaymentFlows).map(item => {
        if (['Panorama', 'Minhas faturas', 'Minhas mesadas'].includes(item.text) && !showExtraInfo) {
          return null
        } else {
          const newSubItems = item?.subItems?.map(subOption => subOption)
          item.subItems = newSubItems || null
          return item
        }
      })
    )
  }, [typeDash, showExtraInfo, selectCrid])

  return menuOptions
}

export const useNotifications = (setNotifications, selectCrid, alreadyGetNotifications, setAlreadyGetNotifications) => {
  useEffect(() => {
    const handleNotifications = async () => {
      const data = await getNotifications(selectCrid?.id)

      if (data.notifications) {
        setNotifications(data.notifications)
        setAlreadyGetNotifications(true)
      }
    }

    if (selectCrid?.id && !alreadyGetNotifications) handleNotifications()
  }, [selectCrid])
}

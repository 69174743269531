import moment from 'moment'
import validatorDefaultCPFs from './validatorDefaultCPFs'

export const isValidCpf = value => {
  if (!value) return false
  let sum = 0
  let rest
  let cpf = value.replace(/[^\d]+/g, '')
  if (cpf.length !== 11) return false

  if (!validatorDefaultCPFs(cpf)) return false

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (11 - i)
  }
  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(cpf.substring(9, 10), 10)) return false
  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (12 - i)
  }
  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(cpf.substring(10, 11), 10)) return false
  return true
}

export const validateBirthDate = dateStr => {
  if (!dateStr) {
    return false
  }
  if (dateStr && dateStr.split('/').length !== 3) {
    return false
  }

  const date = moment(dateStr, 'DD/MM/YYYY')
  const year = date.format('YYYY')
  const age = moment().diff(year, 'years')

  return !(age < 18 || age > 70)
}

export const validateFullName = fullName => {
  if (!fullName) {
    return false
  }

  let result = true
  fullName = fullName.trim()
  const names = fullName.split(' ')
  const firstName = names[0]
  const lastName = names.filter((_name, index) => index > 0)

  if (lastName.length === 0 || !firstName) result = false

  lastName.forEach(name => name.trim().length === 0 || null)
  return result
}

export const validatePhone = phone => {
  if (!phone) {
    return false
  }
  const _value = Number(phone.replace(/[^\d]+/g, ''))
  const regex = /^(?:(55\d{2})|\d{2})[6-9]\d{8}$/

  if (!regex.test(_value) || typeof _value !== 'number' || String(_value).length !== 11) {
    return false
  }

  return true
}

import React, { useContext, useMemo } from 'react'
import { Template } from '../../../components/template'
import { TemplateContext } from '../../../components/template/context'
import { MaxWidth } from '../../../components/max-width'
import { InvoiceCoursing } from './invoice-coursing'
import { useInvoices } from './hooks'
import { ContainerInvoices, ButtonGroup, ButtonItemRight, ButtonItemLeft, Wrapper } from './styles'

const renderInvoiceCoursing = (item, getInvoice) => {
  const obj = {
    pending: <InvoiceCoursing.CardAvailable item={item} key={item.id} getInvoice={getInvoice} />,
    paid: <InvoiceCoursing.CardPaid item={item} key={item.id} getInvoice={getInvoice} />,
    delayed: <InvoiceCoursing.CardDelayed item={item} key={item.id} getInvoice={getInvoice} />,
    locked: <InvoiceCoursing.CardLocked item={item} key={item.id} />,
    delayed_blocked: <InvoiceCoursing.CardDelayedBlocked item={item} key={item.id} />
  }
  return obj[item.status]
}

const renderButtonGroup = (handleToggle, toggle) => {
  return (
    <ButtonGroup>
      <ButtonItemLeft onClick={() => handleToggle('open')} active={toggle === 'open'}>
        Em aberto
      </ButtonItemLeft>
      <ButtonItemRight onClick={() => handleToggle('paid')} active={toggle === 'paid'}>
        Pagas
      </ButtonItemRight>
    </ButtonGroup>
  )
}

export const Invoices = () => {
  const { setMenuActive, typeDash, setIsBack, setIsLoading, selectCrid, extraInfos } = useContext(TemplateContext)

  const [data, invoice, info, toggle, handleToggle, getInvoice] = useInvoices(
    setMenuActive,
    setIsBack,
    setIsLoading,
    selectCrid,
    typeDash
  )

  const getOptions = useMemo(() => {
    if (toggle === 'paid') {
      return invoice?.filter(item => item.status === 'paid')
    } else {
      return invoice?.filter(item => item.status !== 'paid')
    }
  }, [toggle, invoice])

  const options = getOptions

  return (
    <Template>
      <MaxWidth>
        <Wrapper>
          {renderButtonGroup(handleToggle, toggle)}

          <ContainerInvoices>{options.map(item => renderInvoiceCoursing(item, getInvoice))}</ContainerInvoices>
        </Wrapper>
      </MaxWidth>
    </Template>
  )
}

import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const Text = styled.p`
  text-align: center;
  font-family: 'CenturyGothicBold';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${theme.colors.blue900};
  ${theme.breakpoints.down('md')} {
    font-size: 20px;
    line-height: 30px;
  }
`

export const ContainerMain = styled.div`
  float: none;
`

export const Search = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;

  width: 90%;
  height: 99%;

  box-sizing: border-box;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.0075em;

  color: #575757;

  border: none;

  &:focus {
    outline: none;
  }
`

export const SearchComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;

  width: 350px;
  height: 48px;

  background: #ffffff;

  border: 2px solid ${theme.colors.bluePrimaryLight};
  box-sizing: border-box;
  border-radius: 4px;

  margin: 25px 0px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    height: 40px;
    background-position: center left 10px;
  }
`

export const Icon = styled.img``

export const Title = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 31.25px;
  line-height: 40px;
  color: ${theme.colors.bluePrimary};

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 40px 0px;
`

export const WrapperButton = styled.div`
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }

  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;

  height: 25px;
  width: 184px;
  border-radius: 4px;

  background: ${theme.colors.bluePrimary};

  :hover {
    background: ${theme.colors.blueSecundaryDark};
  }

  :active {
    background: ${theme.colors.bluePrimaryDark};
  }

  margin-top: 30px;

  cursor: pointer;

  > p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.005em;

    color: #ffffff;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
  }
`

import React, { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import Content from './Content'
import { Container, Row, Collapse, IconButton, AddIcon } from './styles'

export const CollapseSection = ({
  title = '',
  content = '',
  width = '',
  isLast = false,
  iconColor = '#000000',
  titleColor = '#000000',
  active = false,
  onChange,
  variant = false
}) => {
  const [open, setOpen] = useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  useEffect(() => {
    if (onChange && open) {
      onChange()
    }
  }, [open])

  useEffect(() => {
    setOpen(active)
  }, [active])

  return (
    <Container onClick={handleClick} containerWidth={width} isLast={isLast} variant={variant}>
      <Row aria-label={title} titleColor={titleColor} className="collapse-row">
        {title}
        <IconButton aria-label={`expandir o conteúdo ${title}`} size="small" style={{ color: iconColor }}>
          <AddIcon style={{ transform: open ? 'rotate(45deg)' : 'rotate(0deg)', transition: 'all 0.192s ease' }} />
        </IconButton>
      </Row>
      <Collapse in={open} timeout="auto" style={{ width: '80%' }}>
        {content.map(text => (
          <Content key={`collapse_content_${v4()}`} text={text} />
        ))}
      </Collapse>
    </Container>
  )
}

import axios from 'axios'
import * as Sentry from '@sentry/browser'
import { showToast } from '@provi/provi-components'
import { v4 as uuidv4 } from 'uuid'

import firebase from '../services/firebase'
import { LOCALSTORAGE_TOKENS, ROOT_URL } from '../constants'
import { history } from '../navigation/history'

import { logger, setLogger } from '../utils/logger'

const api = axios.create({
  baseURL: ROOT_URL.dashboard
})

const transactionId = uuidv4().replace(/-/g, '')

export const logout = expired => {
  Sentry.configureScope(scope => scope.setUser(null))
  if (expired) {
    const AllStorage = Object.keys(localStorage)
    AllStorage.forEach(item => {
      if (item !== LOCALSTORAGE_TOKENS.email) {
        localStorage.removeItem(item)
      }
    })
    history.push('/token-invalido')
  } else {
    localStorage.clear()
    window.location.href = 'https://provi.com.br'
  }
}

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKENS.token)
    if (token) {
      Sentry.configureScope(function(scope) {
        scope.setTag('x-request-id', transactionId)
      })
      config.headers['x-request-id'] = transactionId
      config.headers.Authorization = token
      setLogger()
    } else {
      logout(true)
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status !== 401) {
      logger(error)
    }
    return Promise.reject(error)
  }
)

export const getProfileData = (_token, crid = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = crid ? `/profile/${crid}` : '/profile'

      const { data } = await api.get(url)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getFinancing = async token => {
  return new Promise(async (resolve, reject) => {
    try {
      api.defaults.headers.common['Authorization'] = token
      const { data } = await api.get('/financing')
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getFinancingUnique = async id => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/financing/${id}`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getFinancingContracts = id => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/financing/${id}/contracts`)
      resolve(data)
    } catch (err) {
      reject(err)
    }
  })
}

export const getFinancingInvoices = id => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/financing/${id}/invoices`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getDashboard = id => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/dashboard/${id}`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const allowanceSendPhoto = (crid, LoanOptionsIds, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post(`/provi-sign/${crid}/selfie`, { data, LoanOptionsIds })
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getInvoiceUnique = ({ PaymentFlowIds }) => {
  const storage = JSON.parse(localStorage.getItem('CRID'))
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post('/billets/create', { PaymentFlowIds, CreditRequestId: storage && storage.id })
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getDuplicateInvoice = ({ InvoiceId }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post('/billets/duplicate', { InvoiceId })
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const uploadImages = async array => {
  let result = []
  await firebase.auth().signInAnonymously()
  const storage = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
  const crid = JSON.parse(storage).id
  if (array.length > 0) {
    try {
      await Promise.all(
        array.map(async item => {
          const now = new Date().getTime()
          const storagePath = `/${crid}/${now}`
          const uploadRef = firebase.storage().ref(storagePath)
          await uploadRef.put(item)
          const url = await uploadRef.getDownloadURL()
          result.push(url)
        })
      )
    } catch (err) {
      if (err?.response?.data?.message) {
        Sentry.addBreadcrumb({
          category: 'ErrorRequest',
          message: 'ERROR INFO ' + err,
          level: Sentry.Severity.Warning
        })
      }
      showToast()
    }
  }
  return result
}

export const getDeclarationsIsa = async id => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/isa/${id}`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getSelfie = async id => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/selfies/${id}`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getStatusContract = async envelopeId => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.get(`${ROOT_URL.docsign}/v1/user/contract-status/${envelopeId}`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const saveBankAccount = async body => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.post('/profile/bank-account', body)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getExtraInfoCrid = async crid => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.get(`/financing/${crid}/extra-infos`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getIncomeTaxUrl = async crid => {
  try {
    const { data } = await api.get(`/financing/${crid}/income-tax`)

    return data
  } catch (err) {
    Sentry.addBreadcrumb({
      category: 'ErrorRequest',
      message: 'ERROR INFO ' + err,
      level: Sentry.Severity.Warning
    })
  }
}

/**
 * @name getSimulationUpgrade
 * @param {string} crid
 */
export const getSimulationUpgrade = async crid => {
  try {
    return api.get(`provi-sign/upgrade-allowance/${crid}/simulation`)
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'ErrorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning
    })
    throw error
  }
}

/**
 * @name getFinancingSimulation
 * @param {string} crid
 */
export const getFinancingSimulation = async crid => {
  try {
    return api.get(`provi-sign/change-financing/${crid}/simulation`)
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'ErrorRequest',
      message: `ERROR INFO ${error}`,
      level: Sentry.Severity.Warning
    })
    throw error
  }
}

/**
 * @name notifyAdditionalAllowance
 * @param {string} crid
 */
export const notifyAdditionalAllowance = async crid => {
  try {
    return api.post(`provi-sign/upgrade-allowance/${crid}/notify`)
  } catch (err) {
    Sentry.addBreadcrumb({
      category: 'ErrorRequest',
      message: `ERROR INFO ${err}`,
      level: Sentry.Severity.Warning
    })
    throw err
  }
}

/**
 * @name upgradeNewAllowance
 * @param {string} crid
 */
export const upgradeNewAllowance = async crid => {
  try {
    return api.post(`/provi-sign/upgrade-allowance/${crid}`)
  } catch (err) {
    Sentry.addBreadcrumb({
      category: 'ErrorRequest',
      message: 'ERROR INFO ' + err,
      level: Sentry.Severity.Warning
    })
    throw err
  }
}

/**
 * @name changeFinancingOption
 * @param {string} crid
 * @param {number} optionId
 * @param {boolean} createRefinContract
 */
export const changeFinancingOption = async ({ crid, optionId, createRefinContract }) => {
  try {
    const endpoint = createRefinContract
      ? '/provi-sign/create-refin-contract/renegotiation'
      : '/provi-sign/update-financing/renegotiation'

    return api.post(endpoint, {
      RefinSimulationResponseId: optionId,
      CreditRequestId: crid
    })
  } catch (err) {
    Sentry.addBreadcrumb({
      category: 'ErrorRequest',
      message: 'ERROR INFO ' + err,
      level: Sentry.Severity.Warning
    })
    throw err
  }
}

/**
 * @name hasAllowanceUpgradeInProgress
 * @param {number} CreditRequestId
 */
export const hasAllowanceUpgradeInProgress = async CreditRequestId => {
  try {
    return api.get(`/in-progress/upgrade/${CreditRequestId}`)
  } catch (err) {
    Sentry.addBreadcrumb({
      category: 'ErrorRequest',
      message: 'ERROR INFO ' + err,
      level: Sentry.Severity.Warning
    })
    throw err
  }
}

export const resMedPurchaseResume = crid => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/course-resume/${crid}`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const resMedPurchaseInstallments = crid => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/resmed-installment/${crid}`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const resMedSanarPurchase = data => {
  return new Promise(async (_, reject) => {
    try {
      await api.post(`/sanar-purchase/${data.crid.id}`, data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getResMedPurchaseAddress = crid => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/get/address/${crid}`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const updateResMedPurchaseAddress = ({ crid, data }) => {
  return new Promise(async (resolve, reject) => {
    const { street, complement, district, city, state, number, zipcode } = data
    try {
      const response = await api.post(`update/address/${crid}`, {
        street,
        complement,
        neighborhood: district,
        city,
        state,
        number,
        zipcode
      })
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const validateLinkedInRegistration = async () => {
  const crid = JSON.parse(localStorage.getItem('CRID'))

  if (crid?.isISA) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await api.get(`/profile/${crid.id}/linkedIn`)
        resolve(data)
      } catch (err) {
        Sentry.addBreadcrumb({
          category: 'ErrorRequest',
          message: `ERROR INFO ${err}`,
          level: Sentry.Severity.Warning
        })
        reject(err)
      }
    })
  }
}

export const insertLinkedIn = async ({ linkedInUrl, createdInUserDashboard }) => {
  const crid = JSON.parse(localStorage.getItem('CRID'))

  if (crid?.isISA) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await api.post(`/profile/${crid.id}/linkedIn`, {
          linkedInUrl,
          createdInUserDashboard
        })
        resolve(data)
      } catch (err) {
        Sentry.addBreadcrumb({
          category: 'ErrorRequest',
          message: `ERROR INFO ${err}`,
          level: Sentry.Severity.Warning
        })
        reject(err)
      }
    })
  }
}

export const changeProfileSelfie = async photoUrl => {
  const token = localStorage.getItem(LOCALSTORAGE_TOKENS.token)
  return new Promise(async (resolve, reject) => {
    try {
      api.defaults.headers.common['Authorization'] = token
      const data = await api.post('/profile/selfie', {
        data: photoUrl
      })
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: `ERROR INFO ${err}`,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getToken = async (CreditRequestId, metadata = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { ProviContractId } = metadata
      const data = await api.get(`/provi-sign/${CreditRequestId}/generate/token${ProviContractId ? `/${ProviContractId}` : ''}`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: `ERROR INFO ${err}`,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const getNotifications = async CreditRequestId => {
  try {
    const { data } = await api.get(`/dashboard/notifications/${CreditRequestId}`)

    return data
  } catch (err) {
    Sentry.addBreadcrumb({
      category: 'ErrorRequest',
      message: `ERROR INFO ${err}`,
      level: Sentry.Severity.Warning
    })

    return err
  }
}

export const getReceiptTerm = async crid => {
  try {
    const { data } = await api.post(`/receipt/term/${crid}`, {})

    return data
  } catch (err) {
    logger(err)
  }
}

export const getTypeformFlag = async (formId, CreditRequestId) => {
  try {
    const path = CreditRequestId ? `/research/${formId}/${CreditRequestId}` : `/research/${formId}`

    const { data } = await api.get(path)
    return data
  } catch (err) {
    logger(err)
  }
}

export const getReasons = async CreditRequestId => {
  try {
    const { data } = await api.get(`/cancellation/reasons/${CreditRequestId}`)

    return data
  } catch (err) {
    logger(err)
  }
}

export const getFAQ = async CreditRequestId => {
  try {
    const { data } = await api.get(`/faq/${CreditRequestId}`)

    return data
  } catch (err) {
    logger(err)
  }
}

export const getCancellation = async CreditRequestId => {
  try {
    const { data } = await api.get(`/cancellation/${CreditRequestId}`)
    return data
  } catch (error) {
    logger(error)
  }
}

export const cancellationUpdateRequest = (CreditRequestId, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.put(`/cancellation/${CreditRequestId}`, data)
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const createCancellation = async (crid, body) => {
  try {
    return await api.post(`/cancellation/${crid}`, body)
  } catch (err) {
    Sentry.addBreadcrumb({
      category: 'ErrorRequest',
      message: 'ERROR INFO ' + err,
      level: Sentry.Severity.Warning
    })
    throw err
  }
}

export const getRefundSummary = async crid => {
  try {
    const { data } = await api.get(`/cancellation/refund-summary/${crid}`)
    return data
  } catch (error) {
    logger(error)
  }
}

import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'

export const Container = styled.div`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ containerWidth }) => containerWidth || '100%'};
  border-top: 2px solid ${({ variant }) => (variant ? theme.colors.bluePrimaryDark : theme.colors.bluePrimary)};
  color: #000000;
  cursor: pointer;
  padding-bottom: 14px;

  ${({ isLast, variant }) =>
    isLast &&
    css`
      border-bottom: 2px solid ${variant ? theme.colors.bluePrimaryDark : theme.colors.bluePrimary};
    `}

  ${theme.breakpoints.down('md')} {
    padding-bottom: 24px;

    .MuiCollapse-entered {
      width: 100% !important;
    }
  }

  ${theme.breakpoints.up('md')} {
    width: 80%;
  }
`

export const Row = styled.div`
  display: inline-flex;
  justify-content: space-between;
  justify-self: center;
  align-items: flex-start;
  margin-top: 18px;

  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.005em;
  color: ${({ titleColor }) => titleColor || '#000000'};

  ${theme.breakpoints.down('md')} {
    margin-top: 8px;
    font-size: 15.84px;
    line-height: 24px;
  }
`

export const Content = styled.div`
  width: 100%;
  margin-top: 25px;

  ${theme.breakpoints.down('md')} {
    margin-top: 11px;
    font-size: 13.2px;
    line-height: 20px;
    letter-spacing: 0.005em;
  }

  > p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0075em;
    color: #000000;

    & > a {
      margin-right: 5px;
      color: #2d59d7;
      font-weight: 600;
      text-decoration: underline;
    }
  }
`

export { Collapse, IconButton, AddIcon }

import { ButtonV3 } from '@provi/provi-components'
import React from 'react'
import * as S from './styles'
import ErrorMsg from './errorMsg'
import { URL_EXTERNAL } from '../../../../constants'

export const alreadyTryFiveTimes = 'Limite máximo de 5 tentativas de registro de contas bancárias atingido'
export const alreadyTryTenTimes = 'Limite máximo de 10 contas bancárias cadastradas atingido'
const alreadyExists = 'Já existe uma conta com esses parâmetros'

const ModalMessage = ({ errMsg, closeModal, setErrMsg }) => {
  const modals = {
    [alreadyTryTenTimes]: {
      children: ErrorMsg.tryTenTimes,
      showButton: true,
      buttonLabel: 'Falar com atendimento',
      onClick: () => {
        window.location.href = URL_EXTERNAL
      }
    },
    [alreadyExists]: {
      title: 'Ops! Você já tentou estes dados 🤔',
      children: ErrorMsg.alreadyExists,
      showButton: true,
      buttonLabel: 'Revisar dados',
      onClick: () => {
        setErrMsg()
        return closeModal()
      }
    },
    [alreadyTryFiveTimes]: {
      children: ErrorMsg.tryFiveTimes
    }
  }

  return (
    <S.ModalContainer>
      <S.ModalTitle>{modals[errMsg]?.title || 'Ops! Algo deu errado 🤔'}</S.ModalTitle>
      {modals[errMsg]?.children || ErrorMsg.defaultMessage}
      <S.ModalContent>
        {modals[errMsg]?.showButton && (
          <ButtonV3
            color="light"
            height="40px"
            mobileWidth="100%"
            onClick={modals[errMsg]?.onClick}
            text={modals[errMsg]?.buttonLabel}
            width="122px"
          />
        )}
        {!modals[errMsg] && (
          <ButtonV3
            color="light"
            height="40px"
            mobileWidth="100%"
            onClick={() => {
              setErrMsg()
              return closeModal()
            }}
            text="Revisar dados"
            width="122px"
          />
        )}
      </S.ModalContent>
    </S.ModalContainer>
  )
}

export default ModalMessage

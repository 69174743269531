import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: ${({ width }) => width};
  height: 50px;
  background: ${({ isDisabled }) => (isDisabled ? '#CFCFCF' : theme.colors.bluePrimary)};
  border-radius: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;

  @media (max-width: 800px) {
    width: ${({ mobileWidth }) => mobileWidth};
  }
`

export const LoadingBar = styled.div`
  width: ${({ loadingPercent }) => loadingPercent + '%'};
  height: 50px;
  background: ${theme.colors.bluePrimaryDark};
  transition: 0.3s;
`

export const TextWrapper = styled.div`
  width: 100%;
  height: 50px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isDisabled }) => (isDisabled ? '#575757' : '#ffffff')};
  font-weight: 600;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.4s;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  &:active {
    background: #fff;
    color: ${theme.colors.bluePrimaryDark};
  }
`

import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'

export const Card = styled.div`
  background: #fff;
  padding: 25px;

  box-shadow: 0px 4px 24px rgba(0, 11, 60, 0.1);
  border-radius: 8px;
  margin: 15px 0;

  @media (max-width: 330px) {
    padding: 15px;
  }

  @media (min-width: 800px) {
    width: 90%;
  }
`

const textStyle = css`
  color: ${theme.colors.blue900};
  font-family: 'Montserrat', sans-serif;
`

export const SmallText = styled.p`
  ${textStyle}
  font-size: 18px;
  line-height: 24px;

  max-width: 800px;

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-width: 330px) {
    font-size: 12px;
  }
`

export const LargeText = styled.p`
  ${textStyle}
  font-size: 22px;
  line-height: 28px;

  @media (max-width: 425px) {
    font-size: 18px;
  }

  @media (max-width: 330px) {
    font-size: 16px;
  }
`

export const Title = styled.h2`
  ${textStyle}
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;

  @media (max-width: 425px) {
    font-size: 20px;
  }

  @media (max-width: 330px) {
    font-size: 18px;
  }
`

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  width: 100%;

  & > div + div {
    margin-right: 25px;
  }

  @media (max-width: 800px) {
    flex-direction: column;

    & > div + div {
      margin-right: 0px;
      margin-top: 20px;
    }
  }

  @media (max-width: 425px) {
    width: 100%;
    margin: 0;
    justify-content: space-evenly;
    flex-direction: column;
  }
`

export const AltLink = styled.a`
  color: ${theme.colors.bluePrimary};
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 17px;
  text-decoration: none;
  margin: 15px 25px;
`

/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import Webcam from 'react-webcam'
import { Modal, Button } from '@provi/provi-components'
import { TemplateContext } from '../../components/template/context'
import { IconCheckUpload } from '../../assets/svg/icon-check'
import { IconUpload } from '../../assets/svg/upload'
import {
  Container,
  Image,
  Text,
  IconContainer,
  Wrapper,
  Preview,
  WrapperButton,
  WrapperWebcam,
  ContainerComplete,
  ContainerUploadInfo,
  TitleUploadInfo,
  LinkText
} from './styles'
import { useUpload } from './hooks'

export const WebcamWrapper = props => {
  const { setIsLoading } = useContext(TemplateContext)
  const { img, disabled, filesNotUpload, setFilesNotUpload, setIsButtonDisabled, setIsWebcam, file, setPreview } = props

  const [
    handleImage,
    image,
    webcamRef,
    videoConstraints,
    status,
    isButtonModalDisabled,
    completeClick,
    takeNewPhoto,
    isWebcamModal,
    setIsWebcamModal
  ] = useUpload(filesNotUpload, setIsLoading, setFilesNotUpload, setIsButtonDisabled, setIsWebcam, setPreview)
  if (disabled) {
    return (
      <Container disabled>
        <Image style={{ zIndex: 0 }} src={img} margin />
        <Text style={{ zIndex: 1 }}>Tirar foto</Text>
        <IconContainer>
          <IconUpload />
        </IconContainer>
      </Container>
    )
  }

  return (
    <>
      {isWebcamModal && (
        <Modal title="Tirar foto" onDismiss={() => setIsWebcamModal(false)}>
          <Wrapper>
            <WrapperWebcam>
              {!image && (
                <Webcam
                  audio={false}
                  minScreenshotHeight={720}
                  minScreenshotWidth={1280}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
              )}
              {image && <Preview src={image} />}
            </WrapperWebcam>
            <WrapperButton>
              <Button
                text={image ? 'Enviar foto' : 'Tirar foto'}
                disabled={isButtonModalDisabled}
                onClick={handleImage}
                textLink={{
                  text: image ? 'Enviar outra foto?' : '',
                  onClick: () => takeNewPhoto()
                }}
              />
            </WrapperButton>
          </Wrapper>
        </Modal>
      )}
      {status !== 'complete' ? (
        <Container onClick={() => setIsWebcamModal(true)}>
          <Image style={{ zIndex: 0 }} src={img} margin />
          <Text style={{ zIndex: 1 }}>Tirar foto</Text>
          <IconContainer>
            <IconUpload />
          </IconContainer>
        </Container>
      ) : (
        <ContainerComplete file={file} onClick={() => completeClick()}>
          <IconContainer>
            <IconUpload />
          </IconContainer>
          <ContainerUploadInfo>
            <TitleUploadInfo>
              Upload concluído <IconCheckUpload />
            </TitleUploadInfo>
            <LinkText>Tirar outra</LinkText>
          </ContainerUploadInfo>
        </ContainerComplete>
      )}
    </>
  )
}

import React from 'react'
import { currencyParser } from '../../../../utils'
import { ContainerBox, Label, Value } from './stylesV2'

export const CardIsa = ({ data }) => (
  <ContainerBox>
    <Label>
      Valor total: <Value>{currencyParser(data.totalValue || 0, 'R$')}</Value>
    </Label>
    <Label>
      Valor pago: <Value>{currencyParser(data.paidValue || 0, 'R$')}</Value>
    </Label>
    <Label>
      Valor restante: <Value>{currencyParser(data.remainingValue || 0, 'R$')}</Value>
    </Label>
  </ContainerBox>
)

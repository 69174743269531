import React, { useContext } from 'react'

import { TemplateContext } from '../template/context'
import { CrossIcon } from '../../assets/svg/CrossIcon'

import * as S from './styles'

export const PopoverMessage = ({ children, message, position = 'right', show, onDismiss, mobilePosition = 'top' }) => {
  const { isMobile } = useContext(TemplateContext)

  const positionHandler = {
    right: <S.ArrowLeft />,
    top: <S.ArrowDown />
  }

  if (isMobile) {
    position = mobilePosition
  }

  return (
    <S.Container position={position}>
      {children}
      <S.PopoverAnchor position={position} show={show}>
        <S.Wrapper position={position}>
          {positionHandler[position]}
          <S.Popover>
            <S.Text>{message}</S.Text>
            <S.CrossIconWrapper onClick={onDismiss}>
              <CrossIcon />
            </S.CrossIconWrapper>
          </S.Popover>
        </S.Wrapper>
      </S.PopoverAnchor>
    </S.Container>
  )
}

import { createElement, useCallback } from 'react'
const { LOCALSTORAGE_TOKENS } = require('../../../constants')

export const useContent = () => {
  const handleInsideLink = useCallback(string => {
    if (!string.includes('link#')) return createElement('p', {}, string)

    const stringArr = string.split(' ')

    const regex = /link#/g

    const newMessageArr = stringArr.reduce((previousValue, currentValue) => {
      if (regex.test(currentValue)) {
        const [, word, link] = currentValue.split('#')

        const linkElement = createElement('a', { href: link }, word)

        return previousValue.concat(linkElement)
      }

      previousValue.push(currentValue + ' ')
      return previousValue
    }, [])

    return createElement('p', {}, newMessageArr)
  }, [])

  const insertDynamicInfos = useCallback(text => {
    const storage = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)

    const crid = JSON.parse(storage)

    const infos = {
      '{partnerContact}': crid?.partnerContact,
      '{partnerName}': crid?.partner
    }

    const arrayText = text.split(' ')

    return arrayText
      .map(word => {
        if (infos[word]) return infos[word]

        return word
      })
      .join(' ')
  }, [])

  return { handleInsideLink, insertDynamicInfos }
}

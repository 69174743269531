import React from 'react'
import { currencyParser } from '../../../../utils'
import * as S from '../styles'

export const Row = ({ length, index, row, text }) => {
  const isLast = length - 1 === index

  return (
    <S.Row showRadius={isLast}>
      <S.RowItem showRadius={isLast} position="left">
        {text} {row.number}
      </S.RowItem>
      <S.RowItem>{currencyParser(row.value, 'R$ ')}</S.RowItem>
      <S.RowItem showRadius={isLast} position="right">
        {row.date}
      </S.RowItem>
    </S.Row>
  )
}

import React from 'react'

export const IconWhatsApp = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.625 15.25C11.375 17.75 13.5 19.625 15.75 20.5C17.875 21.375 18.75 21.5 19.25 21.5C19.5 21.5 19.625 21.5 19.75 21.375C19.875 21.375 19.875 21.375 20 21.375C20.625 21.375 22.25 20.5 22.5 19.625C22.75 18.875 22.875 18 22.75 17.75C22.625 17.625 22.5 17.5 22.25 17.375L22 17.25C21.625 17.125 19.875 16.25 19.5 16.125C19.25 16 18.875 15.875 18.5 16.375C18.25 16.75 17.625 17.5 17.375 17.75C17.25 17.875 17.125 18 16.75 17.75C16.625 17.75 16.625 17.625 16.5 17.625C16 17.375 15 17 13.875 16C12.875 15.125 12.125 13.875 11.875 13.625C11.75 13.375 11.875 13.25 12 13.125C12.125 13 12.25 12.875 12.375 12.75C12.375 12.625 12.5 12.625 12.5 12.5C12.625 12.25 12.75 12.125 12.875 12V11.875C13 11.625 12.875 11.25 12.875 11.125C12.875 11 12.5 10.25 12.25 9.625C12.375 9.25 12.125 8.875 12 8.5C11.75 7.875 11.5 7.875 11.125 7.875C11 7.875 10.875 7.875 10.75 7.875C10.625 7.875 10.5 7.875 10.375 7.875C10.25 7.875 9.75 7.875 9.25 8.375C8.875 8.75 8 9.625 8 11.375C8.125 13 9.25 14.625 9.625 15.25Z"
      fill="#89D747"
    />
    <path
      d="M8.25 27.375C10.375 28.5 12.875 29.125 15.25 29.125C23.375 29.125 30 22.625 30 14.5C30 6.5 23.375 0 15.375 0C7.25 0 0.625 6.5 0.625 14.625C0.625 17.25 1.375 19.875 2.75 22.125L0 30L8.25 27.375ZM5.5 21.625C4 19.625 3.25 17.125 3.25 14.625C3.25 8 8.625 2.625 15.25 2.625C18.5 2.625 21.5 3.875 23.75 6.125C26 8.375 27.25 11.375 27.25 14.625C27.25 21.25 21.875 26.625 15.25 26.625C12.875 26.625 10.625 26 8.625 24.625L4.125 26L5.5 21.75V21.625Z"
      fill="#89D747"
    />
  </svg>
)

import React from 'react'

export const IconMail = () => (
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.81818 22H24.1818C26.3455 22 28 20.4111 28 18.3333V3.66667C28 1.58889 26.3455 0 24.1818 0H3.81818C1.65455 0 0 1.58889 0 3.66667V18.3333C0 20.4111 1.65455 22 3.81818 22ZM24.1818 19.5556H3.81818C3.05455 19.5556 2.54545 19.0667 2.54545 18.3333V5.98889L13.2364 13.2C13.4909 13.3222 13.7455 13.4444 14 13.4444C14.2545 13.4444 14.5091 13.3222 14.7636 13.2L25.4545 5.98889V18.3333C25.4545 19.0667 24.9455 19.5556 24.1818 19.5556ZM3.81818 2.44444H24.1818C24.6909 2.44444 25.0727 2.68889 25.3273 3.17778L14 10.7556L2.67273 3.17778C2.92727 2.68889 3.30909 2.44444 3.81818 2.44444Z"
      fill="#5CB5FE"
    />
  </svg>
)

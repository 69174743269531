import React from 'react'
import * as S from '../../styles'
import { IconStatusAwaiting } from '../../../../../assets/svg/status-awaiting'
import { AllowanceIconCheck } from '../../../../../assets/svg/allowance-icon-check'

export const CardRequested = ({ allowance, key }) => {
  return (
    <>
      <S.Card requested key={key}>
        <S.CardHeader>
          <S.MonthAllowance>Mesada de {allowance.formatDate}</S.MonthAllowance>
          <S.NumberAllowance>
            {allowance.number} de {allowance.amountOfItems}
          </S.NumberAllowance>
        </S.CardHeader>

        <S.WrapperCard>
          <S.WrapperItem>
            <p>Previsão de depósito</p>
            <p>{allowance.scheduledDate}</p>
          </S.WrapperItem>
        </S.WrapperCard>

        <S.WrapperCard>
          <S.WrapperStatusItem>
            Selfie {allowance.userSentSelfie ? <AllowanceIconCheck /> : <IconStatusAwaiting />}
          </S.WrapperStatusItem>
        </S.WrapperCard>

        {allowance.hasGuarantor && (
          <S.WrapperCard>
            <S.WrapperStatusItem>
              Assinatura avalista {allowance.guarantorSigned ? <AllowanceIconCheck /> : <IconStatusAwaiting />}
            </S.WrapperStatusItem>
          </S.WrapperCard>
        )}

        <S.WrapperCard>
          <S.WrapperItem>
            <p>Caso a data de pagamento caia no final de semana ou feriado o pagamento será realizado no próximo dia útil.</p>
          </S.WrapperItem>
        </S.WrapperCard>

        <S.FooterCard>
          <S.ButtonFooterCardRequested>
            Solicitação em andamento <IconStatusAwaiting />
          </S.ButtonFooterCardRequested>
        </S.FooterCard>
      </S.Card>
    </>
  )
}

import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${theme.colors.blue900};
  position: relative;
`

export const ContainerLogin = styled.div`
  width: 65%;
  height: 100vh;
  background-color: ${theme.colors.blue900};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
`

export const Container = styled.div`
  position: relative;
  width: 50%;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const Title = styled.h4`
  font-size: 48px;
  color: ${theme.colors.white100};
  margin: 20px 0;
  font-family: CenturyGothicBold, sans serif;

  ${theme.breakpoints.down('md')} {
    font-size: 24px;
  }
`

export const SubTitle = styled.p`
  color: ${theme.colors.white100};
  font-size: 20px;
  line-height: 30px;
  font-family: CenturyGothic, sans serif;
  ${({ margin }) => margin && 'margin: 0'};
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

import { useEffect, useState } from 'react'
import { showToast } from '@provi/provi-components'
import { history } from '../../../navigation/history'
import { uploadImages, allowanceSendPhoto, getSelfie } from '../../../services/api'
import { validateSigner } from '../../../services/provi-sign'
import { LOCALSTORAGE_TOKENS } from '../../../constants'
import { logger } from '../../../utils/logger'

export const useSelfie = (setIsLoading, props, setObjectValidation, objectValidation, setTypeUser) => {
  const [filesNotUpload, setFilesNotUpload] = useState([])
  const [preview, setPreview] = useState(undefined)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [loanOptionIds, setLoanOptionIds] = useState([])
  const [isWebcam, setIsWebcam] = useState(false)
  const [numberOfFiles, setNumberOfFiles] = useState(0)
  const [numberOfFilesInitial, setNumberOfFilesInitial] = useState(0)

  useEffect(() => {
    if (filesNotUpload.length > 0) {
      setIsButtonDisabled(false)
    }

    const loanOptions = localStorage.getItem(LOCALSTORAGE_TOKENS.loan_option_ids)

    if (loanOptions !== null) {
      return setLoanOptionIds(JSON.parse(loanOptions))
    }
  }, [filesNotUpload, props])

  useEffect(() => {
    setTypeUser('user')
    localStorage.setItem(LOCALSTORAGE_TOKENS.type, 'user')
    const getData = async () => {
      setIsLoading(true)
      try {
        const token = localStorage.getItem(LOCALSTORAGE_TOKENS.token)
        const loanOptionIds = localStorage.getItem(LOCALSTORAGE_TOKENS.loan_option_ids)
        const crid = JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKENS.crid))
        const { data } = await validateSigner(token, crid, 'user')
        setObjectValidation(data)
        const response = await getSelfie(JSON.parse(loanOptionIds)[0])
        if (response) {
          setNumberOfFiles(1)
          setNumberOfFilesInitial(1)
          setPreview(response)
          setIsButtonDisabled(false)
        }
      } catch (exception) {
        logger({ error: exception })
        showToast()
      } finally {
        setIsLoading(false)
      }
    }

    getData()
  }, [setIsLoading])

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      const crid =
        (objectValidation && objectValidation.CreditRequestId) || JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKENS.crid)).id
      const urls = await uploadImages(filesNotUpload)
      await allowanceSendPhoto(crid, loanOptionIds, urls[0] || preview)

      if (objectValidation && objectValidation.phoneVerification) {
        history.push('/provi-sign/confirmacao-telefone')
      } else {
        history.push('/provi-sign/termos-contrato')
      }
    } catch (exception) {
      logger({ error: exception })
      showToast()
    } finally {
      setIsLoading(false)
    }
  }

  return [
    preview,
    onSubmit,
    isButtonDisabled,
    numberOfFiles,
    filesNotUpload,
    setFilesNotUpload,
    setIsButtonDisabled,
    setIsWebcam,
    isWebcam,
    setPreview,
    setNumberOfFiles,
    numberOfFilesInitial
  ]
}

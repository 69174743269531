import React from 'react'
import { Add, Close } from '@material-ui/icons'

import { useOption } from './hooks'

import {
  Container,
  Content,
  Left,
  ToggleButtonOptionDescription,
  OptionDescriptionContainer,
  CheckBox,
  SelectedBox
} from './styles'

export const Option = ({ option, handlerSelectOption }) => {
  const { optionDescriptionsVisible, handleOptionsDescription } = useOption()

  return (
    <>
      <Container selected={option.active}>
        <Content>
          <Left>
            <div id="checkBoxContainer" onClick={() => handlerSelectOption(option)}>
              <CheckBox>{option.active && <SelectedBox />}</CheckBox>
            </div>
            <strong>{option.displayName}</strong>
          </Left>

          <ToggleButtonOptionDescription>
            {optionDescriptionsVisible ? (
              <button type="button" onClick={handleOptionsDescription}>
                <Close />
              </button>
            ) : (
              <button type="button" onClick={handleOptionsDescription}>
                <Add />
              </button>
            )}
          </ToggleButtonOptionDescription>
        </Content>

        {optionDescriptionsVisible && (
          <OptionDescriptionContainer>
            <p>{option.description}</p>
          </OptionDescriptionContainer>
        )}
      </Container>
    </>
  )
}

import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import bg_dark_desktop from '../../assets/images/bg-dark-declaration.png'

export const View = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const Left = styled.div`
  width: 65%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const Right = styled.div`
  width: 35%;
  height: 100vh;
  background-color: ${theme.colors.white100} !important;
  background: url(${bg_dark_desktop});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100%;
  transform: translate(1px, 1px);
  position: fixed;
  right: 0;
`

export const TextModal = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0;
  color: ${theme.colors.blue900};

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;
  }
`

export const ButtonBackDesktop = styled.div`
  /* height: 100%; */
  /* position: fixed; */
  margin-top: 90px;
  margin-left: 63px;

  ${theme.breakpoints.down('md')} {
    margin-left: 24px;
  }

  #desktopBackButton {
    display: flex;
    /* margin-top: 113px; */
    align-items: center;

    button {
      margin: 0;
      padding: 0;
      background: transparent;
      cursor: pointer;
      border: none;

      svg {
        width: 38px;
        height: 38px;
      }
    }

    p {
      font-family: 'CenturyGothic', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      margin-left: 53px;
    }

    ${theme.breakpoints.down('md')} {
      flex-direction: column;
      align-items: flex-start;

      p {
        padding: 0;
        margin: 0;
        margin-top: 12px;
      }

      button {
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
`

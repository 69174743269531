import React from 'react'
import * as S from './styles'
import { currencyParser } from '../../../../utils'

const SummaryStarkbank = ({ data, refundSummary }) => {
  return (
    <S.CardRequestValueContainer>
      <S.CardRequestAccount>
        <span>Valor: </span>
        {currencyParser(refundSummary?.refundAmount, 'R$ ')}
      </S.CardRequestAccount>
      <S.CardRequestAccount>
        <span>Tipo de conta:</span>
        {data?.bankAccount.typeOfAccount.label}
      </S.CardRequestAccount>
      <S.CardRequestAccount>
        <span>Banco:</span>
        {data?.bankAccount.bank.label}
      </S.CardRequestAccount>
      <S.CardRequestAccount>
        <span>Agência: </span>
        {data?.bankAccount.agency}
      </S.CardRequestAccount>
      <S.CardRequestAccount>
        <span>Conta: </span>
        {data?.bankAccount.account}
      </S.CardRequestAccount>
    </S.CardRequestValueContainer>
  )
}

export default SummaryStarkbank

import { useEffect, useState } from 'react'
import { showToast } from '@provi/provi-components'
import { cancellationUpdateRequest, getCancellation } from '../../services/api'
import { logger } from '../../utils/logger'

export const useRequests = ({ cridInfo, closeModalGiveup }) => {
  const [cancellationData, setCancellationData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCancellationData, setIsLoadingCancellationData] = useState(false)
  const objRefundType = {
    wirecard: cridInfo?.productType ==='Pix' ? 'Reembolso por estorno na conta utilizada para pagamento' : 'Reembolso por estorno no cartão de crédito',
    starkbank: 'Reembolso por depósito em conta',
    'ordem de pagamento': ' Reembolso por ordem de pagamento'
  }

  const getCancelationData = async () => {
    try {
      setIsLoadingCancellationData(true)
      const data = await getCancellation(cridInfo.id)
      setCancellationData(data)
      setIsLoadingCancellationData(false)
    } catch (error) {
      showToast()
      setIsLoadingCancellationData(false)
    }
  }

  useEffect(() => {
    getCancelationData()
  }, [cridInfo])

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      if (cridInfo.id) {
        await cancellationUpdateRequest(cridInfo.id, {
          status: 'canceled',
          studentAnswer: true
        })

        if (cancellationData) {
          setCancellationData({
            ...cancellationData,
            cancellationRequest: {
              ...cancellationData.cancellationRequest,
              status: 'canceled'
            }
          })
        }

        setIsLoading(false)
        closeModalGiveup()
      }
    } catch (error) {
      logger(error)
      showToast()
      setIsLoading(false)
    }
  }

  return {
    objRefundType,
    onSubmit,
    cancellationData,
    isLoading,
    isLoadingCancellationData
  }
}

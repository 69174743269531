import { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { showToast } from '@provi/provi-components'
import { LOCALSTORAGE_TOKENS } from '../../constants'
import { history } from '../../navigation/history'
import { getPathname } from '../../utils'
import { getDataScreenCurrent, postDataScreenCurrent, getDataScreenPrevious } from '../../services/isa'

export const useSendingInfo = (setIsLoading, setIncomeStatementPath, incomeStatementPath) => {
  const goToNextScreen = async (data, redirectTo) => {
    setIsLoading(true)
    try {
      const crid_isa = localStorage.getItem(LOCALSTORAGE_TOKENS.crid_isa)

      const {
        data: { nextScreen }
      } = await postDataScreenCurrent(crid_isa, data)
      const response = await getDataScreenCurrent(crid_isa)
      setIncomeStatementPath(response.data)
      if (redirectTo) {
        return false
      }
      const pathname = getPathname(nextScreen)
      Sentry.addBreadcrumb({
        category: 'goToNextScreen',
        message: 'Next Screen ' + nextScreen,
        level: Sentry.Severity.Info
      })
      history.push(pathname)
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        showToast(err.response.data.errors[0].message)
        return
      }
      showToast()
    } finally {
      setIsLoading(false)
    }
  }

  const goToPreviousScreen = async () => {
    setIsLoading(true)
    const previousScreen =
      incomeStatementPath && incomeStatementPath.progress && incomeStatementPath.progress.previousScreenUniqueName
    if (!previousScreen) {
      localStorage.removeItem(LOCALSTORAGE_TOKENS.crid_isa)
      setIsLoading(false)
      history.push('/inicio')
      return
    }

    const crid_isa = localStorage.getItem(LOCALSTORAGE_TOKENS.crid_isa)

    try {
      const response = await getDataScreenPrevious(crid_isa, previousScreen)
      setIncomeStatementPath(response)
      const pathname = getPathname(previousScreen)
      Sentry.addBreadcrumb({
        category: 'goToPreviousScreen',
        message: 'Previous Screen ' + previousScreen,
        level: Sentry.Severity.Info
      })
      history.push(pathname)
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        showToast(err.response.data.errors[0].message)
        return
      }
      showToast()
    } finally {
      setIsLoading(false)
    }
  }

  return [goToNextScreen, goToPreviousScreen]
}

export const useIncomeStatementPath = (setIsLoading, setIncomeStatementPath) => {
  useEffect(() => {
    const pathname = history.location.pathname
    const split = pathname.split('/')
    const isForm = split[2] === 'isa'

    const getData = async () => {
      setIsLoading(true)
      try {
        const crid_isa = localStorage.getItem(LOCALSTORAGE_TOKENS.crid_isa)
        if (crid_isa && isForm) {
          const { data } = await getDataScreenCurrent(crid_isa)
          setIncomeStatementPath(data)
          const pathnameHistory = getPathname(data.progress.currentScreenUniqueName)
          history.push(pathnameHistory)
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.errors) {
          showToast(err.response.data.errors[0].message)
          history.push('/dashboard/declaracoes-isa')
          return
        }
        history.push('/dashboard/declaracoes-isa')
        showToast()
      } finally {
        setIsLoading(false)
      }
    }
    getData()
  }, [setIsLoading, setIncomeStatementPath])
}

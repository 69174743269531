import React from 'react'
import * as S from './styles'

const ErrorMsg = {
  tryFiveTimes: (
    <>
      <S.ModalContent>Não conseguimos validar os dados da sua conta.</S.ModalContent>
      <S.ModalContent>
        Seu acesso ao Painel do aluno foi temporariamente bloqueado. Tente acessá-lo novamente após 24 horas.
      </S.ModalContent>
    </>
  ),
  tryTenTimes: (
    <>
      <S.ModalContent>Não conseguimos validar os dados da sua conta.</S.ModalContent>
      <S.ModalContent>
        Seu acesso ao Painel do aluno foi bloqueado. Entre em contato com o Atendimento para revalidar o acesso.
      </S.ModalContent>
    </>
  ),
  alreadyExists: (
    <>
      <S.ModalContent>Você já digitou esses dados bancários e eles são inválidos. Confira os possíveis motivos:</S.ModalContent>
      <S.ModalContent>
        <ul>
          <li>A conta bancária não é do titular do curso.</li>
          <li>A conta bancária não existe ou foi digitada com algum dado incorreto.</li>
        </ul>
      </S.ModalContent>
      <S.ModalContent>Insira novos dados e clique em avançar.</S.ModalContent>
    </>
  ),
  defaultMessage: (
    <>
      <S.ModalContent>Não conseguimos validar os dados da sua conta. Confira os possíveis motivos:</S.ModalContent>
      <S.ModalContent>
        <ul>
          <li>A conta bancária não é do titular do curso.</li>
          <li>A conta bancária não existe ou foi digitada com algum dado incorreto.</li>
        </ul>
      </S.ModalContent>
      <S.ModalContent>Corrija as informações e clique em avançar.</S.ModalContent>
    </>
  )
}

export default ErrorMsg

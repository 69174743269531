import { useEffect } from 'react'
import Cookies from 'universal-cookie'

import { history } from '../../navigation/history'
import { validateToken } from '../../services/auth'
import { getFinancingUnique } from '../../services/api'
import { LOCALSTORAGE_TOKENS, COOKIE_TOKENS, COOKIE_DOMAINS, SAVE_COOKIE } from '../../constants'
import { identify } from '../../utils/identify'

export const useValidateToken = (params, setSelectCrid, setToken) => {
  useEffect(() => {
    const getData = async () => {
      try {
        const { data: user } = await validateToken(params.token)

        if (SAVE_COOKIE) {
          const cookieTokenGroup = (!params.partner && 'user') || 'partner'
          const cookies = new Cookies()
          const cookieTokens = cookies.get('DASH_tokens') || { user: [], partner: [] }
          const registeredCookieToken = cookieTokens[cookieTokenGroup].find(token => token.email === user.email)
          if (!registeredCookieToken) {
            cookieTokens[cookieTokenGroup] = [
              ...cookieTokens[cookieTokenGroup],
              {
                token: params.token,
                email: user.email
              }
            ]
            cookies.set(COOKIE_TOKENS.user_dashboard_token, JSON.stringify(cookieTokens), {
              path: '/',
              domain: COOKIE_DOMAINS.default
            })
          }
        }

        localStorage.clear()
        localStorage.setItem(LOCALSTORAGE_TOKENS.token, params.token)
        localStorage.setItem(LOCALSTORAGE_TOKENS.userId, user.userId)
        localStorage.setItem(LOCALSTORAGE_TOKENS.email, user.email)
        localStorage.setItem(LOCALSTORAGE_TOKENS.partnerSlug, user.partnerSlug)
        setToken(params.token)

        if (params.crid) {
          const crid = await getFinancingUnique(params.crid)
          identify({
            CreditRequestId: crid.id,
            UserId: user.userId,
            email: user.email
          })
          localStorage.setItem(LOCALSTORAGE_TOKENS.crid, JSON.stringify(crid))
          setSelectCrid(crid)
          history.push('/dashboard/faturas/abertas')
          return false
        }

        identify({
          UserId: user.userId,
          email: user.email
        })
        history.push('/inicio')
      } catch (e) {
        if (params.partner) {
          history.push(`/token-invalido/${params.partner}`)
          return false
        }

        history.push('/token-invalido')
      }
    }
    getData()
  }, [params.crid, params.partner, params.token, setSelectCrid, setToken])
}

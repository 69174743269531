import React, { useContext } from 'react'

import moment from 'moment'

import { ButtonV2 } from '../../../components/ButtonV2'
import { HeaderV2 } from '../../../components/HeaderV2'
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'
import { Template } from '../../../components/template'
import { TemplateContext } from '../../../components/template/context'
import { LinkedInContext } from '../../../components/LinkedInTooltip/context'

import { formatCPF, formatPhone } from '../../../utils'

import { MODE_DASH } from '../../../constants'

import { useProfileSelfie } from './useProfileSelfie'

import {
  Container,
  BoxWrapper,
  BasicInformation,
  Title,
  ContainerImg,
  ContainerBasicInfo,
  Content,
  InformationField,
  LinkedinInfo,
  AlignCenter,
  InputLinkedin,
  InputFile,
  InputFileLabel
} from './styles'
import { useProfileScreen } from './hooks'

import { BankAccountSection } from './sections'

export const ProfileV2 = () => {
  const { typeDash, dataProfile, isMobile, token, setDataProfile, isLoading } = useContext(TemplateContext)
  const { linkedInData, handleLinkedInInsertion, handleValidationOfLinkedInRegister } = useContext(LinkedInContext)

  const [handleUploadFile, saveProfileDisabled, setSaveProfileDisabled, isLoadingNewProfile, submitPhoto] = useProfileSelfie({
    token,
    setDataProfile
  })

  const {
    linkedInInputValue,
    isEditLinkedInButtonDisabled,
    setIsEditLinkedInButtonDisabled,
    handleOnBlur,
    handleKeyDown,
    onPasteLinkedinInput,
    onChangeLinkedinInput
  } = useProfileScreen({ linkedInData, handleLinkedInInsertion, handleValidationOfLinkedInRegister })

  const widthForLoadingSkeleton = isMobile ? '100%' : '70%'

  return (
    <Template useZeroPadding>
      <Container>
        <HeaderV2 label="Perfil" />
        <BoxWrapper>
          <BasicInformation>
            <Title>Informações básicas</Title>
            <ContainerBasicInfo>
              <ContainerImg>
                <LoadingSkeleton
                  isLoading={isLoading || isLoadingNewProfile}
                  hasHeading
                  containerHeight="130px"
                  containerWidth="130px"
                >
                  {dataProfile && dataProfile.photoUrl ? (
                    <img src={dataProfile.photoUrl} alt={dataProfile.fullName} />
                  ) : (
                    <img
                      src={`https://avatars.dicebear.com/api/initials/${dataProfile &&
                        dataProfile.fullName}.svg?b=%23000b3a&backgroundColorLevel=600&fontSize=30&r=8&bold=1`}
                      alt={dataProfile && dataProfile.fullName}
                    />
                  )}
                </LoadingSkeleton>
                {saveProfileDisabled && <InputFileLabel for="imgUpload">Editar foto</InputFileLabel>}
                <InputFile
                  type="file"
                  name="imgUpload"
                  id="imgUpload"
                  accept="image/x-png,image/gif,image/jpeg"
                  onChange={e => handleUploadFile(e)}
                  onClick={() => setSaveProfileDisabled(false)}
                />
                {!saveProfileDisabled && (
                  <ButtonV2 id="save" onClick={() => submitPhoto()} label="Salvar" isDisabled={saveProfileDisabled} />
                )}
              </ContainerImg>
              <Content>
                <InformationField>
                  <h4>Nome Completo</h4>
                  <LoadingSkeleton
                    isLoading={isLoading}
                    hasHeading
                    containerHeight="25px"
                    containerWidth={widthForLoadingSkeleton}
                  >
                    <p>{dataProfile?.fullName}</p>
                  </LoadingSkeleton>
                </InformationField>
                <InformationField>
                  <h4>CPF</h4>
                  <LoadingSkeleton
                    isLoading={isLoading}
                    hasHeading
                    containerHeight="25px"
                    containerWidth={widthForLoadingSkeleton}
                  >
                    <p>{formatCPF(dataProfile?.cpf)}</p>
                  </LoadingSkeleton>
                </InformationField>
                <InformationField>
                  <h4>Data de nascimento</h4>
                  <LoadingSkeleton
                    isLoading={isLoading}
                    hasHeading
                    containerHeight="25px"
                    containerWidth={widthForLoadingSkeleton}
                  >
                    <p>{moment(dataProfile?.birthDate).format('DD/MM/YYYY')}</p>
                  </LoadingSkeleton>
                </InformationField>
                <InformationField>
                  <h4>Telefone</h4>
                  <LoadingSkeleton
                    isLoading={isLoading}
                    hasHeading
                    containerHeight="25px"
                    containerWidth={widthForLoadingSkeleton}
                  >
                    <p>{formatPhone(dataProfile?.phone)}</p>
                  </LoadingSkeleton>
                </InformationField>
                <InformationField>
                  <h4>Email</h4>
                  <LoadingSkeleton
                    isLoading={isLoading}
                    hasHeading
                    containerHeight="25px"
                    containerWidth={widthForLoadingSkeleton}
                  >
                    <p>{dataProfile?.email}</p>
                  </LoadingSkeleton>
                </InformationField>
              </Content>
            </ContainerBasicInfo>
          </BasicInformation>

          {typeDash === MODE_DASH.allowance && <BankAccountSection />}

          {typeDash === MODE_DASH.isa && (
            <LinkedinInfo>
              <Title>Linkedin</Title>
              <InputLinkedin
                type="text"
                placeholder="Insira o link aqui"
                disabled={isEditLinkedInButtonDisabled}
                onChange={onChangeLinkedinInput}
                onBlur={handleOnBlur}
                onKeyDown={handleKeyDown}
                value={linkedInInputValue}
                onPasteCapture={onPasteLinkedinInput}
              />
              {isMobile ? (
                <AlignCenter>
                  <ButtonV2 label="Editar" onClick={() => setIsEditLinkedInButtonDisabled(state => !state)} />
                </AlignCenter>
              ) : (
                <ButtonV2
                  label="Editar"
                  onClick={() => setIsEditLinkedInButtonDisabled(state => !state)}
                  isDisabled={!isEditLinkedInButtonDisabled}
                />
              )}
            </LinkedinInfo>
          )}
        </BoxWrapper>
      </Container>
    </Template>
  )
}

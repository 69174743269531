export const filterAllowances = ({ response, screen }) => {
  if (!response) return []

  const paid = response.allowances?.filter?.(item => ['paid', 'paid_with_delay'].includes(item.status))
  const open = response.allowances?.filter?.(item =>
    [
      'pending',
      'waiting_signature',
      'delayed',
      'not_paid',
      'available',
      'requested',
      'blocked',
      'locked',
      'locked',
      'scheduled',
      'delayed_blocked',
      'processing_payment'
    ].includes(item.status)
  )

  if (screen === 'paid' && paid) return paid

  if (open) return open

  return []
}

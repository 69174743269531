import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import background_analysis_mobile from '../../../assets/svg/background_signature.svg'

export const Container = styled.div`
  box-sizing: border-box;
  padding-left: 114px;
  padding-right: 22px;
  height: 100vh;
  background-color: ${theme.colors.blue900};
  background-image: url(${background_analysis_mobile});
  background-repeat: no-repeat;
  background-position: right bottom;
  width: 100%;
  display: flex;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    display: block;
    background-size: 100%;
    padding-left: 20px;
    padding-top: 20px;
  }
`

export const Title = styled.h4`
  font-family: CenturyGothicBold, sans-serif;
  margin-top: 100px;
  color: ${theme.colors.blue600};
  font-size: 38px;
  line-height: 47px;
  ${theme.breakpoints.down('md')} {
    font-size: 24px;
    line-height: 36px;
    margin-top: 45px;
  }
`

export const SubTitle = styled.p`
  font-family: CenturyGothic, sans-serif;
  color: ${theme.colors.white100};
  font-size: 20px;
  line-height: 30px;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
  }
`

export const Text = styled.p`
  font-family: 'CenturyGothic', sans serif;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.white100};
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const Wrapper = styled.div`
  width: 640px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const ContainerSocial = styled.div`
  display: flex;
  flex-direction: row;
`

export const Item = styled.a`
  text-decoration: none;
  color: transparent;
  margin-right: 8px;
  cursor: pointer;
`

export const Recipients = styled.p`
  font-family: 'Monteserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;

  ${theme.breakpoints.up('md')} {
    margin-top: -40px;
  }
`

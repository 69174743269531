import { useMemo, useState, useEffect } from 'react'
import moment from 'moment'

export const useInvoiceComponent = ({ item }) => {
  const [dimensions, setDimensions] = useState([window.innerWidth, window.innerHeight])

  const formattedDate = useMemo(() => {
    return moment(item.date, 'DD/MM/YYYY').format('MMMM YYYY')
  }, [item])

  useEffect(() => {
    window.addEventListener('resize', () => setDimensions([window.innerWidth, window.innerHeight]))
    return () => {
      window.removeEventListener('resize', () => setDimensions([window.innerWidth, window.innerHeight]))
    }
  }, [])

  const effected = ['effected', 'analysis'].includes(item.status)

  const getColor = {
    analysis: '#FFEFAD',
    effected: '#CFEFB6',
    delayed: '#FFA5C0',
    review: '#FF9882',
    incomplete: '#D4DAF7'
  }
  return [formattedDate, getColor, effected, dimensions[0] <= 950]
}

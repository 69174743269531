import React from 'react'

import * as S from './styles'

export const ButtonWithIcon = ({ text, icon = false, onClick, width = '180px', mobileWidth = '100%' }) => {
  return (
    <S.Container onClick={onClick} width={width} mobileWidth={mobileWidth}>
      {text}
      {icon}
    </S.Container>
  )
}

import React, { useContext } from 'react'
import moment from 'moment'
import * as S from './styles'
import { ReactComponent as Dolar } from '../../../../assets/svg/currency-circle.svg'
import { ReactComponent as Warning } from '../../../../assets/svg/warning-circle.svg'
import { TemplateCancellationContext } from '../../../../components/template-cancellation/context'
import SummaryStarkbank from './summary-starkbank'
import SummaryWirecard from './summary-wirecard'
import SummaryPix from './summary-pix'
import SummaryPaymentOrder from './summary-payment-order'
import { useSummary } from '../hooks'
import { useReason } from '../../reason/hooks'
import Modal from '../modal'
import SummaryNoRefund from './summary-no-refund'

const SummaryCard = ({ selectCrid, refundSummary, isMobile }) => {
  const { setCancellationData, cancellationData } = useContext(TemplateCancellationContext)
  useReason({ selectCrid, setCancellationData, cancellationData })
  const { objRefundType, onSubmit, modalRef, closeModal, isLoading, redirectReviewData } = useSummary({ selectCrid,refundSummary })

  return (
    <>
      <S.CardRequestContainer>
        <S.CardRequestTitle>Resumo</S.CardRequestTitle>
        <S.CardRequestRow>
          <div>
            <strong>Curso:</strong>
            <span>{cancellationData.courseName}</span>
          </div>
          <div>
            <strong>Instituição:</strong>
            <span>{cancellationData.partnerName}</span>
          </div>
        </S.CardRequestRow>
        <S.CardRequestRow>
          <div>
            {isMobile ? <strong>Data do cancelamento:</strong> : <strong>Data do pedido de cancelamento: </strong>}
            <span>{moment().format('DD/MM/YYYY')}</span>
          </div>
          <div>
            <strong>Motivo: </strong>
            <span>{cancellationData.reason === 'Outro motivo' ? cancellationData.subReason : cancellationData.reason}</span>
          </div>
        </S.CardRequestRow>
        <div>
          <S.CardRequestSubTitle>
            <Dolar />
            {objRefundType[cancellationData.refundType] || 'Reembolso'}
          </S.CardRequestSubTitle>
        </div>
        {cancellationData.refundType === 'starkbank' && refundSummary && (
          <SummaryStarkbank data={cancellationData} refundSummary={refundSummary} />
        )}
        {cancellationData.refundType === 'wirecard' && refundSummary && refundSummary.ProductType === 'Pix' && <SummaryPix data={refundSummary} />}
        {cancellationData.refundType === 'wirecard' && refundSummary && refundSummary.ProductType !== 'Pix' && <SummaryWirecard data={refundSummary} />}
        {cancellationData.refundType === 'ordem de pagamento' && refundSummary && <SummaryPaymentOrder data={refundSummary} />}
        {!cancellationData.refundType && <SummaryNoRefund />}
        <S.CardRequestInfo>
          <Warning />

          <S.CardRequestInfoText>
            <div>Essas informações não poderão ser alteradas depois de criada a solicitação de cancelamento</div>
          </S.CardRequestInfoText>
        </S.CardRequestInfo>
        <S.CardRequestGridButton>
          <a onClick={() => redirectReviewData(cancellationData.refundType)}>Revisar dados</a>
          <S.CardRequestButton
            text=" Confirmar cancelamento"
            onClick={() =>
              onSubmit({
                type: 'cancellation',
                reason: cancellationData.reason,
                subReason: cancellationData.subReason,
                refundType: cancellationData.refundType
              })
            }
            isLoading={isLoading}
          />
        </S.CardRequestGridButton>
      </S.CardRequestContainer>
      <Modal modalRef={modalRef} title="Feito! Recebemos seu pedido de cancelamento 😉 " onSubmit={closeModal}>
        <S.ModalBody>
          <p>
            Se você se arrepender do cancelamento, você tem até 3 dias úteis para desisitir do cancelamento do curso acessando o
            painel de solicitações.
          </p>
        </S.ModalBody>
      </Modal>
    </>
  )
}

export default SummaryCard

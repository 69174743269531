import React, { useContext } from 'react'
import { LoadingProvi } from '@provi/provi-components'
import { TemplateProviSignContext } from '../../../components/template-provisign/context'
import { useLoading } from './hooks'

export const LoadingProviSign = () => {
  const { setTypeUser } = useContext(TemplateProviSignContext)
  useLoading(setTypeUser)
  return <LoadingProvi />
}

import React from 'react'
import { currencyParser } from '../../../../utils'
import * as S from './styles'

const CardPix = data => {
  return (
    <S.CardRequestValueContainer>
      <S.CardRequestAccount>
        <span>Valor: </span> {currencyParser(data?.data.refundSummary.refundAmount, 'R$')}
      </S.CardRequestAccount>
    </S.CardRequestValueContainer>
  )
}

export default CardPix

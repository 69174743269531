import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ContainerHeader = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  z-index: 10;
`

export const Title = styled.h3`
  font-size: 16px;
  line-height: 18px;
  font-family: 'CenturyGothicBold';
  color: ${theme.colors.white100};
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
  }
`

export const ColorBold = styled.span`
  color: ${theme.colors.blue600};
`

export const Progress = styled.div`
  height: 4px;
  width: ${({ value, total }) => (value / total) * 100}%;
  background-color: #0db6ff;
  transition: width 1s;
`

export const WrapperItens = styled.div`
  height: 52px;
  width: 100%;
  background-color: ${theme.colors.blue900};
  display: flex;
  justify-content: center;
  align-items: center;
`

import { ButtonV3, SelectorV3, theme } from '@provi/provi-components'
import styled from 'styled-components'

export const StyledSelect = styled(SelectorV3)`
  > div {
    z-index: 9999;
  }
`

export const StyledSelectSubReason = styled(SelectorV3)``

export const StyledButton = styled(ButtonV3)`
  button span {
    font-size: 16px !important;
  }
`

export const Container = styled.div`
  width: 544px;

  background: #fff;
  padding: 20px;

  border: 2px solid ${theme.colors.bluePrimaryLight};
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 10px;

  border-radius: 4px;

  @media (max-width: 1250px) {
    width: 100%;
  }
`

export const Title = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  letter-spacing: 0.015em;

  color: #000000;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
`

export const ButtonWrapper = styled.div`
  display: flex;

  margin-top: 30px;
  justify-content: flex-end;
`

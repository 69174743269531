import React, { useContext } from 'react'
import moment from 'moment'

import { Template } from '../../../components/template'
import { TemplateContext } from '../../../components/template/context'

import { useContracts } from './hooks'
import {
  Container,
  Content,
  ContractCard,
  PartnerName,
  ProductContainer,
  ProductContent,
  ContractButton,
  NoContractsMessageContainer,
  ButtonWhatsapp
} from './styles'
import { HeaderV2 } from '../../../components/HeaderV2'
import { ReceiptTermCard } from './components/receipt-term-card'

export const ContractsV2 = () => {
  const { isMobile, setMenuActive, setIsBack, setIsLoading, selectCrid } = useContext(TemplateContext)
  const { data, noContractsMessageDisplay } = useContracts({ setMenuActive, setIsBack, setIsLoading, selectCrid })

  return (
    <Template isV2>
      <Container>
        <HeaderV2 to={'/dashboard/faturas/abertas'} label="Contratos" isMobile={isMobile} />

        {data?.receiptTerm && <ReceiptTermCard link={data?.receiptTerm.combinedUrl} />}

        <Content>
          {data?.contracts?.map(contract => (
            <ContractCard key={contract.id}>
              <PartnerName>
                {data.partner} | {moment(contract.signedDate).format('MMMM [de] YYYY')}
              </PartnerName>

              <ProductContainer>
                {data?.courses?.map((course, index) => (
                  <ProductContent key={index}>
                    <span>Produto {index + 1}:</span>
                    <strong>{course}</strong>
                  </ProductContent>
                ))}

                <ProductContent>
                  <span>Início do financiamento:</span>
                  <strong>{data?.startDate}</strong>
                </ProductContent>

                {isMobile ? (
                  <ProductContent>
                    <span>Fim do financiamento:</span>
                    <strong>{data?.endDate}</strong>
                  </ProductContent>
                ) : (
                  <ProductContent isWithContractButton>
                    <div>
                      <span>Fim do financiamento:</span>
                      <strong>{data?.endDate}</strong>
                    </div>
                    <ContractButton type="button" onClick={() => window.open(contract.combinedUrl, '_blank')}>
                      Baixar contrato
                    </ContractButton>
                  </ProductContent>
                )}
              </ProductContainer>
              {isMobile && (
                <ContractButton type="button" onClick={() => window.open(contract.combinedUrl, '_blank')} isWithMarginTop>
                  Baixar contrato
                </ContractButton>
              )}
            </ContractCard>
          ))}

          {noContractsMessageDisplay && (
            <NoContractsMessageContainer>
              <h2>Seu contrato não foi encontrado.</h2>
              <p>
                Caso acredite que isso seja um problema, entre em contato por email: <br />
                <a href="mailto:papo@provi.com.br">
                  <strong>papo@provi.com.br</strong>
                </a>{' '}
                ou via nosso <strong>WhatsApp</strong>
              </p>

              <ButtonWhatsapp
                onClick={() =>
                  window.open(
                    'https://api.whatsapp.com/send?phone=5511957712414&text=N%C3%A3o%20encontrei%20meu%20contrato%20no%20dashboard%20do%20aluno.%20Pode%20me%20ajudar?',
                    '_blank'
                  )
                }
              >
                WhatsApp
              </ButtonWhatsapp>
            </NoContractsMessageContainer>
          )}
        </Content>
      </Container>
    </Template>
  )
}

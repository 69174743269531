import React from 'react'
import { currencyParser } from '../../../../utils'
import { ContainerBox, Value, Label } from './stylesV2'

export const CardDefault = ({ data }) => (
  <ContainerBox>
    <Label>
      Valor total: <Value>{currencyParser(data.totalValue || 0, 'R$')}</Value>
    </Label>
    <Label>
      Valor pago: <Value>{currencyParser(data.paidValue || 0, 'R$')}</Value>
    </Label>
    <Label>
      Valor restante: <Value>{currencyParser(data.remainingValue || 0, 'R$')}</Value>
    </Label>
    <Label>
      Entrada: <Value>{data.amountOfUpfrontInstallments}x</Value>
    </Label>
    <Label>
      Parcelas: <Value>{data.amountOfDefaultInstallments}x</Value>
    </Label>
    <Label>
      Taxas: <Value>{data?.monthlyInterest?.toString?.().replace?.('.', ',')}% a.m.</Value>
    </Label>
  </ContainerBox>
)

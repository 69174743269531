import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const ContainerInvoices = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`

export const ItemInvoice = styled.div`
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  background-color: ${theme.colors.white100};
  justify-content: space-between;
  border-radius: 10px;
  padding: 16px;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : 200)}px;
  width: 600px;
  box-sizing: border-box;
  ${({ active }) => active && 'border: 1px solid #DE0C4B;'};
  ${({ disabled }) => disabled && 'background: #ECECEC;'};
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const ItemInvoiceLocked = styled.div`
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  background-color: ${theme.colors.white100};
  justify-content: space-between;
  border-radius: 10px;
  padding: 16px;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : 200)}px;
  width: 600px;
  box-sizing: border-box;
  ${({ active }) => active && 'border: 1px solid #DE0C4B;'};
  ${({ disabled }) => disabled && 'background: #ECECEC;'};
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.grey600};
  font-family: 'CenturyGothicBold', sans-serif;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
    margin-right: 8px;
    text-align: right;
  }
`

export const WarningText = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.red100};
  font-family: 'CenturyGothicBold', sans-serif;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
`

export const InformationText = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothicBold', sans-serif;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
`

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const TextModal = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothic', sans-serif;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
    margin-right: 8px;
    text-align: left;
  }
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  margin-top: 15px;

  > div button {
    width: 100% > span {
      font-size: 12px;
    }
    position: static;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  ${({ marginLeft }) => marginLeft && 'margin-left: 20px'};
  ${({ paddingBottom }) => paddingBottom && 'padding-bottom: 16px'};
  ${({ link }) => link && 'text-decoration: underline'};
`

export const Month = styled.p`
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 12px;
  ${theme.breakpoints.down('md')} {
    margin-top: 16px;
  }
`

export const Price = styled.p`
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: 'CenturyGothicBold', sans-serif;
  color: ${theme.colors.blue900};
  font-size: 20px;
  line-height: 30px;
`

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.grey500};
  font-family: 'CenturyGothicBold', sans-serif;
  justify-content: flex-end;
  width: 50%;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
    align-items: flex-end;
    flex-direction: column-reverse;
  }
`

export const Status = styled.div`
  margin-left: 8px;
  width: 18px;
  height: 18px;
  background-color: ${props => props.color};
  border-radius: 50%;
`

export const Header = styled.div`
  display: flex;
`

export const WrapperQuestion = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
`

export const ButtonGroup = styled.div`
  width: 350px;
  border-radius: 50px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${theme.colors.blue900};
  margin-bottom: 20px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const ButtonItemLeft = styled.div`
  cursor: pointer;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 18px;
  background-color: ${({ active }) => (active ? theme.colors.blue900 : 'transparent')};
  color: ${({ active }) => (active ? theme.colors.white100 : theme.colors.blue900)};
  font-family: ${({ active }) => (active ? 'CenturyGothicBold, sans-serif' : 'CenturyGothic, sans-serif')};
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  &:hover {
    opacity: 0.7;
  }
`

export const ButtonItemRight = styled.div`
  cursor: pointer;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 18px;
  background-color: ${({ active }) => (active ? theme.colors.blue900 : 'transparent')};
  font-family: ${({ active }) => (active ? 'CenturyGothicBold, sans-serif' : 'CenturyGothic, sans-serif')};
  color: ${({ active }) => (active ? theme.colors.white100 : theme.colors.blue900)};
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  &:hover {
    opacity: 0.7;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ButtonPayment = styled.div`
  font-family: 'CenturyGothicBold', sans-serif;
  height: 25px;
  border: 1px solid ${theme.colors.pink900};
  color: ${theme.colors.pink900};
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 20px;
  right: 15px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`

export const TextTitle = styled.p`
  font-family: 'CenturyGothicBold', sans-serif;
  color: ${theme.colors.blue900};
  font-size: 24px;
  line-height: 30px;
  margin: 0;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const TextSimple = styled.p`
  font-family: 'CenturyGothic', sans-serif;
  color: ${theme.colors.blue900};
  font-size: 12px;
  line-height: 24px;
  margin: 0;

  br {
    display: none;
  }

  @media (max-width: 400px) {
    br {
      display: inline;
    }
  }
`

export const ContainerModal = styled.div`
  text-align: center;
`

export const SubTitle = styled.p`
  font-family: 'CenturyGothic', sans-serif;
  color: ${theme.colors.blue900};
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`

export const Bold = styled.span`
  font-family: 'CenturyGothicBold', sans-serif;
  color: ${theme.colors.blue900};
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`

export const Image = styled.img`
  ${({ wide }) => (wide ? 'width: 100%' : 'height: 400px')};
`

export const Link = styled.div`
  font-family: 'CenturyGothicBold', sans-serif;
  color: ${theme.colors.pink900};
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 20px;
  }
`

export const WrapperLocked = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${theme.breakpoints.down('sm')} {
    svg {
      width: 30px;
      height: 30px;
    }
  }
`

export const Button = styled.div`
  cursor: pointer;
  width: 200px;
  padding: 8px 0;
  border: 1px solid #de0c4b;
  border-radius: 20px;
  box-sizing: border-box;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  &:hover {
    opacity: 0.7;
  }

  ${theme.breakpoints.down('md')} {
    width: 100px;
    padding: 4px 0;
  }
`

export const TextButton = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.pink900};
  font-family: 'CenturyGothicBold', sans-serif;
  margin: 0;

  ${theme.breakpoints.down('md')} {
    font-size: 10px;
    line-height: 18px;
  }
`
export const Br = styled.br``

import { useState, useEffect } from 'react'
import { theme } from '@provi/provi-components'

const options = [
  {
    id: 1,
    text: 'SIM',
    value: true
  },
  {
    id: 2,
    text: 'NÃO',
    value: false
  }
]

export const useExtraIncome = (goToNextScreen, incomeStatementPath, setDataModal, setIsShowModal) => {
  const [selectedOption, setSelectedOption] = useState()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  useEffect(() => {
    setDataModal({
      title: 'O que considerar como outros tipos de renda: ',
      texts: [
        'Aqui você pode trazer todos os tipos de renda que não tenham sido relacionadas ao seu trabalho: rentabilidade de investimento, auxílio do governo, doação de alguém, vendeu alguma coisa de valor, restituição do imposto de renda, herança, pensão, etc. ',
        'A ideia é que você seja o mais transparente possível. Nem todas as rendas que você declarar serão contabilizadas no cálculo de renda mínima, mas é importante trazer todas elas. ',
        'Você deve enviar o link o quanto antes para a pessoa indicada, e avisaremos você por email sobre o status de preenchimento.',
        'Por exemplo, não iremos cobrar em cima de auxílios do governo, mas sabendo que você recebeu conseguimos consolidar com as informações de gastos e não levantar nenhuma divergência de informação. ',
        'Caso você queira saber sobre quais rendas o percentual de compartilhamento de renda irá incidir, o ideal é olhar o contrato novamente, tá? '
      ],
      textButton: 'Entendi',
      buttonFunction: () => setIsShowModal(false),
      textLink: {
        color: theme.colors.blue900,
        text: 'Entrar em contato',
        onClick: () => window.open('mailto:meuisa@provi.com.br', '_blank')
      },
      color: theme.colors.blue900
    })
    if (incomeStatementPath && incomeStatementPath.ReadExtraIncome) {
      const find = options.find(item => item.value === incomeStatementPath.ReadExtraIncome)
      if (find) {
        onSelectOption(find)
        setIsButtonDisabled(false)
      }
    }
  }, [incomeStatementPath])

  const onSelectOption = option => {
    setSelectedOption(option)
    setIsButtonDisabled(false)
  }

  const onSubmit = e => {
    e.preventDefault()
    if (selectedOption?.value) {
      goToNextScreen(selectedOption.value)
    }
  }

  return [selectedOption, onSelectOption, onSubmit, isButtonDisabled, options]
}

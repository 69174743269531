import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  flex-direction: column;
  ${theme.breakpoints.down('md')} {
    padding: 0;
  }
`

export const Containers = styled.div`
  width: 70%;
  height: 100%;
  margin-top: 25px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  ${theme.breakpoints.down('md')} {
    width: 95%;
    margin-top: 20px;
    margin-bottom: 50px;
    flex-direction: column;
    height: 90%;
  }
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;

  ${theme.breakpoints.down('md')} {
    margin: 16px 0;
  }
`

export const NameSchool = styled.h5`
  margin: 0;
  font-family: 'CenturyGothicBold', sans-serif;
  color: ${theme.colors.pink900};
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 8px;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
  }
`

export const TotalPercentage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  width: 50%;
  ${theme.breakpoints.down('md')} {
    width: 90%;
  }
  .CircularProgressbar {
    color: ${theme.colors.bluePrimary};
  }
  .CircularProgressbar .CircularProgressbar-path {
    stroke: ${theme.colors.bluePrimary};
  }
`

export const Boxs = styled.div`
  display: flex;
  width: 50%;
  border-left: 1px solid #cfcfcf;
  ${theme.breakpoints.down('md')} {
    width: 90%;
    border: 0px;
    padding-left: 0;
  }
  padding-left: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ContainerCircular = styled.div`
  width: 60%;
  margin: 16px;
`

export const Porcentage = styled.p`
  margin: 0;
  font-size: 40px;
  line-height: 24px;
  font-weight: ${props => (props.isBold ? 'bold' : 'normal')};
  font-family: 'Montserrat', sans-serif;
  color: ${theme.colors.blue900};
  margin-bottom: 4px;
  ${theme.breakpoints.down('md')} {
    font-size: 38px;
  }
`

export const ContainerProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const MaxWidth = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`

export const SubTitle = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: ${props => (props.isBold ? 'bold' : 'normal')};
  font-size: ${props => props.fontSize}px;
  color: ${theme.colors.blue900};
  width: ${props => props.width}%;
`

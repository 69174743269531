import React, { useContext } from 'react'
import { TextLink, Title, SubTitle } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'
import { TemplateProviSignContext } from '../../../components/template-provisign/context'
import { Timer } from '../../../components/timer'
import { formatPhone } from '../../../utils'

import { CodeView, CodeNumber, ContainerComponent, Wrapper } from './styles'
import { useCodeValidator } from './hooks'

export const PhoneConfirmation = () => {
  const { setIsLoading } = useContext(TemplateContext)
  const { typeUser, setObjectValidation } = useContext(TemplateProviSignContext)
  const [code, setCodeNumber, addInput, setNextFocus, onKeyDown, sendVerification, counter, phone] = useCodeValidator(
    setIsLoading,
    typeUser,
    setObjectValidation
  )

  return (
    <Wrapper>
      <Title text="Verificação de celular" />
      <SubTitle
        text={`Para assinar o contrato insira abaixo o código que enviamos via SMS para o número ${formatPhone(phone)}`}
      />
      <ContainerComponent>
        <CodeView>
          {code.map((_, position) => (
            <CodeNumber
              id={`id-${position}`}
              aria-label={`id-${position}`}
              cy={`cy-${position}`}
              ref={addInput}
              key={position}
              onKeyDown={e => onKeyDown(e, position)}
              onChange={({ target }) => {
                setCodeNumber(target.value, position)
                setNextFocus(target.value, position)
              }}
            />
          ))}
        </CodeView>
        {counter === 0 ? <TextLink text="Reenviar código" onClick={() => sendVerification()} /> : <Timer counter={counter} />}{' '}
      </ContainerComponent>
    </Wrapper>
  )
}

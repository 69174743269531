import ReactGa from 'react-ga'
import { LOCALSTORAGE_TOKENS, gaDimensions } from '../constants'

export const identify = ({ CreditRequestId, UserId, email, partnerSlug }) => {
  window.smartlook &&
    window.smartlook('identify', UserId, {
      CreditRequestId,
      UserId,
      email
    })

  ReactGa.set({
    [gaDimensions.userId]: UserId,
    [gaDimensions.partnerSlug]: partnerSlug,
    [gaDimensions.creditRequestId]: CreditRequestId
  })
}

const getStoredIdentity = () => {
  const UserId = localStorage.getItem(LOCALSTORAGE_TOKENS.userId)
  const email = localStorage.getItem(LOCALSTORAGE_TOKENS.email)
  const partnerSlug = localStorage.getItem(LOCALSTORAGE_TOKENS.partnerSlug)

  return {
    UserId,
    email,
    partnerSlug
  }
}

export const identifyFromStoredIdentity = () => {
  const payload = getStoredIdentity()
  identify(payload)
}

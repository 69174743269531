import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  padding-left: 155px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: 802px;
  z-index: 2;

  ${theme.breakpoints.down('md')} {
    padding: 0 27px;
    height: 77%;
  }
`

export const Text = styled.p`
  font-family: CenturyGothic, sans serif;
  font-size: 16px;
  line-height: 24px;
  text-align: 100%;
  text-align: justify;
  color: ${theme.colors.blue900};
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const TextIpca = styled.p`
  margin: 0;
  font-family: CenturyGothic, sans serif;
  font-size: 16px;
  line-height: 24px;
  text-align: 100%;
  text-align: justify;
  color: ${theme.colors.blue900};
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const TextValue = styled.p`
  font-family: CenturyGothicBold, sans-serif;
  color: ${({ color }) => color};
  font-size: 18px;
  line-height: 24px;
  margin: 0px;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Row = styled.div`
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const ContainerButton = styled.div`
  margin-top: 20px;

  ${theme.breakpoints.down('lg')} {
    margin-left: 10px;
    margin-top: 10px;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  @media (max-width: 600px) {
    .MuiButton-root {
      margin-bottom: 20px;
    }
  }

  ${theme.breakpoints.down('lg')} {
    align-items: center;
    display: flex;
  }

  ${theme.breakpoints.down('md')} {
    display: inline-block;
  }
`

import React from 'react'
import { currencyParser } from '../../utils'
import * as S from './styles'

export const AllowanceUpgrade = ({ onClick, value }) => {
  return (
    <S.Container show>
      <S.Title>Dê um up na sua Mesada! 🤑</S.Title>
      <S.Subtitle>
        Você pode aumentar o valor que vai receber nos próximos meses para {currencyParser(value, 'R$ ')}. Confira a simulação
        das parcelas sem compromisso!
      </S.Subtitle>

      <S.ButtonUpgrade margin={false} onClick={onClick}>
        Conferir simulação
      </S.ButtonUpgrade>

      <S.BorderBottom />
    </S.Container>
  )
}

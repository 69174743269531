import React from 'react'
import { AllowanceIconCheck } from '../../../../../assets/svg/allowance-icon-check'
import * as S from '../../styles'

export const CardPaid = ({ allowance, key }) => {
  return (
    <S.Card paid key={key}>
      <S.CardHeader>
        <S.MonthAllowance>Mesada de {allowance.formatDate}</S.MonthAllowance>
        <S.NumberAllowance>
          {allowance.number} de {allowance.amountOfItems}
        </S.NumberAllowance>
      </S.CardHeader>

      <S.WrapperCard>
        <S.WrapperItem>
          <p>Depósito</p>
          <p>{allowance.beneficiaryReceivedFundsAt}</p>
        </S.WrapperItem>
      </S.WrapperCard>

      <S.FooterCard>
        <S.ButtonFooterCard paid>
          Retirada concluída <AllowanceIconCheck />
        </S.ButtonFooterCard>
      </S.FooterCard>
    </S.Card>
  )
}

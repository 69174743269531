import React, { useContext } from 'react'
import { HeaderV2 } from '../HeaderV2'
import { Template } from '../template'
import { TemplateCancellationContext } from './context'
import { Modal } from './Modal'
import * as S from './styles'
import { useTemplateCancellation } from './hooks'

import { formatCPF } from '../../utils/'

import { Redirect } from 'react-router'

export const TemplateCancellation = ({ title = '', children, modalProps, showResume = true }) => {
  const { cancellationData, closeModal, modalRef } = useContext(TemplateCancellationContext)

  const { goBackToSelectReason, canRequestCancellation } = useTemplateCancellation()

  if (!canRequestCancellation) {
    return <Redirect to="/dashboard/solicitacoes" exact />
  }

  return (
    <Template>
      <HeaderV2 showBorder={false} label={title} />

      <S.FlexWrapper>
        <S.Container>{children}</S.Container>

        {showResume && (
          <S.LeftResume>
            <S.TitleResume>Informações do cancelamento</S.TitleResume>
            <S.Wrapper>
              <S.Name>Curso</S.Name>
              <S.Description>{cancellationData.courseName}</S.Description>
            </S.Wrapper>
            <S.Wrapper showBorder>
              <S.Name>Escola</S.Name>
              <S.Description>{cancellationData.partnerName}</S.Description>
            </S.Wrapper>

            <S.Wrapper>
              <S.Name>Nome do titular</S.Name>
              <S.Description>{cancellationData.fullName}</S.Description>
            </S.Wrapper>
            <S.Wrapper showBorder={!!cancellationData.reason}>
              <S.Name>CPF do titular</S.Name>
              <S.Description>{formatCPF(cancellationData.CPF)}</S.Description>
            </S.Wrapper>

            {cancellationData.reason && (
              <S.Wrapper>
                <S.Name>Motivo de cancelamento</S.Name>
                <S.Description>
                  {cancellationData.reason === 'Outro motivo' ? cancellationData.subReason : cancellationData.reason}
                </S.Description>
              </S.Wrapper>
            )}

            {cancellationData.reason && <S.ChangeButton onClick={goBackToSelectReason}>Alterar</S.ChangeButton>}
          </S.LeftResume>
        )}
      </S.FlexWrapper>
      <Modal modalRef={modalRef} closeModal={closeModal} {...modalProps} />
    </Template>
  )
}

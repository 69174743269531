import { useCallback } from 'react'
import { history } from '../../../navigation/history'

export const useChangeFinancingSuccess = () => {
  const goBack = useCallback(() => {
    history.push('/dashboard/faturas/abertas')
  })

  return { goBack }
}

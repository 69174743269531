import React from 'react'

import { DownloadIcon } from '../../../../../assets/svg/download-icon'
import openDownload from '../../../../../utils/openDownload'

import * as S from './styles'

export const ReceiptTermCard = ({ link }) => {
  return (
    <S.Container>
      Financiamento concluído
      <S.Link onClick={() => openDownload(link)}>
        Baixar Termo de quitação <DownloadIcon />
      </S.Link>
    </S.Container>
  )
}

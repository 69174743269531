import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import background_analysis_mobile from '../../../assets/svg/background-desktop-login.svg'

export const Container = styled.div`
  box-sizing: border-box;
  padding-left: 114px;
  padding-right: 20px;
  height: 100vh;
  background-color: ${theme.colors.blue900};
  background-image: url(${background_analysis_mobile});
  background-repeat: no-repeat;
  background-position: right bottom;
  width: 100%;
  display: flex;
  align-items: center;
  ${theme.breakpoints.down('md')} {
    background-image: none;
    padding-left: 20px;
  }
`

export const Title = styled.h4`
  margin-top: 50px;
  color: ${theme.colors.pink900};
  font-size: 30px;
  line-height: 36px;
  font-family: CenturyGothicBold, sans-serif;
  ${theme.breakpoints.down('md')} {
    font-size: 24px;
    line-height: 30px;
  }
`
export const Text = styled.p`
  font-family: 'CenturyGothic', sans serif;
  font-size: 20px;
  line-height: 30px;
  color: ${theme.colors.white100};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}px`};
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 22px;
  }
`

export const Wrapper = styled.div`
  width: 640px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const Timer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const Bold = styled.span`
  font-family: 'CenturyGothicBold', sans-serif;
`

import { showToast } from '@provi/provi-components'
import { useCallback, useEffect, useState } from 'react'
import { history } from '../../../navigation/history'
import { getProfileData, getReasons, getRefundSummary } from '../../../services/api'

export const useReason = ({ selectCrid, setCancellationData, cancellationData }) => {
  const [optionsReason, setOptionsReason] = useState([])
  const [subOptionsReason, setSubOptionsReason] = useState([])
  const [valueReason, setValueReason] = useState('')
  const [valueSubReason, setValueSubReason] = useState('')
  const [showFreeInput, setShowFreeInput] = useState(false)
  const [showOtherSelector, setShowOtherSelector] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [refund, setRefund] = useState(0)

  useEffect(() => {
    setButtonDisabled(!valueReason || !valueSubReason)
  }, [valueReason, valueSubReason])

  const onChangeReason = useCallback(e => {
    setValueReason(e)
    setValueSubReason('')

    if (e.value === 'Outro motivo') {
      setShowOtherSelector(false)

      return setShowFreeInput(true)
    }

    const formattedSubReasons = e.SubCancellationReasons.map?.(({ message, id }) => {
      return { id, value: message, label: message }
    })

    setShowFreeInput(false)
    setSubOptionsReason(formattedSubReasons)
    setShowOtherSelector(true)
  }, [])

  const onChangeSubReason = useCallback(e => {
    setValueSubReason(e?.target?.value || e)
  }, [])

  useEffect(() => {
    const loadReasonsAndRefundAmount = async () => {
      try {
        setIsLoading(true)

        if (!selectCrid?.id) return

        const refundData = await getRefundSummary(selectCrid?.id)
        setRefund(refundData)

        const reasons = await getReasons(selectCrid?.id)

        const formattedReasons = reasons?.map?.(({ message, id, SubCancellationReasons }) => {
          return { id, SubCancellationReasons, value: message, label: message }
        })

        if (!formattedReasons) return

        const other = { value: 'Outro motivo', label: 'Outro motivo' }

        setOptionsReason([...formattedReasons, other])

        setIsLoading(false)

        const userData = await getProfileData(null, selectCrid.id)

        setCancellationData({
          ...cancellationData,
          courseName: selectCrid?.course,
          partnerName: selectCrid?.partner,
          fullName: userData?.fullName,
          CPF: userData?.cpf
        })
      } catch (error) {
        showToast('Erro, por favor tente novamente')

        setIsLoading(false)
      }
    }

    loadReasonsAndRefundAmount()
  }, [selectCrid])

  const goToNextStep = useCallback(() => {
    if (valueReason.label === 'Outro motivo') {
      setCancellationData({ ...cancellationData, reason: valueReason.label, subReason: valueSubReason })
    }
    if (valueReason.label !== 'Outro motivo') {
      setCancellationData({ ...cancellationData, reason: valueReason.label, subReason: valueSubReason.label })
    }
    if (!refund?.refundAmount || refund?.refundAmount === 0) {
      setCancellationData({
        ...cancellationData,
        reason: valueReason.label,
        subReason: valueSubReason.label || valueSubReason,
        refundType: null
      })
      return history.push('/cancelamento/resumo')
    }

    if (['CreditCard', 'Pix'].includes(selectCrid.productType)) {
      setCancellationData({
        ...cancellationData,
        reason: valueReason.label,
        subReason: valueSubReason.label || valueSubReason,
        refundType: 'wirecard'
      })
      return history.push('/cancelamento/resumo')
    }

    return history.push('/cancelamento/tipo-de-reembolso')
  }, [cancellationData, setCancellationData, valueReason, valueSubReason])

  return {
    optionsReason,
    onChangeReason,
    valueReason,
    showOtherSelector,
    showFreeInput,
    subOptionsReason,
    valueSubReason,
    setSubOptionsReason,
    onChangeSubReason,
    isLoading,
    buttonDisabled,
    goToNextStep
  }
}

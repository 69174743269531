import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const WrapperContainer = styled.div`
  padding: 20px 50px;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  overflow: -moz-scrollbars-none;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  height: 100vh;

  ${props =>
    props.isV2 &&
    css`
      background-color: #f6f8fb;
      padding-top: 0px;
      padding: 0px 50px 100px 50px;
    `}

  ${theme.breakpoints.down('md')} {
    width: 100%;
    padding: 20px 20px 100px 20px;
  }
`

export const Container = styled(WrapperContainer)``

import React, { useContext } from 'react'
import { InputFieldV3, SelectorV3, ButtonV3 } from '@provi/provi-components'

import { TemplateContext } from '../../../../../components/template/context'
import { ArrowLeft } from '../../../../../assets/svg/ArrowLeft'
import { InfoIcon } from '../../../../../assets/svg/InfoIcon'
import { ErrorIcon } from '../../../../../assets/svg/ErrorIcon'
import { LoadingBarButton } from '../../../../../components/LoadingBarButton'
import { PopoverMessage } from '../../../../../components/PopoverMessage'

import * as S from './styles'
import { useBankAccountSection } from './hooks'

export const BankAccountSection = () => {
  const { selectCrid, token } = useContext(TemplateContext)
  const {
    isBankAccountInEditMode,
    setIsBankAccountInEditMode,
    handleInputChange,
    bankAccountData,
    canEditBankAccount,
    bankOptions,
    showPopover,
    setShowPopover,
    allowSubmit,
    handleCancelEdit,
    handleBankAccountSubmit,
    loadingBarIsLoading,
    loadingPercent,
    handleOnBlur,
    bankAccountFormErrors,
    showErrorCard,
    showInfoCard
  } = useBankAccountSection(selectCrid, token)

  const btnText = showErrorCard ? 'Tentar de novo' : 'Salvar'

  return (
    <S.BankAccountContainer>
      <S.Title>Dados bancários</S.Title>

      <S.Subtitle>Tipo de conta</S.Subtitle>
      <S.InlineWrapper>
        <S.AccountTypeButton
          onClick={() => handleInputChange('accountType', 'CONTA_CORRENTE')}
          isActive={bankAccountData.accountType === 'CONTA_CORRENTE'}
          isEnabledToSelect={isBankAccountInEditMode}
        >
          Corrente
        </S.AccountTypeButton>
        <S.AccountTypeButton
          onClick={() => handleInputChange('accountType', 'CONTA_POUPANCA')}
          isActive={bankAccountData.accountType === 'CONTA_POUPANCA'}
          isEnabledToSelect={isBankAccountInEditMode}
        >
          Poupança
        </S.AccountTypeButton>
      </S.InlineWrapper>

      <S.BankSelectWrapper>
        {isBankAccountInEditMode ? (
          <SelectorV3
            width="380px"
            label="Banco"
            options={bankOptions}
            disabled={true}
            isValid={true}
            value={bankAccountData.financialInstitution}
            onValueChange={item => handleInputChange('financialInstitution', item)}
          />
        ) : (
          <InputFieldV3
            label="Banco"
            width="380px"
            value={bankAccountData.financialInstitution.label}
            disabled={!isBankAccountInEditMode}
          />
        )}
      </S.BankSelectWrapper>

      <S.InlineWrapper>
        <InputFieldV3
          label="Agência"
          width="150px"
          mobileWidth="130px"
          placeholder="0000"
          mask="9999"
          disabled={!isBankAccountInEditMode}
          value={bankAccountData.agency}
          onChange={e => handleInputChange('agency', e.target.value)}
          errorMessage={bankAccountFormErrors.agency.message}
          hasError={bankAccountFormErrors.agency.hasError}
          onBlur={() => handleOnBlur('agency', 'Insira o número da agência sem dígito')}
          isValid={bankAccountFormErrors.agency.isValid}
        />
        <InputFieldV3
          label="Dígito"
          width="150px"
          mobileWidth="130px"
          placeholder="-"
          mask="9"
          disabled={!isBankAccountInEditMode}
          value={bankAccountData.agencyDigit}
          onChange={e => handleInputChange('agencyDigit', e.target.value)}
          onBlur={() => handleOnBlur('agencyDigit', '')}
          isValid={bankAccountFormErrors.agencyDigit.isValid}
        />
      </S.InlineWrapper>

      <S.InlineWrapper>
        <InputFieldV3
          label="Conta"
          width="150px"
          mobileWidth="130px"
          placeholder="000000000"
          mask="999999999"
          disabled={!isBankAccountInEditMode}
          value={bankAccountData.account}
          onChange={e => handleInputChange('account', e.target.value)}
          errorMessage={bankAccountFormErrors.account.message}
          hasError={bankAccountFormErrors.account.hasError}
          onBlur={() => handleOnBlur('account', 'Insira o número da conta sem dígito')}
          isValid={bankAccountFormErrors.account.isValid}
        />
        <InputFieldV3
          label="Dígito"
          width="150px"
          mobileWidth="130px"
          placeholder="0"
          mask="9"
          disabled={!isBankAccountInEditMode}
          value={bankAccountData.accountDigit}
          onChange={e => handleInputChange('accountDigit', e.target.value)}
          errorMessage={bankAccountFormErrors.accountDigit.message}
          hasError={bankAccountFormErrors.accountDigit.hasError}
          onBlur={() => handleOnBlur('accountDigit', 'Insira o dígito da conta')}
          isValid={bankAccountFormErrors.accountDigit.isValid}
        />
      </S.InlineWrapper>

      <S.InformationCard show={showInfoCard.length > 0}>
        <InfoIcon />
        {showInfoCard}
      </S.InformationCard>

      <S.ErrorCard show={showErrorCard}>
        <ErrorIcon />
        Ops! Alguma coisa deu errado na validação dos seus dados bancários. Tente novamente.
      </S.ErrorCard>

      {isBankAccountInEditMode ? (
        <S.SaveOrCancelWrapper>
          <S.CancelEditionButton onClick={handleCancelEdit}>
            <ArrowLeft />
            Cancelar
          </S.CancelEditionButton>
          <PopoverMessage
            position="right"
            message="Parece que você já agendou a mesada para o mês atual. Você pode trocar os seus dados bancários, mas isso só vai valer no mês seguinte."
            show={showPopover}
            onDismiss={() => setShowPopover(false)}
          >
            <LoadingBarButton
              text={btnText}
              width="170px"
              mobileWidth="88vw"
              disabled={!allowSubmit}
              onClick={handleBankAccountSubmit}
              isLoading={loadingBarIsLoading}
              loadingPercent={loadingPercent}
              textOnLoading="Carregando..."
            />
          </PopoverMessage>
        </S.SaveOrCancelWrapper>
      ) : (
        <ButtonV3
          text="Trocar dados bancários"
          width="280px"
          mobileWidth="100%"
          onClick={() => {
            if (canEditBankAccount) setIsBankAccountInEditMode(true)
          }}
          isDisabled={!canEditBankAccount}
        />
      )}
    </S.BankAccountContainer>
  )
}

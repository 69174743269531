import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import CurrencyInput from 'react-currency-input'

export const Container = styled.div`
  padding-left: 154px;
  box-sizing: border-box;
  width: 100%;
  height: 75%;
  max-height: 850px;
  z-index: 2;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .subTitleContainer {
    p {
      margin: 0;
      padding: 0;
    }
  }

  ${theme.breakpoints.down('md')} {
    padding: 0 27px;
  }

  form {
    padding-bottom: 25px;
  }
`

export const InputMasks = styled(CurrencyInput)`
  margin-top: 20px;
  width: ${({ width }) => width || 'auto'};
  padding: 10px 8px;
  font-size: 1rem;
  border: 1px solid ${theme.colors.blue900};
  border-radius: 8px;
  margin-bottom: 7px;

  &:before {
    content: '';
    position: absolute;
  }
  &:focus {
    outline-offset: 0;
    outline: none;
  }
`

export const Label = styled.p`
  font-family: 'CenturyGothicBold', sans serif;
  margin: 0;
  font-size: 16px;
  color: ${theme.colors.blue900};
`

export const IncomeTypeDescription = styled.p`
  font-family: 'CenturyGothic' sans serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
`

export const WrapperForm = styled.div`
  margin: 32px 16px 32px 0;
`

export const ContainerItemForm = styled.div`
  display: flex;
  flex-direction: column;
`

export const ObservationsContainer = styled.div`
  width: 100%;
  max-width: 391px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  > label {
    font-family: 'CenturyGothic' sans serif;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
  }

  > input {
    border: none;
    border-bottom: 1px solid ${theme.colors.blue900};
    padding: 04px 0;
    margin-top: 16px;
    font-size: 16px;

    &:focus {
      outline-offset: 0;
      outline: none;
    }

    &::placeholder {
      font-size: 16px;
      color: ${theme.colors.grey500};
    }
  }
`

import React from 'react'

export const IconDoubt = ({ color = '#DE0C4B', size = '20' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0C4.45455 0 0 4.45455 0 10C0 15.5455 4.45455 20 10 20C15.5455 20 20 15.5455 20 10C20 4.45455 15.5455 0 10 0ZM10 18.1818C5.45455 18.1818 1.81818 14.5455 1.81818 10C1.81818 5.45455 5.45455 1.81818 10 1.81818C14.5455 1.81818 18.1818 5.45455 18.1818 10C18.1818 14.5455 14.5455 18.1818 10 18.1818Z"
        fill={color}
      />
      <path
        d="M11.0903 4.72726C9.18124 4.0909 7.09033 5.0909 6.45396 6.90908C6.36305 7.45454 6.54487 7.99999 7.09033 8.0909C7.54487 8.27272 7.99942 8.0909 8.18124 7.54545C8.54487 6.63636 9.54487 6.0909 10.5449 6.45454C11.2721 6.72726 11.7267 7.36363 11.7267 8.18181C11.7267 9.0909 10.1812 9.81817 9.63578 10.0909C9.54487 10.0909 9.54487 10.1818 9.45396 10.1818L9.36305 10.2727C9.36305 10.2727 9.27214 10.3636 9.27214 10.4545C9.27214 10.5454 9.18124 10.5454 9.18124 10.6364C9.18124 10.7273 9.18124 10.7273 9.18124 10.8182C9.18124 10.9091 9.18124 10.9091 9.18124 11V11.9091C9.18124 12.4545 9.54487 12.8182 10.0903 12.8182C10.6358 12.8182 10.9994 12.4545 10.9994 11.9091V11.6364C11.9994 11.1818 13.7267 10.0909 13.7267 8.27272C13.5449 6.63636 12.5449 5.27272 11.0903 4.72726Z"
        fill={color}
      />
      <path
        d="M10.0009 13.6367C9.45543 13.6367 9.0918 14.0004 9.0918 14.5458C9.0918 15.0913 9.54634 15.4549 10.0009 15.4549C10.4554 15.4549 10.91 15.0913 10.91 14.5458C10.91 14.0004 10.5463 13.6367 10.0009 13.6367Z"
        fill={color}
      />
    </svg>
  )
}

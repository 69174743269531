import React from 'react'

export const CrossIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="https://www.w3.org/2000/svg">
      <path
        d="M3.70711 2.29289C3.31659 1.90237 2.68342 1.90237 2.2929 2.29289C1.90237 2.68342 1.90237 3.31658 2.2929 3.7071L3.70711 2.29289ZM12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L12.2929 13.7071ZM13.7071 3.70711C14.0976 3.31658 14.0976 2.68342 13.7071 2.29289C13.3166 1.90237 12.6834 1.90237 12.2929 2.29289L13.7071 3.70711ZM2.29289 12.2929C1.90237 12.6834 1.90237 13.3166 2.29289 13.7071C2.68342 14.0976 3.31658 14.0976 3.70711 13.7071L2.29289 12.2929ZM2.2929 3.7071L7.2929 8.7071L8.70711 7.29289L3.70711 2.29289L2.2929 3.7071ZM7.29289 8.7071L12.2929 13.7071L13.7071 12.2929L8.70711 7.29289L7.29289 8.7071ZM8.70711 8.7071L13.7071 3.70711L12.2929 2.29289L7.2929 7.29289L8.70711 8.7071ZM7.2929 7.29289L2.29289 12.2929L3.70711 13.7071L8.70711 8.7071L7.2929 7.29289Z"
        fill="white"
      />
    </svg>
  )
}

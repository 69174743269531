import axios from 'axios'
import * as Sentry from '@sentry/browser'
import { v4 as uuidv4 } from 'uuid'
import { ROOT_URL, LOCALSTORAGE_TOKENS } from '../constants'
import { logout } from './api'

const transactionId = uuidv4().replace(/-/g, '')

const api = axios.create({
  baseURL: ROOT_URL.sdk
})

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKENS.token)
    if (token) {
      Sentry.configureScope(function(scope) {
        scope.setTag('x-request-id', transactionId)
      })
      config.headers.Authorization = token
      config.headers['x-request-id'] = transactionId
    } else {
      logout(true)
    }

    return config
  },
  error => {
    Promise.reject(error)
  }
)

export const getDataScreenCurrent = async crid => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.get(`/isa/${crid}`)
      resolve(data)
    } catch (error) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequestIsa',
        message: 'ERROR INFO ' + error,
        level: Sentry.Severity.Warning
      })
      reject(error)
    }
  })
}

export const postDataScreenCurrent = async (crid, data) => {
  return new Promise(async (resolve, reject) => {
    const body = {
      data: data
    }
    try {
      const dataIsa = await api.post(`/isa/${crid}`, body)
      resolve(dataIsa)
    } catch (error) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequestIsa',
        message: 'ERROR INFO ' + error,
        level: Sentry.Severity.Warning
      })
      reject(error)
    }
  })
}

export const getDataScreenPrevious = (crid, uniqueName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/isa/${crid}/screen/${uniqueName}`)
      resolve(data)
    } catch (error) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequestIsa',
        message: 'ERROR INFO ' + error,
        level: Sentry.Severity.Warning
      })
      reject(error)
    }
  })
}

import React, { useContext } from 'react'
import * as S from './styles'
import { TemplateCancellation } from '../../../components/template-cancellation'
import { TemplateCancellationContext } from '../../../components/template-cancellation/context'
import { TemplateContext } from '../../../components/template/context'
import { useTypePayment } from './hooks'
import DataBank from './dataBank/dataBank'
import WithoutAccount from './withoutAccount'

export const TypeOfPayment = () => {
  const { selectCrid } = useContext(TemplateContext)
  const { setCancellationData, cancellationData } = useContext(TemplateCancellationContext)
  const { modePayment, setModePayment } = useTypePayment({ selectCrid, setCancellationData, cancellationData })
  const views = {
    bankAccount: <DataBank selectCrid={selectCrid} />,
    withoutAccount: <WithoutAccount />
  }

  return (
    <TemplateCancellation title="Solicitar cancelamento">
      <S.Container>
        <S.Title>Como você prefere receber o reembolso?</S.Title>
        <S.PaymentMode>
          <S.Label>
            <S.Radio
              type="radio"
              name="PaymentMode"
              value="bankAccount"
              onChange={({ target }) => setModePayment(target.value)}
            />
            <S.RadioText>Informando meus dados bancários</S.RadioText>
          </S.Label>
          <S.Label>
            <S.Radio
              type="radio"
              name="PaymentMode"
              value="withoutAccount"
              onChange={({ target }) => setModePayment(target.value)}
            />
            <S.RadioText>Não tenho uma conta</S.RadioText>
          </S.Label>
        </S.PaymentMode>
        {views[modePayment]}
      </S.Container>
    </TemplateCancellation>
  )
}

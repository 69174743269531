import React from 'react'
import { withFormik } from 'formik'
import { Button, Input, TextErrorInput, Form } from '@provi/provi-components'

const Formik = props => {
  const { handleChange, values, errors, touched, handleBlur, handleSubmit } = props

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        autoFocus={!values.incomeDetails}
        margin="normal"
        type="text"
        multiline
        placeholder="Escreva aqui ..."
        onChange={handleChange('incomeDetails')}
        onBlur={handleBlur('incomeDetails')}
        error={!!errors.incomeDetails}
        value={values.incomeDetails}
        rowsMax={7}
      />
      {errors.incomeDetails && touched.incomeDetails && <TextErrorInput text={errors.incomeDetails} />}

      <Button marginHorizontal={0} type="submit" text="Avançar" />
    </Form>
  )
}

export const FormFormik = withFormik({
  validateOnBlur: true,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: props => ({ incomeDetails: props.data ? props.data && props.data.value : '' }),
  handleSubmit: async ({ incomeDetails }, { props }) => {
    props.goToNextScreen(incomeDetails || '')
  }
})(Formik)

import React, { useContext } from 'react'
import SummaryCard from './summary-card'
import { TemplateContext } from '../../../components/template/context'
import { useSummary } from './hooks'

import { TemplateCancellation } from '../../../components/template-cancellation'

const Summary = () => {
  const { selectCrid, isMobile } = useContext(TemplateContext)
  const { refund } = useSummary({ selectCrid })

  return (
    <TemplateCancellation title="Solicitar cancelamento" showResume={false}>
      <SummaryCard selectCrid={selectCrid} refundSummary={refund} isMobile={isMobile} />
    </TemplateCancellation>
  )
}

export default Summary

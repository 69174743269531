import React, { useContext } from 'react'
import { Container, Button, Title, Form } from '@provi/provi-components'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { TemplateDeclaration } from '../../../components/template-declaration'
import { ContainerOptions, Option, OptionText } from './style'
import { usePaymentDate } from './hooks'

export const PaymentDate = () => {
  const { goToNextScreen, setDataModal, incomeStatementPath } = useContext(TemplateDeclarationContext)
  const [onSelectOption, onSubmit, value, options] = usePaymentDate(goToNextScreen, setDataModal, incomeStatementPath)

  return (
    <TemplateDeclaration>
      <Container>
        <Form onSubmit={onSubmit}>
          <Title text="Qual é a melhor data para você pagar esse boleto?" />
          <ContainerOptions>
            {options.map(option => (
              <Option key={option.label} isSelected={value && value.id === option.id} onClick={() => onSelectOption(option)}>
                <OptionText isSelected={value && value.id === option.id}>Próximo dia</OptionText>
                <OptionText bold isSelected={value && value.id === option.id}>
                  {option.label}
                </OptionText>
              </Option>
            ))}
          </ContainerOptions>

          <Button marginHorizontal={0} type="submit" disabled={!value} text="Avançar" />
        </Form>
      </Container>
    </TemplateDeclaration>
  )
}

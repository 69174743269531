import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getFinancingInvoices, getInvoiceUnique, getDuplicateInvoice } from '../../../services/api'
import { history } from '../../../navigation/history'
import { showToast } from '@provi/provi-components'
import { MODE_DASH } from '../../../constants'

export const useInvoices = (setMenuActive, setIsBack, setIsLoading, selectCrid, typeDash) => {
  const { status } = useParams()
  const [data, setData] = useState([])
  const [info, setInfo] = useState()
  const [invoice, setInvoice] = useState([])
  const toggle = status === 'pagas' ? 'paid' : 'open'

  const handleToggle = useCallback(
    toggleStatus => {
      if (toggleStatus === 'paid') history.push('/dashboard/faturas/pagas')
      else history.push('/dashboard/faturas/abertas')
    },
    [toggle]
  )

  const getData = useCallback(
    async id => {
      setIsLoading(true)
      try {
        const response = await getFinancingInvoices(id)
        if (typeDash === MODE_DASH.allowance) {
          setData([])
          setInvoice(response.invoices)
          setInfo({})
        } else {
          setData([])
          setInvoice(response)
        }
      } catch (err) {
        if (err?.response?.status !== 401) {
          showToast('Error, por favor tente novamente')
        }
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading, typeDash]
  )

  useEffect(() => {
    if (selectCrid) {
      getData(selectCrid.id)
    } else {
      history.push('/inicio')
    }

    setMenuActive('invoices')
    setIsBack(true)
  }, [getData, selectCrid, setIsBack, setIsLoading, setMenuActive])

  const getInvoice = async item => {
    if (item.status === 'delayed' && item.InvoiceId) {
      try {
        setIsLoading(true)
        const { data: dataDuplicateInvoice } = await getDuplicateInvoice(item)
        window.open(dataDuplicateInvoice.url, '_blank')
        getData(selectCrid.id)
      } catch (err) {
        if (err.response) {
          showToast(err.response.data.message)
          return
        }
        showToast()
      } finally {
        setIsLoading(false)
      }
    } else {
      if (item.url) {
        window.open(item.url, '_blank')
        return
      }

      try {
        setIsLoading(true)
        const { data: dataInvoice } = await getInvoiceUnique(item)
        window.open(dataInvoice.url, '_blank')
        getData(selectCrid.id)
      } catch (err) {
        if (err.response) {
          showToast(err.response.data.message)
          return
        }
        showToast()
      } finally {
        setIsLoading(false)
      }
    }
  }
  return [data, invoice, info, toggle, handleToggle, getInvoice]
}

import { useCallback } from 'react'
import { history } from '../../../navigation/history'

export const useUpgradeAllowanceSuccess = () => {
  const goBack = useCallback(() => {
    history.push('/dashboard/mesadas/abertas')
  })

  return { goBack }
}

import React from 'react'
import * as S from '../styles'

export const DescriptionText = ({ title, description, isLoading, isMobile }) => {
  return (
    <>
      <S.Title>{title}</S.Title>
      {isLoading ? (
        <S.StyledLoadingDescription isLoading hasHeading containerHeight="120px" containerWidth={isMobile ? '100%' : '408px'} />
      ) : (
        <S.Description>{description}</S.Description>
      )}
    </>
  )
}

import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const Container = styled.div`
  width: 544px;
  background: #fff;
  padding: 20px;
  border: 2px solid ${theme.colors.bluePrimaryLight};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 4px;
  @media (max-width: 1250px) {
    width: 100%;
  }
`

export const Title = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.015em;
  color: #000000;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
`
export const PaymentMode = styled.div`
  margin-top: 16px;
`

export const Radio = styled.input`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9px 7px;
  position: static;
  width: 20px;
  height: 20px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border: 1px solid ${theme.colors.bluePrimary};
  box-sizing: border-box;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px 0px 0px;
`
export const RadioText = styled.span`
  max-width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px;
`

export const Label = styled.div`
  display: flex;
  margin-top: 16px;
  max-width: 100%;
`

export const DivButton = styled.div`
  margin-top: 48px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const ContentWithoutAccount = styled.div`
  width: 100%;
  height: max-content;
  border-top: 1px solid #cfcfcf;
  margin-top: 16px;
`

export const SubtitleWithoutAccount = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.015em;
  color: #000000;
  margin-top: 32px;
  margin-bottom: 24px;
`

export const ListSteps = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  li {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #000000;
  }
  span {
    font-weight: bold;
  }
`

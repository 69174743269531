import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Title = styled.h4`
  margin-top: 50px;
  color: ${theme.colors.blue900};
  font-size: 30px;
  line-height: 36px;
  font-family: CenturyGothicBold, sans-serif;
  ${theme.breakpoints.down('md')} {
    font-size: 24px;
    line-height: 30px;
  }
`

export const Text = styled.p`
  font-family: 'CenturyGothic', sans serif;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.blue900};
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const Wrap = styled.div`
  .MuiButtonBase-root .MuiButton-label {
    font-size: 12px;
  }
`

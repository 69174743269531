import { InputFieldV3 } from '@provi/provi-components'
import React, { useContext } from 'react'
import { TemplateCancellation } from '../../../components/template-cancellation'
import { TemplateCancellationContext } from '../../../components/template-cancellation/context'
import { TemplateContext } from '../../../components/template/context'
import { useReason } from './hooks'
import * as S from './styles'

export const Reason = () => {
  const { selectCrid } = useContext(TemplateContext)
  const { setCancellationData, cancellationData } = useContext(TemplateCancellationContext)
  const {
    optionsReason,
    onChangeReason,
    valueReason,
    showOtherSelector,
    showFreeInput,
    subOptionsReason,
    valueSubReason,
    onChangeSubReason,
    isLoading,
    buttonDisabled,
    goToNextStep
  } = useReason({ selectCrid, setCancellationData, cancellationData })

  return (
    <TemplateCancellation title="Solicitar cancelamento">
      <S.Container>
        <S.Title>Por que você deseja cancelar a compra deste curso?</S.Title>
        <S.StyledSelect
          width="100%"
          onChange={onChangeReason}
          options={optionsReason}
          value={valueReason}
          placeholder="Selecione o motivo"
        />
        {showOtherSelector && (
          <S.StyledSelectSubReason
            onChange={onChangeSubReason}
            options={subOptionsReason}
            value={valueSubReason}
            width="100%"
            placeholder="Selecione o motivo"
          />
        )}
        {showFreeInput && <InputFieldV3 onChange={onChangeSubReason} width="100%" placeholder="Escreva o motivo" />}

        <S.ButtonWrapper>
          <S.StyledButton
            onClick={goToNextStep}
            disabled={buttonDisabled}
            isLoading={isLoading}
            text="Avançar"
            icon
            width="121px"
          />
        </S.ButtonWrapper>
      </S.Container>
    </TemplateCancellation>
  )
}

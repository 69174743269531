import { theme } from '@provi/provi-components'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const HeaderContainer = styled.header`
  padding: 25px 0;
  display: flex;
  justify-content: space-between;

  ${({ showBorder }) =>
    showBorder &&
    css`
      border-bottom: 1px solid #cfcfcf;
    `}
`

export const TitleHeader = styled.h2`
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  color: ${theme.colors.blue900};
  font-weight: bold;
  @media (max-width: 800px) {
    font-size: 20px;
  }
`

export const ButtonHeader = styled(Link)`
  min-width: 230px;
  width: 210px;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  padding: 5px 10px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
`

export const TextButton = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.colors.bluePrimaryDark};
`

import axios from 'axios'
import * as Sentry from '@sentry/browser'
import { v4 as uuidv4 } from 'uuid'
import { showToast } from '@provi/provi-components'

import { history } from '../navigation/history'
import { LOCALSTORAGE_TOKENS, ROOT_URL } from '../constants'

import { logger } from '../utils/logger'

const api = axios.create({
  baseURL: ROOT_URL.dashboard
})

const transactionId = uuidv4().replace(/-/g, '')

export const logout = () => {
  Sentry.configureScope(scope => scope.setUser(null))
  localStorage.clear()
  history.push('/token-invalido')
}

api.interceptors.request.use(
  config => {
    const tokenOther = localStorage.getItem(LOCALSTORAGE_TOKENS.token_provi_sign)
    const tokenUser = localStorage.getItem(LOCALSTORAGE_TOKENS.token)
    if (config.headers.type === 'user') {
      if (tokenUser) {
        Sentry.configureScope(function(scope) {
          scope.setTag('x-request-id', transactionId)
        })
        config.headers['x-request-id'] = transactionId
        config.headers.Authorization = tokenUser
      } else {
        // logout()
      }
    } else {
      if (tokenOther) {
        Sentry.configureScope(function(scope) {
          scope.setTag('x-request-id', transactionId)
        })
        config.headers['x-request-id'] = transactionId
        config.headers.Authorization = tokenOther
      }
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => response,
  error => {
    logger(error)
    return Promise.reject(error)
  }
)

export const validateSigner = (token, crid, type) => {
  const body = {}
  if (crid) {
    body.CreditRequestId = crid.id
  }
  body.token = token

  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.post('/provi-sign/validate-signer', body, {
        headers: { type: type, Authorization: token }
      })
      resolve(data)
    } catch (error) {
      logger(error)
      reject(error)
    }
  })
}

export const getRecipients = async CreditRequestId => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`/provi-sign/${CreditRequestId}/get/recipients`)
      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequest',
        message: `ERROR INFO ${err}`,
        level: Sentry.Severity.Warning
      })
      reject(err)
    }
  })
}

export const sendCode = type => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.get('/provi-sign/send-sms-user', {
        headers: { type }
      })
      resolve(data)
    } catch (error) {
      logger(error)
      reject(error)
    }
  })
}

export const checkCode = (code, type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.post('/provi-sign/verify-token', code, {
        headers: { type }
      })
      resolve(data)
    } catch (error) {
      logger(error)
      reject(error)
    }
  })
}

export const getCCB = (body, type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.post('/provi-sign/create', body, {
        headers: { type }
      })
      resolve(data)
    } catch (error) {
      const errorObject = error.response && error.response.data && error.response.data.error
      if (errorObject && errorObject.name === 'InvalidAllowanceRequestPeriod') {
        showToast(errorObject.message)
        return
      }

      logger(error)
      reject(error)
    }
  })
}

export const getGuarantorInfos = (body, type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.post('/provi-sign/get-crid-info', body, {
        headers: { type }
      })
      resolve(data)
    } catch (error) {
      logger(error)
      reject(error)
    }
  })
}

export const validateCPF = (body, type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.post('/provi-sign/validate/cpf', body, {
        headers: { type }
      })
      resolve(data)
    } catch (error) {
      logger(error)
      reject(error)
    }
  })
}

export const validateCNPJ = (body, type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.post('/provi-sign/validate/cnpj', body, {
        headers: { type }
      })
      resolve(data)
    } catch (error) {
      logger(error)
      reject(error)
    }
  })
}

import React from 'react'

import { LoadingSkeleton } from '../../../../components/LoadingSkeleton'
import { Row } from '../components/row'
import * as S from '../styles'

export const TableInstallments = ({ collapsed, installments, isLoading, isMobile }) => {
  const installmentsCollapsed = collapsed ? installments.filter((_installment, index) => index <= 5) : installments

  return (
    <>
      {isLoading ? (
        <LoadingSkeleton isLoading hasHeading containerHeight="264px" containerWidth={isMobile ? '100%' : '408px'} />
      ) : (
        <S.TableContainer height={38 * installmentsCollapsed.length + 34} collapsed={collapsed}>
          <S.HeaderWrapper>
            <S.HeaderText showRadius position="left">
              Fatura
            </S.HeaderText>
            <S.HeaderText>Valor a pagar</S.HeaderText>
            <S.HeaderText showRadius position="right">
              Vencimento
            </S.HeaderText>
          </S.HeaderWrapper>
          {installmentsCollapsed.map((row, index) => (
            <Row length={installmentsCollapsed.length} index={index} row={row} key={index} text="Parcela" />
          ))}
        </S.TableContainer>
      )}
    </>
  )
}

import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f6f8fb;
  font-family: 'Montserrat', sans-serif;
  overflow: auto;
`

export const Header = styled.header`
  border-bottom: 1px solid #cfcfcf;
  padding: 24px 104px;
  margin: 0 24px;

  ${theme.breakpoints.down('md')} {
    padding: 25px 80px;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 25px;
    margin: 0 25px 0 25px;
  }
`

export const TitleHeader = styled.h2`
  margin: 0;
  font-size: 24px;
  color: ${theme.colors.blue900};
  font-weight: 600;
`

export const Title = styled.h3`
  color: #414141;
  margin: 25px 0 25px 130px;

  ${theme.breakpoints.down('md')} {
    margin: 25px 0 25px 80px;
  }

  ${theme.breakpoints.down('sm')} {
    margin: 25px;
  }
`

export const WrapperCrids = styled.div`
  padding: 0 25px 0 130px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  margin-bottom: 50px;

  ${theme.breakpoints.down('md')} {
    padding: 0 80px;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 0 25px;
  }
`

export const BoxCrid = styled.div`
  height: 200px;
  width: 30%;
  min-width: 350px;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  position: relative;

  ${({ isBlocked }) =>
    isBlocked &&
    css`
      background-color: #eaeaea;
    `}

  ${({ hover }) =>
    hover &&
    css`
      cursor: pointer;
      transition: filter 0.2s;
      &:hover {
        filter: brightness(0.96);
      }
    `}

  ${theme.breakpoints.down('sm')} {
    min-width: 100%;
  }
`

export const BoxLoading = styled.div`
  height: 200px;
  width: 30%;
  min-width: 350px;
  border-radius: 5px;
  ${theme.breakpoints.down('sm')} {
    min-width: 100%;
  }
`

export const StatusCrid = styled.div`
  position: absolute;
  bottom: 0;
  background-color: ${props => props.color};
  width: 100%;
  height: 45px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0 0 5px 5px;

  color: #414141;
  font-weight: bold;
`

export const ContainerCrid = styled.div`
  padding: 10px;
  display: flex;
  height: 140px;
  column-gap: 10px;
`

export const BoxLogo = styled.div`
  min-width: 80px;
  width: 80px;
  height: 80px;
  border-radius: 5px;

  color: #fff;
  font-weight: bold;
  font-size: 26px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    min-width: 80px;
    width: 80px;
    height: 80px;

    @media (max-width: 370px) {
      min-width: 60px;
      width: 60px;
      height: 60px;
    }
  }
  @media (max-width: 370px) {
    min-width: 60px;
    width: 60px;
    height: 60px;
  }
`

export const ContentCrid = styled.div`
  position: relative;
  p {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #414141;
    margin-bottom: 10px;
  }

  h3 {
    margin: 0;
    font-size: 16px;
    color: ${theme.colors.blue900};
  }

  span {
    font-size: 14px;
    color: #b0afaf;
    position: absolute;
    margin-top: 17px;
  }
`
export const ContainerResMed = styled.div`
  padding: 25px 130px 0 130px;

  ${theme.breakpoints.down('md')} {
    padding: 25px 80px;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 25px;
  }
`

export const ResMedOffer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 25px;

  width: 100%;

  ${theme.breakpoints.down('sm')} {
    align-items: flex-start;
  }

  h1 {
    margin: 0;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    color: ${theme.colors.blue900};
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;

    img {
      margin-right: 11px;
    }

    ${theme.breakpoints.down('sm')} {
      align-items: flex-start;
    }
  }

  p {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: ${theme.colors.blue900};
    margin-top: 10px;
  }

  strong {
    margin-top: 20px;
    color: ${theme.colors.blue900};
    span {
      font-weight: normal;
      text-decoration: line-through;
    }
  }
`

export const ResMedOfferButtonsContainer = styled.div`
  display: flex;
  margin-top: 20px;
  column-gap: 20px;
  width: 100%;
  justify-content: center;

  ${theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    color: ${theme.colors.blue900};
    font-family: 'Montserrat' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  > p {
    font-family: 'Montserrat' sans-serif;
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${theme.colors.blue900};
  }

  .date-container {
    margin-bottom: 23px;
    strong {
      margin-right: 5px;
    }
  }

  ul {
    padding-left: 18px;
    margin-bottom: 29px;

    li {
      font-family: 'Montserrat' sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: ${theme.colors.blue900};
    }
  }
`

export const ButtonCloseModal = styled.p`
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.bluePrimary} !important;
  cursor: pointer;
`
export const AlignButtons = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
  text-align: center;
`

import { history } from '../../../navigation/history'

export const useMenuOption = () => {
  const pathName = history.location.pathname

  const pathNameId = window.location.href
    .replace('https://', '')
    .replace('http://', '')
    .split('/')
    .filter(string => string.includes('#'))

  const formatPathId = path => {
    return path.split('/').filter(string => string.includes('#'))
  }

  const getActive = path => {
    if (path.includes('#')) {
      return pathNameId[0] === encodeURI(formatPathId(path)[0])
    }

    return pathName === path
  }

  return [getActive]
}

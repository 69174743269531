import React from 'react'
import { TextEmpty } from '../styles'

export const EmptyView = ({ toggle, effected, not_effected }) => {
  return (
    <>
      {toggle === 'effected' && effected && effected.length == 0 ? (
        <TextEmpty>Você ainda não possui declarações efetuadas</TextEmpty>
      ) : null}
      {toggle === 'not_effected' && not_effected && not_effected.length == 0 ? (
        <TextEmpty>A sua próxima declaração ainda não está disponível</TextEmpty>
      ) : null}
    </>
  )
}

import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'

export const Margin = styled.p`
  @media (min-width: 950px) {
    ${({ zeroMargin }) =>
      !zeroMargin &&
      css`
        margin: 0px 0px 0px 35px;
        display: flex;
        align-items: center;
      `}

    ${({ effected }) =>
      !effected &&
      css`
        margin: 0px 0px 0px 80px;
      `}
  }
`

export const ItemInvoice = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media (min-width: 950px) {
    flex-direction: row;
    height: 72px;
    justify-content: space-between;
  }

  ${({ index }) =>
    index === 0 &&
    css`
      @media (min-width: 950px) {
        margin-top: 2px;
      }
    `}

  ${({ effected }) =>
    effected &&
    css`
      @media (max-width: 324px) {
        height: 250px;
      }
    `}
`

export const TextTitle = styled(Margin)`
  font-family: 'CenturyGothicBold', sans-serif;
  color: ${theme.colors.blue900};
  font-size: 15px;
  line-height: 30px;
  margin: 0;
  white-space: nowrap;
  width: 68px;

  @media (max-width: 950px) {
    font-size: 20px;
  }
`

export const TextModal = styled.div`
  margin-bottom: 50px;

  p {
    font-family: 'CenturyGothic', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colors.blue900};

    ${theme.breakpoints.down('md')} {
      font-size: 14px;
      line-height: 18px;
      margin-right: 8px;
      text-align: left;
    }
  }
`

export const Button = styled.div`
  width: ${({ width }) => (width ? width : '150px')};
  height: 40px;
  background: ${theme.colors.bluePrimary};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 950px) {
    width: 100%;
    bottom: 15px;
  }

  > p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
  }
`

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  @media (max-width: 950px) {
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors.bluePrimaryLight};
    padding-bottom: 10px;
  }
`
export const ContainerTitleEffected = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 950px) {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 950px) {
    flex-direction: row;
    width: 100%;
    margin-left: 10px;
    align-items: center;
  }
`
export const DateLimit = styled(Margin)`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  margin: 0px;
  display: flex;
  align-items: center;

  color: #414141;

  > a {
    display: flex;
    margin-left: 4px;
    line-height: 16px;
  }

  @media (min-width: 900px) {
    display: none;
  }
`

export const Date = styled(Margin)`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  margin: -3px 0px 0px 0px;

  color: #414141;

  width: 80px;

  @media (min-width: 950px) {
    margin: 0px 0px 0px 100px;
  }
`

export const StatusContent = styled.div`
  width: 132px;
  height: 32px;

  background: ${props => props.backgroundColor};
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    margin: 0px 0px 0px 20px;
  }

  @media (max-width: 950px) {
    margin: 0px;
  }

  > p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;

    color: #414141;
  }

  @media (min-width: 950px) {
    display: flex;
    align-items: center;
    margin: 0px 0px 0px 75px;

    ${({ effected }) =>
      !effected &&
      css`
        margin: 0px 0px 0px 100px;
      `}
  }

  ${({ effected }) =>
    effected &&
    css`
      @media (max-width: 1135px) {
        margin: 0px 0px 0px 31px;
      }
    `}
`

export const ContainerResume = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media (max-width: 950px) {
    justify-content: space-between;
  }
`

export const BoxInfo = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;

  @media (min-width: 950px) {
    margin: 0px 35px;
  }

  @media (max-width: 900px) {
    margin: 0px 20px;
  }

  @media (max-width: 1135px) {
    margin: 0px 0px 0px 15px;
  }

  @media (max-width: 800px) {
    margin: 0px 10px 0px 0px;
  }
`

export const Title = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #414141;

  margin: 0;
`

export const Value = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;

  color: #414141;

  margin: 0;
`

export const Declaration = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  margin-top: 12px;
  margin-bottom: 32px;
  > p {
    margin: 0;
    display: flex;
    align-items: center;
    > a {
      display: flex;
      margin-left: 6px;
      line-height: 16px;
    }
  }
`

export const Tool = styled.div`
  display: inline-block;
  position: relative;
  border-bottom: 1px dotted #666;
  text-align: left;
`

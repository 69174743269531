import React from 'react'
import { ButtonV3 } from '@provi/provi-components'

import { CloseIcon } from '../../../../assets/svg/close'
import { currencyParser } from '../../../../utils'
import * as S from './styles'

export const Modal = ({
  modalRef,
  closeModal,
  onSubmit,
  isLoading,
  newAllowances,
  oldAllowances,
  day,
  month,
  oldAllowancesValue = 0,
  newAllowancesValue = 0,
  extraInformation,
}) => {
  const { requireNewSignature, firstAllowanceMonth, firstAllowanceYear, lastAllowanceMonth, lastAllowanceYear } = extraInformation

  const createText = (custom) => `Ao confirmar no botão abaixo, você receberá um email para assinar um novo contrato e garantir o upgrade ${custom}`

  const text = createText(`entre os meses de ${firstAllowanceMonth}/${firstAllowanceYear} e ${lastAllowanceMonth}/${lastAllowanceYear}, que tiveram sua assinatura antecipada.`)
  const textForOnlyOneMonth = createText(`para o mes de ${firstAllowanceMonth}/${firstAllowanceYear} que teve sua assinatura antecipada.`)
  const extraSignatureText = firstAllowanceMonth && lastAllowanceMonth ? text : textForOnlyOneMonth

  return (
    <S.StyledModal ref={modalRef} requireNewSignature={requireNewSignature}>
      <S.HeaderWrapper>
        <S.Title>Confirmação de aumento</S.Title>
        <S.IconContainer onClick={closeModal}>
          <CloseIcon />
        </S.IconContainer>
      </S.HeaderWrapper>
      <S.Text>
        Você vai aumentar sua Sanar Up Mesada de {currencyParser(oldAllowancesValue || oldAllowances[0]?.value || 0, 'R$ ')}{' '} 
        para {currencyParser(newAllowancesValue || newAllowances[0]?.value || 0, 'R$ ')}. Clicando no botão abaixo, você confirma a mudança.
      </S.Text>
      {requireNewSignature && <S.Text>{extraSignatureText}</S.Text>}
      {requireNewSignature && <S.Text>As mesadas futuras ainda não assinadas já refletirão o novo valor com seu upgrade.</S.Text>}
      <S.Text>Você começará a receber o novo valor dia {day} de {month}.</S.Text>
      <S.ButtonsWrapper>
        <S.Cancel disabled={isLoading} onClick={closeModal}>Cancelar</S.Cancel>
        <ButtonV3 mobileWidth="100%" isLoading={isLoading} width="118px" height="48px" text="Confirmar" onClick={onSubmit} />
      </S.ButtonsWrapper>
    </S.StyledModal>
  )
}

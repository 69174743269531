import React from 'react'

export const IconLocked = () => (
  <svg width="39" height="45" viewBox="0 0 39 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.8889 21H5.11111C2.84061 21 1 22.8723 1 25.1818V39.8182C1 42.1277 2.84061 44 5.11111 44H33.8889C36.1594 44 38 42.1277 38 39.8182V25.1818C38 22.8723 36.1594 21 33.8889 21Z"
      fill="var(--brandPrimaryDark)"
      stroke="var(--brandPrimaryDark)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 21V13C9 10.3478 10.1062 7.8043 12.0754 5.92893C14.0445 4.05357 16.7152 3 19.5 3C22.2848 3 24.9555 4.05357 26.9246 5.92893C28.8938 7.8043 30 10.3478 30 13V21"
      stroke="var(--brandPrimaryDark)"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="20" cy="31" r="4" fill="var(--brandPrimary)" />
    <path d="M20 33L23.4641 39H16.5359L20 33Z" fill="var(--brandPrimary)" />
  </svg>
)

import { useState, useEffect, useCallback } from 'react'
import { showToast } from '@provi/provi-components'

import { getFinancingContracts } from '../../../services/api'

export const useContracts = ({ setMenuActive, setIsBack, setIsLoading, selectCrid }) => {
  const [data, setData] = useState({})
  const [noContractsMessageDisplay, setNoContractsMessageDisplay] = useState(false)

  const getData = useCallback(async id => {
    setIsLoading(true)
    try {
      const response = await getFinancingContracts(id)
      if (Object.keys(response).length === 0 || !response.contracts || response.contracts.length < 0) {
        setNoContractsMessageDisplay(true)
      }
      setData(response)
    } catch (err) {
      if (err?.response?.status !== 401) {
        showToast('Erro, por favor tente novamente')
      }
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (selectCrid) {
      getData(selectCrid.id)
    }

    setMenuActive('contracts')
    setIsBack(true)
  }, [selectCrid])

  return { data, noContractsMessageDisplay }
}

import React from 'react'

export const IconBlockedAllowance = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2495 6.875H3.74951C3.40433 6.875 3.12451 7.15482 3.12451 7.5V16.25C3.12451 16.5952 3.40433 16.875 3.74951 16.875H16.2495C16.5947 16.875 16.8745 16.5952 16.8745 16.25V7.5C16.8745 7.15482 16.5947 6.875 16.2495 6.875Z"
      stroke="#575757"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.18701 6.875V4.0625C7.18701 3.31658 7.48333 2.60121 8.01077 2.07376C8.53822 1.54632 9.25359 1.25 9.99951 1.25C10.7454 1.25 11.4608 1.54632 11.9882 2.07376C12.5157 2.60121 12.812 3.31658 12.812 4.0625V6.875"
      stroke="#575757"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

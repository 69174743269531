import React, { useContext, useEffect } from 'react'
import { Title } from '@provi/provi-components'
import { TemplateDeclaration } from '../../../components/template-declaration'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { FormFormik } from './form'
import { Container } from './styles'

export const SkillDetails = () => {
  const { goToNextScreen, incomeStatementPath, setDataModal } = useContext(TemplateDeclarationContext)

  useEffect(() => {
    setDataModal(null)
  }, [setDataModal])

  return (
    <TemplateDeclaration subTitle="Pesquisa de Acompanhamento">
      <Container>
        <Title text="Vamos falar sobre o seu desenvolvimento? Você sente falta de alguma habilidade ou conhecimento específico?" />
        <div
          style={{
            marginTop: '108px'
          }}
        >
          <FormFormik data={incomeStatementPath && incomeStatementPath.ReadSkillDetails} goToNextScreen={goToNextScreen} />
        </div>
      </Container>
    </TemplateDeclaration>
  )
}

import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Text = styled.p`
  font-size: 16px;
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothic', sans-serif;
  margin: 0;
  margin-bottom: 15px;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: 0 auto;
  text-align: center;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const ContainerBox = styled.div`
  height: 72px;
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  background-color: ${theme.colors.white100};
  margin-bottom: 20px;
  border-radius: 10px;
  padding-left: 25px;
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    opacity: 0.7;
  }

  ${theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`

export const ContainerBoxText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothic', sans-serif;
  margin: 20px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 18px;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Bold = styled.div`
  font-family: 'CenturyGothicBold', sans-serif;
  margin-left: 5px;

  ${theme.breakpoints.down('md')} {
    margin-left: 0;
  }
`

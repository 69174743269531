import { useEffect, useState } from 'react'
import { LOCALSTORAGE_TOKENS } from '../../../constants'
import { getGuarantorInfos } from '../../../services/provi-sign'

export const useIntroGuarantor = (setIsLoading, typeUser, objectValidation = null) => {
  const [data, setData] = useState()
  let nextScreenToGo = objectValidation?.phoneVerification ? '/provi-sign/confirmacao-telefone' : '/provi-sign/termos-contrato'

  useEffect(() => {
    setIsLoading(true)
    localStorage.removeItem(LOCALSTORAGE_TOKENS.invoice_active)
    const getData = async () => {
      try {
        const { data: dataGuarantor } = await getGuarantorInfos(
          {
            GuarantorId: objectValidation?.GuarantorId,
            CreditRequestId: objectValidation?.CreditRequestId
          },
          typeUser
        )
        setData(dataGuarantor)
      } catch (err) {
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    }

    if (objectValidation) getData()
  }, [objectValidation])

  return [data, nextScreenToGo]
}

import React, { useContext } from 'react'
import { LoadingProvi } from '@provi/provi-components'
import { TemplateContext } from '../../components/template/context'
import { useValidateToken } from './hooks'

export const ValidateToken = props => {
  const { setSelectCrid, setToken } = useContext(TemplateContext)
  useValidateToken(props.match.params, setSelectCrid, setToken)
  return <LoadingProvi />
}

import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import background_analysis_mobile from '../../../assets/images/background-declaration-success.png'

export const Container = styled.div`
  box-sizing: border-box;
  padding-left: 114px;
  padding-right: 20px;
  height: 100vh;
  background-color: ${theme.colors.blue900};
  background-image: url(${background_analysis_mobile});
  background-repeat: no-repeat;
  background-position: right bottom;
  width: 100%;
  display: flex;
  align-items: center;

  ${theme.breakpoints.down('lg')} {
    font-size: 24px;
  }

  ${theme.breakpoints.down('md')} {
    padding-left: 20px;
    background-image: none;
  }
`

export const SubTitle = styled.p`
  font-family: CenturyGothicBold, sans-serif;
  color: ${theme.colors.blue600};
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 80px;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
  }
`
export const Text = styled.p`
  font-family: 'CenturyGothic', sans serif;
  font-size: 20px;
  line-height: 30px;
  color: ${theme.colors.white100};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}px`};
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Wrapper = styled.div`
  width: 640px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }

  div {
    margin-top: 64px;
  }
  .MuiButton-label {
    font-size: 14px;

    svg {
      display: none;
    }
  }
`

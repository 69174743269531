import { ROUTES } from '../navigation/routes'
import moment from 'moment'
import { LOCALSTORAGE_TOKENS } from '../constants'

export function currencyParser(value, prefix = '') {
  let stringValue = String(value) // eslint-disable-line
  let integers = []
  let decimals = ''

  const sign = /-.+/.test(stringValue) ? '-' : ''

  stringValue = stringValue
    .replace(/-/g, '')
    .replace(/,/g, '.')
    .replace(prefix, '')

  const splitedValues = stringValue.split('.')

  decimals = splitedValues.length > 1 ? splitedValues.pop() : '00'
  const integerValues = splitedValues.join('')

  integers = placeDots(integerValues)

  decimals = decimals.length < 2 ? `${decimals}0` : decimals.substring(0, 2)

  return `${prefix + sign + integers},${decimals}`
}

export function placeDots(string) {
  let iteration = -2
  const arrayWithDots = []

  for (let i = string.length - 1; i > -1; i -= 1) {
    if (iteration % 3 === 0 && i !== 0) {
      arrayWithDots.push(`.${string[i]}`)
    } else {
      arrayWithDots.push(string[i])
    }

    iteration += 1
  }

  return arrayWithDots.reverse().join('')
}

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

export const formatCPF = cpf => {
  if (!cpf) return ''
  return `${cpf.substr(0, 3)}.${cpf.substr(3, 3)}.${cpf.substr(6, 3)}-${cpf.substr(9, 2)}`
}

export const formatPhone = phone => {
  if (!phone) return ''

  if (phone.includes('+55')) {
    phone = phone.substr(3)
  }

  const ddd = `(${phone.substr(0, 2)})`

  const firstBlock = `${phone.length === 11 ? phone.substr(2, 5) : phone.substr(2, 4)}`
  const secondBlock = `${phone.length === 11 ? phone.substr(7) : phone.substr(6)}`

  const _phone = `${firstBlock}-${secondBlock}`

  return `${ddd} ${_phone}`
}

export const getPathname = nextScreen => {
  const pathname = ROUTES.find(element => element.uniqueName === nextScreen)
  return pathname.path
}

export const getPreviousMonthName = () => {
  const { date = new Date() } = JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKENS.incomedeclaration)) || {}

  return `${moment(date, 'DD/MM/YYYY')
    .subtract(1, 'months')
    .format('MMMM')}`
}

export const formatDate = d => {
  // YYYY-MM-DD to DD/MM/YYYY
  if (!d) return ''
  d = moment(d)
    .utcOffset(-3)
    .format()
  const dateHour = d.split('T')
  const date = dateHour[0].split('-')
  const years = date[0]
  const month = date[1]
  const days = date[2]
  return `${days}/${month}/${years}`
}

import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import BG_SUCCESS_CASE_CONFIRMATION_DESKTOP from '../../../assets/svg/bg-case-success-desktop.svg'

export const Container = styled.div`
  box-sizing: border-box;
  padding-left: 114px;
  padding-right: 20px;
  height: 100vh;
  background-color: ${theme.colors.blue900};
  background-image: url(${BG_SUCCESS_CASE_CONFIRMATION_DESKTOP});
  background-repeat: no-repeat;
  background-position: right bottom;
  width: 100%;
  display: flex;
  align-items: center;
  ${theme.breakpoints.down('md')} {
    background-image: none;
    padding-left: 20px;
  }
`

export const Title = styled.h4`
  margin-top: 50px;
  color: ${theme.colors.pink900};
  font-size: 30px;
  line-height: 36px;
  font-family: CenturyGothicBold, sans-serif;
  ${theme.breakpoints.down('md')} {
    font-size: 24px;
    line-height: 30px;
  }
`

export const SubTitle = styled.p`
  font-family: CenturyGothic, sans-serif;
  color: ${theme.colors.white100};
  font-size: 24px;
  line-height: 30px;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
  }
`

export const Wrapper = styled.div`
  width: 640px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
  .MuiButtonBase-root .MuiButton-label {
    font-size: 12px;
  }
`

import React, { useContext } from 'react'
import { TextLink } from '@provi/provi-components'
import { LOCALSTORAGE_TOKENS } from '../../../constants'
import { IconLinkedin, IconInstagram, IconFacebook, IconBlog } from '../../../assets/svg/icons-socials'
import { LogoProvi } from '../../../assets/svg/logo-provi'
import { TemplateContext } from '../../../components/template/context'
import { TemplateProviSignContext } from '../../../components/template-provisign/context'
import { history } from '../../../navigation/history'
import { Container, Title, SubTitle, Wrapper, ContainerSocial, Item, Recipients } from './styles'
import { useSignedSucess } from './hooks'

export const SignedSuccess = () => {
  const invoiceActive = localStorage.getItem(LOCALSTORAGE_TOKENS.invoice_active)
  const { objectValidation } = useContext(TemplateProviSignContext)
  const { isMobile } = useContext(TemplateContext)
  const [pendingRecipients] = useSignedSucess(objectValidation && objectValidation.CreditRequestId)

  return (
    <Container isMobile={isMobile}>
      <Wrapper>
        <LogoProvi />
        <Title>Assinatura concluída com sucesso!</Title>
        {pendingRecipients.length ? (
          <Recipients>
            Assinaturas restantes: {pendingRecipients.map(recipient => recipient)}
            <br />
          </Recipients>
        ) : null}

        {invoiceActive && objectValidation && !objectValidation.GuarantorId && !objectValidation.systemSignature && (
          <TextLink text="Voltar para o dashboard" color="white" onClick={() => history.push('/dashboard/faturas/abertas')} />
        )}

        {objectValidation && !objectValidation.systemSignature && (
          <>
            <SubTitle>Siga a gente nas redes sociais!</SubTitle>
            <ContainerSocial>
              <Item href="https://www.linkedin.com/company/provi/">
                <IconLinkedin />
              </Item>
              <Item href="https://www.instagram.com/sejaprovi/">
                <IconInstagram />
              </Item>
              <Item href="https://www.facebook.com/SejaProvi/">
                <IconFacebook />
              </Item>
              <Item href="https://blog.provi.com.br/">
                <IconBlog />
              </Item>
            </ContainerSocial>
          </>
        )}
      </Wrapper>
    </Container>
  )
}

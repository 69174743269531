import { useState, useEffect, useRef, useCallback } from 'react'
import { showToast } from '@provi/provi-components'

import { saveBankAccount, getProfileData } from '../../../../../services/api'

export const useBankAccountSection = (selectCrid, token) => {
  const [isBankAccountInEditMode, setIsBankAccountInEditMode] = useState(false)
  const [canEditBankAccount, setCanEditBankAccount] = useState(false)
  const [showPopover, setShowPopover] = useState(false)
  const [allowSubmit, setAllowSubmit] = useState(true)
  const [bankOptions, setBankOptions] = useState([{ label: 'Carregando...', value: 0 }])
  const [loadingPercent, setLoadingPercent] = useState(0)
  const [loadingBarIsLoading, setLoadingBarIsLoading] = useState(false)
  const [showErrorCard, setShowErrorCard] = useState(false)
  const [showInfoCard, setShowInfoCard] = useState('')
  const bankDataBackup = useRef(null)

  const [bankAccountData, setBankAccountData] = useState({
    accountType: '',
    agency: '',
    agencyDigit: '',
    account: '',
    accountDigit: '',
    financialInstitution: {
      label: '',
      value: 0
    }
  })

  const [bankAccountFormErrors, setBankAccountFormErrors] = useState({
    agency: {
      message: '',
      hasError: false,
      isValid: false,
      minLength: 4
    },
    agencyDigit: {
      message: '',
      hasError: false,
      isValid: false,
      minLength: 0
    },
    account: {
      message: '',
      hasError: false,
      isValid: false,
      minLength: 4
    },
    accountDigit: {
      message: '',
      hasError: false,
      isValid: false,
      minLength: 1
    }
  })

  const handleOnBlur = useCallback(
    (inputName, message) => {
      if (bankAccountData[inputName].length >= bankAccountFormErrors[inputName].minLength) {
        return setBankAccountFormErrors(prevState => {
          return { ...prevState, [inputName]: { ...prevState[inputName], isValid: true, message: '', hasError: false } }
        })
      }

      if (bankAccountData[inputName].length === 0) {
        return setBankAccountFormErrors(prevState => {
          return { ...prevState, [inputName]: { ...prevState[inputName], message, hasError: true, isValid: false } }
        })
      }

      return setBankAccountFormErrors(prevState => {
        return { ...prevState, [inputName]: { ...prevState[inputName], message: '', hasError: false, isValid: false } }
      })
    },
    [bankAccountData]
  )

  const handleLoadingBarCount = () => {
    setLoadingPercent(prevState => {
      if (prevState <= 90) {
        setTimeout(handleLoadingBarCount, 100)

        return prevState + 1
      }

      return prevState
    })
  }

  const getBankAccountData = async () => {
    try {
      const { bankAccount, alreadyRequestedAllowance, bankAccountLimit } = await getProfileData(token, selectCrid?.id)
      const { agency, agencyDigit, accountType, account, accountDigit, options, FinancialInstitution } = bankAccount

      const accountData = {
        agency,
        agencyDigit: agencyDigit !== '0' ? agencyDigit : '',
        accountType,
        account,
        accountDigit,
        financialInstitution: {
          label: FinancialInstitution.name,
          value: FinancialInstitution.id
        }
      }

      setBankAccountData(accountData)
      bankDataBackup.current = accountData

      setBankOptions(options.map(option => ({ label: option.label, value: option.id })))

      if (alreadyRequestedAllowance) {
        setShowPopover(true)
      }

      if (bankAccountLimit?.isLimitReached) {
        if (bankAccountLimit?.reason === 'five_bank_accounts_limit')
          return setShowInfoCard('Não conseguimos validar os seus dados bancários. Tente novamente após 24h.')

        return setShowInfoCard('Não conseguimos validar seus dados bancários, entre em contato com atendimento')
      }

      setCanEditBankAccount(true)
    } catch {
      showToast('Ocorreu um erro ao tentar carregar seus dados bancários.')
    }
  }

  const handleInputChange = (key, value) => {
    if (isBankAccountInEditMode) {
      setBankAccountData(prevState => ({ ...prevState, [key]: value }))
    }
  }

  const handleCancelEdit = () => {
    setBankAccountData(bankDataBackup.current)
    setIsBankAccountInEditMode(false)
    setBankAccountFormErrors({
      agency: {
        message: '',
        hasError: false,
        isValid: false,
        minLength: 4
      },
      agencyDigit: {
        message: '',
        hasError: false,
        isValid: false,
        minLength: 0
      },
      account: {
        message: '',
        hasError: false,
        isValid: false,
        minLength: 4
      },
      accountDigit: {
        message: '',
        hasError: false,
        isValid: false,
        minLength: 1
      }
    })
  }

  const handleBankAccountSubmit = async () => {
    try {
      handleLoadingBarCount()
      setLoadingBarIsLoading(true)
      setShowErrorCard(false)

      await saveBankAccount({
        ...bankAccountData,
        agencyDigit: bankAccountData.agencyDigit ? bankAccountData.agencyDigit : '0',
        financialInstitutionId: bankAccountData.financialInstitution.value,
        CreditRequestId: selectCrid?.id
      })

      setTimeout(() => setLoadingPercent(100), 1000)

      setTimeout(() => setLoadingBarIsLoading(false), 2000)

      setIsBankAccountInEditMode(false)
    } catch (err) {
      setLoadingBarIsLoading(false)
      setLoadingPercent(0)
      setShowErrorCard(true)
    }
  }

  useEffect(() => {
    getBankAccountData()
  }, [])

  useEffect(() => {
    const { account, accountDigit, agency, financialInstitution } = bankAccountData
    const isValid = account.length > 3 && accountDigit.length > 0 && agency.length > 3 && financialInstitution && !showPopover

    setAllowSubmit(isValid)
  }, [bankAccountData, showPopover])

  return {
    isBankAccountInEditMode,
    setIsBankAccountInEditMode,
    handleInputChange,
    bankAccountData,
    canEditBankAccount,
    bankOptions,
    showPopover,
    setShowPopover,
    allowSubmit,
    handleCancelEdit,
    handleBankAccountSubmit,
    loadingBarIsLoading,
    loadingPercent,
    handleOnBlur,
    bankAccountFormErrors,
    showErrorCard,
    showInfoCard
  }
}

import { TemplateProviSignContext } from './context'
import React, { useState } from 'react'
import { useScreenMeasure } from '../template/hooks'
import { useTemplateProviSign } from './hooks'

export const TemplateProviSignProvider = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [objectValidation, setObjectValidation] = useState()
  const [typeUser, setTypeUser] = useState()
  const [isMobile] = useScreenMeasure()
  useTemplateProviSign(setIsLoading, setObjectValidation, setTypeUser, typeUser)

  const value = {
    isLoading,
    setIsLoading,
    isMobile,
    objectValidation,
    setObjectValidation,
    typeUser,
    setTypeUser
  }

  return <TemplateProviSignContext.Provider value={value}>{props.children}</TemplateProviSignContext.Provider>
}

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForward from '@material-ui/icons/ArrowForward'
import { theme } from '@provi/provi-components'
import { ButtonWrapper, ContainerButton } from './style'

const useStyles = makeStyles({
  icon: {
    fontSize: theme.variables.spacer * 2,
    marginLeft: 10
  }
})

export const ButtonOutline = ({
  disabled,
  onClick,
  text,
  type = 'button',
  backgroundDisabled,
  icon,
  isWithoutIcon,
  marginVertical,
  marginHorizontal
}) => {
  const classes = useStyles()
  return (
    <ContainerButton marginVertical={marginVertical} marginHorizontal={marginHorizontal}>
      <ButtonWrapper
        backgroundDisabled={backgroundDisabled}
        variant="outlined"
        onClick={onClick}
        disabled={disabled}
        type={type}
      >
        {text}
        {!isWithoutIcon && <>{icon || <ArrowForward className={classes.icon} />}</>}
      </ButtonWrapper>
    </ContainerButton>
  )
}

ButtonOutline.defaultProps = {
  marginVertical: 24,
  marginHorizontal: 24,
  isWithoutIcon: false,
  onClick: undefined
}

ButtonOutline.propTypes = {
  background: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  backgroundDisabled: PropTypes.bool,
  marginVertical: PropTypes.number,
  marginHorizontal: PropTypes.number
}

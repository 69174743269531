import { useState, useEffect, useCallback } from 'react'
import { history } from '../../../navigation/history'
import { getToken, getReceiptTerm, getIncomeTaxUrl } from '../../../services/api'
import openDownload from '../../../utils/openDownload'

export const useNotificationsScreen = (notifications, setNotifications, selectCrid, setIsLoading) => {
  const [courseInfoModal, setCourseInfoModal] = useState({
    show: false,
    materials: []
  })

  useEffect(() => {
    for (const item of notifications) {
      const { notification, type } = item
      if (type === 2) {
        setCourseInfoModal(prevProps => ({ ...prevProps, ...notification }))
      }
    }
  }, [notifications])

  const handleOpenCourseInfo = () => {
    setCourseInfoModal(prevProps => ({ ...prevProps, show: true }))
  }

  const redirectToSimulation = useCallback(() => {
    return history.push('/provi-sign/alteracao-mesada')
  }, [])

  const handleCloseCourseInfo = () => {
    setCourseInfoModal(prevProps => ({ ...prevProps, show: false }))
  }

  const redirectToContract = useCallback(async () => {
    setIsLoading(true)
    const upgradeAllowanceObject = notifications.find(item => item.type === 8)
    const response = await getToken(selectCrid?.id, upgradeAllowanceObject)

    const { token: tokenRedirect } = await response.data

    setIsLoading(false)
    return history.push(`/provi-sign/carregando/${tokenRedirect}`)
  }, [selectCrid, notifications])

  const handleButtonClick = async (type, routeString = false) => {
    if (type === 3) {
      return redirectToContract()
    }

    if (type === 6) {
      return redirectToSimulation()
    }

    if (type === 5) {
      setIsLoading(true)
      const data = await getReceiptTerm(selectCrid?.id)

      openDownload(data?.url)
      setIsLoading(false)
    }

    if(type === 8) {
      return redirectToContract()
    }

    setNotifications(prevState => {
      const newState = [...prevState]
      return newState.filter(item => item.type !== type)
    })

    if (routeString) history.push(routeString)
  }

  const handleDeclarationDownload = async () => {
    const incomeTaxResponse = await getIncomeTaxUrl(selectCrid?.id)

    window.open(incomeTaxResponse?.urlIncomeTax, '_blank')
  }

  return [courseInfoModal, handleOpenCourseInfo, handleCloseCourseInfo, handleButtonClick, handleDeclarationDownload]
}

export default useNotificationsScreen

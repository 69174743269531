import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ContainerOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${theme.variables.spacer * 2}px;
  margin-bottom: 38px;

  ${theme.breakpoints.down('md')} {
    justify-content: space-between;
  }
`

export const Option = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px;
  height: ${theme.variables.spacer * 13.5}px;
  width: ${theme.variables.spacer * 16}px;
  box-shadow: 2px 1px 15px -9px rgba(0, 0, 0, 0.5);
  margin-right: ${theme.variables.spacer}px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 20px;
  background-color: ${({ isSelected }) => (isSelected ? theme.colors.blue900 : theme.colors.white100)};
  ${theme.breakpoints.down('md')} {
    width: 48%;
    margin: 0;
  }
`

export const OptionText = styled.p`
  font-family: ${({ bold }) => (bold ? 'CenturyGothicBold' : 'CenturyGothic')}, sans-serif;
  font-size: ${({ bold }) => (bold ? '30px' : '16px')};
  ${({ bold }) => bold && 'margin-top: 10px'};
  margin-bottom: 0;
  margin-top: 10px;
  color: ${({ isSelected }) => (isSelected ? theme.colors.white100 : theme.colors.blue900)};
`

import React from 'react'
import { Label, Value, ContainerBox } from './stylesV2'

export const CardAllowance = ({ data }) => (
  <ContainerBox>
    <Label>
      Total de mesadas: <Value>{data.amountOfAllowances}</Value>
    </Label>
    <Label>
      Recebidas: <Value>{data.receivedAllowances}</Value>
    </Label>
    <Label>
      Restantes: <Value>{data.remainingAllowances}</Value>
    </Label>
  </ContainerBox>
)

import { useEffect, useState } from 'react'
import { showToast } from '@provi/provi-components'
import { getDashboard } from '../../../services/api'
import { MODE_DASH } from '../../../constants'

export const usePanel = (setMenuActive, setIsBack, selectCrid, typeDash) => {
  const [data, setData] = useState({})
  const [allowances, setAllowances] = useState([])
  const [installments, setInstallments] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getData = async id => {
      setLoading(true)
      try {
        const response = await getDashboard(id)
        setData(response)
        if (typeDash === MODE_DASH.allowance) {
          setInstallments(response.installments)
          setAllowances(response.allowances)
        }
      } catch (err) {
        if (err?.response?.status !== 401) {
          showToast('Error, por favor tente novamente')
        }
      } finally {
        setLoading(false)
      }
    }

    if (selectCrid) {
      getData(selectCrid.id)
    }

    setMenuActive('panel')
    setIsBack(true)
  }, [selectCrid, setIsBack, setLoading, setMenuActive, typeDash])

  return { data, allowances, installments, loading }
}

import React from 'react'
import { currencyParser } from '../../../../utils'
import * as S from './styles'

const CardWirecard = data => {
  return (
    <S.CardRequestValueContainer>
      <S.CardRequestAccount>
        <span>Valor: </span> {currencyParser(data?.data.refundSummary.refundAmount, 'R$')}
      </S.CardRequestAccount>
      <S.CardRequestAccount>
        <span>Bandeira:</span> {data?.data.refundSummary.creditCardDetails.brand}
      </S.CardRequestAccount>
      <S.CardRequestAccount>
        <span>Cartão:</span> **** **** **** {data?.data.refundSummary.creditCardDetails.last4}
      </S.CardRequestAccount>
    </S.CardRequestValueContainer>
  )
}

export default CardWirecard

import React, { Component } from 'react'
import { Background } from './background'
import * as Sentry from '@sentry/browser'
class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error) {
    const eventId = Sentry.captureException(error)
    Sentry.showReportDialog({
      eventId: eventId,
      title: 'Parece que estamos com problemas.',
      subtitle: 'Nossa equipe foi notificada.',
      subtitle2: 'Se você gostaria de ajudar, conte-nos o que aconteceu abaixo.',
      labelName: 'Nome',
      labelEmail: 'Email',
      labelComments: 'O que aconteceu?',
      labelClose: 'Fechar',
      labelSubmit: 'Enviar Feedback',
      errorFormEntry: 'Alguns campos eram inválidos. Corrija os erros e tente novamente.',
      errorGeneric: 'Ocorreu um erro desconhecido ao enviar seu relatório. Por favor, tente novamente.',
      successMessage: 'Seu feedback foi enviado. Obrigado!'
    })
  }

  render() {
    if (this.state.hasError) {
      return <Background history={this.props.history} />
    }

    return this.props.children
  }
}

export default ErrorBoundary

import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  padding-left: 155px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 802px;
  z-index: 2;

  ${theme.breakpoints.down('md')} {
    padding-left: 29px;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 0 24px;
    align-items: center;
  }

  > h1 {
    color: ${theme.colors.blue900};
    font-family: 'CenturyGothic', sans-serif;

    ${theme.breakpoints.down('md')} {
      font-size: 1.75rem;
    }

    ${theme.breakpoints.down('sm')} {
      font-size: 1.25rem;
    }
  }
`

export const DoubtMenuContainer = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${props => (props.isDoubtMenuContainerToggled ? '495px' : '132px')};

  .doubt-menu-content {
    display: flex;
    justify-content: space-between;

    border-bottom: ${`1px solid ${theme.colors.blue900}`};

    .toggleDoubtMenu {
      background: transparent;
      border: none;

      font-family: 'CenturyGothic';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: ${theme.colors.blue900};
      margin-bottom: 4px;
      padding: 0;
      cursor: pointer;
    }

    .closeDoubtMenu {
      display: ${props => (props.isDoubtMenuContainerToggled ? 'inline' : 'none')};

      background: transparent;
      border: none;
      margin-bottom: 4px;
      cursor: pointer;
    }
  }

  .doubt-text-container {
    display: ${props => (props.isDoubtMenuContainerToggled ? 'flex' : 'none')};
    flex-direction: column;

    margin-top: 14px;
    font-family: 'CenturyGothic';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colors.blue900};
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 540px;
  justify-content: space-between;
  margin-top: 80px;

  ${theme.breakpoints.down('md')} {
    .MuiButton-root {
      width: 224px;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    div + div {
      margin-top: 12px;
    }
  }

  div {
    margin: 0;
  }
`

export const TextModal = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothic', sans-serif;
`

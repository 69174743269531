import React from 'react'
import { ButtonV3, Modal } from '@provi/provi-components'

const ContractText = () => {
  return (
    <ul>
      <li>Para uma renegociação de dívida, você precisa assinar um novo contrato de financiamento.</li>
      <li>Após a assinatura do novo contrato, você precisa voltar a esta tela e confirmar sua escolha.</li>
      <li>Caso o acordo não seja cumprido por completo você perderá todo o desconto ofertado nesta proposta.</li>
    </ul>
  )
}

export const ConfirmationModal = ({
  confirm,
  onDismiss,
  isLoadingSubmit,
  shouldGenerateContract,
  hasContract,
  isThirdOption,
  fullySigned
}) => {
  let defaultText = 'Caso o acordo não seja cumprido por completo você perderá todo o desconto ofertado nesta proposta.'

  const text = shouldGenerateContract ? <ContractText /> : defaultText

  let parsedText = hasContract
    ? 'Assine o contrato que enviamos no seu email. Caso já tenha assinado, estamos processando a sua assinatura. Em instantes, você poderá confirmar a sua escolha. Volte novamente mais tarde.'
    : text

  if (fullySigned) {
    parsedText = defaultText
  }

  return (
    <Modal
      title="Você deseja realmente seguir com essa negociação?"
      onDismiss={onDismiss}
      containerStyle={{
        maxWidth: 400,
        borderRadius: '8px',
        fontFamily: 'Montserrat, sans-serif'
      }}
    >
      <div>
        <p
          style={{
            fontFamily: 'Montserrat, sans-serif',
            listStyle: 'none',
            fontSize: '16px',
            lineHeight: '24px'
          }}
        >
          {parsedText}
        </p>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px'
          }}
        >
          {isThirdOption ? (
            <ButtonV3
              isLoading={isLoadingSubmit}
              onClick={() => confirm({ createRefinContract: true })}
              mobileWidth="100%"
              width="100%"
              text="Gerar contrato"
              disabled={hasContract || isLoadingSubmit}
              color="light"
            />
          ) : null}

          <ButtonV3
            onClick={() => confirm({ createRefinContract: false })}
            mobileWidth="100%"
            width="100%"
            text="Escolher opção"
            disabled={shouldGenerateContract || isLoadingSubmit}
          />
        </div>
      </div>
    </Modal>
  )
}

import React, { useContext } from 'react'
import { Button, LoadingProvi } from '@provi/provi-components'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { IconTimerIsa } from '../../../assets/svg/icon-timer-isa'
import { Timer, Container, Title, SubTitle, Text, Wrapper } from './style'
import { useIntro } from './hooks'

export const Intro = () => {
  const { setIncomeStatementPath, setIsLoading, isLoading, incomeStatementPath, goToNextScreen } = useContext(
    TemplateDeclarationContext
  )
  useIntro(setIncomeStatementPath, setIsLoading)
  const firstName = incomeStatementPath && incomeStatementPath.ReadFullName && incomeStatementPath.ReadFullName.split(' ')
  return (
    <>
      {isLoading && <LoadingProvi isLoadingDark={false} />}

      <Container>
        <Wrapper>
          <Timer>
            <IconTimerIsa /> <Text marginLeft={10}>Leva 7 min</Text>
          </Timer>
          <Title>Olá, {firstName && firstName.length > 0 && firstName[0]}!</Title>
          <SubTitle>Chegou aquele momento no mês de contar pra gente como que anda sua vida profissional.</SubTitle>
          <Text>
            Sabendo como você está e o que precisa, conseguimos direcionar todas as iniciativas da PIN (Provi in Network) pra te
            ajudar cada vez mais!
          </Text>
          <SubTitle>Vamos?</SubTitle>

          <Button marginHorizontal={0} marginVertical={0} text="Avançar" onClick={() => goToNextScreen()} />
        </Wrapper>
      </Container>
    </>
  )
}

import { theme } from '@provi/provi-components'
import React from 'react'

export const Chevron = ({ toggled }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.34292 7.75732L4.92871 9.17154L11.9998 16.2426L19.0708 9.17157L17.6566 7.75735L11.9998 13.4142L6.34292 7.75732Z"
      fill={toggled ? theme.colors.blueSecundary : theme.colors.bluePrimary}
    />
  </svg>
)

import React from 'react'
import { ButtonV3, Modal } from '@provi/provi-components'

import * as Styled from './styles'

const ResMedModal = ({ modalInfo, handleCloseModal, btnClick }) => {
  return (
    <Modal
      title={modalInfo.courseName}
      onDismiss={handleCloseModal}
      containerStyle={{
        maxWidth: 670,
        borderRadius: '5px'
      }}
    >
      <Styled.ModalContent>
        <h3>{modalInfo.target}</h3>

        <div>
          <p>
            <strong>Data de início: </strong>
            {modalInfo.courseInitiation}
          </p>

          <p>
            <strong>Data de termino: </strong>
            {modalInfo.courseEnding}
          </p>
        </div>

        <h4>
          <strong>Lista de materiais:</strong>
        </h4>

        <ul>
          {modalInfo.materials.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <Styled.AlignButtons>
          <ButtonV3 text="Quero Comprar" onClick={btnClick} />
          <Styled.ButtonCloseModal onClick={handleCloseModal}>Fechar</Styled.ButtonCloseModal>
        </Styled.AlignButtons>
      </Styled.ModalContent>
    </Modal>
  )
}

export default ResMedModal

import React from 'react'

export const BellIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.02474 9.99992C3.02308 8.81393 3.25618 7.63934 3.71059 6.54386C4.165 5.44838 4.83174 4.45367 5.67238 3.61707C6.51302 2.78047 7.51093 2.11852 8.60858 1.66938C9.70624 1.22025 10.8819 0.992809 12.0679 1.00017C17.0166 1.03696 20.975 5.15034 20.975 10.1129V10.9999C20.975 15.4769 21.9116 18.0748 22.7366 19.4947C22.8255 19.6464 22.8728 19.8189 22.8737 19.9947C22.8746 20.1705 22.8292 20.3435 22.7419 20.4962C22.6547 20.6488 22.5288 20.7758 22.3768 20.8642C22.2248 20.9527 22.0522 20.9995 21.8764 20.9999H2.12231C1.94646 20.9995 1.77384 20.9526 1.62187 20.8642C1.46989 20.7757 1.34393 20.6487 1.2567 20.496C1.16947 20.3434 1.12404 20.1704 1.12502 19.9945C1.12599 19.8187 1.17332 19.6462 1.26223 19.4945C2.08764 18.0745 3.02473 15.4766 3.02473 10.9999L3.02474 9.99992Z"
      stroke="#01163E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 21V22C8 23.0609 8.42143 24.0783 9.17157 24.8284C9.92172 25.5786 10.9391 26 12 26C13.0609 26 14.0783 25.5786 14.8284 24.8284C15.5786 24.0783 16 23.0609 16 22V21"
      stroke="#01163E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

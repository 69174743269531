import { PortalModal, theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'

export const StyledModal = styled(PortalModal)`
  width: 496px;

  ${({ requireNewSignature }) =>
    requireNewSignature
      ? css`
          height: 350px;
        `
      : css`
          height: 268px;
        `}

  @media (max-width: 800px) {
    height: auto;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
`

export const Text = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.0075em;

  color: #000000;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 24px 0px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`

export const Cancel = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;

  color: ${theme.colors.bluePrimary};

  cursor: pointer;

  @media (max-width: 800px) {
    margin-top: 20px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: #cfcfcf;
      cursor: default;
    `}
`

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: ${theme.colors.bluePrimary};
`

export const IconContainer = styled.div`
  cursor: pointer;
`

import { useRef, useCallback, useState, useEffect } from 'react'

import { showToast } from '@provi/provi-components'
import { createCancellation, getRefundSummary } from '../../../services/api'
import { logger } from '../../../utils/logger'
import { history } from '../../../navigation/history'

export const useSummary = ({ selectCrid, refundSummary }) => {
  const [isLoading, setIsLoading] = useState(false)
  const modalRef = useRef()
  const openModal = useCallback(() => {
    modalRef.current.openModalFn()
  }, [modalRef])

  const closeModal = useCallback(() => {
    modalRef.current.closeModalFn()
  }, [modalRef])

  const objRefundType = {
    wirecard: refundSummary?.ProductType === 'Pix' ? 'Reembolso por estorno na conta utilizada para pagamento' : 'Reembolso por estorno no cartão de crédito',
    starkbank: 'Reembolso por depósito em conta',
    'ordem de pagamento': ' Reembolso por ordem de pagamento'
  }

  const [refund, setRefound] = useState()

  const getRefundAmount = async () => {
    const data = await getRefundSummary(selectCrid?.id)
    setRefound(data)
  }

  useEffect(() => {
    getRefundAmount()
  }, [selectCrid])

  const accountType = {
    CONTA_POUPANCA: 'Conta poupança',
    CONTA_CORRENTE: 'Conta corrente'
  }

  const redirectReviewData = refundType => {
    return history.push(`/cancelamento/${!refundType || refundType === 'wirecard' ? 'motivo' : 'tipo-de-reembolso'}`)
  }

  const onSubmit = async data => {
    try {
      if (data) {
        setIsLoading(true)
        await createCancellation(selectCrid?.id, data)
        openModal()
        setIsLoading(false)
      }
    } catch (error) {
      logger(error)
      setIsLoading(false)

      showToast('Algo deu errado, por favor tente novamente em alguns minutos')
    }
  }

  return {
    objRefundType,
    accountType,
    onSubmit,
    modalRef,
    openModal,
    closeModal,
    refund,
    isLoading,
    redirectReviewData
  }
}

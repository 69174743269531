import React from 'react'

export const IconLock = () => (
  <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.66667 7.75V4.75C3.66667 3.75544 4.01786 2.80161 4.64298 2.09835C5.2681 1.39509 6.11595 1 7 1C7.88406 1 8.7319 1.39509 9.35702 2.09835C9.98214 2.80161 10.3333 3.75544 10.3333 4.75V7.75M2.33333 7.75H11.6667C12.403 7.75 13 8.42157 13 9.25V14.5C13 15.3284 12.403 16 11.6667 16H2.33333C1.59695 16 1 15.3284 1 14.5V9.25C1 8.42157 1.59695 7.75 2.33333 7.75Z"
      stroke="#CFCFCF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

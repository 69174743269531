import { ButtonV3 } from '@provi/provi-components'
import React, { useContext } from 'react'
import { TemplateCancellationContext } from '../../../components/template-cancellation/context'
import { useTypePayment } from './hooks'
import * as S from './styles'

const WithoutAccount = () => {
  const { setCancellationData, cancellationData } = useContext(TemplateCancellationContext)
  const { gotToNextStepPaymentOrder } = useTypePayment({ setCancellationData, cancellationData })
  return (
    <S.ContentWithoutAccount>
      <S.SubtitleWithoutAccount>
        Para quem não tem conta bancária, oferecemos a opção de reembolso via ordem de pagamento.
      </S.SubtitleWithoutAccount>
      <S.ListSteps>
        <li>
          ● O reembolso pode ser retirado em qualquer <span>agência do Itaú.</span>
        </li>
        <li>
          ● Será necessário a <span>apresentação de um documento</span> para retirar o reembolso.
        </li>
      </S.ListSteps>
      <S.DivButton>
        <ButtonV3 color="primary" icon text="Avançar" width="127px" mobileWidth="100%" onClick={gotToNextStepPaymentOrder} />
      </S.DivButton>
    </S.ContentWithoutAccount>
  )
}

export default WithoutAccount

import React from 'react'

import { CloseIcon } from '../../../assets/svg/close'
import * as S from './styles'

export const Modal = ({ modalRef, closeModal, onSubmit, isLoading, showButton, children, title = '', buttonText = '' }) => {
  return (
    <S.StyledModal ref={modalRef}>
      <S.HeaderWrapper>
        <S.Title>{title}</S.Title>
        <S.IconContainer onClick={closeModal}>
          <CloseIcon />
        </S.IconContainer>
      </S.HeaderWrapper>
      {children}
      {showButton && (
        <S.ButtonsWrapper>
          <S.Button isLoading={isLoading} onClick={onSubmit}>
            {buttonText}
          </S.Button>
        </S.ButtonsWrapper>
      )}
    </S.StyledModal>
  )
}

import React from 'react'

import approvalFrame from '../../../assets/svg/approval-frame.svg'
import { history } from '../../../navigation/history'

import { Container, Wrapper, Content, Title, SubTitle, Button } from './styles'

export const Approval = () => {
  return (
    <Container>
      <Wrapper>
        <Content>
          <Title>Parabéns</Title>
          <SubTitle>Compra confirmada com sucesso!</SubTitle>

          <p>
            Te enviaremos um email com o link para <strong>o seu contrato em até 2 dias úteis.</strong>
          </p>

          <Button type="button" onClick={() => history.push('/selecao-financiamento')}>
            Voltar para o painel do aluno
          </Button>

          <span>
            Caso ocorra algum problema com o seu contrato ou seu primeiro boleto, entre em contato com a gente via email
            papo@provi.com.br, ou via WhatsApp (11) 95771-2414
          </span>
        </Content>
      </Wrapper>
      <img src={approvalFrame} alt="Ilustração de aprovação" />
    </Container>
  )
}

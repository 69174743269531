import React, { useContext } from 'react'
import { LoadingProvi } from '@provi/provi-components'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { useIncomeStatementPath } from '../../../components/template-declaration/hooks'

export const LoadingIsa = () => {
  const { setIncomeStatementPath, setIsLoading } = useContext(TemplateDeclarationContext)
  useIncomeStatementPath(setIsLoading, setIncomeStatementPath)
  return <LoadingProvi isLoadingDark={false} />
}

import axios from 'axios'
import * as Sentry from '@sentry/browser'
import { v4 as uuidv4 } from 'uuid'

import { ROOT_URL } from '../constants'
import { logger, setLogger } from '../utils/logger'

const api = axios.create({
  baseURL: ROOT_URL.passwordless
})

const transactionId = uuidv4().replace(/-/g, '')

api.interceptors.request.use(
  config => {
    setLogger()
    Sentry.configureScope(function(scope) {
      scope.setTag('x-request-id', transactionId)
    })
    config.headers['x-request-id'] = transactionId
    return config
  },
  error => {
    Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status !== 401) {
      logger(error)
    }
    return Promise.reject(error)
  }
)

export const validateToken = token => {
  return new Promise(async (resolve, reject) => {
    try {
      api.defaults.headers.Authorization = token
      const response = await api.get('/auth')
      resolve(response)
    } catch (error) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequestAuth',
        message: 'ERROR INFO ' + error,
        level: Sentry.Severity.Warning
      })
      reject(error)
    }
  })
}

export const signIn = body => {
  let data = {
    email: body.email
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post('/sign-in', data)
      resolve(response)
    } catch (error) {
      Sentry.addBreadcrumb({
        category: 'ErrorRequestAuth',
        message: 'ERROR INFO ' + error,
        level: Sentry.Severity.Warning
      })
      reject(error)
    }
  })
}

import React, { useContext } from 'react'
import { Template } from '../../../components/template'
import { TemplateContext } from '../../../components/template/context'
import { useDeclarationsIsa } from './hooks'
import { LinkedInContext } from '../../../components/LinkedInTooltip/context'
import { Wrapper, HeaderTable, Title1, Title2, Title3, Subtitle, InfoCard, InfoCardParagraph, AltLink } from './styles'
import { HeaderV2 } from '../../../components/HeaderV2'
import { Loading } from './Loading'
import { EmptyView } from './EmptyView'
import { Question2 } from '../../../assets/svg/question2'

export const Pending = () => {
  const { setMenuActive, setIsBack, selectCrid, isMobile } = useContext(TemplateContext)
  const { isLinkedInRegistered, handleValidationOfLinkedInRegister } = useContext(LinkedInContext)
  const { renderInvoiceIsa, isLoading, toggle, effected, not_effected } = useDeclarationsIsa(
    setMenuActive,
    setIsBack,
    selectCrid,
    handleValidationOfLinkedInRegister
  )

  return (
    <Template>
      <HeaderV2 label="Declarações pendentes" isMobile={isMobile} />
      <Subtitle>
        Para quem já finalizou seus estudos, a declaração de renda é obrigatória e deve ser entregue todo mês. Para quem ainda
        não terminou o curso, ela é opcional e permite antecipar parcelas.
      </Subtitle>
      <Subtitle>Você pode conferir no status abaixo se já é possível declarar.</Subtitle>
      <Wrapper isLinkedInRegistered={isLinkedInRegistered}>
        {!isLoading && not_effected && not_effected.length > 0 ? (
          <HeaderTable>
            <Title1>
              Mês de referência
              <a tooltip-text="Mês em que você recebeu os valores a serem declarados.">
                <Question2 />
              </a>
            </Title1>
            <Title2>
              Declarar até
              <a tooltip-text="Data limite para que você declare os valores recebidos no mês de referência.">
                <Question2 />
              </a>
            </Title2>
            <Title3>Status</Title3>
          </HeaderTable>
        ) : (
          <></>
        )}
        {not_effected && not_effected.length ? not_effected.map((item, index) => renderInvoiceIsa(item, index)) : null}
        {isLoading ? <Loading isMobile={isMobile} /> : null}
        <EmptyView toggle={toggle} effected={effected} not_effected={not_effected} />
      </Wrapper>
      {!isLoading && not_effected && not_effected.length ? (
        <InfoCard>
          <InfoCardParagraph>
            <strong>Cuidado para não perder o prazo!</strong>
          </InfoCardParagraph>
          <InfoCardParagraph>
            <strong>Até 30 dias de atraso,</strong> há uma multa diária aplicada equivalente a 0,1% do Valor de Referência
            (valor total em aberto).
          </InfoCardParagraph>
          <InfoCardParagraph>
            <strong>A partir de 30 dias de atraso,</strong> você passa a dever o valor total do seu financiamento.
          </InfoCardParagraph>
        </InfoCard>
      ) : (
        <></>
      )}

      <AltLink href="/dashboard/declaracoes/enviadas">Ver declarações enviadas</AltLink>
    </Template>
  )
}

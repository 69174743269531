import React from 'react'
import { theme } from '@provi/provi-components'
import { currencyParser } from '../../../../utils'
import { ItemInvoice, Left, Text, Footer, TextTitle, TextSimple, Right, Status, Header, Link } from './styles'

export const CardPaid = ({ item, getInvoice }) => {
  return (
    <ItemInvoice>
      <Header>
        <Left>
          <TextTitle>{currencyParser(item.price || 0, 'R$')}</TextTitle>
          <TextSimple>Vencimento: {item.date}</TextSimple>
        </Left>
        <Right>
          <Text>Fatura paga</Text>
          <Status color={theme.colors.green100} />
        </Right>
      </Header>
      <Footer>
        <Left>
          <Link onClick={() => getInvoice(item)}>Ver boleto</Link>
        </Left>
        <Right>
          <Text>{item.isUpfront ? 'Entrada' : `${item.number}/${item.amountOfItems}`}</Text>
        </Right>
      </Footer>
    </ItemInvoice>
  )
}

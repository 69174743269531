import React, { useContext, useEffect } from 'react'
import { Title } from '@provi/provi-components'
import { TemplateDeclaration } from '../../../components/template-declaration'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { FormFormik } from './form'
import { currencyParser } from '../../../utils'
import { Container, SubTitle, TextValue, Row } from './styles'

export const AltenartivePaymentValue = () => {
  const { goToNextScreen, setDataModal, incomeStatementPath } = useContext(TemplateDeclarationContext)
  useEffect(() => {
    setDataModal(null)
  }, [setDataModal])
  return (
    <TemplateDeclaration subTitle="Informações de Pagamento">
      <Container>
        <Title text="Quanto você deseja pagar do ISA referente ao mês passado?" />
        <SubTitle>
          <Row>
            <TextValue>
              O valor mínimo é {currencyParser(incomeStatementPath?.readAlternativePaymentValue?.minValue || 0, ' R$')}
              . Depois de indicar o valor que deseja adiantar, <br />
              você vai receber um boleto para pagar até o próximo dia 25.
            </TextValue>
          </Row>
        </SubTitle>
        <FormFormik goToNextScreen={goToNextScreen} />
      </Container>
    </TemplateDeclaration>
  )
}

import React, { useContext, useEffect, useCallback } from 'react'
import { ButtonV2 } from '@provi/provi-components'
import Big from 'big.js'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { TemplateContext } from '../../../components/template/context'
import { WhiteCheck } from '../../../assets/svg/white-check'
import { PurchaseResume } from '../purchase-resume'
import sonarUpLogo2 from '../../../assets/svg/sonar-up-logo-2.svg'
import { currencyParser } from '../../../utils'

import { useFormOfPayment } from './hooks'
import {
  Container,
  Wrapper,
  Option,
  OptionContainer,
  CheckBox,
  Pipe,
  Card,
  MobilePurchaseResume,
  ToggleMobilePurchaseResumeButton,
  MobileSanarProductContainer,
  AmountMobileContainer
} from './styles'
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'

export const FormOfPayment = () => {
  const { isMobile, isLoading, setIsLoading } = useContext(TemplateContext)
  /** @type {(value: string | number) => string} */
  const formatCurrencyWithRounding = useCallback(value => {
    if (/^[\d]+(\.[0-1]+)$/.test(value)) return currencyParser(Big(value).round(1), 'R$ ')
    return currencyParser(value, 'R$ ')
  }, [])
  const {
    options,
    onSelectOption,
    handleNextScreen,
    formatInstallmentPrice,
    formatUpfrontAmount,
    getResMedInstallmentOptions,
    getPurchaseTotalData,
    formatPurchaseResumeTotal,
    purchaseResumeData,
    getPurchaseResumeData,
    handlePurchaseResumeVisibility,
    isPurchaseResumeMobileVisible
  } = useFormOfPayment(setIsLoading)

  useEffect(() => {
    getResMedInstallmentOptions()
    getPurchaseTotalData()
    getPurchaseResumeData()
  }, [])

  return (
    <>
      <Container>
        <Wrapper>
          <Card>
            <MobilePurchaseResume>
              <div className="header">
                <h5>Resumo da compra</h5>
                <div className="toggle-button-container">
                  {!isPurchaseResumeMobileVisible && <strong>{formatPurchaseResumeTotal}</strong>}
                  <ToggleMobilePurchaseResumeButton
                    toggled={isPurchaseResumeMobileVisible}
                    type="button"
                    onClick={handlePurchaseResumeVisibility}
                  >
                    <ExpandMoreIcon />
                  </ToggleMobilePurchaseResumeButton>
                </div>
              </div>

              <MobileSanarProductContainer visible={isPurchaseResumeMobileVisible}>
                <div className="sanar-up-logo-container">
                  <img src={sonarUpLogo2} alt="Logo Sonar UP" />
                </div>

                <div className="sanar-up-card-info">
                  <strong>Sanar Residência Médica</strong>
                  <span className="title">{purchaseResumeData?.response?.courseName}</span>
                  <h4>{formatCurrencyWithRounding(purchaseResumeData?.response?.total || 0)}</h4>
                </div>
              </MobileSanarProductContainer>

              <AmountMobileContainer visible={isPurchaseResumeMobileVisible}>
                <div className="column">
                  <span>Subtotal</span>
                  <div>
                    <span>Descontos</span>
                  </div>
                  <div className="total-info">
                    <span className="indicator">Total</span>
                  </div>
                </div>

                <div className="column price">
                  <strong>{formatCurrencyWithRounding(purchaseResumeData?.response?.subtotal || 0)}</strong>
                  <div>
                    <strong>- {formatCurrencyWithRounding(purchaseResumeData?.response?.discount || 0)}</strong>
                  </div>
                  <div className="total-info">
                    <strong className="total">{formatCurrencyWithRounding(purchaseResumeData?.response?.total || 0)}</strong>
                  </div>
                </div>
              </AmountMobileContainer>
            </MobilePurchaseResume>

            <h2 id="title">A data de pagamento das parcelas será todo dia 10</h2>
            <Option>
              {isLoading ? (
                <LoadingSkeleton isLoading={true} hasHeading containerHeight="150px" containerWidth="100%" />
              ) : (
                options?.map((installment, index) => (
                  <OptionContainer active={installment.active} key={index}>
                    <CheckBox active={installment.active} onClick={() => onSelectOption(installment)}>
                      {installment.active && <WhiteCheck />}
                    </CheckBox>

                    <div className="option-content">
                      <div className="portion-container">
                        <strong>entrada</strong>
                        <strong>{formatUpfrontAmount}</strong>
                      </div>
                      <div className="portion-container">
                        <span>+</span> <strong>{installment.installmentsToApply}x</strong> <span>de</span>
                        <strong>{formatInstallmentPrice(installment.price)}</strong>
                      </div>
                    </div>

                    <Pipe />

                    <div className="fees-container">
                      <strong>sem juros</strong>
                    </div>
                  </OptionContainer>
                ))
              )}
            </Option>

            <h6>A entrada deverá ser paga em até 3 dias após a emissão do contrato.</h6>

            <p>
              Cada parcela é composta pela amortização do valor parcelado + juros do período (se houver) + IOF fixo de 0,38% +
              IOF diário de 0,01118%
            </p>

            <div className="buttonContainer">
              <ButtonV2 type="button" text="Finalizar" onClick={handleNextScreen} />
            </div>
          </Card>
          {!isMobile && purchaseResumeData && <PurchaseResume purchaseResumeData={purchaseResumeData} />}
        </Wrapper>
      </Container>
    </>
  )
}

import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const ButtonGoBack = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;

  color: ${theme.colors.bluePrimary};

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 20px;
`

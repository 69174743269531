import React from 'react'

import * as S from './styles'

export const LoadingBarButton = ({
  loadingPercent,
  text,
  textOnLoading,
  isLoading = false,
  disabled = false,
  width = '200px',
  onClick,
  mobileWidth = '100%'
}) => {
  return (
    <S.Container
      isDisabled={disabled}
      width={width}
      mobileWidth={mobileWidth}
      onClick={() => {
        if (!disabled && !isLoading) onClick()
      }}
    >
      <S.LoadingBar loadingPercent={isLoading ? loadingPercent : '0'}>
        <S.TextWrapper>{isLoading ? textOnLoading : text}</S.TextWrapper>
      </S.LoadingBar>
    </S.Container>
  )
}

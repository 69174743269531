import { TemplateContext } from './context'
import React, { useState } from 'react'
import { useScreenMeasure, useMenu, useMenuOptions } from './hooks'

export const TemplateProvider = props => {
  const [isLoadingGlobal, setIsLoadingGlobal] = useState(false)
  const [dataProfile, setDataProfile] = useState()
  const [showExtraInfo, setShowExtraInfo] = useState(false)
  const [extraInfos, setExtraInfos] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selectCrid, setSelectCrid] = useState()
  const [token, setToken] = useState()
  const [typeDash, setTypeDash] = useState()
  const [isMobile] = useScreenMeasure()
  const [isSubMenuToggled, setIsSubMenuToggled] = useState({ id: null, toggled: false })
  const [notifications, setNotifications] = useState([])
  const [alreadyGetNotifications, setAlreadyGetNotifications] = useState(false)
  const [menuActive, setMenuActive, isBack, setIsBack, isMenuDesktopOpened, setIsMenuDesktopOpened] = useMenu()
  const menuOptions = useMenuOptions(typeDash, showExtraInfo, selectCrid)

  const value = {
    isMobile,
    menuActive,
    setMenuActive,
    isBack,
    setIsBack,
    dataProfile,
    setDataProfile,
    selectCrid,
    setSelectCrid,
    isLoadingGlobal,
    setIsLoadingGlobal,
    isLoading,
    setIsLoading,
    token,
    setToken,
    typeDash,
    setTypeDash,
    showExtraInfo,
    setShowExtraInfo,
    extraInfos,
    setExtraInfos,
    isMenuDesktopOpened,
    setIsMenuDesktopOpened,
    isSubMenuToggled,
    setIsSubMenuToggled,
    menuOptions,
    notifications,
    setNotifications,
    alreadyGetNotifications,
    setAlreadyGetNotifications
  }

  return <TemplateContext.Provider value={value}>{props.children}</TemplateContext.Provider>
}

import { useState, useEffect } from 'react'
import { showToast, theme } from '@provi/provi-components'
import { uploadImages } from '../../../services/api'
import { getPreviousMonthName } from '../../../utils'

const titleDefault = 'Se você quiser enviar algum documento que mostre a renda que você teve mês passado, é só anexar aqui, tá?'
const generateTitle = (value, incomeType) =>
  ` Para comprovar a renda de R$${value}\nque você declarou como 
    ${incomeType} no mês de ${getPreviousMonthName()}, envie o maior número de 
    documentos que você tiver disponíveis. 
    `

export const useIncomeProof = (goToNextScreen, incomeStatementPath, setIsLoading, setDataModal, setIsShowModal) => {
  const [preview, setPreview] = useState()
  const [filesNotUpload, setFilesNotUpload] = useState([])
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [numberOfFiles, setNumberOfFiles] = useState(0)
  const [numberOfFilesInitial, setNumberOfFilesInitial] = useState(0)
  const [title, setTitle] = useState(titleDefault)
  const [example, setExample] = useState([])

  useEffect(() => {
    setDataModal({
      title: 'Quais documentos anexar? ',
      texts: [
        'Aqui você pode anexar todos os documentos que comprovem a renda que você declarou: holerite, nota fiscal de serviço prestado, fatura do cartão de crédito, extrato bancário, etc.',
        'É legal enviar inclusive quando você não teve renda para mostrar que não teve nenhuma entrada, tá? '
      ],
      textButton: 'Entendi',
      buttonFunction: () => setIsShowModal(false),
      textLink: {
        color: theme.colors.blue900,
        text: 'Entrar em contato',
        onClick: () => window.open('mailto:meuisa@provi.com.br', '_blank')
      },
      color: theme.colors.blue900
    })
    if (incomeStatementPath && incomeStatementPath.readIncomeProof) {
      setIsButtonDisabled(incomeStatementPath.readIncomeProof.buttonBlocked)
      setNumberOfFiles(incomeStatementPath.readIncomeProof.existingProofofIncomes.length + filesNotUpload.length)
      setNumberOfFilesInitial(incomeStatementPath.readIncomeProof.existingProofofIncomes.length)
      setPreview(incomeStatementPath.readIncomeProof.existingProofofIncomes[0])
      setTitle(
        incomeStatementPath.readIncomeProof.textToShowInCaseOfNothingDeclared ||
          generateTitle(
            incomeStatementPath.readIncomeProof.amountDeclared,
            incomeStatementPath.readIncomeProof.incomeTypeToShow
          )
      )
      const splittedExamples = incomeStatementPath.readIncomeProof.exampleOfDocumentsToShow.split(' | ')
      setExample(splittedExamples)
    }
  }, [filesNotUpload, incomeStatementPath, setDataModal, setIsShowModal])

  useEffect(() => {
    if (incomeStatementPath && incomeStatementPath.readIncomeProof) {
      if (incomeStatementPath.readIncomeProof.existingProofofIncomes.length + filesNotUpload.length > 0) {
        setIsButtonDisabled(false)
      }
    }
  }, [filesNotUpload, incomeStatementPath])

  const onSubmit = async e => {
    e.preventDefault()
    if (filesNotUpload.length > 0) {
      setIsLoading(true)
      try {
        const urls = await uploadImages(filesNotUpload)
        goToNextScreen(urls)
      } catch (err) {
        showToast()
      }
    } else {
      goToNextScreen([])
      setIsLoading(true)
    }
  }

  return [
    onSubmit,
    isButtonDisabled,
    numberOfFiles,
    preview,
    setPreview,
    setFilesNotUpload,
    filesNotUpload,
    setNumberOfFiles,
    numberOfFilesInitial,
    title,
    example
  ]
}

import React, { useContext } from 'react'
import { Template } from '../../../components/template'
import { TemplateContext } from '../../../components/template/context'
import { Title, ContainerMain, Search, Text, SearchComponent, Icon, WrapperButton, Button } from './styles'
import { Question } from './question'
import { useHelp } from './hooks'
import { HeaderV2 } from '../../../components/HeaderV2'
import SearchIcon from '../../../assets/svg/icon-searchV2.svg'
import { history } from '../../../navigation/history'

export const HelpV2 = () => {
  const { setMenuActive, selectCrid } = useContext(TemplateContext)
  const [questions, handleSearchValue] = useHelp({ setMenuActive, selectCrid })

  return (
    <Template>
      <HeaderV2 label="Perguntas frequentes" />
      <SearchComponent>
        <Icon src={SearchIcon} />
        <Search type="text" placeholder="Pesquise aqui" onChange={handleSearchValue} />
      </SearchComponent>
      <ContainerMain>
        {questions?.length ? (
          questions?.map(item => (
            <>
              <Title id={item.title}>{item.title}</Title>
              {item.content.map((content, index) => (
                <Question key={index} itemProps={content} items={item.content} index={index} />
              ))}
            </>
          ))
        ) : (
          <Text>Não foi encontrado nenhum item</Text>
        )}
      </ContainerMain>
      <WrapperButton>
        <Button onClick={() => history.push('/dashboard/ajuda/contato')}>
          <p>Entrar em contato</p>
        </Button>
      </WrapperButton>
    </Template>
  )
}

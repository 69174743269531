import React from 'react'
import { LoadingSkeleton } from '../../../../components/LoadingSkeleton'
import { LoadingContainer } from '../styles'

export const Loading = ({ isMobile }) => {
  return (
    <>
      <LoadingContainer margin={isMobile ? '20px' : '8%'}>
        <LoadingSkeleton isLoading hasHeading containerHeight={isMobile ? '185px' : '72px'} containerWidth="100%" />
      </LoadingContainer>
      <LoadingContainer margin={'20px'}>
        <LoadingSkeleton isLoading hasHeading containerHeight={isMobile ? '185px' : '72px'} containerWidth="100%" />
      </LoadingContainer>
    </>
  )
}

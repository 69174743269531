import { Modal, theme } from '@provi/provi-components'
import React, { useContext } from 'react'
import { IconRight } from '../../../../../assets/svg/icon-right'
import { TemplateContext } from '../../../../../components/template/context'
import { modals } from '../../../invoices/invoice-allowance/card-available'
import * as InvoiceStyle from '../../../invoices/invoice-allowance/styles'
import * as S from '../../styles'
import { useCards } from '../hooks'

export const CardAvailable = ({ allowance, key }) => {
  const { isMobile } = useContext(TemplateContext)
  const { withdrawalAllowance, isShowModal, modalActive, dismissModal, setModalActive, setIsShowModal } = useCards()

  return (
    <>
      <S.Card key={key}>
        <S.CardHeader>
          <S.MonthAllowance>Mesada de {allowance.formatDate}</S.MonthAllowance>
          <S.NumberAllowance>
            {allowance.number} de {allowance.amountOfItems}
          </S.NumberAllowance>
        </S.CardHeader>

        <S.WrapperCard>
          <S.WrapperItem>
            <p>Início da retirada</p>
            <p>{allowance.availableDate}</p>
          </S.WrapperItem>

          <S.WrapperItem>
            <p>Previsão de depósito</p>
            <p>{allowance.scheduledDate}</p>
          </S.WrapperItem>
        </S.WrapperCard>

        <S.FooterCard>
          <S.ButtonFooterCard onClick={() => withdrawalAllowance(allowance)}>
            Retirar mesada <IconRight color={theme.colors.bluePrimaryDark} />
          </S.ButtonFooterCard>
        </S.FooterCard>
      </S.Card>
      {isShowModal && (
        <Modal
          title={modals({ setModalActive, setIsShowModal, isMobile })[modalActive].title}
          footer={modals({ setModalActive, setIsShowModal, isMobile })[modalActive].footer}
          onDismiss={dismissModal}
        >
          <InvoiceStyle.SubTitle>
            {modals({ setModalActive, setIsShowModal, isMobile })[modalActive]['sub-title']}
          </InvoiceStyle.SubTitle>
          <InvoiceStyle.ContainerModal>
            {modals({ setModalActive, setIsShowModal, isMobile })[modalActive].children}
          </InvoiceStyle.ContainerModal>
        </Modal>
      )}
    </>
  )
}

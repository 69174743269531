import React, { useContext } from 'react'
import { ButtonV3 } from '@provi/provi-components'

import { HeaderV2 } from '../../../components/HeaderV2'
import { Template } from '../../../components/template'
import { TemplateContext } from '../../../components/template/context'
import * as S from './styles'
import { useAlterAllowance } from './hooks'
import { ArrowLeft } from '../../../assets/svg/ArrowLeft'
import { TableAllowance } from './tables/tableAllowances'
import { TableInstallments } from './tables/tableInstallments'
import { Modal } from './modal'

export const AlterAllowance = () => {
  const { isMobile } = useContext(TemplateContext)
  const {
    collapsed,
    handleShowMore,
    newAllowances,
    installments,
    screen,
    goBack,
    onSubmit,
    isLoading,
    isLoadingSubmit,
    closeModal,
    openModal,
    modalRef,
    oldAllowances,
    day,
    month,
    oldAllowancesValue,
    newAllowancesValue,
    extraInformation,
  } = useAlterAllowance()

  const length = {
    allowance: newAllowances.length,
    installment: installments.length,
  }

  const tables = {
    allowance: <TableAllowance isMobile={isMobile} isLoading={isLoading} collapsed={collapsed} newAllowances={newAllowances} />,
    installment: (
      <TableInstallments isMobile={isMobile} isLoading={isLoading} collapsed={collapsed} installments={installments} />
    ),
  }

  const titles = {
    allowance: 'Mesadas a receber',
    installment: 'Faturas a pagar',
  }

  const descriptions = {
    allowance: `A partir do dia ${day} de ${month}, você receberá de acordo com a tabela:`,
    installment: 'Com o aumento, veja como ficam as faturas de pagamento da sua Mesada:',
  }

  const buttonText = {
    allowance: 'Ver faturas',
    installment: 'Fazer upgrade',
  }

  return (
    <Template>
      <HeaderV2 label="Simulação do aumento" isMobile={isMobile}></HeaderV2>
      <S.Title>{titles[screen]}</S.Title>
      {isLoading ? (
        <S.StyledLoadingDescription isLoading hasHeading containerHeight="48px" containerWidth={isMobile ? '100%' : '408px'} />
      ) : (
        <S.Description>{descriptions[screen]}</S.Description>
      )}
      {tables[screen]}
      {length[screen] > 6 ? (
        <S.ShowMore onClick={handleShowMore}>{collapsed ? 'Mostrar mais' : 'Mostrar menos'}</S.ShowMore>
      ) : null}
      <S.ButtonWrapper>
        <S.Back disabled={isLoadingSubmit} onClick={isLoadingSubmit ? () => '' : goBack}>
          Voltar
        </S.Back>
        <ButtonV3
          isLoading={isLoadingSubmit}
          onClick={openModal}
          mobileWidth="80%"
          text={buttonText[screen]}
          icon={ArrowLeft}
          disabled={isLoading}
        />
      </S.ButtonWrapper>
      <Modal
        day={day}
        month={month}
        oldAllowancesValue={oldAllowancesValue}
        newAllowancesValue={newAllowancesValue}
        oldAllowances={oldAllowances}
        newAllowances={newAllowances}
        modalRef={modalRef}
        closeModal={closeModal}
        onSubmit={onSubmit}
        isLoading={isLoadingSubmit}
        extraInformation={extraInformation}
      />
    </Template>
  )
}

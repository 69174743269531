import { useCallback, useEffect, useState } from 'react'
import { showToast } from '@provi/provi-components'
import { useParams } from 'react-router'
import { history } from '../../../navigation/history'
import { getFinancingInvoices } from '../../../services/api'
import moment from 'moment'
import { filterAllowances } from './utils'

export const useAllowance = ({ selectCrid }) => {
  const { status } = useParams()

  const [collapsed, setCollapsed] = useState(true)
  const [allowances, setAllowances] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [upgradeDetails, setUpgradeDetails] = useState({})
  const [screen, setScreen] = useState('open')
  const [response, setResponse] = useState({})

  const showMoreOrMinus = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed])

  const removeDuplicatedAllowances = useCallback(array => {
    return array.reduce((unique, item) => {
      const dates = unique.map(({ date }) => date)

      const hasAllowanceInThisMonth = dates.includes(item.date)

      return hasAllowanceInThisMonth ? unique : [...unique, item]
    }, [])
  }, [])

  const formatMonth = useCallback(allowancesArray => {
    return allowancesArray.map(allowance => {
      allowance.formatDate = moment(allowance.beneficiaryPaymentDate, 'DD/MM/YYYY')
        .locale('pt-br')
        .format('MMMM')
        .toLowerCase()

      return allowance
    })
  }, [])

  useEffect(() => {
    setScreen(status === 'recebidas' ? 'paid' : 'open')
  }, [status])

  useEffect(() => {
    const allowancesFiltered = filterAllowances({ response, screen })
    const mergedAllowances = removeDuplicatedAllowances(allowancesFiltered)
    const formattedAllowances = formatMonth(mergedAllowances)

    return setAllowances(formattedAllowances)
  }, [screen, response])

  const goToUpgrade = useCallback(() => {
    return history.push('/provi-sign/alteracao-mesada')
  }, [])

  const changeScreen = useCallback(nextScreen => {
    setCollapsed(true)
    setScreen(nextScreen)
  }, [])

  useEffect(() => {
    async function loadAllowances() {
      try {
        setIsLoading(true)

        if (!selectCrid) return

        const endpointResponse = await getFinancingInvoices(selectCrid?.id)

        setResponse(endpointResponse)

        const nextAvailableAllowance = endpointResponse?.startDate

        setUpgradeDetails({
          nextAvailableMonth: nextAvailableAllowance ?  moment(nextAvailableAllowance,'DD/MM/YYYY').locale('pt-br').format('MMMM').toLowerCase() : null,
          newValue: endpointResponse.allowanceUpgradeValue,
          show: endpointResponse.hasAllowanceInvitation,
          oldValue: endpointResponse.oldAllowanceValue
        })
        setIsLoading(false)
      } catch (error) {
        showToast('Erro, por favor tente novamente')

        setIsLoading(false)
      }
    }

    loadAllowances()
  }, [selectCrid])

  const filteredAllowances = collapsed ? allowances.filter((_allowance, index) => index <= 1) : allowances

  return {
    showMoreOrMinus,
    allowances,
    collapsed,
    isLoading,
    goToUpgrade,
    upgradeDetails,
    screen,
    filteredAllowances,
    changeScreen
  }
}

import React from 'react'
import { LoadingSkeleton } from '../../../../components/LoadingSkeleton'
import * as S from './styles'

export const Loading = () => {
  return (
    <>
      <S.ButtonGroup>
        <S.StyledLoadingSkeleton isLoading hasHeading containerHeight="100%" containerWidth="100%" />
      </S.ButtonGroup>

      <S.Card>
        <LoadingSkeleton isLoading hasHeading containerHeight="100%" containerWidth="100%" />
      </S.Card>
      <S.Card>
        <LoadingSkeleton isLoading hasHeading containerHeight="100%" containerWidth="100%" />
      </S.Card>
    </>
  )
}

import { useCallback, useEffect, useRef, useState } from 'react'
import { history } from '../../../navigation/history'
import { getProfileData, saveBankAccount } from '../../../services/api'
import { alreadyTryFiveTimes, alreadyTryTenTimes } from './modal/modalMsg'

export const useTypePayment = ({ selectCrid, setCancellationData, cancellationData }) => {
  const [modePayment, setModePayment] = useState('')
  const [typeOfAccount, setTypeOfAccount] = useState()
  const [bank, setBank] = useState()
  const [agency, setAgency] = useState()
  const [account, setAccount] = useState()
  const [banks, setBanks] = useState()
  const [valid, setValid] = useState()
  const [errMsg, setErrMsg] = useState()
  const [disabledButton, setDisabledButton] = useState(true)

  const modalRef = useRef()

  const openModal = () => {
    modalRef.current.openModalFn()
  }

  const closeModal = () => {
    modalRef.current.closeModalFn()
  }

  const onChangeTypeOfAccount = useCallback(e => {
    setTypeOfAccount(e?.target?.value || e)
  }, [])

  const onChangeBank = useCallback(e => {
    setBank(e?.target?.value || e)
  }, [])

  const onChangeAgency = useCallback(e => {
    setAgency(e?.target?.value || '')
  }, [])

  const onChangeAccount = useCallback(e => {
    const accountValue = e?.target?.value.replace(/\D/g, '').replace(/(.$)/, '-$1')

    setAccount(accountValue || '')
  }, [])

  const loadInfoAccount = ({ accountType, FinancialInstitution, agencyData, accountData, accountDigit }) => {
    const accountTypes = {
      CONTA_CORRENTE: 'Conta Corrente',
      CONTA_POUPANCA: 'Conta Poupança'
    }

    if (accountTypes[accountType]) setTypeOfAccount({ value: accountType, label: accountTypes[accountType] })
    if (FinancialInstitution) {
      setBank({
        id: FinancialInstitution.id,
        value: FinancialInstitution.code,
        label: `${FinancialInstitution.code} - ${FinancialInstitution.name}`
      })
    }
    if (agencyData) setAgency(agencyData)
    if (accountData && accountDigit) setAccount(`${accountData}-${accountDigit}`)
  }

  async function fetchData() {
    const data = await getProfileData(null, selectCrid.id)
    const optionsBanks = data.bankAccount.options.map(theBank => ({
      id: theBank.id,
      value: theBank.code,
      label: theBank.label
    }))
    setBanks(optionsBanks)
    const { accountType, FinancialInstitution, accountDigit } = data.bankAccount || {}
    loadInfoAccount({
      accountType,
      FinancialInstitution,
      agencyData: data.bankAccount.agency,
      accountData: data.bankAccount.account,
      accountDigit
    })
  }

  const validateAccount = CreditRequestId => {
    setValid(false)
    async function getDataProfile() {
      const data = await getProfileData(null, CreditRequestId)
      const { bankAccount } = data
      try {
        await saveBankAccount({
          UserId: bankAccount.UserId,
          CreditRequestId,
          financialInstitutionId: bank?.id,
          agency: agency,
          agencyDigit: '0',
          account: account?.split('-')?.[0],
          accountDigit: account?.split('-')?.[1],
          accountType: typeOfAccount?.value
        })
        setErrMsg('validado')
        setValid(true)
      } catch (error) {
        setValid(true)
        const response = error.response.data
        const message = response.error?.message || response.errors?.[0].message || response
        if (message) return setErrMsg(message)
      }
    }
    getDataProfile()
  }

  const buttonCloseModal = () => {
    setErrMsg()
    return closeModal()
  }

  const modalHeight = errorMessage => {
    if (!errorMessage || [alreadyTryFiveTimes, alreadyTryTenTimes].includes(errorMessage)) {
      return {
        desktopHeight: 'max-content',
        mobileHeight: 'max-content'
      }
    }
    return {
      desktopHeight: '312px',
      mobileHeight: '352px'
    }
  }

  useEffect(() => {
    if (selectCrid) fetchData()
  }, [])

  useEffect(() => {
    return setDisabledButton(!typeOfAccount || !bank || !agency || !account)
  }, [typeOfAccount, bank, agency, account])

  const goToNextStep = useCallback(
    CreditRequestId => {
      openModal()
      validateAccount(CreditRequestId)
      return setCancellationData?.({
        ...cancellationData,
        refundType: 'starkbank',
        bankAccount: {
          typeOfAccount,
          bank,
          agency,
          account
        }
      })
    },
    [cancellationData, setCancellationData, typeOfAccount, bank, agency, account]
  )

  const gotToNextStepPaymentOrder = () => {
    setCancellationData({
      ...cancellationData,
      refundType: 'ordem de pagamento'
    })

    return history.push('/cancelamento/resumo')
  }

  return {
    modePayment,
    setModePayment,
    goToNextStep,
    typeOfAccount,
    onChangeTypeOfAccount,
    bank,
    onChangeBank,
    agency,
    onChangeAgency,
    account,
    onChangeAccount,
    validateAccount,
    loadInfoAccount,
    fetchData,
    openModal,
    closeModal,
    modalRef,
    buttonCloseModal,
    modalHeight,
    banks,
    valid,
    setValid,
    errMsg,
    setErrMsg,
    disabledButton,
    setDisabledButton,
    gotToNextStepPaymentOrder
  }
}

import React from 'react'

import { ButtonWithIcon } from '../buttonWithIcon'

import * as S from './styles'

const Card = ({ title, text, btnClick, btnText, btnIcon = false, altLink = false, altLinkText }) => {
  return (
    <S.Card>
      <S.Title>{title}</S.Title>

      <S.SmallText>{text}</S.SmallText>

      <S.BtnContainer>
        <ButtonWithIcon mobileWidth="90%" width="250px" text={btnText} onClick={btnClick} icon={btnIcon} />

        {altLink && (
          <S.AltLink href={altLink} target="_blank">
            {altLinkText}
          </S.AltLink>
        )}
      </S.BtnContainer>
    </S.Card>
  )
}

export default Card

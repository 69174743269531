import { useEffect, useState } from 'react'
import { getRecipients } from '../../../services/provi-sign'

export const useSignedSucess = CreditRequestId => {
  const [pendingRecipients, setPendingRecipients] = useState([])

  const obj = {
    Provi: 'Provi',
    Guarantor: 'Avalista',
    Partner: 'Instituição parceira',
    Witness: 'Testemunha',
    User: 'Estudante',
    FinancialInstitution: 'Credor'
  }

  useEffect(() => {
    async function GetPendingRecipients() {
      if (!CreditRequestId) return

      const { pedingRecipients } = await getRecipients(CreditRequestId)

      const filteredPedingRecipients = pedingRecipients.filter(recipient => recipient === 'Guarantor')

      const pendingFormatedRecipients = filteredPedingRecipients.map(
        (pendingFormatedRecipient, index) =>
          `${obj[pendingFormatedRecipient]}${index !== filteredPedingRecipients.length - 1 ? ', ' : ' '} `
      )

      setPendingRecipients(pendingFormatedRecipients)
    }
    GetPendingRecipients()
  }, [CreditRequestId])

  return [pendingRecipients]
}

import { showToast } from '@provi/provi-components'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { LOCALSTORAGE_TOKENS } from '../../../constants'
import { history } from '../../../navigation/history'
import { getFinancingSimulation, changeFinancingOption, getFinancingInvoices } from '../../../services/api'
import { logger } from '../../../utils/logger'

export const useAlterFinancing = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isReviewing, setIsReviewing] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [installments, setInstallments] = useState([])
  const [currentPaymentFlow, setCurrentPaymentFlow] = useState([])
  const [isModalShowing, setIsModalShowing] = useState(false)
  const [sumPaymentValuePending, setSumPaymentValuePending] = useState(null)
  const [fullySigned, setFullySigned] = useState(false)
  const [hasContract, setHasContract] = useState(false)

  const buttonRef = useRef(null)

  const chosenInstallment = useMemo(() => {
    return installments?.find(installment => installment.id === selectedOption)
  }, [installments, selectedOption])

  const formatPaymentFlowToInstallments = useCallback(paymentFlows => {
    const paymentFlowsArray = paymentFlows?.invoices || paymentFlows

    const installments = paymentFlowsArray?.map(paymentFlow => {
      return {
        id: paymentFlow?.id,
        value: paymentFlow?.price,
        date: paymentFlow?.date,
        number: paymentFlow?.number,
        isPaid: paymentFlow?.status === 'paid' ? true : false
      }
    })
    return installments
  }, [])

  useEffect(() => {
    const crid = JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKENS?.crid))

    const getCurrentPaymentFlow = async () => {
      try {
        const response = await getFinancingInvoices(crid?.id)
        const formatedPaymentFlow = formatPaymentFlowToInstallments(response)
        setCurrentPaymentFlow(formatedPaymentFlow)
      } catch (error) {
        logger({ error })
        showToast('Erro ao buscar dados do financiamento')
      } finally {
        setIsLoading(false)
      }
    }

    const getSimulation = async () => {
      try {
        setIsLoading(true)

        const { data } = await getFinancingSimulation(crid.id)

        if (data?.error) {
          showToast('Você não pode atualizar seu financiamento.')
          return history.push('/dashboard/faturas/abertas')
        }

        const orderedSimulations = data?.simulations?.flow?.sort((a, b) => a?.number - b?.number)

        setInstallments(orderedSimulations)
        setSumPaymentValuePending(data?.simulations?.sumPaymentValuePending)
        setFullySigned(data?.simulations?.fullySigned)
        setHasContract(data?.simulations?.ProviContractLinked)
      } catch (error) {
        logger({ error: error })
        showToast('Você não pode ver as simulações.')
      } finally {
        setIsLoading(false)
      }
    }

    getSimulation()
    getCurrentPaymentFlow()
  }, [])

  const goBack = useCallback(() => {
    return history.push('/dashboard/faturas/abertas')
  }, [])

  const shouldGenerateContract = useMemo(() => {
    return chosenInstallment?.number === 3 && fullySigned === false
  }, [chosenInstallment, fullySigned])

  const onSubmit = useCallback(
    async ({ createRefinContract }) => {
      if (!isReviewing) {
        setIsReviewing(true)
        window.scrollTo(0, 0)

        return
      }

      try {
        setIsLoadingSubmit(true)
        const crid = JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKENS.crid))

        const { data } = await changeFinancingOption({
          crid: crid.id,
          optionId: selectedOption,
          createRefinContract
        })

        if (data.error) {
          return showToast('Você não pode atualizar seu financiamento.')
        }

        if (createRefinContract) {
          return history.push('/provi-sign/alteracao-financiamento-sucesso?generateContract=true')
        }

        history.push('/provi-sign/alteracao-financiamento-sucesso')
      } catch (error) {
        logger({ error: error })
        showToast('Você não pode atualizar seu financiamento.')
        setIsModalShowing(false)
      } finally {
        setIsLoadingSubmit(false)
      }
    },
    [setIsLoading, selectedOption, isReviewing, setIsModalShowing]
  )

  const handleSelectOption = useCallback(
    optionId => {
      setSelectedOption(optionId)

      setTimeout(() => {
        buttonRef.current.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    },
    [setSelectedOption, setIsReviewing]
  )

  const reviewText = useMemo(() => {
    if (chosenInstallment?.kind.includes('Quitação de contrato especial')) {
      return (
        <>
          Escolhendo esta opção, você quita o seu contrato conosco realizando o pagamento com uma entrada mais duas parcelas
          mensais. Você pode efetuar o pagamento através do PIX, dúvidas veja o passo a passo{' '}
          <a href="https://www.youtube.com/watch?v=LXA2gMk9V4s" target="_blank" rel="noreferrer">
            https://www.youtube.com/watch?v=LXA2gMk9V4s
          </a>
          <br />
          Após realizar a quitação do contrato, você pode acessar o seu painel do aluno e solicitar o{' '}
          <strong>Termo de Quitação Contratual</strong>. Caso esteja com a dívida negativada e/ou protestada acompanhe os prazos
          e processos em nossa FAQ{' '}
          <a href="https://bit.ly/3KggE9w" target="_blank" rel="noreferrer">
            https://bit.ly/3KggE9w
          </a>
        </>
      )
    }

    if (chosenInstallment?.kind.includes('Quitação de dívida') || chosenInstallment?.kind.includes('Quitação de contrato')) {
      return (
        <>
          Escolhendo esta opção, você quita o seu contrato conosco. Você pode efetuar o pagamento através do PIX, veja o passo a
          passo:{' '}
          <a href="https://www.youtube.com/watch?v=LXA2gMk9V4s" target="_blank" rel="noreferrer">
            https://www.youtube.com/watch?v=LXA2gMk9V4s
          </a>
          <br />
          Após realizar a quitação do contrato, você pode acessar o seu painel do aluno e solicitar o{' '}
          <strong>Termo de Quitação Contratual</strong>. Caso esteja com a dívida negativada e/ou protestada acompanhe os prazos
          e processos em nossa FAQ{' '}
          <a href="https://bit.ly/3KggE9w" target="_blank" rel="noreferrer">
            https://bit.ly/3KggE9w
          </a>
        </>
      )
    }

    if (chosenInstallment?.kind.includes('Renegociação de dívida com entrada')) {
      return (
        <>
          Ao realizar a Renegociação de Dívida um Termo de Aditamento de Contrato será enviado para o seu e-mail e do seu
          avalista (caso possua). É muito importante que os dados cadastrais estejam atualizados para envio do termo de
          aditamento.
          <br />É imprescindível a assinatura de ambos para efetivação do novo fluxo de pagamento. Caso possua alguma dúvida
          sobre este processo acesse a nossa FAQ:{' '}
          <a href="https://bit.ly/3zXpWVD" target="_blank" rel="noreferrer">
            https://bit.ly/3zXpWVD
          </a>
          . Caso esteja com a dívida negativada e/ou protestada acompanhe os prazos e processos em nossa FAQ{' '}
          <a href="https://bit.ly/3KggE9w" target="_blank" rel="noreferrer">
            https://bit.ly/3KggE9w
          </a>
          <br />
        </>
      )
    }

    return (
      <>
        Com o desconto sobre a regularização das faturas em atraso você regulariza seu débito e segue seu financiamento conosco.
        Veja como ficam os valores de cada parcela.
        <br />
        Você pode efetuar o pagamento através do PIX, veja o passo a passo:{' '}
        <a href="https://www.youtube.com/watch?v=LXA2gMk9V4s" target="_blank" rel="noreferrer">
          https://www.youtube.com/watch?v=LXA2gMk9V4s
        </a>
        . Caso esteja com a dívida negativada e/ou protestada acompanhe os prazos e processos em nossa FAQ{' '}
        <a href="https://bit.ly/3KggE9w" target="_blank" rel="noreferrer">
          https://bit.ly/3KggE9w
        </a>
        <br />
      </>
    )
  }, [chosenInstallment])

  return {
    selectedOption,
    installments,
    currentPaymentFlow,
    handleSelectOption,
    isLoading,
    onSubmit,
    goBack,
    isLoadingSubmit,
    buttonRef,
    isReviewing,
    setIsReviewing,
    chosenInstallment,
    reviewText,
    isModalShowing,
    setIsModalShowing,
    sumPaymentValuePending,
    shouldGenerateContract,
    hasContract,
    fullySigned
  }
}

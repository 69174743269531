import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'

import { Template } from '../../../components/template'
import { HeaderV2 } from '../../../components/HeaderV2'
import { TemplateContext } from '../../../components/template/context'
import { Title, Description } from '../alter-allowance-v2/styles'
import * as S from './styles'
import { useChangeFinancingSuccess } from './hooks'
import { history } from '../../../navigation/history'

export const ChangeFinancingSuccess = () => {
  const { isMobile } = useContext(TemplateContext)
  const { goBack } = useChangeFinancingSuccess()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const generatedContract = params.get('generateContract') === 'true'

  if (generatedContract) {
    return (
      <Template>
        <HeaderV2 label="Parabéns, seu contrato está sendo gerado!" isMobile={isMobile}></HeaderV2>
        <Title>Em instantes, vamos enviá-lo para o e-mail.</Title>
        <Description>
          Para efetivação da renegociação de dívida, você precisa assinar este novo contrato de financiamento.
        </Description>

        <Description>
          Após a assinatura do novo contrato, você precisa voltar a tela de <strong>Alteração de Financiamento</strong>, e
          aceitar a sua opção escolhida novamente.
        </Description>

        <Description>
          Caso o acordo não seja cumprido por completo, você perderá todo desconto ofertado nesta proposta.
        </Description>

        <S.ButtonGoBack onClick={() => history.push('/provi-sign/alteracao-financiamento')}>
          Voltar para suas opções de financiamento
        </S.ButtonGoBack>
      </Template>
    )
  }

  return (
    <Template>
      <HeaderV2 label="Parabéns, a alteração foi feita com sucesso!" isMobile={isMobile} />
      <Title>Você alterou o financiamento com sucesso</Title>
      <Description>
        Em instantes, vamos enviar por e-mail as faturas atualizadas e caso prefira acesso a opção{' '}
        <strong>Minhas faturas</strong> aqui no portal.
      </Description>
      <S.ButtonGoBack onClick={goBack}>Voltar para as faturas</S.ButtonGoBack>
    </Template>
  )
}

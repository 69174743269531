import React, { useContext, useEffect } from 'react'
import { SubTitle, Container, Title } from '@provi/provi-components'
import { TemplateDeclaration } from '../../../components/template-declaration'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { FormFormik } from './form'

export const IncomeDetails = () => {
  const { goToNextScreen, incomeStatementPath, setDataModal } = useContext(TemplateDeclarationContext)

  useEffect(() => {
    setDataModal(null)
  }, [setDataModal])

  return (
    <TemplateDeclaration>
      <Container>
        <Title text="Se você quiser contar algo da sua renda pra gente, fica à vontade!" />
        <SubTitle text="Esta pergunta é opcional" />
        <FormFormik data={incomeStatementPath && incomeStatementPath.ReadIncomeDetails} goToNextScreen={goToNextScreen} />
      </Container>
    </TemplateDeclaration>
  )
}

import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  padding-left: 155px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: 802px;
  z-index: 2;

  ${theme.breakpoints.down('md')} {
    padding: 0 27px;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 0 24px;
  }
`

import { TemplateDeclarationContext } from './context'
import React, { useState } from 'react'
import { useScreenMeasure } from '../template/hooks'
import { useSendingInfo, useIncomeStatementPath } from './hooks'

export const TemplateDeclarationProvider = props => {
  const [incomeStatementPath, setIncomeStatementPath] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const [dataModal, setDataModal] = useState(false)
  const [isMobile] = useScreenMeasure()
  const [goToNextScreen, goToPreviousScreen] = useSendingInfo(setIsLoading, setIncomeStatementPath, incomeStatementPath)
  useIncomeStatementPath(setIsLoading, setIncomeStatementPath)

  const value = {
    isShowModal,
    setIsShowModal,
    dataModal,
    setDataModal,
    isLoading,
    setIsLoading,
    goToNextScreen,
    goToPreviousScreen,
    incomeStatementPath,
    setIncomeStatementPath,
    isMobile
  }

  return <TemplateDeclarationContext.Provider value={value}>{props.children}</TemplateDeclarationContext.Provider>
}

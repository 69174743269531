import { useState, useEffect } from 'react'

export const useWorkIncomeValue = (goToNextScreen, incomeStatementPath, setDataModal) => {
  const [values, setValues] = useState({})
  const [isButtonDisabled] = useState(false)

  useEffect(() => {
    setDataModal(null)
  }, [setDataModal])

  useEffect(() => {
    if (incomeStatementPath && incomeStatementPath.readWorkIncomeValue && incomeStatementPath.readWorkIncomeValue.length > 0) {
      const data = {}
      incomeStatementPath.readWorkIncomeValue.map(item => {
        data[item.internalName] = {
          value: item.value,
          id: item.id,
          cnpj: item.cnpj || null,
          observations: item.observations || ''
        }
      })
      setValues(data)
    }
  }, [incomeStatementPath])

  const formatInternalName = item => {
    const internalNameOptions = {
      clt: 'CLT: Valor bruto'
    }
    return internalNameOptions[item.internalName] || item.displayName
  }

  const onSubmit = e => {
    e.preventDefault()
    const keys = Object.keys(values)
    const result = keys.map(key => {
      return values[key]
    })
    goToNextScreen(result)
  }

  const onValueChange = ({ value, id, name, cnpj, observations }) => {
    setValues({
      ...values,
      [name]: {
        value,
        id,
        cnpj,
        observations
      }
    })
  }
  return [formatInternalName, onSubmit, onValueChange, values, isButtonDisabled]
}

import { useCallback, useEffect, useState } from 'react'

import openDownload from '../../utils/openDownload'

export const useSelectCridNotification = downloadUrl => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    const shouldShow = localStorage.getItem('showSelectCridNotification') !== 'false'

    setShow(shouldShow)
  }, [])

  const handleDownload = useCallback(() => {
    localStorage.setItem('showSelectCridNotification', 'false')

    openDownload(downloadUrl, 'Informe-Rendimentos-2021.pdf')
  }, [])

  return [handleDownload, show]
}

import React, { useContext } from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Template } from '../../../components/template'
import { MODE_DASH } from '../../../constants'
import { TemplateContext } from '../../../components/template/context'
import { usePanel } from './hooks'
import {
  Wrapper,
  Containers,
  TotalPercentage,
  Boxs,
  ContainerCircular,
  Porcentage,
  ContainerProgressBar,
  MaxWidth,
  SubTitle
} from './stylesV2'
import { PanelDetails } from './panel-details'
import { HeaderV2 } from '../../../components/HeaderV2'
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'



const DetailsView = (data, detailName = 'default') => {
  const obj = {
    allowanceReceived: <PanelDetails.CardAllowance data={data} />,
    isa: <PanelDetails.CardIsa data={data} />,
    default: <PanelDetails.CardDefault data={data} />
  }
  return obj[detailName]
}

export const Panel = () => {
  const { typeDash, setMenuActive, setIsBack, selectCrid, isMobile } = useContext(TemplateContext)

  const { data,loading } = usePanel(setMenuActive, setIsBack, selectCrid, typeDash)

  return (
    <Template isV2>
      <HeaderV2
        to={'/dashboard/faturas/pagas'}
        label="Panorama"
        buttonLabel="Ver faturas pagas"
        displayButton
        isMobile={isMobile}
      />
      <Wrapper>
        <Containers>
          {loading ? (
            <LoadingSkeleton isLoading={loading} hasHeading containerHeight="300px" containerWidth="100%" />
          ) : (
            <>
              <TotalPercentage>
                <SubTitle fontSize={16} width={100}>
                Porcentagem do valor pago até o momento:
                </SubTitle>
                <ContainerCircular>
                  <CircularProgressbarWithChildren strokeWidth={15} value={data.percentageCompleted || 0}>
                    <ContainerProgressBar>
                      <Porcentage isBold={!isMobile}>{Math.round(data.percentageCompleted) || 0}%</Porcentage>
                    </ContainerProgressBar>
                  </CircularProgressbarWithChildren>
                </ContainerCircular>
              </TotalPercentage>
              <Boxs>
                {typeDash === MODE_DASH.isa ? DetailsView(data, 'isa') : DetailsView(data,'default')}
              </Boxs>
            </>
          )}
        </Containers>
      </Wrapper>
    </Template>
  )
}

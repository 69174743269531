import React from 'react'

export const Chart = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.5 26V17H12.5" stroke="var(--brandPrimaryDark)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M28.5 26H3.5" stroke="var(--brandPrimaryDark)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.5 26V11H19.5" stroke="var(--brandPrimaryDark)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M26.5 5H19.5V26H26.5V5Z"
      stroke="var(--brandPrimaryDark)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

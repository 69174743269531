import React from 'react'

export const IconTimerIsa = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
      fill="white"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 3.7998V7.9998L10.8 9.3998" stroke="#000B3C" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

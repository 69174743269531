import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ContainerActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  ${theme.breakpoints.down('md')} {
    align-items: flex-start;
  }
`

export const ContainerPdf = styled.div`
  border: 1px solid ${theme.colors.blue900};
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 0px;
  width: 100%;
  height: 100%;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const SubContainerPdf = styled.div`
  height: 100%;
  min-height: 180px;
  .react-pdf__Document {
    overflow: auto;
    height: 100%;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .react-pdf__message {
    color: white;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ContainerHeaderContract = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .MuiButton-label svg {
    margin-left: 15px;
  }
  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`

export const ContainerLoading = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1000;
`
export const TextLoading = styled.p`
  color: ${theme.colors.blue900};
  font-family: 'CenturyGothicBold';
  font-size: 16px;
  line-height: 24px;
  margin-top: 120px;
  ${theme.breakpoints.down('md')} {
    font-size: 12px;
  }
`

export const OpacityTrick = styled.div`
  will-change: opacity;
  transition: opacity 250ms ease-in;
  opacity: ${({ hide }) => (hide ? '0' : '1')};
`

export const HackButtonGlobals = styled.span`
  .MuiButton-startIcon.MuiButton-iconSizeMedium svg {
    width: 20px;
    height: 20px;
  }

  ${theme.breakpoints.down('md')} {
    .MuiButton-label svg {
      width: 23px !important;
      height: auto !important;
    }
  }
`

export const ContainHeaderButtons = styled.div`
  display: flex;
  position: fixed;
  flex-direction: row;
  z-index: 1000;
  width: 100%;
  background-color: ${theme.colors.blue900};
  padding: 20px;
  align-items: center;
  justify-content: center;
`

export const Main = styled.div`
  overflow: auto;
`

export const SignerBottomSheet = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${theme.colors.blue900};
  min-height: auto;
  width: 100%;
  will-change: transform;
  transform: translateY(${({ isOpen }) => (isOpen ? '0%' : '100%')});
  transition: transform 400ms ease-in-out;

  ${theme.breakpoints.up('md')} {
    padding: 12px 0;
  }

  ${theme.breakpoints.down('md')} {
    padding-bottom: 16px;
  }
`

export const SignerBottomSheetContainer = styled.div`
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 0 18px 60px;
`

export const SignerCallToAction = styled.button`
  width: 100%;
  border: 1px solid white;
  background-color: white;
  flex-grow: 1;
  border-radius: 3px;
  padding: 12px;
  color: ${theme.colors.blue900};
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-right: 6px;
  cursor: pointer;
  outline: none;

  svg {
    width: 32px;
    margin-right: 17px;
  }

  ${({ checked }) => {
    if (checked) {
      return `
        background-color: ${theme.colors.blue600};
        color: white;
        cursor: initial;
      `
    }
  }}

  ${theme.breakpoints.down('md')} {
    margin-bottom: 12px;
    margin-right: 0px;

    svg {
      width: ${({ checked }) => (checked ? '30px' : '40px')};
      margin-right: 16px;
    }
  }
`

export const LayoutContent = styled.div`
  display: flex;
  justify-content: space-between;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: center;
  }
`

export const HotClickableDragableArea = styled.button`
  width: 100%;
  text-align: center;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;

  ${theme.breakpoints.up('md')} {
    display: none;
  }

  ${theme.breakpoints.down('md')} {
    padding: 14px;
  }
`

import React from 'react'
import { Button, theme } from '@provi/provi-components'
import { currencyParser } from '../../../../utils'
import {
  ItemInvoice,
  Left,
  Text,
  Footer,
  TextTitle,
  TextSimple,
  Right,
  Status,
  Header,
  Center,
  WarningText,
  Br
} from './styles'
import { Hr } from '../../../../components/horizontal-row'

export const CardDelayed = ({ item, getInvoice }) => {
  return (
    <ItemInvoice active>
      <Header>
        <Left>
          <TextTitle>{currencyParser(item.price || 0, 'R$')}</TextTitle>
          <TextSimple>Vencimento: {item.date}</TextSimple>
        </Left>
        <Right>
          <Text>Fatura atrasada</Text>
          <Status color={theme.colors.pink900} />
        </Right>
      </Header>
      <Footer>
        <Left>
          <Button
            onClick={() => getInvoice(item)}
            marginVertical={0}
            marginHorizontal={0}
            text={`${item.InvoiceId ? 'Gerar 2º via' : 'Pagar agora'}`}
          />
        </Left>
        <Right>
          <Text>{item.isUpfront ? 'Entrada' : `${item.number}/${item.amountOfItems}`}</Text>
        </Right>
      </Footer>
      <Hr />
      <Center>
        <WarningText>
          • Nas faturas em atraso são aplicadas taxas de 2% de multa + mora de 0,033% ao dia <Br />
          • Ao gerar uma segunda via, a data de vencimento é sempre o dia seguinte <Br />
        </WarningText>
      </Center>
    </ItemInvoice>
  )
}

import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;

  flex-direction: column;
`
export const Content = styled.div`
  display: flex;

  ${theme.breakpoints.up('md')} {
    padding-left: 10px;
  }

  width: 100%;
  height: 100%;
  flex-direction: row;
  flex-wrap: wrap;
`
export const TitleContainer = styled.div`
  display: flex;
  height: 60px;

  border-bottom: 1px solid rgba(207, 207, 207, 0.8);

  > h1 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 20px;
    color: ${theme.colors.blue900};
    line-height: 24px;
  }
`
export const ContractCard = styled.div`
  ${theme.breakpoints.up('sm')} {
    max-width: 468px;
    align-self: flex-start;
  }

  display: flex;
  width: 95%;
  flex-direction: column;
  align-self: center;

  margin: 25px 0px 0 25px;

  padding: 18px 20px;

  ${theme.breakpoints.down('md')} {
    margin: 25px 0px 0px 0px;
  }

  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(45, 108, 154, 0.2);
  border-radius: 5px;
`
export const PartnerName = styled.strong`
  ${theme.breakpoints.up('sm')} {
    font-size: 20px;
  }
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  color: ${theme.colors.blue900};
`
export const ProductContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;

  margin-top: 14px;
`

const isWithContractButtonStyles = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0;

  > div {
    display: flex;
    flex-direction: column;

    > span {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #414141;
    }

    > strong {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 18px;
      color: ${theme.colors.blue900};
    }
  }
`

const defaultProductContentStyles = css`
  display: flex;
  flex-direction: column;

  margin: 10px 0;

  span {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #414141;
  }

  strong {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
    color: ${theme.colors.blue900};
  }
`
export const ProductContent = styled.div`
  ${props => (props.isWithContractButton ? isWithContractButtonStyles : defaultProductContentStyles)}
`

const marginTopContractButton = css`
  margin-top: 20px;
`
export const ContractButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  width: 174px;

  ${theme.breakpoints.down('md')} {
    height: 60px;
  }

  @media (max-width: 280px) {
    height: 75px;
  }

  height: 40px;

  color: #ffff;
  font-weight: bold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;

  background: ${theme.colors.bluePrimary};
  border-radius: 5px;
  outline: none;
  border: none;
  ${props => props.isWithMarginTop && marginTopContractButton}
  cursor: pointer;
  transition: 200ms ease;

  :hover {
    opacity: 0.8;
  }
`

export const NoContractsMessageContainer = styled.div`
  width: 700px;
  display: flex;
  flex-direction: column;
  align-self: center;
  background-color: ${theme.colors.white100};
  margin-top: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px;
  box-shadow: 0px 4px 15px rgba(6, 51, 103, 0.11);
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }

  > h2 {
    font-family: 'Montserrat', sans-serif;

    color: ${theme.colors.pink900};
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 8px;

    ${theme.breakpoints.down('md')} {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0px;
    }
  }

  > p {
    font-family: 'Montserrat', sans-serif;

    color: ${theme.colors.blue900};
    font-size: 16px;
    line-height: 24px;
    ${theme.breakpoints.down('md')} {
      font-size: 12px;
      line-height: 18px;
    }

    > a {
      color: ${theme.colors.blue900};
    }
  }
`

export const ButtonWhatsapp = styled.button`
  cursor: pointer;
  width: 200px;
  padding: 20px 0;
  border: 1px solid ${theme.colors.pink900};
  border-radius: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: transparent;

  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.pink900};
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;

  margin: 0 auto;
  margin-top: 26px;

  ${theme.breakpoints.down('md')} {
    font-size: 10px;
    line-height: 18px;
  }

  &:hover {
    opacity: 0.7;
  }

  ${theme.breakpoints.down('md')} {
    width: 100px;
    padding: 4px 0;
  }
`

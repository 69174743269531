import { getTypeformFlag } from '../../services/api'
import { verifyBooleanString } from '../../utils/verifyBooleanString'

export const getFlag = async ({ setShow, selectCrid, blockedUrls, allowedUrls, typeFlag, id, registredUrl, setOpenEvent }) => {
  if (!selectCrid) return setShow(false)

  const actualLocation = window.location.pathname

  if (blockedUrls?.includes?.(actualLocation)) return setShow(false)

  if (allowedUrls && !allowedUrls?.includes?.(actualLocation)) return setShow(false)

  if (verifyBooleanString(sessionStorage.getItem(typeFlag), 'false')) return setShow(false)

  const response = await getTypeformFlag(id, selectCrid?.id)

  if (!response?.showResearch) {
    return sessionStorage.setItem(typeFlag, 'false')
  }

  const hasAlreadySubmitted = verifyBooleanString(sessionStorage.getItem(typeFlag), 'true')

  if (registredUrl.url !== actualLocation && registredUrl.shouldUpdate) {
    setOpenEvent(undefined)
  }

  if (hasAlreadySubmitted) return setShow(false)

  return setShow(true)
}

import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { Button, Input, TextErrorInput, Form } from '@provi/provi-components'

const Formik = props => {
  const { handleChange, values, errors, touched, handleBlur, handleSubmit } = props
  return (
    <Form onSubmit={handleSubmit}>
      <Input
        autoFocus={!values.generalComments}
        margin="normal"
        type="text"
        multiline
        placeholder="Escreva aqui..."
        onChange={handleChange('generalComments')}
        onBlur={handleBlur('generalComments')}
        error={!!errors.generalComments}
        value={values.generalComments}
        rowsMax={7}
      />
      {errors.generalComments && touched.generalComments && <TextErrorInput text={errors.generalComments} />}

      <Button marginHorizontal={0} disabled={!values.generalComments} type="submit" text="Avançar" />
    </Form>
  )
}

export const FormFormik = withFormik({
  validateOnBlur: true,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: props => ({ generalComments: props.data ? props.data && props.data.value : '' }),
  validationSchema: Yup.object().shape({
    generalComments: Yup.string()
      .required('Por favor, preencha o campo acima')
      .nullable()
  }),
  handleSubmit: async ({ generalComments }, { props }) => {
    props.goToNextScreen(generalComments || '')
  }
})(Formik)

import React from 'react'
import { history } from '../../../../navigation/history'
import * as S from './styles'

const Modal = ({ modalRef, isLoading, children, title = '' }) => {
  return (
    <S.StyledModal ref={modalRef}>
      <S.HeaderWrapper>
        <S.Title>{title}</S.Title>
      </S.HeaderWrapper>
      {children}
      <S.ModalFooter>
        <S.ButtonsWrapper>
          <S.Button isLoading={isLoading} onClick={() => history.push('/dashboard/solicitacoes')}>
            Ir para solicitações
          </S.Button>
        </S.ButtonsWrapper>
      </S.ModalFooter>
    </S.StyledModal>
  )
}

export default Modal

import React from 'react'

export const Share = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 19L28 13L22 7" stroke="#DE0C4B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M24 27H5C4.73478 27 4.48043 26.8946 4.29289 26.7071C4.10536 26.5196 4 26.2652 4 26V11"
      stroke="#DE0C4B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.37891 22C10.0449 19.424 11.5478 17.1423 13.6514 15.5133C15.755 13.8843 18.3402 13.0002 21.0008 13H28.0008"
      stroke="#DE0C4B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

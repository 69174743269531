import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'

export const FlexWrapper = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 1250px) {
    flex-direction: column;
  }
`

export const Container = styled.div`
  max-width: 704px;
`

export const LeftResume = styled.div`
  position: relative;

  width: 384px;
  height: max-content;

  background: #ffffff;

  border: 2px solid ${theme.colors.bluePrimaryLight};
  box-sizing: border-box;
  border-radius: 5px;

  padding: 20px;

  @media (max-width: 1250px) {
    width: 100%;
    max-width: 704px;
  }
`

export const TitleResume = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.015em;

  color: #000000;
  margin: 0;
`

export const Wrapper = styled.div`
  margin-top: 20px;

  padding-bottom: 10px;

  ${({ showBorder }) =>
    showBorder &&
    css`
      border-bottom: 1px solid ${theme.colors.bluePrimaryLight};
    `}
`

export const Name = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.02em;
  margin: 3px 0;

  color: ${theme.colors.bluePrimary};
`

export const Description = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.02em;
  margin: 3px 0;

  color: #575757;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const ChangeButton = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;

  color: ${theme.colors.bluePrimary};

  cursor: pointer;

  width: 100%;

  display: flex;
  justify-content: right;
  margin: 20px 0 0 0;
`

import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  > input {
    margin-top: 20px;
    width: 226px;
    padding: 10px 8px;
    font-size: 1rem;
    border: 1px solid ${theme.colors.blue900};
    border-radius: 8px;
    margin-bottom: 7px;
    margin-right: 28px;

    &:before {
      content: '';
      position: absolute;
    }
    &:focus {
      outline-offset: 0;
      outline: none;
    }
  }
`

import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  background-color: #f6f8fb;
  font-family: 'Montserrat', sans-serif;
  overflow: auto;
  padding: 0 25px 25px 25px;

  ${theme.breakpoints.down('sm')} {
    header {
      margin: 0 17px;
    }
    padding: 0 8px 8px 8px;
  }
`

export const BoxWrapper = styled.main`
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  margin-top: 32px;
  padding: 25px;

  ${theme.breakpoints.down('sm')} {
    margin-top: 10px;
    padding: 15px;
  }
`

export const BasicInformation = styled.section`
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 40px;
`

export const Title = styled.h3`
  margin: 0;
  color: ${theme.colors.blue900};
  font-weight: 600;
  font-size: 20px;

  margin-bottom: 18px;
`

export const ContainerBasicInfo = styled.div`
  display: flex;

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`

export const ContainerImg = styled.div`
  max-width: 130px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  button {
    width: 100%;
  }

  img {
    width: 130px;
    height: 130px;
    border-radius: 5px;
  }

  ${theme.breakpoints.down('sm')} {
    max-width: 100%;
    border-bottom: 1px solid #cfcfcf;
    padding-bottom: 25px;
    margin-right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      width: 130px;
    }
  }
`

export const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: 130px;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;

    padding-top: 25px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    height: auto;

    div {
      &:last-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
`

export const InformationField = styled.div`
  h4 {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    color: ${theme.colors.blue900};
    font-size: 12px;
    font-weight: bold;
    line-height: 24px;
  }

  p {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    color: #414141;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    font-weight: normal;
  }

  @media (max-width: 800px) {
    word-break: break-all;
  }
`

export const LinkedinInfo = styled.section`
  padding: 25px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  button {
    max-width: fit-content;
    height: 32px;
  }

  label {
    max-width: 100%;
  }

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
    grid-template-rows: 32px 1fr 1fr;

    h3 {
      margin-bottom: 0;
    }
  }
`
export const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 10px;
`

export const InputLinkedin = styled.input`
  font-family: 'Montserrat', sans-serif;
  border: 1px solid #b0afaf;
  border-radius: 5px;
  font-size: 16px;
  padding-left: 10px;

  &:focus {
    outline: 0;
  }

  &:disabled {
    background-color: #eaeaea;
  }
`

export const InputFile = styled.input`
  display: none;
`

export const InputFileLabel = styled.label`
  min-width: 90px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;

  border: 1px solid ${theme.colors.bluePrimary};
  border-radius: 5px;

  color: ${theme.colors.bluePrimary};
  background-color: #fff;

  padding: 5px 20px;

  cursor: pointer;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.95);
  }
`

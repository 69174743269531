import React, { useContext } from 'react'
import { LoadingProvi } from '@provi/provi-components'
import { TemplateContext } from '../../components/template/context'
import { Container } from '../../components/container/indexV2'

import { history } from '../../navigation/history'
import { MenuV2 } from '../../components/menu-v2'

import { Main, View } from './style'
import { useProfile, useCrid, useNotifications } from './hooks'

export const Template = props => {
  const {
    isMobile,
    isLoadingGlobal,
    isLoading,
    setIsLoading,
    setDataProfile,
    dataProfile,
    token,
    selectCrid,
    setTypeDash,
    setSelectCrid,
    setShowExtraInfo,
    setExtraInfos,
    isMenuDesktopOpened,
    setNotifications,
    alreadyGetNotifications,
    setAlreadyGetNotifications
  } = useContext(TemplateContext)

  useProfile(setIsLoading, setDataProfile, dataProfile, token, setTypeDash)
  useCrid(setSelectCrid, setShowExtraInfo, setExtraInfos)
  useNotifications(setNotifications, selectCrid, alreadyGetNotifications, setAlreadyGetNotifications)
  const isShowMenu = selectCrid && !props.isShowMenu && !props.rightActions
  const { pathname } = history.location

  return (
    <View data-iam="template">
      {isLoadingGlobal ? (
        <LoadingProvi />
      ) : (
        <>
          {isLoading && <LoadingProvi />}

          {isShowMenu && pathname !== '/selecao-financiamento' && <MenuV2 isMobile={isMobile} />}

          <Main isMobile={isMobile} isSelectCrid={pathname} data-main isMenuToggled={isMenuDesktopOpened}>
            <Container
              data-container
              isV2={props.isV2}
              style={{
                padding: props.useZeroPadding ? 0 : null,
                height: props.useFullHeight ? '100%' : null
              }}
            >
              {props.children}
            </Container>
          </Main>
        </>
      )}
    </View>
  )
}

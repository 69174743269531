import { useEffect } from 'react'
import { showToast } from '@provi/provi-components'

export const useHelp = (setMenuActive, setIsBack) => {
  useEffect(() => {
    setMenuActive('help')
    setIsBack(true)
  }, [setMenuActive, setIsBack])
}

export const copyMailToClipboard = () => {
  const el = document.createElement('textarea')
  el.value = 'papo@provi.com.br'
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const copyPhoneToClipboard = () => {
  const el = document.createElement('textarea')
  el.value = '11 957712414'
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const clickOnMail = isMobile => {
  if (isMobile) {
    window.open('mailto:papo@provi.com.br', '_blank')
  } else {
    copyMailToClipboard()
    showToast('Email copiado com sucesso!')
  }
}

export const clickOnPhone = isMobile => {
  if (isMobile) {
    window.open('tel:11957712414', '_blank')
  } else {
    copyPhoneToClipboard()
    showToast('Telefone copiado com sucesso!')
  }
}

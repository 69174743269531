import React from 'react'
import { Modal } from '@provi/provi-components'

import { LOCALSTORAGE_TOKENS } from '../../../../../constants'
import { history } from '../../../../../navigation/history'
import { TextModal, Button } from '../styles'

export default function ModalComponent({ item, setIsShowModal }) {
  return (
    <Modal
      title="Antes de começar..."
      footer={
        <Button
          width="250px"
          onClick={() => {
            setIsShowModal(false)
            localStorage.setItem(LOCALSTORAGE_TOKENS.crid_isa, item.id)
            localStorage.setItem(LOCALSTORAGE_TOKENS.incomedeclaration, JSON.stringify(item))
            history.push('/dashboard/isa/carregando')
          }}
        >
          <p>Combinado!</p>
        </Button>
      }
      onDismiss={() => setIsShowModal(false)}
      containerStyle={{
        maxWidth: '598px',
        width: '90%',
        borderRadius: '5px'
      }}
    >
      <TextModal>
        <p>Vamos combinar duas coisas?</p>
        <p>
          Você se compromete a fornecer informações verdadeiras <br />
          na sua declaração de renda.
        </p>

        <p>
          Do nosso lado, buscamos promover cada vez mais <br />
          iniciativas para melhorar o ISA e o seu fluxo <br />
          de declaração de renda mensal.
        </p>
      </TextModal>
    </Modal>
  )
}

import { showToast } from '@provi/provi-components'
import { useState } from 'react'
import { changeProfileSelfie, getProfileData, uploadImages } from '../../../services/api'

export const useProfileSelfie = ({ token, setDataProfile }) => {
  const [cardFile, setCardFile] = useState([])
  const [saveProfileDisabled, setSaveProfileDisabled] = useState(true)
  const [isLoadingNewProfile, setIsLoadingNewProfile] = useState(false)

  const submitPhoto = async () => {
    try {
      setIsLoadingNewProfile(true)
      setSaveProfileDisabled(true)
      const photoUrl = await uploadImages(cardFile)
      await changeProfileSelfie(photoUrl[0])
      const response = await getProfileData(token)
      setDataProfile(response)
    } catch (err) {
      showToast(err?.response?.data?.message || err?.response?.data?.msg || 'Ocorreu um erro inesperado!')
    } finally {
      setIsLoadingNewProfile(false)
    }
  }

  const handleUploadFile = e => setCardFile([e.target.files[0]])

  return [handleUploadFile, saveProfileDisabled, setSaveProfileDisabled, isLoadingNewProfile, submitPhoto]
}

import React from 'react'
import { IconCheckUpload } from '../../../../assets/svg/icon-check'
import { currencyParser } from '../../../../utils'
import * as S from '../styles'

export const Row = ({ length, index, row, text, isPaid }) => {
  const isLast = length - 1 === index
  const isUpfront = row?.number === 0
  const isOnlyOne = length === 1

  const upfrontOrTextWithNumber = isUpfront ? 'Entrada' : `${text} ${row?.number}`
  const parsedText = isOnlyOne ? 'Valor de Quitação' : upfrontOrTextWithNumber

  return (
    <S.Row showRadius={isLast}>
      <S.RowItem showRadius={isLast} position="left" isSmallText={isOnlyOne}>
        <S.PaidFlag>{isPaid ? <IconCheckUpload /> : null}</S.PaidFlag>
        {parsedText}
      </S.RowItem>
      <S.RowItem>{currencyParser(row.value, 'R$ ')}</S.RowItem>
      <S.RowItem showRadius={isLast} position="right">
        {row.date}
      </S.RowItem>
    </S.Row>
  )
}

import { theme } from '@provi/provi-components'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TextEmpty = styled.p`
  font-family: 'Montserrat', sans-serif;
  color: ${theme.colors.grey600};
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  margin-top: 20px;
  text-align: center;
`

export const HeaderTable = styled.div`
  width: 100%;

  @media (max-width: 950px) {
    display: none;
  }

  display: flex;
  flex-direction: row;
  width: 100%;

  margin-top: 5%;

  > p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;

    color: #414141;
  }
`

export const Title1 = styled.p`
  margin: 0px 0px 0px 252px;
  display: flex;
  align-items: center;
  > a {
    margin-left: 8px;
    display: flex;
    align-items: center;
  }
`

export const Title2 = styled.p`
  margin: 0px 0px 0px 30px;
  width: 160px;
  display: flex;
  align-items: center;
  > a {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
`

export const Title3 = styled.p`
  margin: 0px 0px 0px 5px;
`
export const Title1Effected = styled.p`
  margin: 0px 0px 0px 20px;
`

export const Title2Effected = styled.p`
  margin: 0px 0px 0px 33px;
`

export const Title3Effected = styled.p`
  margin: 0px 0px 0px 126px;

  @media (max-width: 900px) {
    margin: 0px 0px 0px 97px;
  }

  @media (max-width: 1135px) {
    margin: 0px 0px 0px 103px;
  }
`
export const LoadingContainer = styled.div`
  border-radius: 5px;
  margin-top: ${({ margin }) => margin};
  width: 100%;
`
export const Subtitle = styled.p`
  font-family: 'Montserrat', sans-serif;
  max-width: 496px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
`

export const InfoCard = styled.div`
  max-width: 528px;
  padding: 16px;
  background: #ffffff;
  border: 2px solid ${theme.colors.bluePrimaryLight};
  border-radius: 16px;
  box-sizing: border-box;
  margin-top: 24px;
`

export const InfoCardParagraph = styled.p`
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
`
export const AltLink = styled.a`
  color: ${theme.colors.bluePrimary};
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  margin-top: 48px;
`

export const Tooltip = styled(ReactTooltip)`
  background-color: ${theme.colors.bluePrimaryDark};
  color: ${theme.colors.white100};
  padding: 16px;
  width: 368px;
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif;
  svg {
    float: right;
  }
  &.type-dark.place-top {
    border-radius: 16px;
  }
`

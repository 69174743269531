import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin-top: 25px;

  display: flex;
  flex-direction: column;
  gap: 25px;

  @media screen and (max-width: 800px) {
    align-items: center;
  }
`
export const SubContainer = styled(Container)`
  margin-top: 10px;
  gap: 0;
`

export const CardAvailableUpgrade = styled.div`
  background: ${theme.colors.bluePrimaryDark};
  border-radius: 5px;

  width: 496px;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const TitleAvailableUpgrade = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;

  letter-spacing: 0.01em;

  color: #ffffff;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  margin: 0;
`

export const DescriptionAvailableUpgrade = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.02em;

  color: #ffffff;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 15px 0;
`

export const ButtonAvailableUpgrade = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;

  cursor: pointer;

  svg {
    margin-left: 10px;
  }
`

export const Card = styled.div`
  background: #ffffff;

  border: 2px solid ${theme.colors.bluePrimaryLight};
  box-sizing: border-box;
  border-radius: 16px;

  width: 496px;
  height: 156px;

  position: relative;

  padding: 15px;

  ${({ requested }) =>
    requested &&
    css`
      height: 261px;
    `}

  @media (max-width: 800px) {
    width: 100%;
    height: 292px;

    ${({ paid }) =>
      paid &&
      css`
        height: 190px;
      `}

    ${({ requested }) =>
      requested &&
      css`
        height: 321px;
      `}
  }

  ${({ blocked }) =>
    blocked &&
    css`
      border: 2px solid #cfcfcf;

      p {
        color: #575757;
        cursor: default;
      }
    `}
`

export const MonthAllowance = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;

  letter-spacing: 0.01em;

  color: #000000;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
`

export const NumberAllowance = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.015em;

  color: #000000;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0;
`

export const CardHeader = styled.div`
  border-bottom: 2px solid ${theme.colors.bluePrimaryLight};

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    align-items: flex-start;

    padding: 5px 0;
  }

  ${({ blocked }) =>
    blocked &&
    css`
      border-bottom: 2px solid #cfcfcf;
    `}
`

export const WrapperCard = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: 12px 0;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 10px;
  }
`

export const WrapperItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    letter-spacing: 0.02em;

    color: #000000;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0;
  }
`

export const WrapperStatusItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;

  p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    letter-spacing: 0.02em;

    color: #000000;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0;
  }
`

export const FooterCard = styled.div`
  background: ${theme.colors.bluePrimaryLight};

  height: 40px;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  ${({ blocked }) =>
    blocked &&
    css`
      background: #cfcfcf;
    `}
`

export const ButtonFooterCard = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  padding: 0 15px;

  letter-spacing: 0.02em;

  color: #00198a;

  flex: none;
  order: 0;
  flex-grow: 0;

  display: flex;
  align-items: center;

  cursor: pointer;

  svg {
    margin-left: 10px;
  }

  ${({ paid }) =>
    paid &&
    css`
      color: #000;
      cursor: default;
    `}
`
export const ButtonFooterCardRequested = styled(ButtonFooterCard)`
  color: black;
  cursor: default;
`

export const ShowMore = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;

  color: ${theme.colors.bluePrimary};

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px;

  cursor: pointer;
`

export const ButtonGroup = styled.div`
  background: #ffffff;

  border: 2px solid ${theme.colors.bluePrimary};
  box-sizing: border-box;
  border-radius: 24px;

  width: 296px;
  height: 48px;

  display: flex;

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const ButtonItem = styled.div`
  width: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.015em;
  color: ${theme.colors.bluePrimary};

  flex: none;
  order: 0;
  flex-grow: 0;

  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;

  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background: ${theme.colors.bluePrimary};

      color: #ffffff;
    `}
`

export const ButtonItemRight = styled(ButtonItem)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
`

export const Title = styled.p`
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  color: #000;
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: 800px) {
    font-size: 22px;
    line-height: 32px;
  }
`

export const SubTitle = styled.p`
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #000;
  margin-top: 5px;
  margin-bottom: 0;

  @media (max-width: 800px) {
    font-size: 18px;
    line-height: 28px;
  }
`

export const Paragraph = styled.p`
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
`

export const BorderBottom = styled.div`
  border-bottom: 1px solid #cfcfcf;
  width: 100%;
  height: 1px;
  margin-top: 30px;

  @media (max-width: 800px) {
    margin-top: 0px;
  }
`
export const LiOption = styled.li`
  padding: 6px 8px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`

export const Sublink = styled.a`
  text-decoration: none;
  color: ${theme.colors.bluePrimary};
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;

  @media (max-width: 800px) {
    margin: 30px auto;
  }
`
export const Br = styled.br``

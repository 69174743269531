import React, { useContext } from 'react'
import { HeaderV2 } from '../../../components/HeaderV2'
import { Template } from '../../../components/template'
import { TemplateContext } from '../../../components/template/context'
import { useAllowance } from './hooks'
import { Loading } from './Loading'
import { RenderAllowance } from './renderAllowance'
import * as S from './styles'

export const Allowances = () => {
  const { selectCrid } = useContext(TemplateContext)
  const {
    showMoreOrMinus,
    allowances,
    collapsed,
    isLoading,
    goToUpgrade,
    upgradeDetails,
    screen,
    filteredAllowances,
    changeScreen
  } = useAllowance({
    selectCrid
  })

  return (
    <Template>
      <HeaderV2 label="Minhas Mesadas" />
      <S.Container>
        <S.SubContainer show>
          <S.Title>Lembre-se das datas de assinatura das suas mesadas:</S.Title>
          <S.SubTitle>
            A partir de Maio de 2024, o contrato para solicitar a sua Mesada estará disponível a partir do dia 01 de cada mês.
          </S.SubTitle>
          <S.Br />
          <S.SubTitle>
            A assinatura do contrato passará a ser feita exclusivamente por este portal do dia 01 ao dia 07 de cada mês. Não
            teremos outra data para assinatura, como era feito nos meses anteriores. Assim, você receberá sua mesada no dia 25
            ou no próximo dia útil.
          </S.SubTitle>
          <S.Br />
          <S.SubTitle>
            <strong>
              Atente-se ao prazo de solicitação da sua mesada, pois sem a assinatura, o programa Mesada será cancelado. É de sua
              responsabilidade acompanhar as atualizações deste portal.
            </strong>
          </S.SubTitle>
          <S.Br />
          <S.SubTitle>
            No dia do pagamento, aguarde até o final do horário bancário para nos notificar sobre qualquer problema!
          </S.SubTitle>
          <S.Br />
          <S.BorderBottom />
        </S.SubContainer>

        {!isLoading && (
          <S.ButtonGroup>
            <S.ButtonItem onClick={() => changeScreen('open')} active={screen === 'open'}>
              A receber
            </S.ButtonItem>
            <S.ButtonItemRight onClick={() => changeScreen('paid')} active={screen === 'paid'}>
              Recebidas
            </S.ButtonItemRight>
          </S.ButtonGroup>
        )}

        {filteredAllowances.map(allowance => (
          <RenderAllowance key={allowance.id} allowance={allowance} />
        ))}
        {isLoading && <Loading />}

        {allowances.length > 2 && <S.ShowMore onClick={showMoreOrMinus}>{collapsed ? 'Ver mais' : 'Ver menos'}</S.ShowMore>}
      </S.Container>
    </Template>
  )
}

import { useEffect, useCallback } from 'react'
import { LOCALSTORAGE_TOKENS } from '../../constants'
import { history } from '../../navigation/history'
import { validateSigner } from '../../services/provi-sign'

export const useTemplateProviSign = (setIsLoading, setObjectValidation, setTypeUser, typeUser) => {
  const redirect = data => {
    if (data?.alreadyHasCRID) return history.push('/provi-sign/bloqueio-contrato-assinado')

    if (data?.showSigningCompleteScreen) return history.push('/provi-sign/assinatura-sucesso')

    if (!data?.isSigner) return history.push('/provi-sign/termos-contrato')

    if (data?.showDetailsScreen) return history.push('/provi-sign/introducao-avalista')

    if (!data?.showDetailsScreen && data?.phoneVerification) return history.push('/provi-sign/confirmacao-telefone')

    return history.push('/provi-sign/termos-contrato')
  }

  const getData = useCallback(async (isParamsToken, isParamsSelfie, split) => {
    const tokenParams = split[split.length - 1]
    isParamsToken && localStorage.setItem(LOCALSTORAGE_TOKENS.token_provi_sign, tokenParams)
    const tokenUser = localStorage.getItem(LOCALSTORAGE_TOKENS.token)
    const token = isParamsToken ? tokenParams : tokenUser
    const crid = JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKENS.crid))
    const type = localStorage.getItem(LOCALSTORAGE_TOKENS.type)
    setTypeUser(type)

    if (!isParamsSelfie) {
      try {
        const { data } = await validateSigner(token, crid, typeUser)
        setObjectValidation(data)

        window.smartlook &&
          window.smartlook('identify', data?.UserId, {
            CreditRequestId: data?.CreditRequestId,
            UserId: data?.UserId,
            email: data?.email
          })

        return redirect(data)
      } catch (err) {
        console.log('err', err)
      } finally {
        setIsLoading(false)
      }
    }
  }, [])

  useEffect(() => {
    const pathname = history.location.pathname
    const split = pathname.split('/')
    const isProviSign = split[1] === 'provi-sign'
    const isParamsToken = split[2] === 'carregando'
    const isParamsSelfie = split[2] === 'selfie'
    const isAllowanceUpgrade = ['alteracao-mesada', 'alteracao-mesada-sucesso'].includes(split[2])

    if (isProviSign && !isAllowanceUpgrade) {
      getData(isParamsToken, isParamsSelfie, split)
    }
  }, [setIsLoading, setObjectValidation, setTypeUser, typeUser])
}

import React from 'react'

export const ErrorIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M12.0002 22.8C17.9649 22.8 22.8002 17.9647 22.8002 12C22.8002 6.03534 17.9649 1.20001 12.0002 1.20001C6.03552 1.20001 1.2002 6.03534 1.2002 12C1.2002 17.9647 6.03552 22.8 12.0002 22.8Z"
      fill="#B30142"
    />
    <path
      d="M11.9998 18C12.6625 18 13.1998 17.4627 13.1998 16.8C13.1998 16.1373 12.6625 15.6 11.9998 15.6C11.3371 15.6 10.7998 16.1373 10.7998 16.8C10.7998 17.4627 11.3371 18 11.9998 18Z"
      fill="white"
    />
    <rect x="10.7998" y="6" width="2.4" height="7.2" rx="1" fill="white" />
  </svg>
)

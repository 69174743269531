import React, { useContext } from 'react'
import { LoadingProvi, Button, Modal, theme } from '@provi/provi-components'
import { TemplateDeclarationContext } from '../../components/template-declaration/context'
import { Header } from '../header-isa'
import { View, Left, Right, TextModal, ButtonBackDesktop } from './style'
import { BackArrow } from '../../assets/svg/back-arrow'

export const TemplateDeclaration = props => {
  const { isMobile, isShowModal, setIsShowModal, dataModal, isLoading, goToPreviousScreen, incomeStatementPath } = useContext(
    TemplateDeclarationContext
  )

  return (
    <>
      {isShowModal && (
        <Modal
          title={dataModal.title}
          onDismiss={() => setIsShowModal(false)}
          footer={
            dataModal.textButton && (
              <Button
                marginHorizontal={0}
                type="button"
                text={dataModal.textButton}
                onClick={() => dataModal.buttonFunction && dataModal.buttonFunction()}
                textLink={{
                  color: theme.colors.blue900,
                  text: dataModal && dataModal.textLink && dataModal.textLink.text,
                  onClick: () => dataModal && dataModal.textLink && dataModal.textLink.onClick && dataModal.textLink.onClick()
                }}
              />
            )
          }
        >
          {dataModal && dataModal.texts.map((item, index) => <TextModal key={index}>{item}</TextModal>)}
        </Modal>
      )}
      {isLoading && <LoadingProvi isLoadingDark={false} />}

      <View>
        <Left>
          {incomeStatementPath && incomeStatementPath.progress && (
            <Header current={Math.round(incomeStatementPath.progress.percentageCompleted)} total={100} />
          )}
          {!isMobile && (
            <ButtonBackDesktop>
              <div id="desktopBackButton">
                <button type="button" onClick={props.backTo || goToPreviousScreen}>
                  <BackArrow />
                </button>
                {props.subTitle && <p>{props.subTitle.toUpperCase()}</p>}
              </div>
            </ButtonBackDesktop>
          )}

          {isMobile && (
            <>
              {/* <SubHeader
                isShowButtonHelpModal={!!dataModal}
                functionBackButton={props.backTo || goToPreviousScreen}
                functionHelpModal={() => setIsShowModal(true)}
              /> */}
              <ButtonBackDesktop>
                <div id="desktopBackButton">
                  <button type="button" onClick={props.backTo || goToPreviousScreen}>
                    <BackArrow />
                  </button>
                  {props.subTitle && <p>{props.subTitle.toUpperCase()}</p>}
                </div>
              </ButtonBackDesktop>
            </>
          )}

          {props.children}
        </Left>
        {!isMobile && <Right />}
      </View>
    </>
  )
}

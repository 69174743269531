import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'

export const Title = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;

  color: #000;

  letter-spacing: 0.01em;

  margin-top: 30px;
  margin-bottom: 0px;
  max-width: 408px;
`

export const Description = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.015em;

  max-width: 408px;

  color: #000;

  margin: 15px 0;

  * {
    font-family: 'Montserrat', sans-serif;
  }
`

export const TableWrapper = styled.div`
  margin-bottom: 32px;

  @media (max-width: 800px) {
    margin-bottom: 72px;
  }
`

export const TableContainer = styled.div`
  border-left: 1px solid ${theme.colors.bluePrimaryLight};
  border-right: 1px solid ${theme.colors.bluePrimaryLight};

  max-width: 408px;

  border-radius: 8px;

  border-spacing: 0px;

  transition: height 0.1s ease;

  ${({ height }) => css`
    height: ${height}px;
  `}
`

export const HeaderText = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.02em;

  color: ${theme.colors.bluePrimaryDark};

  text-align: left;

  @media (max-width: 800px) {
    font-size: 12px;
  }
`

export const RowItem = styled.div`
  font-family: 'Montserrat', sans-serif;

  display: flex;
  align-items: center;
  position: relative;

  height: 36px;

  background: #ffffff;

  box-sizing: border-box;

  ${({ showRadius, position }) =>
    showRadius &&
    css`
    border-bottom-${position}-radius: 6.5px;
  `}

  ${({ isSmallText }) => css`
    font-size: ${isSmallText ? '14px' : '16px'};
  `}
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${theme.colors.bluePrimaryLight};
  height: 36px;

  padding: 0 20px;

  border-radius: 6.5px 6.5px 0 0;

  div:nth-child(1) {
    width: 128px;
  }

  div:nth-child(2) {
    width: 152px;
  }

  div:nth-child(3) {
    width: 128px;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  div:nth-child(1) {
    width: 128px;
  }

  div:nth-child(2) {
    width: 152px;
  }

  div:nth-child(3) {
    width: 128px;
  }

  border-top: 0.5px solid ${theme.colors.bluePrimaryLight};
  border-bottom: 0.5px solid ${theme.colors.bluePrimaryLight};

  box-sizing: border-box;
  padding: 0 20px;
  background: #fff;

  ${({ showRadius }) =>
    showRadius &&
    css`
      border-radius: 0 0 6.5px 6.5px;
    `}
`

export const ShowMore = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: left;
  text-align: left;
  letter-spacing: 0.005em;

  width: 130px;

  @media (max-width: 800px) {
    width: 100%;
    justify-content: center;
  }

  color: ${theme.colors.bluePrimary};

  cursor: pointer;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  max-width: 408px;

  @media (max-width: 800px) {
    max-width: inherit;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
    height: 152px;
    position: fixed;

    bottom: 0;
    right: 0;
    left: 0;

    background: #fff;
  }
`

export const StyledLoadingDescription = styled(LoadingSkeleton)`
  margin-bottom: 15px;
  margin-top: 15px;
`

export const Back = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;

  cursor: pointer;

  color: ${theme.colors.bluePrimary};

  ${({ disabled }) =>
    disabled &&
    css`
      color: #cfcfcf;
      cursor: default;
    `}
`

export const PaidFlag = styled.div`
  margin-left: -16px;
  position: absolute;
`

export const ExtraInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  max-width: 408px;
  min-width: 130px;

  * {
    font-family: 'Montserrat', sans-serif;
  }
`

export const ExtraInfoPriceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding-top: 2px;
  border-top: 1px solid ${theme.colors.bluePrimary};
  gap: 2px;
`

export const ExtraInfoPriceLabel = styled.p`
  font-weight: 500;
  font-size: 15.84px;
  line-height: 24px;
  letter-spacing: 0.005em;
  margin: 0;
  color: #000000;
`

export const ExtraInfoPriceValue = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;
  color: ${theme.colors.bluePrimary};

  ${theme.breakpoints.down('md')} {
    font-weight: 500;
    font-size: 15.84px;
    letter-spacing: 0.005em;
  }
`

export const ExtraInfoDiscount = styled.span`
  display: flex;
  flex-direction: column;
`

export const ExtraInfoDiscountedItem = styled.span`
  display: flex;
  gap: 4px;
`

export const ExtraInfoItemPrice = styled.span`
  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: ${({ isVariant }) => (isVariant ? theme.colors.bluePrimary : '#000000')};

  ${({ hasDiscount }) =>
    hasDiscount &&
    css`
      color: ${theme.colors.greyPrimary};
      text-decoration-line: line-through;
    `};

  ${theme.breakpoints.down('md')} {
    line-height: 16px;
    letter-spacing: 0.0075em;
  }
`

export const ExtraInfoFullPrice = styled.span`
  display: flex;
  gap: 4px;
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 408px;
  margin-bottom: 4px;
`

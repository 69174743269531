import React, { useContext } from 'react'
import { Template } from '../../components/template'
import { HeaderV2 } from '../../components/HeaderV2'
import CardRequest from './components/card-request'
import { TemplateContext } from '../../components/template/context'

import * as S from './styles'

const Requests = () => {
  const { selectCrid, isMobile } = useContext(TemplateContext)

  return (
    <Template>
      <HeaderV2 label="Solicitações" />

      <S.ContainerRequests>
        <CardRequest cridInfo={selectCrid} isMobile={isMobile} />
      </S.ContainerRequests>
    </Template>
  )
}

export default Requests

import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  justify-content: space-between;
  position: relative;

  > img {
    height: 350px;
    position: absolute;
    top: 62%;

    ${theme.breakpoints.down('md')} {
      height: 350px;
      position: absolute;
      top: 24%;
    }

    @media (max-width: 414px) {
      height: 320px;
      position: absolute;
      top: 24%;
    }

    @media (max-width: 411px) {
      height: 300px;
      position: absolute;
      top: 26%;
    }

    @media (max-width: 375px) {
      height: 270px;
      position: absolute;
      top: 27%;
    }

    @media (max-width: 360px) {
      height: 253px;
      position: absolute;
      top: 27%;
    }

    ${theme.breakpoints.down('xs')} {
      height: 195px;
      position: absolute;
      top: 33%;
    }
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: 71%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${theme.breakpoints.down('md')} {
    justify-content: flex-start;
    height: 50%;
  }

  background: ${theme.colors.blue900};

  p {
    width: 100%;
    max-width: 518px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${theme.colors.white100};

    ${theme.breakpoints.down('md')} {
      position: absolute;
      bottom: 20%;
      color: ${theme.colors.blue900};
    }

    ${theme.breakpoints.down('xs')} {
      font-size: 14px;
    }
  }

  span {
    width: 100%;
    max-width: 593px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${theme.colors.white100};
    text-align: center;

    ${theme.breakpoints.down('md')} {
      display: none;
    }
  }
`

export const Content = styled.div`
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${theme.breakpoints.down('md')} {
    margin-top: 45px;
  }
`
export const Title = styled.h1`
  font-family: 'Montserrat' sans-serif;
  font-style: normal;
  font-weight: bold;
  color: ${theme.colors.blue600};
  font-size: 24px;
  line-height: 24px;
`

export const SubTitle = styled.h2`
  font-family: 'Montserrat' sans-serif;
  color: ${theme.colors.white100};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  text-align: center;

  ${theme.breakpoints.down('sm')} {
    font-size: 20px;
  }
`

export const Button = styled.button`
  width: 100%;
  max-width: 337px;
  height: 37px;

  margin: 33px 0;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.colors.white100};

  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: ${theme.colors.pink900};
  transition: 0.2s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  ${theme.breakpoints.down('md')} {
    max-width: 323px;
    position: absolute;
    bottom: 45px;
  }

  ${theme.breakpoints.down('xs')} {
    max-width: 285px;
  }
`

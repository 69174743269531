import { useState, useEffect } from 'react'

export const useWorkIncomeType = (goToNextScreen, incomeStatementPath, setDataModal) => {
  const [value, setValue] = useState()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (
      incomeStatementPath &&
      incomeStatementPath.readWorkIncomeType &&
      incomeStatementPath.readWorkIncomeType.options &&
      incomeStatementPath.readWorkIncomeType.options.length > 0 &&
      incomeStatementPath.readWorkIncomeType.options
    ) {
      const newArray = incomeStatementPath.readWorkIncomeType.options.map(item => {
        incomeStatementPath.readWorkIncomeType.choosed.map(choosedId => {
          if (choosedId.answer) {
            onValueChange(false, choosedId.answer)
          }
          if (item.id === choosedId.id) {
            item.active = true
            setIsButtonDisabled(false)
          }
        })
        return item
      })
      setOptions(newArray)
    }
  }, [incomeStatementPath])

  useEffect(() => {
    const isActive = options.filter(item => item.active).length > 0
    setIsButtonDisabled(!isActive)
  }, [options])

  const onSelectOption = item => {
    const arrayNew = options.map(option => {
      if (item.active) {
        if (item.id === option.id) {
          item.active = false
          return option
        } else {
          return option
        }
      } else {
        if (item.id === option.id) {
          item.active = true
          return option
        } else {
          return option
        }
      }
    })

    setOptions(arrayNew)
    setValue('')
  }

  const onSubmit = e => {
    e.preventDefault()
    const body = []
    options.forEach(item => {
      if (item.active && item.requiresExplanation) {
        body.push({ id: item.id, answer: value })
        return false
      }
      if (item.active) {
        body.push({ id: item.id })
      }
    })

    goToNextScreen(body)
  }

  const handlerSelectOption = item => {
    onSelectOption(item)
    return false
  }

  const onValueChange = (which, valueData) => {
    let text
    if (valueData) {
      text = value
    } else {
      text = which.target.value
    }

    setValue(text)
  }
  return { handlerSelectOption, onSubmit, onValueChange, value, isButtonDisabled, options }
}

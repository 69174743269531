import { useEffect, useState, useRef } from 'react'
import { showToast } from '@provi/provi-components'
import { history } from '../../../navigation/history'
import { getCCB } from '../../../services/provi-sign'
import { LOCALSTORAGE_TOKENS } from '../../../constants'

export const useTermsContract = (setIsLoading, objectValidation, typeUser) => {
  const [arrayPages, setArrayPages] = useState(null)
  const [isShowModal, setIsShowModal] = useState(false)
  const [proviContractId, setProviContractId] = useState()
  const [proviContractUrl, setProviContractUrl] = useState()
  const [isLoadingIntern, setIsLoadingIntern] = useState(false)
  const [openBottomSheetSigner, setOpenBottomSheetSigner] = useState(false)
  const [fingerSigned, onFingerSignToggle] = useState(false)
  const [loadProgress, setLoadProgress] = useState(0)

  const documentRef = useRef(null)

  useEffect(() => {
    const getData = async () => {
      setIsLoadingIntern(true)

      const body = {}
      const LoanOptionsIds = localStorage.getItem(LOCALSTORAGE_TOKENS.loan_option_ids)

      if (LoanOptionsIds) {
        body.LoanOptionsIds = JSON.parse(LoanOptionsIds)
      } else {
        body.ContractRecipientId = objectValidation && objectValidation.ContractRecipientId
      }

      const responseCCB = await getCCB(body, typeUser)

      setProviContractId(responseCCB.data.ProviContractId)
      setProviContractUrl(responseCCB.data.url.replace('https://', ''))
      setIsLoadingIntern(false)
    }

    if (!objectValidation) return

    if (objectValidation.isSigner) {
      getData()
    } else if (objectValidation.contractUrl && objectValidation.isSigner === false) {
      setProviContractUrl(objectValidation.contractUrl.replace('https://', ''))
    }
  }, [objectValidation])

  const onSubmit = async () => {
    try {
      setIsLoadingIntern(true)
      history.push('/provi-sign/assinatura-sucesso')
    } catch (err) {
      showToast()
    } finally {
      setIsLoadingIntern(false)
    }
  }

  const onLoadSuccess = pages => {
    const array = Array.from(Array(pages).keys())
    setArrayPages(array)
    setIsLoading(false)
    setIsLoadingIntern(false)
  }

  return [
    onLoadSuccess,
    arrayPages,
    isShowModal,
    setIsShowModal,
    onSubmit,
    proviContractId,
    proviContractUrl,
    isLoadingIntern,
    openBottomSheetSigner,
    setOpenBottomSheetSigner,
    fingerSigned,
    onFingerSignToggle,
    loadProgress,
    setLoadProgress,
    documentRef
  ]
}

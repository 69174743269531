import React from 'react'

import { ArrowLineDown } from '../../assets/svg/ArrowLineDown'

import { useSelectCridNotification } from './hooks'
import { AllowanceUpgrade } from './allowanceUpgrade'
import * as S from './styles'

export const SelectCridNotification = ({ downloadUrl, type, onClick, value }) => {
  const [handleDownload, show] = useSelectCridNotification(downloadUrl)

  if (type === 'AllowanceUpgrade') return <AllowanceUpgrade onClick={onClick} value={value} />

  return (
    <S.Container show={show}>
      <S.Title>Chegou a hora de declarar o Imposto de Renda</S.Title>
      <S.Subtitle>
        Se você vai declarar o Imposto de Renda este ano, fique de olho: financiamentos acima de R$5.000 devem ser incluídos no
        documento. Para valores menores, a inclusão é opcional.
      </S.Subtitle>

      <S.ButtonWrapper>
        <S.Sublink href="https://provi.com.br/ir" target="_blank">
          Tirar dúvidas
        </S.Sublink>

        <S.Button onClick={handleDownload}>
          Baixar Informe <ArrowLineDown />
        </S.Button>
      </S.ButtonWrapper>

      <S.BorderBottom />
    </S.Container>
  )
}

import { history } from '../../../../navigation/history'
import { activeStatus, ProviPayProductTypes } from './constants'

/**
 * @function
 * @param {object} data
 * @param {{showDetailedParts: true, canRequestCancellation: boolean}} data.dataExtraInfo
 * @param {string} data.route
 * @param {object} data.crid
 * @returns {void}
 */
export const redirect = ({ dataExtraInfo, route, crid }) => {
  if (!dataExtraInfo.showDetailedParts) return history.push('/dashboard/contratos')

  if (route) return history.push(route)

  if (ProviPayProductTypes.includes(crid.productType)) return history.push('/dashboard/ajuda')

  if (['ISA Ativo', 'ISA Concluído'].includes(crid.resumeStatus)) return history.push('/dashboard/declaracoes/pendentes')

  if (['ISA Concluído', 'Financiamento concluído'].includes(crid.resumeStatus)) {
    return history.push('/dashboard/faturas/pagas')
  }

  if (!crid.showPaymentFlows && crid.productType === 'Allowance') {
    return history.push('/dashboard/mesadas/abertas')
  }

  if (activeStatus.includes(crid.resumeStatus)) return history.push('/dashboard/faturas/abertas')
}

import React, { useEffect, useState } from 'react'
import { getDeclarationsIsa } from '../../../services/api'
import ItemInvoiceComponent from './invoice-isa/ItemInvoiceComponent'
import { InvoiceIsa } from './invoice-isa'

export const useDeclarationsIsa = (setMenuActive, setIsBack, selectCrid, handleValidationOfLinkedInRegister) => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getData = async id => {
      setIsLoading(true)
      try {
        const response = await getDeclarationsIsa(id)
        setData(response)
      } catch (err) {
      } finally {
        setIsLoading(false)
      }
    }
    if (selectCrid) {
      getData(selectCrid.id)
    }

    setMenuActive('invoicesIsa')
    setIsBack(true)
  }, [selectCrid])

  useEffect(() => {
    handleValidationOfLinkedInRegister()
  }, [])

  const renderInvoiceIsa = (item, index) => {
    let obj = {
      delayed: <InvoiceIsa.InvoiceDelayed index={index} item={item} key={item.id} />,
      incomplete: <InvoiceIsa.InvoiceAvaliable index={index} item={item} key={item.id} />,
      analysis: <ItemInvoiceComponent index={index} item={item} status="em análise" />,
      effected: <ItemInvoiceComponent index={index} item={item} showButton={false} status="enviada" />,
      review: <InvoiceIsa.InvoiceReview index={index} item={item} key={item.id} />
    }
    return obj[item.status]
  }

  const toggle = 'not_effected'

  const effected = data && data?.filter(item => ['effected', 'analysis'].includes(item.status))
  const not_effected = data && data?.filter(item => ['incomplete', 'delayed', 'review'].includes(item.status))

  return { renderInvoiceIsa, isLoading, toggle, effected, not_effected }
}

import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  padding-left: 155px;
  box-sizing: border-box;
  width: 100%;
  height: 75%;
  z-index: 2;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .subTitleContainer {
    p {
      margin: 0;
      padding: 0;
    }
  }

  ${theme.breakpoints.down('md')} {
    width: 100%;
    padding: 0 24px;
  }

  ${theme.breakpoints.down('xs')} {
    .subTitleContainer {
      p {
        font-size: 12px;
        > br {
          display: none;
        }
      }
    }
  }
`

export const ContainerOptions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 38px;
  width: 94%;

  ${theme.breakpoints.down('md')} {
    justify-content: space-between;
  }
`

export const SubTitle = styled.p`
  font-family: CenturyGothic, sans-serif;
  color: ${theme.colors.blue900};
  font-size: 16px;
  line-height: 30px;
  ${theme.breakpoints.down('md')} {
    font-size: 14px;
  }

  font-weight: ${prop => (prop.isBold ? 'bold' : 'normal')};
`

export const ButtonPosition = styled.div`
  padding-bottom: 1px;
`

import React, { useState } from 'react'
import { useEffect } from 'react'

import { LoadingSkeleton } from '../../../../components/LoadingSkeleton'
import { Row } from '../components/row'
import * as S from '../styles'

export const TableInstallments = ({ selected, installments, isLoading, isMobile }) => {
  const [collapsed, setCollapsed] = useState(true)

  useEffect(() => {
    selected ? setCollapsed(false) : setCollapsed(true)

    return () => {
      setCollapsed(true)
    }
  }, [selected])

  const installmentsCollapsed = collapsed ? installments?.filter((_installment, index) => index <= 2) : installments

  return (
    <>
      {isLoading || !installments?.length ? (
        <LoadingSkeleton isLoading hasHeading containerHeight="264px" containerWidth={isMobile ? '100%' : '408px'} />
      ) : (
        <S.TableWrapper>
          <S.TableContainer height={38 * installmentsCollapsed?.length + 34} collapsed={collapsed}>
            <S.HeaderWrapper>
              <S.HeaderText showRadius position="left">
                Fatura
              </S.HeaderText>
              <S.HeaderText>Valor</S.HeaderText>
              <S.HeaderText showRadius position="right">
                Vencimento
              </S.HeaderText>
            </S.HeaderWrapper>
            {installmentsCollapsed?.map((row, index) => (
              <Row
                length={installmentsCollapsed?.length}
                index={index}
                row={row}
                key={index}
                text="Parcela"
                isPaid={row?.isPaid}
              />
            ))}
          </S.TableContainer>
          {installments?.length > 3 && (
            <S.ShowMore onClick={() => setCollapsed(!collapsed)}>{collapsed ? 'Mostrar mais' : 'Mostrar menos'}</S.ShowMore>
          )}
        </S.TableWrapper>
      )}
    </>
  )
}

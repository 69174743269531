import React from 'react'
import styled from 'styled-components'

export const Hr = styled.hr`
  margin: 25px 0 15px 0;
`

const HorizontalRow = () => {
  return <Hr />
}

export default HorizontalRow

import React from 'react'
import * as S from './styles'
import { currencyParser } from '../../../../utils'
import { ReactComponent as Calendar } from '../../../../assets/svg/calendar.svg'

const SummaryPaymentOrder = ({ data }) => {
  return (
    <>
      <S.CardRequestValueContainer>
        <S.CardRequestValue>
          <span>Valor: </span> {currencyParser(data.refundAmount, 'R$ ')}
        </S.CardRequestValue>
        <S.Textinfo>
          <Calendar />
          <p>A sua ordem de pagamento estará disponível em até 15 dias úteis para saque em qualquer agência Itaú</p>
        </S.Textinfo>
      </S.CardRequestValueContainer>
    </>
  )
}

export default SummaryPaymentOrder

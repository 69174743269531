import { useState, useEffect } from 'react'

export const useProfileScreen = ({ linkedInData, handleLinkedInInsertion, handleValidationOfLinkedInRegister }) => {
  const [linkedInInputValue, setInputLinkedInValue] = useState('https://www.linkedin.com/in/')
  const [isEditLinkedInButtonDisabled, setIsEditLinkedInButtonDisabled] = useState(true)

  const onPasteLinkedinInput = e => {
    e.preventDefault()
    const clipboardData = e.clipboardData.getData('Text')
    const linkedinUrl = 'https://www.linkedin.com/in/'

    if (clipboardData.includes(linkedinUrl)) {
      return setInputLinkedInValue(clipboardData)
    }

    return setInputLinkedInValue(linkedinUrl + clipboardData)
  }

  const onChangeLinkedinInput = e => {
    const { value } = e.target
    const linkedinUrl = 'https://www.linkedin.com/in/'

    if (value.length <= 27) {
      return setInputLinkedInValue(linkedinUrl)
    }

    return setInputLinkedInValue(value)
  }

  const handleOnBlur = async () => {
    handleLinkedInInsertion(linkedInInputValue)
    if (linkedInData) {
      setInputLinkedInValue(linkedInData)
    }
    setIsEditLinkedInButtonDisabled(true)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleLinkedInInsertion(linkedInInputValue)
      setIsEditLinkedInButtonDisabled(true)
    }
  }

  useEffect(() => {
    if (linkedInData) {
      setInputLinkedInValue(linkedInData)
    }
  }, [linkedInData])

  useEffect(() => {
    handleValidationOfLinkedInRegister()
  }, [])

  return {
    linkedInInputValue,
    setInputLinkedInValue,
    isEditLinkedInButtonDisabled,
    setIsEditLinkedInButtonDisabled,
    handleOnBlur,
    handleKeyDown,
    onPasteLinkedinInput,
    onChangeLinkedinInput
  }
}

import React, { useContext, useEffect } from 'react'
import { Button, theme, LoadingProvi } from '@provi/provi-components'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { Container, SubTitle, Text, Wrapper } from './style'

export const Terms = () => {
  const { isLoading, incomeStatementPath, goToNextScreen, setDataModal } = useContext(TemplateDeclarationContext)

  useEffect(() => {
    setDataModal(null)
  }, [setDataModal])

  return (
    <>
      {isLoading && <LoadingProvi isLoadingDark={false} />}
      <Container>
        <Wrapper>
          <Text>Antes de começar, queremos que você nos diga se concorda com a seguinte frase:</Text>
          <SubTitle>
            “Eu, {incomeStatementPath && incomeStatementPath.ReadFullName}, prometo falar a verdade nas respostas que darei para
            a PIN (Provi in Network).”
          </SubTitle>

          <Button
            marginHorizontal={0}
            marginVertical={0}
            text="Avançar"
            onClick={() => goToNextScreen(true)}
            textLink={{
              color: theme.colors.white100,
              text: 'Não concordo',
              onClick: () => goToNextScreen(false)
            }}
          />
        </Wrapper>
      </Container>
    </>
  )
}

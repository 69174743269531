import { useState, useCallback, useMemo } from 'react'
import { showToast } from '@provi/provi-components'
import { resMedPurchaseInstallments, resMedSanarPurchase, resMedPurchaseResume } from '../../../services/api'
import { history } from '../../../navigation/history'

export const useFormOfPayment = setIsLoading => {
  const [options, setOptions] = useState([])
  const [upfrontAmount, setUpfrontAmount] = useState(null)
  const [purchaseResumeTotal, setPurchaseResumeTotal] = useState(null)
  const [purchaseFinalTotal, setPurchaseFinalTotal] = useState(null)
  const [purchaseResumeData, setPurchaseResumeData] = useState({})
  const [isPurchaseResumeMobileVisible, setIsPurchaseResumeMobileVisible] = useState(false)

  const formatUpfrontAmount = useMemo(
    () =>
      upfrontAmount ? Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(upfrontAmount) : 'R$00,00',
    [upfrontAmount]
  )

  const formatInstallmentPrice = useCallback(
    price => (price ? Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price) : 'R$00,00'),
    []
  )

  const formatPurchaseResumeTotal = useMemo(
    () =>
      purchaseResumeTotal
        ? Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(purchaseResumeTotal)
        : 'R$00,00',
    [purchaseResumeTotal]
  )

  const formatPurchaseFinalTotal = useMemo(
    () =>
      purchaseFinalTotal
        ? Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(purchaseFinalTotal)
        : 'R$00,00',
    [purchaseFinalTotal]
  )

  const getResMedInstallmentOptions = useCallback(async () => {
    setIsLoading(true)
    try {
      const crid = JSON.parse(localStorage.getItem('CRID'))
      const data = await resMedPurchaseInstallments(crid.id)

      setUpfrontAmount(data?.response?.values.upfrontAmount)
      setPurchaseFinalTotal(data?.response?.values.finalTotal)
      setOptions(data.response.options)
    } catch (error) {
      showToast('Erro, por favor tente novamente')
    } finally {
      setIsLoading(false)
    }
  }, [setIsLoading])

  const getPurchaseTotalData = useCallback(async () => {
    setIsLoading(true)
    try {
      const crid = JSON.parse(localStorage.getItem('CRID'))
      const data = await resMedPurchaseResume(crid.id)

      setPurchaseResumeTotal(data?.response?.total)
    } catch (error) {
      showToast('Erro, por favor tente novamente')
      setIsLoading(false)
    }
  }, [setIsLoading])

  const handleNextScreen = useCallback(
    async event => {
      event.preventDefault()
      setIsLoading(true)
      try {
        const crid = JSON.parse(localStorage.getItem('CRID'))

        const activeOption = options.find(option => option.active)

        if (!activeOption) {
          showToast('Por favor selecione uma opção!')
        } else {
          const data = { installment: activeOption, crid }
          await resMedSanarPurchase(data).then(history.push('/provi-pay/aprovacao-resmed'))
        }
      } catch (error) {
        showToast('Erro, por favor tente novamente')
        setIsLoading(false)
      }
    },
    [options, setIsLoading]
  )

  const getPurchaseResumeData = useCallback(async () => {
    setIsLoading(true)
    try {
      const crid = JSON.parse(localStorage.getItem('CRID'))
      const data = await resMedPurchaseResume(crid.id)
      setPurchaseResumeData(data)
    } catch (error) {
      showToast('Error, por favor tente novamente')
      setIsLoading(false)
    }
  }, [setIsLoading])

  const onSelectOption = item => {
    const arrayNew = options.map(option => {
      if (item.active) {
        if (item.id === option.id) {
          item.active = false
          return option
        } else {
          option.active = false
          return option
        }
      } else {
        if (item.id === option.id) {
          item.active = true
          return option
        } else {
          option.active = false
          return option
        }
      }
    })

    setOptions(arrayNew)
  }

  const handlePurchaseResumeVisibility = useCallback(() => {
    setIsPurchaseResumeMobileVisible(!isPurchaseResumeMobileVisible)
  }, [isPurchaseResumeMobileVisible])

  return {
    onSelectOption,
    options,
    handleNextScreen,
    formatInstallmentPrice,
    formatUpfrontAmount,
    getResMedInstallmentOptions,
    getPurchaseTotalData,
    formatPurchaseResumeTotal,
    formatPurchaseFinalTotal,
    purchaseResumeData,
    getPurchaseResumeData,
    handlePurchaseResumeVisibility,
    isPurchaseResumeMobileVisible
  }
}

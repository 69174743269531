import React, { useContext } from 'react'

import { Template } from '../../../components/template'
import { HeaderV2 } from '../../../components/HeaderV2'
import { TemplateContext } from '../../../components/template/context'
import { Title, Description } from '../alter-allowance-v2/styles'
import * as S from './styles'
import { useUpgradeAllowanceSuccess } from './hooks'

export const UpgradeAllowanceSuccess = () => {
  const { isMobile } = useContext(TemplateContext)
  const { goBack } = useUpgradeAllowanceSuccess()

  return (
    <Template>
      <HeaderV2 label="Aumento em análise" isMobile={isMobile} />
      <Title>Estamos analisando seu pedido 🔍</Title>
      <Description>Vamos entrar em contato por e-mail assim que confirmarmos o upgrade da sua Mesada.</Description>
      <S.ButtonGoBack onClick={goBack}>Voltar para mesadas</S.ButtonGoBack>
    </Template>
  )
}

import React from 'react'
import * as S from './styles'

const CardNoRefund = () => {
  return (
    <>
      <S.CardRequestValueContainer>
        <S.CardRequestValue>Você não tem valores a pagar ou receber</S.CardRequestValue>
      </S.CardRequestValueContainer>
    </>
  )
}

export default CardNoRefund

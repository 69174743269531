import React from 'react'

import { Content as ContentComponent } from '../styles'
import { useContent } from './hooks'
import * as S from './styles'

function Content({ text }) {
  const { handleInsideLink, insertDynamicInfos } = useContent()

  if (text.includes('call_to_action'))
    return (
      <S.ButtonCallToAction aria-label="conteúdo" className="collapse-content">
        <a href={text.split(':')[2]}>{text.split(':')[1]}</a>
      </S.ButtonCallToAction>
    )

  return (
    <ContentComponent aria-label="conteúdo" className="collapse-content">
      {handleInsideLink(insertDynamicInfos(text))}
    </ContentComponent>
  )
}

export default Content

import styled from 'styled-components'
import CurrencyInput from 'react-currency-input'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  padding-left: 155px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: 593px;
  z-index: 2;
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.down('md')} {
    padding: 0 27px;
  }

  input {
    margin-top: 0;
    margin-bottom: 20px;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 0 24px;
    form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      padding-bottom: 20px;

      input {
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
  }
`

export const SubTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-family: CenturyGothic;
  margin-top: -20px !important;
`

export const InputMasks = styled(CurrencyInput)`
  margin-top: 24px;
  width: ${({ width }) => width || 'auto'};
  height: 26px;
  padding: 7px 8px;
  border: 1px solid ${theme.colors.blue900};
  border-radius: 8px;
  margin-bottom: 120px;

  &:before {
    content: '';
    position: absolute;
  }
  &:focus {
    outline-offset: 0;
    outline: none;
  }

  ${theme.breakpoints.down('md')} {
    margin-bottom: 120px;
  }

  ${theme.breakpoints.down('sm')} {
    margin-bottom: 100px;
  }
`

export const Row = styled.div`
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const TextValue = styled.p`
  font-family: CenturyGothic;
  font-size: 18px;
  line-height: 24px;
  margin: 0px;

  ${theme.breakpoints.down('md')} {
    font-size: 14px;
    line-height: 24px;
  }
`

import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { Button } from '@material-ui/core'

export const ContainerButton = styled.div`
  margin-top: ${({ marginVertical }) => marginVertical}px;
  margin-bottom: ${({ marginVertical }) => marginVertical}px;

  margin-left: ${({ marginHorizontal }) => marginHorizontal}px;
  margin-right: ${({ marginHorizontal }) => marginHorizontal}px;

  width: ${theme.variables.spacer * 32}px;
  text-align: center;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

export const ButtonWrapper = styled(Button)`
  float: none;
  border-radius: ${theme.variables.spacer * 4}px !important;
  width: ${theme.variables.spacer * 32}px;
  height: ${theme.variables.spacer * 6}px;
  .MuiButton-label {
    color: ${theme.colors.pink900};
    font-weight: bold;
    text-transform: none;
    font-size: 16px;
    font-family: 'CenturyGothic', sans-serif;
  }
`

export const ButtonTitle = styled.p`
  font-size: ${theme.variables.spacer * 2}px;
  margin: 0;
  font-weight: bold;
  color: ${props => (props.visuals ? props.visuals.buttonTextColor : theme.colors.white100)};
`

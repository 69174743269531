import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const OptionsList = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${theme.variables.spacer * 4}px;
  flex-wrap: wrap;
  ${theme.breakpoints.down('md')} {
    display: block;
  }
`
export const Option = styled.div`
  margin-top: 20px;
  cursor: pointer;
  height: ${theme.variables.spacer * 8}px;
  box-shadow: 2px 1px 15px -9px rgba(0, 0, 0, 0.5);
  height: 88px;
  box-sizing: border-box;
  width: 272px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  background-color: ${({ active }) => (active ? theme.colors.blue900 : theme.colors.white100)};
  margin-right: 16px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
    margin-right: 0;
  }
`
export const OptionText = styled.p`
  font-family: ${({ bold }) => (bold ? 'CenturyGothicBold' : 'CenturyGothic')}, sans serif;
  margin: 0;
  font-size: ${({ size }) => (size ? size : 16)}px;
  color: ${({ active }) => (active ? theme.colors.white100 : theme.colors.blue900)};
`

export const WrapperOption = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  padding-left: 155px;
  box-sizing: border-box;
  width: 100%;
  height: 75%;
  max-width: 593px;
  z-index: 2;

  ${theme.breakpoints.down('md')} {
    padding: 0 27px;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 0 24px;
  }
`

export const SubTitle = styled.p`
  font-family: CenturyGothicBold, sans-serif;
  color: ${theme.colors.blue900};
  font-size: 20px;
  line-height: 30px;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;

  div {
    margin: 0;
    padding: 0;
    margin-right: 24px;
  }

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    padding-top: 80px;

    div + div {
      margin-top: 17px;
    }
  }

  ${theme.breakpoints.down('sm')} {
    padding-top: 80px;
    padding-bottom: 20px;
  }
`

export const Text = styled.p`
  font-family: CenturyGothic, sans serif;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.blue900};
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

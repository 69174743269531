import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Card = styled.div`
  width: 100%;
  max-width: 234px;

  margin-left: 32px;
  margin-top: -162px;
  padding: 1rem;
  background-color: white;
`

export const Wrapper = styled.div`
  h1 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    color: ${theme.colors.blue900};
  }
`

export const SanarUpCardContent = styled.div`
  min-height: 112px;
  border: 1px solid ${theme.colors.grey500};
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;

  .sanar-up-logo-container {
    width: 52px;
    height: 52px;
    background: #f6f3f3;
    border-radius: 3px;
    display: flex;
  }

  .sanar-up-card-info {
    width: 100%;
    max-width: 195px;
    margin-left: 8px;

    h2 {
      margin: 0;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;

      color: ${theme.colors.blue900};
    }

    .title {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      margin: 10px 0;

      color: ${theme.colors.blue900};
    }

    .sanar-up-price-info {
      > strong {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: ${theme.colors.blue900};
      }

      > span {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        margin-left: 5px;

        text-decoration-line: line-through;

        color: #414141;
      }
    }
  }
`

export const AmountContainer = styled.div`
  margin-top: 22px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  .total-info {
    margin-top: 23px;
    .indicator {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;

      color: ${theme.colors.blue900};
    }
  }

  #total {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    margin-top: 28px;

    text-align: right;
    color: ${theme.colors.blue900};
  }

  .column {
    display: flex;
    flex-direction: column;

    span {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;

      color: ${theme.colors.blue900};
    }

    strong {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;

      text-align: right;
      color: ${theme.colors.blue900};
    }
  }

  .colum.price {
    align-items: flex-end;
  }
`

export const AmountFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 11px;
  }

  h4 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    color: #000000;
  }
`

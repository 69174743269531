import styled, { css } from 'styled-components'

export const View = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f6f8fb;
`

const defaultMobileMargin = css`
  margin: 0;
`

export const Main = styled.div`
  min-height: 100%;
  min-height: -webkit-fill-available;
  overflow: auto;
  margin-left: ${props => (props.isMobile ? defaultMobileMargin : props.isMenuToggled ? '240px' : '60px')};
  transition: margin-left 0.3s ease-in-out;
`

import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #f1f1f1;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;
`
export const Card = styled.div`
  width: 100%;
  max-width: 656px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  height: 80%;
  max-height: 600px;
  overflow-y: auto;

  margin-top: 25px;
  background: ${theme.colors.white100};

  @media (max-height: 568px) {
    max-height: 511px;
  }

  ${theme.breakpoints.down('sm')} {
    max-width: 432px;
  }

  @media (max-width: 414px) {
    max-width: 347px;
  }

  @media (max-width: 411px) {
    max-width: 339px;
  }

  @media (max-width: 375px) {
    max-width: 305px;
  }

  @media (max-width: 360px) {
    max-width: 295px;
  }

  ${theme.breakpoints.down('xs')} {
    max-width: 228px;
  }

  @media (max-width: 320px) {
    max-width: 249px;
  }

  #title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: ${theme.colors.blue900};

    ${theme.breakpoints.down('md')} {
      font-size: 14px;
      text-align: center;
    }
  }

  #subtitle {
    margin-top: 45px;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;

    color: ${theme.colors.blue900};

    ${theme.breakpoints.down('sm')} {
      align-self: flex-start;
      font-size: 14px;

      color: ${theme.colors.pink900};
    }
  }

  h6 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
  }

  p {
    width: 100%;
    max-width: 512px;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.colors.blue900};
    text-align: center;

    ${theme.breakpoints.down('md')} {
      font-size: 12px;
    }
  }

  .buttonContainer {
    display: flex;
    align-self: flex-end;

    div {
      margin: 24px 0;
    }

    @media (max-width: 414px) {
      width: 109%;

      div {
        margin-top: 24px;
        margin-bottom: 0;
        margin-left: -3px;
        > button {
          width: 110%;
        }
      }
    }

    @media (max-width: 411px) {
      width: 110%;

      div {
        margin-top: 24px;
        margin-bottom: 0;
        margin-left: -3px;
        > button {
          width: 111%;
        }
      }
    }

    @media (max-width: 375px) {
      width: 111%;

      div {
        margin-top: 24px;
        margin-bottom: 0;
        margin-left: -3px;
        > button {
          width: 112%;
        }
      }
    }

    @media (max-width: 360px) {
      width: 110%;

      div {
        margin-top: 24px;
        margin-bottom: 0;
        margin-left: -3px;
        > button {
          width: 111%;
        }
      }
    }

    @media (max-width: 320px) {
      width: 113%;

      div {
        margin-top: 24px;
        margin-bottom: 0;
        margin-left: -3px;
        > button {
          width: 114%;
        }
      }
    }
  }
`

export const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 70%;
  margin-top: 35px;

  @media (max-width: 768px) {
    width: 60%;
  }

  @media (max-width: 540px) {
    width: 70%;
  }

  @media (max-width: 414px) {
    width: 65%;
  }

  @media (max-width: 375px) {
    width: 80%;
  }

  @media (max-width: 320px) {
    width: 100%;
  }
`

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid ${theme.colors.blue600};
  background: ${props => (props.active ? 'rgba(92, 181, 254, 0.25)' : 'transparent')};
  padding: 0 7px;

  ${theme.breakpoints.down('xs')} {
    max-width: 270px;
  }

  .fees-container {
    width: 38%;
    margin-left: 12px;
    display: flex;
    align-items: center;

    ${theme.breakpoints.down('sm')} {
      font-size: 12px;
    }
  }

  .option-content {
    display: flex;

    width: 100%;

    padding: 6px 0;
    margin-left: 10px;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 24px;
    color: ${theme.colors.blue900};

    ${theme.breakpoints.down('md')} {
      max-width: 200px;
      flex-direction: column;
      line-height: 15px;
    }

    @media (max-width: 414px) {
      max-width: 120px;
    }

    ${theme.breakpoints.down('sm')} {
      font-size: 10px;
    }

    .portion-container {
      display: flex;
      align-items: center;

      span {
        margin-left: 2px;
      }

      strong {
        font-size: 15px;
        margin: 0 3px 0 3px;

        ${theme.breakpoints.down('sm')} {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
`

const checkedBackground = css`
  background: ${theme.colors.blue600};
`

const transparentBackground = css`
  background: transparent;
`
export const CheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 5px;
  border: 1px solid ${theme.colors.blue600};
  cursor: pointer;
  flex-shrink: 0;

  ${props => (props.active ? checkedBackground : transparentBackground)};

  > svg {
    width: 15px;
    height: 15px;
  }

  ${theme.breakpoints.down('md')} {
    border-radius: 50%;
  }
`

export const Pipe = styled.div`
  background: ${theme.colors.pink900};
  border-radius: 5px;
  height: 24px;
  width: 1px;

  @media (max-width: 320px) {
    margin: 0 12px;
  }
`
export const MobilePurchaseResume = styled.div`
  display: none;
  flex-direction: column;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cfcfcf;
    padding: 14px 0;

    .header {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      > h5 {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        margin: 0;
        color: ${theme.colors.blue900};
      }

      .toggle-button-container {
        display: flex;
        align-items: center;
        > strong {
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-size: 12px;
          line-height: 18px;
          color: ${theme.colors.blue900};
          margin-right: 7px;
        }
      }
    }
  }
`

const rotate180deg = css`
  transform: rotate(180deg);
`

export const ToggleMobilePurchaseResumeButton = styled.div`
  margin: 0;
  padding: 0;
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;

  ${props => props.toggled && rotate180deg};

  > svg {
    color: ${theme.colors.pink900};
  }
`
export const MobileSanarProductContainer = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  border: 1px solid ${theme.colors.grey500};
  box-sizing: border-box;
  border-radius: 2px;
  justify-content: center;
  padding: 7px;
  min-height: 83px;
  width: 100%;
  margin-top: 7px;
  align-items: center;

  .sanar-up-logo-container {
    height: 46px;
    background: #f6f3f3;
    border-radius: 3px;
    display: flex;
  }

  .sanar-up-card-info {
    width: 100%;
    margin-left: 8px;
    display: flex;
    flex-direction: column;

    > strong {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;
      color: ${theme.colors.pink900};
      margin: 3px 0;
    }

    > span {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
      color: ${theme.colors.blue900};
      margin: 6px 0;
    }

    > h4 {
      font-family: 'Montserrat', sans-serif;

      font-style: normal;
      font-size: 16px;
      line-height: 18px;
      margin: 3px 0;
      color: ${theme.colors.blue900};
    }
  }
`
export const AmountMobileContainer = styled.div`
  margin-top: 22px;
  display: ${props => (props.visible ? 'grid' : 'none')};

  grid-template-columns: 1fr 1fr;
  width: 100%;

  .total-info {
    .indicator {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;

      color: ${theme.colors.blue900};
    }

    .total {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;

      text-align: right;
      color: ${theme.colors.blue900};
    }
  }

  .column {
    display: flex;
    flex-direction: column;

    span {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;

      color: ${theme.colors.blue900};
    }

    strong {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;

      text-align: right;
      color: ${theme.colors.blue900};
    }
  }

  .column.price {
    align-items: flex-end;
  }
`

import TagManager from 'react-gtm-module'

const crid = JSON.parse(localStorage.getItem('CRID'))

/**
 * @description Init sanar GTM
 * @function
 * @name initGTM
 */
export const initGTM = enviroment => {
  const isProduction = enviroment === 'production'

  const tagManagerArgs = {
    gtmId: isProduction ? 'GTM-MPR3KXX' : 'GTM-KVDFQSK',
    dataLayer: {
      ProductType: crid?.ProductType,
      UserId: crid?.UserId,
      CreditRequestId: crid?.id
    }
  }

  TagManager.initialize(tagManagerArgs)
}

import styled from 'styled-components'
import { LoadingSkeleton } from '../../../../components/LoadingSkeleton'

export const Card = styled.div`
  width: 496px;
  height: 156px;

  border-radius: 16px;

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const ButtonGroup = styled.div`
  width: 296px;
  height: 48px;

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const StyledLoadingSkeleton = styled(LoadingSkeleton)`
  h2 {
    border-radius: 24px;
  }
`

import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #f1f1f1;
  overflow-x: scroll;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  hr {
    margin: 24px -17px 4px -17px;
  }

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: ${theme.colors.blue900};
  }

  h3 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #414141;
  }

  h5 {
    font-family: 'Montserrat', sans-serif;
    color: ${theme.colors.pink900};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  ${theme.breakpoints.down('md')} {
    h3,
    h1 {
      display: none;
    }
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 35px;

  label + label {
    margin-bottom: 24px;
  }

  .input-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .input-group {
    width: 100%;
    max-width: 280px;
    display: flex;
    justify-content: space-between;

    ${theme.breakpoints.down('md')} {
      max-width: 100%;
      > label + label {
        margin-left: 15px;
      }
    }
  }

  .buttonContainer {
    display: flex;
    align-self: flex-end;

    > div {
      margin: 24px 0 24px 0;
    }

    ${theme.breakpoints.down('md')} {
      align-self: center;
    }
  }
`

import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 25px 0 25px;
`

export const Content = styled.div`
  width: 100vw;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  background-color: ${theme.colors.white100};
  position: fixed;
  inset: 0;

  cursor: default;
  z-index: 6;

  transition: 400ms ease;
`

export const ContentHeader = styled.div`
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;

  > button {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  & > a > svg {
    max-width: 90px;
    height: auto;
  }
`
export const LinkedInValidationAlert = styled.div`
  font-family: 'Montserrat', sans-serif;
  width: 20px;
  height: 20px;
  margin-left: 25px;
  background-color: ${theme.colors.pink900};
  border-radius: 50%;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 14px;
  margin-top: 15px;
`

export const LogoutMobileContainer = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  align-items: center;
  align-self: stretch;
  flex-grow: 0;
  padding: 0 16px;
  margin-top: 100px;

  > a {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: ${theme.colors.pink900};
    cursor: pointer;
    text-decoration: none;
  }
`
export const DeclarationsIsaButton = styled.a`
  position: absolute;
  right: 0;
  padding: 0px 20px;
  cursor: pointer;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  text-decoration-line: underline;

  color: ${theme.colors.blue900};
`

export const ContainerHamburguer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${theme.colors.white100};
  position: relative;
`

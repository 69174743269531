import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
`

export const ModalTitle = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0 0 24px 0;
  color: ${theme.colors.bluePrimary};
`

export const ModalContent = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13.2px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px;
  ul {
    margin: 16px 0 16px 24px;
  }
  button {
    margin-top: 32px;
  }
`

export const CloseModal = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 464px;
  position: fixed;
  button {
    border: none;
    background: transparent;
    cursor: pointer;
  }
  @media (max-width: 800px) {
    width: 276px;
  }
`

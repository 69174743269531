import { theme } from '@provi/provi-components'
import React from 'react'

export const ArrowLeft = () => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="https://www.w3.org/2000/svg">
      <path
        d="M19 8.5L1.75 8.5M8 15.5L1 8.5L8 1"
        stroke={theme.colors.bluePrimary}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

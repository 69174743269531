import React, { useContext, useEffect } from 'react'
import { Button } from '@provi/provi-components'
import { history } from '../../../navigation/history'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { Container, Title, SubTitle, Wrapper } from './style'

export const SuccessCaseConfirmation = () => {
  const { incomeStatementPath, setDataModal } = useContext(TemplateDeclarationContext)

  useEffect(() => {
    setDataModal(null)
  }, [setDataModal])

  const firstName = incomeStatementPath && incomeStatementPath.ReadFullName && incomeStatementPath.ReadFullName.split(' ')

  return (
    <Container>
      <Wrapper>
        <Title>Valeu, {firstName && firstName.length > 0 && firstName[0]}!</Title>
        <SubTitle>
          Entraremos em contato com você pelo Slack da PIN assim que possível, para combinarmos o melhor jeito.{' '}
        </SubTitle>

        <Button
          onClick={() => history.push('/dashboard/declaracoes-isa')}
          marginHorizontal={0}
          marginVertical={0}
          text="Voltar para o dashboard"
        />
      </Wrapper>
    </Container>
  )
}

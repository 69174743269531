import React from 'react'
import { currencyParser } from '../../../../utils'
import * as S from './styles'

const CardPaymentOrder = data => {
  return (
    <>
      <S.CardRequestValueContainer>
        <S.CardRequestValue>
          <span>Valor : </span> {currencyParser(data.data.refundSummary.refundAmount, 'R$')}
        </S.CardRequestValue>
      </S.CardRequestValueContainer>

      <S.CardRequestWithdraw>Saque em qualquer agência Itaú</S.CardRequestWithdraw>
    </>
  )
}

export default CardPaymentOrder

import React, { useCallback, useContext } from 'react'
import Big from 'big.js'

import sonarUpLogo2 from '../../../assets/svg/sonar-up-logo-2.svg'
import safetyPurchaseIcon from '../../../assets/svg/safety-purchase.svg'
import { currencyParser } from '../../../utils'

import { Card, Wrapper, SanarUpCardContent, AmountContainer, AmountFooter } from './styles'
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'
import { TemplateContext } from '../../../components/template/context'

export const PurchaseResume = ({ purchaseResumeData }) => {
  /** @type {(value: string | number) => string} */
  const formatCurrencyWithRounding = useCallback(value => {
    if (/^[\d]+(\.[0-1]+)$/.test(value)) return currencyParser(Big(value).round(1), 'R$ ')
    return currencyParser(value, 'R$ ')
  }, [])
  const { isLoading } = useContext(TemplateContext)

  console.log(isLoading)
  return (
    <Card>
      <Wrapper>
        <h1>Resumo da compra</h1>
        <SanarUpCardContent>
          <div className="sanar-up-logo-container">
            <img src={sonarUpLogo2} alt="Logo Sonar UP" />
          </div>

          <div className="sanar-up-card-info">
            <h2>Sanar Residência Médica</h2>

            {isLoading ? (
              <LoadingSkeleton isLoading={isLoading} hasHeading containerHeight="80%" containerWidth="100%" />
            ) : (
              <>
                <span className="title">{purchaseResumeData?.response?.courseName}</span>

                <div className="sanar-up-price-info">
                  <strong>{formatCurrencyWithRounding(purchaseResumeData?.response?.total || 0)}</strong>{' '}
                  <span> {formatCurrencyWithRounding(purchaseResumeData?.response?.subtotal || 0)} </span>
                </div>
              </>
            )}
          </div>
        </SanarUpCardContent>

        <AmountContainer>
          <div className="colum">
            <span>Subtotal</span>
            <div>
              <span>Descontos</span>
            </div>
            <div className="total-info">
              <span className="indicator">Total</span>
            </div>
          </div>

          <div className="column price">
            <strong>{formatCurrencyWithRounding(purchaseResumeData?.response?.subtotal || 0)}</strong>
            <strong>- {formatCurrencyWithRounding(purchaseResumeData?.response?.discount || 0)}</strong>
            <strong id="total">{formatCurrencyWithRounding(purchaseResumeData?.response?.total || 0)}</strong>
          </div>
        </AmountContainer>

        <hr />

        <AmountFooter>
          <img src={safetyPurchaseIcon} alt="Compra segura" />
          <h4>Compra 100% Segura</h4>
        </AmountFooter>
      </Wrapper>
    </Card>
  )
}

import React, { useContext } from 'react'
import { Template } from '../../../components/template'
import { TemplateContext } from '../../../components/template/context'
import { useDeclarationsIsa } from './hooks'
import { LinkedInContext } from '../../../components/LinkedInTooltip/context'
import { Wrapper, HeaderTable, Title1Effected, Title2Effected, Title3Effected, AltLink } from './styles'
import { HeaderV2 } from '../../../components/HeaderV2'
import { Loading } from './Loading'
import { EmptyView } from './EmptyView'

export const Effected = () => {
  const { setMenuActive, setIsBack, selectCrid, isMobile } = useContext(TemplateContext)
  const { isLinkedInRegistered, handleValidationOfLinkedInRegister } = useContext(LinkedInContext)
  const { renderInvoiceIsa, isLoading, effected, not_effected } = useDeclarationsIsa(
    setMenuActive,
    setIsBack,
    selectCrid,
    handleValidationOfLinkedInRegister
  )

  return (
    <Template>
      <HeaderV2 label="Declarações enviadas" />
      <Wrapper isLinkedInRegistered={isLinkedInRegistered}>
        {!isLoading && effected && effected.length > 0 ? (
          <HeaderTable>
            <Title1Effected>Mês de referência</Title1Effected>
            <Title2Effected>Status</Title2Effected>
            <Title3Effected>Resumo</Title3Effected>
          </HeaderTable>
        ) : (
          <></>
        )}
        {effected && effected.length ? effected.map((item, index) => renderInvoiceIsa(item, index)) : null}
        {isLoading ? <Loading isMobile={isMobile} /> : null}
        <EmptyView toggle={'effected'} effected={effected} not_effected={not_effected} />
      </Wrapper>
      <AltLink href="/dashboard/declaracoes/pendentes">Ver declarações pendentes</AltLink>
    </Template>
  )
}

import React from 'react'
import { House } from '../assets/svg/house'
import { CurrencyDollar } from '../assets/svg/currency-dollar'
import { Note } from '../assets/svg/note'
import { Chart } from '../assets/svg/chart'
import { Question } from '../assets/svg/question'
import { UserCircle } from '../assets/svg/user-circle'
import { Receipt } from '../assets/svg/receipt'
import { BellIcon } from '../assets/svg/icon-bell'
import { ReactComponent as Clock } from '../assets/svg/clock.svg'

const { REACT_APP_API_ENV } = process.env

export const URLS = {
  production: {
    docsign: 'https://ms-docusign.provi.com.br',
    dashboard: 'https://ms-user.provi.com.br',
    passwordless: 'https://ms-passwordless.provi.com.br',
    sdk: 'https://api.provi.com.br/isa-path'
  },
  staging: {
    docsign: 'https://ms-docusign-staging.provi.com.br',
    dashboard: 'https://ms-user-staging.provi.com.br',
    // dashboard: 'http://localhost:5006/user',
    passwordless: 'https://ms-passwordless-staging.provi.com.br',
    sdk: 'https://api-staging.provi.com.br/isa-path'
  },
  development: {
    docsign: 'https://ms-docusign-dev.provi.com.br',
    dashboard: 'https://ms-user-dev.provi.com.br',
    passwordless: 'https://ms-passwordless-dev.provi.com.br',
    sdk: 'https://ms-creditpath-dev.provi.com.br'
  }
}

export const URLS_REDIRECT = {
  production: {
    passwordless: 'https://login.principia.net'
  },
  staging: {
    passwordless: 'https://login-staging.principia.net'
  },
  development: {
    passwordless: 'https://login-dev.principia.net'
  }
}

export const setRedirectUrl = environment => {
  switch (environment) {
    case 'production':
      return URLS_REDIRECT.production
    case 'staging':
      return URLS_REDIRECT.staging
    case 'development':
      return URLS_REDIRECT.development
    default:
      return URLS_REDIRECT.development
  }
}

export const REDIRECT_URLS = setRedirectUrl(REACT_APP_API_ENV)

export const setEnvironment = environment => {
  switch (environment) {
    case 'production':
      return URLS.production
    case 'staging':
      return URLS.staging
    case 'development':
      return URLS.development
    default:
      return URLS.development
  }
}

let _ROOT_URL = setEnvironment(REACT_APP_API_ENV)

export const ROOT_URL = _ROOT_URL
export const URL_EXTERNAL = 'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?'

export const MENU_ITEMS = [
  {
    id: 1,
    text: 'Meus Produtos',
    path: 'inicial',
    route: '/inicial',
    icon: House()
  },
  {
    id: 2,
    text: 'Minhas faturas',
    path: 'invoices',
    route: '/dashboard/faturas/abertas',
    icon: CurrencyDollar(),
    subItems: [
      {
        id: 3,
        text: 'Abertas',
        path: '/dashboard/faturas/abertas'
      },
      {
        id: 4,
        text: 'Pagas',
        path: '/dashboard/faturas/pagas'
      }
    ]
  },
  {
    text: 'Solicitações',
    path: 'solicitacoes',
    route: '/dashboard/solicitacoes',
    icon: <Clock />
  },
  {
    id: 5,
    text: 'Contratos',
    path: 'contracts',
    route: '/dashboard/contratos',
    icon: Note()
  },
  {
    id: 6,
    text: 'Panorama',
    path: 'panel',
    route: '/dashboard/painel',
    icon: Chart()
  },
  {
    id: 7,
    text: 'FAQ',
    path: 'help',
    route: '/dashboard/ajuda',
    icon: Question(),
    subItems: [
      {
        text: 'Sobre a Principia',
        path: '/dashboard/ajuda/#Sobre a Provi'
      },
      {
        text: 'Parceiros',
        path: '/dashboard/ajuda/#Parceiros'
      },
      {
        text: 'Pagamento',
        path: '/dashboard/ajuda/#Pagamento'
      },
      {
        text: 'Novos Financiamentos',
        path: '/dashboard/ajuda/#Novos Financiamentos'
      },
      {
        text: 'Avalista',
        path: '/dashboard/ajuda/#Avalista'
      },
      {
        text: 'Cancelamento',
        path: '/dashboard/ajuda/#Cancelamento'
      }
    ]
  },
  {
    id: 8,
    text: 'Perfil',
    path: 'profile',
    route: '/perfil',
    icon: UserCircle()
  },
  {
    text: 'Notificações',
    path: 'notificacoes',
    route: '/dashboard/notificacoes',
    icon: BellIcon()
  }
]

export const MENU_ITEMS_ISA = [
  {
    id: 1,
    text: 'Meus Produtos',
    path: 'inicial',
    route: '/inicial',
    icon: House()
  },
  {
    id: 2,
    text: 'Declarações',
    path: 'invoices',
    route: '/dashboard/declaracoes/pendentes',
    icon: CurrencyDollar(),
    subItems: [
      {
        id: 3,
        text: 'Pendentes',
        path: '/dashboard/declaracoes/pendentes'
      },
      {
        id: 4,
        text: 'Enviadas',
        path: '/dashboard/declaracoes/enviadas'
      }
    ]
  },
  {
    id: 5,
    text: 'Contratos',
    path: 'contracts',
    route: '/dashboard/contratos',
    icon: Note()
  },
  {
    text: 'Solicitações',
    path: 'solicitacoes',
    route: '/dashboard/solicitacoes',
    icon: <Clock />
  },
  {
    id: 6,
    text: 'Panorama',
    path: 'panel',
    route: '/dashboard/painel',
    icon: Chart()
  },
  {
    id: 7,
    text: 'FAQ',
    path: 'help',
    route: '/dashboard/ajuda',
    icon: Question(),
    subItems: [
      {
        text: 'Sobre a Principia',
        path: '/dashboard/ajuda/#Sobre a Provi'
      },
      {
        text: 'Sobre o ISA',
        path: '/dashboard/ajuda/#Sobre o ISA'
      },
      {
        text: 'Declaração de renda',
        path: '/dashboard/ajuda/#Declaração de renda'
      },
      {
        text: 'Pagamento',
        path: '/dashboard/ajuda/#Pagamento'
      }
    ]
  },
  {
    id: 8,
    text: 'Perfil',
    path: 'profile',
    route: '/perfil',
    icon: UserCircle()
  },
  {
    text: 'Notificações',
    path: 'notificacoes',
    route: '/dashboard/notificacoes',
    icon: BellIcon()
  }
]

export const MENU_ITEMS_ISA_INVOICE = [
  {
    id: 1,
    text: 'Meus Produtos',
    path: 'inicial',
    route: '/inicial',
    icon: House()
  },
  {
    id: 2,
    text: 'Declarações',
    path: 'invoicesIsa',
    route: '/dashboard/declaracoes/pendentes',
    icon: Receipt(),
    subItems: [
      {
        text: 'Pendentes',
        path: '/dashboard/declaracoes/pendentes'
      },
      {
        text: 'Enviadas',
        path: '/dashboard/declaracoes/enviadas'
      }
    ]
  },
  {
    id: 3,
    text: 'Minhas faturas',
    path: 'invoices',
    route: '/dashboard/faturas/abertas',
    icon: CurrencyDollar(),
    subItems: [
      {
        text: 'Abertas',
        path: '/dashboard/faturas/abertas'
      },
      {
        text: 'Recebidas',
        path: '/dashboard/faturas/pagas'
      }
    ]
  },
  {
    text: 'Solicitações',
    path: 'solicitacoes',
    route: '/dashboard/solicitacoes',
    icon: <Clock />
  },
  {
    id: 4,
    text: 'Contratos',
    path: 'contracts',
    route: '/dashboard/contratos',
    icon: Note()
  },
  {
    id: 5,
    text: 'Panorama',
    path: 'panel',
    route: '/dashboard/painel',
    icon: Chart()
  },
  {
    id: 6,
    text: 'FAQ',
    path: 'help',
    route: '/dashboard/ajuda',
    icon: Question(),
    subItems: [
      {
        text: 'Sobre a Principia',
        path: '/dashboard/ajuda/#Sobre a Provi'
      },
      {
        text: 'Sobre o ISA',
        path: '/dashboard/ajuda/#Sobre o ISA'
      },
      {
        text: 'Declaração de renda',
        path: '/dashboard/ajuda/#Declaração de renda'
      },
      {
        text: 'Pagamento',
        path: '/dashboard/ajuda/#Pagamento'
      }
    ]
  },
  {
    id: 7,
    text: 'Perfil',
    path: 'profile',
    route: '/perfil',
    icon: UserCircle()
  },
  {
    text: 'Notificações',
    path: 'notificacoes',
    route: '/dashboard/notificacoes',
    icon: BellIcon()
  }
]

export const MENU_ITEMS_SANAR = [
  {
    id: 1,
    text: 'Meus Produtos',
    path: 'inicial',
    route: '/inicial',
    icon: House()
  },
  {
    id: 8,
    text: 'Minhas mesadas',
    path: 'invoices',
    route: '/dashboard/mesadas/abertas',
    icon: CurrencyDollar(),
    subItems: [
      {
        id: 3,
        text: 'Abertas',
        path: '/dashboard/mesadas/abertas'
      },
      {
        id: 4,
        text: 'Recebidas',
        path: '/dashboard/mesadas/recebidas'
      }
    ]
  },
  {
    id: 2,
    text: 'Minhas faturas',
    path: 'invoices',
    route: '/dashboard/faturas/abertas',
    icon: CurrencyDollar(),
    subItems: [
      {
        id: 3,
        text: 'Abertas',
        path: '/dashboard/faturas/abertas'
      },
      {
        id: 4,
        text: 'Pagas',
        path: '/dashboard/faturas/pagas'
      }
    ]
  },
  {
    id: 5,
    text: 'Contratos',
    path: 'contracts',
    route: '/dashboard/contratos',
    icon: Note()
  },
  {
    text: 'Solicitações',
    path: 'solicitacoes',
    route: '/dashboard/solicitacoes',
    icon: <Clock />
  },
  {
    id: 6,
    text: 'Panorama',
    path: 'panel',
    route: '/dashboard/painel',
    icon: Chart()
  },
  {
    id: 7,
    text: 'FAQ',
    path: 'help',
    route: '/dashboard/ajuda',
    icon: Question(),
    subItems: [
      {
        id: 9,
        text: 'Sobre a Principia',
        path: '/dashboard/ajuda/#Sobre a Provi'
      },
      { id: 10, text: 'Sobre a Mesada', path: '/dashboard/ajuda/#Sobre a Mesada' },
      { id: 3, text: 'Recebimento', path: '/dashboard/ajuda/#Recebimento' },
      {
        id: 11,
        text: 'Pagamento',
        path: '/dashboard/ajuda/#Pagamento'
      },
      {
        id: 12,
        text: 'Avalista',
        path: '/dashboard/ajuda/#Avalista'
      },
      {
        id: 13,
        text: 'Cancelamento',
        path: '/dashboard/ajuda/#Cancelamento'
      }
    ]
  },
  {
    id: 8,
    text: 'Perfil',
    path: 'profile',
    route: '/perfil',
    icon: UserCircle()
  },
  {
    text: 'Notificações',
    path: 'notificacoes',
    route: '/dashboard/notificacoes',
    icon: BellIcon()
  }
]

export const MENU_PERSONAL = [
  { id: 1, text: 'Meus Produtos', path: 'inicial', route: '/inicial', icon: House() },
  {
    id: 2,
    text: 'Minhas faturas',
    path: 'invoices',
    route: '/dashboard/faturas/abertas',
    icon: CurrencyDollar(),
    subItems: [
      { id: 3, text: 'Abertas', path: '/dashboard/faturas/abertas' },
      { id: 4, text: 'Pagas', path: '/dashboard/faturas/pagas' }
    ]
  },
  { id: 5, text: 'Contratos', path: 'contracts', route: '/dashboard/contratos', icon: Note() },
  { id: 6, text: 'Panorama', path: 'panel', route: '/dashboard/painel', icon: Chart() },
  {
    id: 7,
    text: 'FAQ',
    path: 'help',
    route: '/dashboard/ajuda',
    icon: Question(),
    subItems: [
      { id: 8, text: 'Sobre a Principia', path: '/dashboard/ajuda/#Sobre a Provi' },
      { id: 9, text: 'Pagamento', path: '/dashboard/ajuda/#Pagamento' },
      { id: 10, text: 'Avalista', path: '/dashboard/ajuda/#Avalista' }
    ]
  },
  { id: 11, text: 'Perfil', path: 'profile', route: '/perfil', icon: UserCircle() },
  {
    text: 'Notificações',
    path: 'notificacoes',
    route: '/dashboard/notificacoes',
    icon: BellIcon()
  }
]

export const MENU_PROVI_PAY = [
  { id: 1, text: 'Meus Produtos', path: 'inicial', route: '/inicial', icon: House() },
  {
    text: 'Solicitações',
    path: 'solicitacoes',
    route: '/dashboard/solicitacoes',
    icon: <Clock />
  },
  {
    id: 7,
    text: 'FAQ',
    path: 'help',
    route: '/dashboard/ajuda',
    icon: Question(),
    subItems: [
      { id: 8, text: 'Sobre a Principia', path: '/dashboard/ajuda/#Sobre a Provi' },
      { id: 9, text: 'Pagamento', path: '/dashboard/ajuda/#Pagamento' },
      { id: 10, text: 'Avalista', path: '/dashboard/ajuda/#Avalista' },
      { id: 11, text: 'Cancelamento', path: '/dashboard/ajuda/#Cancelamento' }
    ]
  }
]

export const menuRender = (type, isPaymentFlow) => {
  const obj = {
    ISA: isPaymentFlow ? MENU_ITEMS_ISA_INVOICE : MENU_ITEMS_ISA,
    Allowance: MENU_ITEMS_SANAR,
    CourseFinancing: MENU_ITEMS,
    PersonalLoan: MENU_PERSONAL,
    Boleto: MENU_PROVI_PAY,
    ProviPay: MENU_PROVI_PAY,
    CreditCard: MENU_PROVI_PAY,
    CaaS: MENU_ITEMS,
    Pix: MENU_PROVI_PAY
  }

  return obj[type] || []
}

export const LOCALSTORAGE_TOKENS = {
  crid: 'CRID',
  email: 'EMAIL',
  token: 'TOKEN',
  userId: 'user_id',
  crid_isa: 'CRID_ISA',
  partnerSlug: 'partner_slug',
  token_provi_sign: 'TOKEN_PROVI_SIGN',
  invoice_active: 'INVOICE_ACTIVE',
  type: 'TYPE_USER',
  loan_option_ids: 'LOAN_OPTION_IDS',
  incomedeclaration: 'INCOMEDECLARATION'
}

export const COOKIE_TOKENS = {
  user_dashboard_token: 'DASH_tokens'
}

export const COOKIE_DOMAINS = {
  default: '.provi.com.br'
}

export const SAVE_COOKIE = REACT_APP_API_ENV === 'production'

export const MODE_DASH = {
  isa: 'ISA',
  allowance: 'Allowance',
  course_financing: 'CourseFinancing',
  personal_loan: 'PersonalLoan',
  CaaS: 'CaaS'
}

export const gaDimensions = {
  userId: 'userId',
  creditRequestId: 'dimension4',
  partnerSlug: 'dimension5'
}

export const gaTrackingId = 'UA-146320363-13'

export const holidays = [
  /**
   * 2021
   */

  '01/01/2021', // (Confraternização universal (Ano Novo))
  '15/02/2021', // (Carnaval)
  '16/02/2021', // (Carnaval)
  '17/02/2021', // (Quarta-feira de cinzas)
  '02/04/2021', // (Paixão de Cristo)
  '21/04/2021', // (Tiradentes)
  '01/05/2021', // (Dia Mundial do Trabalho)
  '03/06/2021', // (Corpus Christi)
  '07/09/2021', // (Independência do Brasil)
  '12/10/2021', // (Nossa Senhora Aparecida)
  '28/10/2021', // (Dia do Servidor Público)
  '02/11/2021', // (Finados)
  '15/11/2021', // (Proclamação da República)
  '24/12/2021', // (Véspera de Natal)
  '25/12/2021', // (Natal)
  '31/12/2021' // (Véspera de ano-novo)
]

const proxyURL = {
  production: 'https://api.provi.com.br/proxy',
  staging: 'https://api-staging.provi.com.br/proxy'
}

export const PROXY_BASE_URL = proxyURL[REACT_APP_API_ENV]

import React from 'react'

import InputMask from 'react-input-mask'

import { Container } from './styles'

const onlyNumbers = str => str.replace(/[^0-9]/g, '')

export const MaskedCnpj = ({ value, onChange, placeholder }) => {
  function handleChange(event) {
    onChange({
      ...event,
      target: {
        ...event.target,
        value: onlyNumbers(event.target.value)
      }
    })
  }
  return (
    <Container>
      <InputMask mask="99.999.999-9999/99" value={value} onChange={handleChange} placeholder={placeholder} />
    </Container>
  )
}

import React from 'react'

export const DownloadIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 1.87471V14.3747" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M4.375 8.74969L10 14.3747L15.625 8.74969"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M3.75 16.8747H16.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

import React from 'react'
import { Button } from './styles'

export const ButtonV2 = ({ onClick, label, isActive, isFill, isDisabled, ...props }) => {
  return (
    <Button onClick={onClick} isActive={isActive} isFill={isFill} disabled={isDisabled} {...props}>
      {label}
    </Button>
  )
}

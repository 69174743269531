import { useState, useEffect } from 'react'
import { showToast } from '@provi/provi-components'
import { LOCALSTORAGE_TOKENS, REDIRECT_URLS } from '../../constants'
import { history } from '../../navigation/history'
import { signIn } from '../../services/auth'

export const useInvalidToken = partner => {
  const [email, setEmail] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const emailStorage = localStorage.getItem(LOCALSTORAGE_TOKENS.email)
    if (emailStorage) {
      setEmail(emailStorage)
    }
  }, [])

  const onSubmit = async () => {
    if (email) {
      try {
        setIsLoading(true)
        await signIn({ email })
        history.push('/email-enviado')
      } catch (err) {
        setIsLoading(false)
        showToast()
      } finally {
        setIsLoading(false)
      }
    } else {
      const url = partner ? `${REDIRECT_URLS.passwordless}/${partner}` : REDIRECT_URLS.passwordless
      window.open(url)
    }
  }

  return [onSubmit, email, isLoading]
}

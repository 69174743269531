import React, { useContext } from 'react'

import { Template } from '../../../components/template'
import { TemplateContext } from '../../../components/template/context'
import { HeaderV2 } from '../../../components/HeaderV2'
import { Card, ResMedCard } from '../../../components/NotificationCard'
import { DownloadIcon } from '../../../assets/svg/download-icon'
import { currencyParser } from '../../../utils'

import useNotificationsScreen from './hooks'

import * as S from './styles'

export const NotificationsScreen = () => {
  const { isMobile, setNotifications, selectCrid, setIsLoading, notifications } = useContext(TemplateContext)
  const [
    courseInfoModal,
    handleOpenCourseInfo,
    handleCloseCourseInfo,
    handleButtonClick,
    handleDeclarationDownload
  ] = useNotificationsScreen(notifications, setNotifications, selectCrid, setIsLoading)

  return (
    <Template>
      <HeaderV2 label="Notificações" isMobile={isMobile} />
      <S.Container>
        {notifications.length ? (
          notifications.map(({ notification, type, metadata }) => {
            if (type === 1) {
              return (
                <Card
                  title="Você ainda não realizou o cadastro do seu perfil do Linkedin"
                  text="O linkedin é muito importante para validarmos a sua declaração de renda"
                  btnClick={() => handleButtonClick(type, '/perfil')}
                  btnText="Atualizar"
                />
              )
            }

            if (type === 2) {
              return (
                <ResMedCard
                  courseInfo={notification}
                  darkBtnClick={() => handleButtonClick(type, '/provi-pay/confirmacao-endereco')}
                  lightBtnClick={handleOpenCourseInfo}
                  modalInfo={courseInfoModal}
                  handleCloseModal={handleCloseCourseInfo}
                />
              )
            }

            if (type === 3) {
              return (
                <Card
                  title="Assinatura de aditamento de contrato"
                  text="Você precisa assinar o aditamento de contrato para prosseguir com a renegociação de dívida"
                  btnText="Assinar Contrato"
                  btnClick={() => handleButtonClick(type)}
                />
              )
            }

            if (type === 4) {
              return (
                <Card
                  title="Chegou a hora de declarar o Imposto de Renda"
                  text="Se você vai declarar o Imposto de Renda este ano, fique de olho: financiamentos acima de R$5.000 devem ser incluídos no documento. Para valores menores, a inclusão é opcional."
                  btnText="Baixar Informe"
                  btnClick={handleDeclarationDownload}
                  altLink=" https://provi.com.br/ir"
                  altLinkText="Tirar dúvidas"
                />
              )
            }

            if (type === 5) {
              return (
                <Card
                  title="Parabéns! Você quitou suas parcelas"
                  text={'Baixe o Termo de quitação aqui ou acesse quando quiser em "Contrato"'}
                  btnText="Baixar Termo de quitação"
                  btnClick={() => handleButtonClick(type)}
                  btnIcon={<DownloadIcon />}
                />
              )
            }

            // if (type === 6) {
            //   return (
            //     <Card
            //       title="Dê um up na sua Mesada! 🤑"
            //       text={`Você pode aumentar o valor que vai receber nos próximos meses para ${currencyParser(
            //         metadata.valueAvailable,
            //         'R$ '
            //       )}. Confira a simulação das parcelas sem compromisso!`}
            //       btnText="Conferir simulação"
            //       btnClick={() => handleButtonClick(type)}
            //     />
            //   )
            // }

            if (type === 7) {
              return (
                <Card
                  title="Confirme a renegociação do seu financiamento."
                  text={`Você precisa realizar o pagamento da primeira parcela para confirmar a sua renegociação.`}
                  btnText="Conferir parcela"
                  btnClick={() => handleButtonClick(type, '/dashboard/faturas/abertas')}
                />
              )
            }

            // if (type === 8 && metadata.hasMoreThanOneDate) {
            //   return (
            //     <Card
            //       title="Confirme seu upgrade de Mesada! 🤑"
            //       text={`Você precisa assinar seu contrato para confirmar o recebimento do upgrade entre 
            //       ${metadata.firstBeneficiaryDateMonth}/${metadata.firstBeneficiaryDateYear} e ${metadata.lastBeneficiaryDateMonth}/${metadata.lastBeneficiaryDateYear}. Nos próximos meses o seu upgrade ja esta garantido com a solicitação mensal.`}
            //       btnText="Assinar Contrato"
            //       btnClick={() => handleButtonClick(type, false, metadata)}
            //     />
            //   )
            // }

            if (type === 8 && metadata.hasMoreThanOneDate) {
              return (
                <Card
                  title="Seu Upgrade não foi garantido! Você não assinou os contratos do upgrade e por isso ele foi cancelado"
                  text={`O valor correto de recebimento de mesada dos próximos meses está disponível no seu contrato. Confira!`}
                />
              )
            }

            // if (type === 8 && !metadata.hasMoreThanOneDate) {
            //   return (
            //     <Card
            //       title="Confirme seu upgrade de Mesada! 🤑"
            //       text={`Você precisa assinar seu contrato para confirmar o recebimento do upgrade.`}
            //       btnText="Assinar Contrato"
            //       btnClick={() => handleButtonClick(type, false, metadata)}
            //     />
            //   )
            // }

            // if (type === 10) {
            //   return (
            //     <Card
            //       title="Suas mesadas de 2023 já estão liberadas para solicitação! Assim, você garante todas as suas mesadas até o final do ano!"
            //       text={`Com apenas uma clique e uma assinatura, você agenda todas as suas mesadas disponíveis para 2023, garantindo o seu o dinheiro até terminar o ano.`}
            //       btnText="Solicitar agora"
            //       btnClick={() => handleButtonClick(type, '/dashboard/mesadas/abertas')}
            //     />
            //   )
            // }

        
          })
        ) : (
          <S.GrayText>Você não possui notificações no momento.</S.GrayText>
        )}
      </S.Container>
    </Template>
  )
}

import React from 'react'
import { Button, theme } from '@provi/provi-components'
import { currencyParser } from '../../../../utils'
import {
  ItemInvoice,
  Left,
  Text,
  Footer,
  TextTitle,
  TextSimple,
  Right,
  Status,
  Header,
  Center,
  WarningText,
  Br
} from './styles'
import { Hr } from '../../../../components/horizontal-row'

export const CardDelayedBlocked = ({ item }) => {
  return (
    <ItemInvoice active>
      <Header>
        <Left>
          <TextTitle>{currencyParser(item.price || 0, 'R$')}</TextTitle>
          <TextSimple>Vencimento: {item.date}</TextSimple>
        </Left>
        <Right>
          <Text>Fatura atrasada</Text>
          <Status color={theme.colors.pink900} />
        </Right>
      </Header>
      <Footer>
        <Left>
          <Button
            onClick={() => window.open('https://provi.com.br/mobile/help', '_blank')}
            marginVertical={0}
            marginHorizontal={0}
            text={'Entrar em contato'}
          />
        </Left>
        <Right>
          <Text>{item.isUpfront ? 'Entrada' : `${item.number}/${item.amountOfItems}`}</Text>
        </Right>
      </Footer>
      <Hr />
      <Center>
        <WarningText>
          Você já usou as suas 3 gerações de 2º via desse mesmo boleto. <Br />
          Entre em contato com a Provi clicando no botão acima para quitar a sua dívida.
        </WarningText>
      </Center>
    </ItemInvoice>
  )
}

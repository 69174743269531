import React from 'react'

export const IconUpload = () => (
  <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1496 11.0494V14.3252C17.1496 15.226 16.3846 15.9631 15.4496 15.9631H3.54961C2.61461 15.9631 1.84961 15.226 1.84961 14.3252V11.0494M13.7496 5.31665L9.49961 1.22186M9.49961 1.22186L5.24961 5.31665M9.49961 1.22186V11.0494"
      stroke="#101D37"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const Container = styled.div`
  width: ${({ width }) => width};
  height: 50px;

  background-color: ${theme.colors.bluePrimary};
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;

  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  font-family: 'Montserrat', sans-serif;

  padding: 0 15px;

  cursor: pointer;

  & > svg {
    color: #ffffff;
    margin-left: 15px;
  }

  &:active {
    background: ${theme.colors.white100};
    color: ${theme.colors.bluePrimary};
  }

  @media (max-width: 800px) {
    width: ${({ mobileWidth }) => mobileWidth};
    font-size: 14px;
  }

  @media (max-width: 340px) {
    font-size: 12px;
  }
`

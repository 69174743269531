import { CardDelayed } from './card-delayed'
import { CardLocked } from './card-locked'
import { CardPaid } from './card-paid'
import { CardAvailable } from './card-avaliable'
import { CardDelayedBlocked } from './card-delayed-blocked'

export const InvoiceCoursing = {
  CardDelayed,
  CardLocked,
  CardPaid,
  CardAvailable,
  CardDelayedBlocked
}

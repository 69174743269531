// DASHBOARD
import { NotFound } from '../screens/not-found'
import { ValidateToken } from '../screens/validate-token'
import { InvalidToken } from '../screens/invalid-token'
import { RedirectToken } from '../screens/redirect-token'
import { Invoices } from '../screens/dashboard/invoices'
import { Panel } from '../screens/dashboard/panel/indexV2'
import { HelpV2 } from '../screens/dashboard/helpV2'
import { HelpContact } from '../screens/dashboard/help-contact'
import { DeclarationsIsa } from '../screens/dashboard/declarations-isaV2'
import { ProfileV2 } from '../screens/dashboard/profile-v2'
import { ContractsV2 } from '../screens/dashboard/contracts_v2'
import { NotificationsScreen } from '../screens/dashboard/notifications'

// DECLARATION ISA
import { Intro } from '../screens/form-isa/intro'
import { Terms } from '../screens/form-isa/terms'
import { TermsNegative } from '../screens/form-isa/terms-negative'
import { WorkIncome } from '../screens/form-isa/work-income'
import { WorkIncomeType } from '../screens/form-isa/work-income-type'
import { WorkIncomeValue } from '../screens/form-isa/work-income-value'
import { ExtraIncome } from '../screens/form-isa/extra-income'
import { ExtraIncomeType } from '../screens/form-isa/extra-income-type'
import { ExtraIncomeValue } from '../screens/form-isa/extra-income-value'
import { IncomeDetails } from '../screens/form-isa/income-details'
import { SkillDetails } from '../screens/form-isa/skill-details'
import { GeneralComments } from '../screens/form-isa/general-comments'
import { IncomeBellowThreshold } from '../screens/form-isa/income-bellow-threshold'
import { IncomeAboveThreshold } from '../screens/form-isa/income-above-threshold'
import { AltenartivePaymentValue } from '../screens/form-isa/alternative-payment-value'
import { SuccessCase } from '../screens/form-isa/success-case'
import { SuccessCaseConfirmation } from '../screens/form-isa/success-case-confirmation'
import { IncomeProof } from '../screens/form-isa/income-proof'
import { LoadingIsa } from '../screens/form-isa/loading-sdk'
import { PaymentDate } from '../screens/form-isa/payment-date'
import { DeclarationSuccess } from '../screens/form-isa/declaration-success'
import { IncomeDeclarationFinished } from '../screens/form-isa/income-declaration-finished'
import { HadIncomePerWork } from '../screens/form-isa/had-income-per-work'
import { OthersIncomeTypes } from '../screens/form-isa/others-income-types'

// PROVI-SIGN
import { IntroGuarantor } from '../screens/provi-sign/intro-guarantor'
import { Selfie } from '../screens/provi-sign/selfie'
import { PhoneConfirmation } from '../screens/provi-sign/phone-confirmation'
import { TermsContract } from '../screens/provi-sign/terms-contract'
import { SignedSuccess } from '../screens/provi-sign/signed-success'
import { LoadingProviSign } from '../screens/provi-sign/loading'
import { IndicationPartner } from '../screens/form-isa/indication-partner'
import { AlterAllowance } from '../screens/provi-sign/alter-allowance-v2'
import { AlterFinancing } from '../screens/provi-sign/alter-financing'
import { ChangeFinancingSuccess } from '../screens/provi-sign/change-financing-success'

import { UpgradeAllowanceSuccess } from '../screens/provi-sign/upgrade-allowance-success-v2'
import { SelectCridV2 } from '../screens/dashboard/select-crid-v2'

// Provi-pay Sanar UP res_med
import { AddressConfirmation } from '../screens/provi-pay/address-confirmation'
import { FormOfPayment } from '../screens/provi-pay/form-of-payment'
import { Approval } from '../screens/provi-pay/approval'
import { AlreadyHasCrid } from '../screens/provi-sign/already-has-crid'
import { Allowances } from '../screens/dashboard/allowances'

// Cancellation
import { Reason } from '../screens/cancellation/reason'
import { TypeOfPayment } from '../screens/cancellation/TypeOfPayment'
import Summary from '../screens/cancellation/summary'

// Requests
import Requests from '../screens/requests'

export const ROUTES = [
  {
    path: '/404',
    component: NotFound
  },
  {
    path: '/token/:token',
    component: ValidateToken
  },
  {
    path: '/token/:token/:crid',
    component: ValidateToken
  },
  {
    path: '/token-invalido/:partner',
    component: InvalidToken
  },
  {
    path: '/token-invalido',
    component: InvalidToken
  },
  {
    path: '/email-enviado',
    component: RedirectToken
  },
  {
    path: '/dashboard/mesadas/:status?',
    component: Allowances
  },
  {
    path: '/dashboard/faturas/:status?',
    component: Invoices
  },
  {
    path: '/dashboard/declaracoes/pendentes',
    component: DeclarationsIsa.Pending
  },
  {
    path: '/dashboard/declaracoes/enviadas',
    component: DeclarationsIsa.Effected
  },
  {
    path: '/dashboard/painel',
    component: Panel
  },
  {
    path: '/dashboard/ajuda',
    component: HelpV2
  },
  {
    path: '/dashboard/ajuda/contato',
    component: HelpContact
  },
  {
    path: '/dashboard/contratos',
    component: ContractsV2
  },
  {
    path: '/dashboard/notificacoes',
    component: NotificationsScreen
  },
  {
    path: '/perfil',
    component: ProfileV2
  },
  {
    path: '/inicio',
    component: SelectCridV2
  },
  {
    path: '/inicio-v2',
    component: SelectCridV2
  },
  // SDK ISA
  {
    path: '/dashboard/isa/declaracao/intro',
    component: Intro,
    uniqueName: 'intro'
  },
  {
    path: '/dashboard/isa/declaracao/termos-condicoes',
    component: Terms,
    uniqueName: 'termsAndConditions'
  },
  {
    path: '/dashboard/isa/declaracao/termos-recusa',
    component: TermsNegative,
    uniqueName: 'termsDenied'
  },
  {
    path: '/dashboard/isa/declaracao/renda-trabalho',
    component: WorkIncome,
    uniqueName: 'workIncome'
  },
  {
    path: '/dashboard/isa/declaracao/teve-renda-por-trabalho',
    component: HadIncomePerWork,
    uniqueName: 'hadIncomePerWork'
  },
  {
    path: '/dashboard/isa/declaracao/tipo-renda-trabalho',
    component: WorkIncomeType,
    uniqueName: 'workIncomeType'
  },
  {
    path: '/dashboard/isa/declaracao/valor-renda-trabalho',
    component: WorkIncomeValue,
    uniqueName: 'workIncomeValue'
  },
  {
    path: '/dashboard/isa/declaracao/renda-extra',
    component: ExtraIncome,
    uniqueName: 'extraIncome'
  },
  {
    path: '/dashboard/isa/declaracao/tipo-renda-extra',
    component: ExtraIncomeType,
    uniqueName: 'extraIncomeType'
  },
  {
    path: '/dashboard/isa/declaracao/valor-renda-extra',
    component: ExtraIncomeValue,
    uniqueName: 'extraIncomeValue'
  },
  {
    path: '/dashboard/isa/declaracao/detalhes-renda',
    component: IncomeDetails,
    uniqueName: 'incomeDetails'
  },
  {
    path: '/dashboard/isa/declaracao/indicacao',
    component: IndicationPartner,
    uniqueName: 'indicationPartner'
  },
  {
    path: '/dashboard/isa/declaracao/detalhes-habilidades',
    component: SkillDetails,
    uniqueName: 'skillDetails'
  },
  {
    path: '/dashboard/isa/declaracao/comentarios-gerais',
    component: GeneralComments,
    uniqueName: 'generalComments'
  },
  {
    path: '/dashboard/isa/declaracao/resultado-declaracao',
    component: IncomeBellowThreshold,
    uniqueName: 'incomeBelowThreshold'
  },
  {
    path: '/dashboard/isa/declaracao/pagamento-mensal',
    component: IncomeAboveThreshold,
    uniqueName: 'incomeAboveThreshold'
  },
  {
    path: '/dashboard/isa/declaracao/valor-alternativo-pagamento',
    component: AltenartivePaymentValue,
    uniqueName: 'alternativePaymentValue'
  },
  {
    path: '/dashboard/isa/declaracao/caso-de-sucesso',
    component: SuccessCase,
    uniqueName: 'successCase'
  },
  {
    path: '/dashboard/isa/declaracao/confirmacao-caso-de-sucesso',
    component: SuccessCaseConfirmation,
    uniqueName: 'successCaseConfirmation'
  },
  {
    path: '/dashboard/isa/declaracao/prova-renda',
    component: IncomeProof,
    uniqueName: 'incomeProof'
  },
  {
    path: '/dashboard/isa/declaracao/dia-pagamento',
    component: PaymentDate,
    uniqueName: 'incomeDeclarationPaymentDateSelection'
  },
  {
    path: '/dashboard/isa/declaracao/sucesso',
    component: DeclarationSuccess,
    uniqueName: 'incomeDeclarationSuccess'
  },
  {
    path: '/dashboard/isa/declaracao/finalizacao',
    component: IncomeDeclarationFinished,
    uniqueName: 'incomeDeclarationFinished'
  },
  {
    path: '/dashboard/isa/carregando',
    component: LoadingIsa
  },
  {
    path: '/dashboard/isa/outros-tipos-renda',
    component: OthersIncomeTypes,
    uniqueName: 'othersIncomeTypes'
  },
  // PROVI SIGN
  {
    path: '/provi-sign/carregando/:token',
    component: LoadingProviSign
  },
  {
    path: '/provi-sign/bloqueio-contrato-assinado',
    component: AlreadyHasCrid
  },
  {
    path: '/provi-sign/selfie',
    component: Selfie
  },
  {
    path: '/provi-sign/introducao-avalista',
    component: IntroGuarantor
  },
  {
    path: '/provi-sign/confirmacao-telefone',
    component: PhoneConfirmation
  },
  {
    path: '/provi-sign/termos-contrato',
    component: TermsContract
  },
  {
    path: '/provi-sign/assinatura-sucesso',
    component: SignedSuccess
  },
  {
    path: '/provi-sign/alteracao-financiamento-sucesso',
    component: ChangeFinancingSuccess
  },
  {
    path: '/provi-sign/alteracao-mesada-sucesso',
    component: UpgradeAllowanceSuccess
  },
  {
    path: '/provi-sign/alteracao-mesada',
    component: AlterAllowance
  },
  {
    path: '/provi-sign/alteracao-financiamento',
    component: AlterFinancing
  },
  // Provi-pay Sanar UP
  {
    path: '/provi-pay/confirmacao-endereco',
    component: AddressConfirmation
  },
  {
    path: '/provi-pay/forma-pagamento',
    component: FormOfPayment
  },
  {
    path: '/provi-pay/aprovacao-resmed',
    component: Approval
  },
  // Cancellation
  {
    path: '/cancelamento/motivo',
    component: Reason
  },
  {
    path: '/cancelamento/tipo-de-reembolso',
    component: TypeOfPayment
  },
  {
    path: '/cancelamento/resumo',
    component: Summary
  },
  {
    path: '/dashboard/solicitacoes',
    component: Requests
  }
]

import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const ContainerBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px 0px;
  ${props => !props.notMargin && 'margin-bottom: 8px;'};
`
export const Label = styled.p`
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  color: ${theme.colors.blue900};
  font-size: 16px;
  font-weight: 500;

  line-height: 24px;
  text-align: left;
  margin: 10px 0;

  ${theme.breakpoints.down('md')} {
    font-weight: bold;
    text-align: center;
  }
`

export const Value = styled.span`
  font-family: 'Montserrat', sans-serif;
  color: ${theme.colors.blue900};
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`

import React from 'react'
import {
  ItemInvoice,
  TextTitle,
  Button,
  ContainerTitle,
  DateLimit,
  Date,
  Content,
  StatusContent,
  ContainerResume,
  BoxInfo,
  Title,
  Value,
  ContainerTitleEffected,
  Declaration
} from '../styles'
import { useInvoiceComponent } from './hooks'
import { currencyParser } from '../../../../../utils'
import moment from 'moment'
import { Question2 } from '../../../../../assets/svg/question2'

const Status = ({ backgroundColor, status, effected = false, style }) => {
  return (
    <StatusContent effected={effected} backgroundColor={backgroundColor} style={style}>
      <p>{status}</p>
    </StatusContent>
  )
}

export default function ItemInvoiceComponent({
  item,
  setIsShowModal,
  status = 'disponível',
  buttonLabel = 'Declarar renda',
  showButton = true,
  index = 0
}) {
  const [formattedDate, getColor, effected, isMobile] = useInvoiceComponent({ item })

  return (
    <>
      {effected ? (
        <ItemInvoice index={index} effected>
          <ContainerTitleEffected>
            <Content effected>
              <TextTitle zeroMargin effected>
                {formattedDate}
              </TextTitle>
              {!isMobile && <Status backgroundColor={getColor[item.status]} status={status} effected />}
            </Content>
            {isMobile && (
              <Status
                backgroundColor={getColor[item.status]}
                status={status}
                effected
                style={{
                  marginTop: '5px'
                }}
              />
            )}
          </ContainerTitleEffected>
          <ContainerResume>
            <BoxInfo>
              <Title>Renda de trabalho</Title>
              <Value>{currencyParser(item.primary || 0, 'R$')}</Value>
            </BoxInfo>

            <BoxInfo>
              <Title>Outros tipos de renda</Title>
              <Value>{currencyParser(item.extra || 0, 'R$')}</Value>
            </BoxInfo>

            <BoxInfo>
              <Title>Pgto proporcional</Title>
              <Value>{currencyParser(item.validatedAmount || 0, 'R$')}</Value>
            </BoxInfo>
          </ContainerResume>
        </ItemInvoice>
      ) : (
        <ItemInvoice index={index}>
          <ContainerTitle>
            <Content>
              {showButton && !isMobile && (
                <Button onClick={() => setIsShowModal(true)}>
                  <p>{buttonLabel}</p>
                </Button>
              )}
              <DateLimit>
                Mês referência{' '}
                <a data-tip data-for="question1" tooltip-text="Mês em que você recebeu os valores a serem declarados.">
                  <Question2 />
                </a>
              </DateLimit>

              <TextTitle zeroMargin>{formattedDate}</TextTitle>
              {!isMobile && <Date>{moment(item.date, 'DD/MM/YYYY').format('DD/MM/YYYY')}</Date>}
              {!isMobile && (
                <Status
                  backgroundColor={getColor[item.status]}
                  status={status}
                  style={{
                    marginLeft: '80px'
                  }}
                />
              )}
            </Content>
            {isMobile && (
              <Status
                backgroundColor={getColor[item.status]}
                status={status}
                style={{
                  marginTop: '5px'
                }}
              />
            )}
          </ContainerTitle>
          {isMobile && (
            <Declaration>
              <p>
                Declarar até{' '}
                <a tooltip-text="Data limite para que você declare os valores recebidos no mês de referência.">
                  <Question2 />
                </a>
              </p>
              <Date>{moment(item.date, 'DD/MM/YYYY').format('DD/MM/YYYY')}</Date>
            </Declaration>
          )}
          {showButton && isMobile && (
            <Button onClick={() => setIsShowModal(true)}>
              <p>{buttonLabel}</p>
            </Button>
          )}
        </ItemInvoice>
      )}
    </>
  )
}

import React from 'react'

export const UserCircle = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
      stroke="#01163E"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M16 20C18.7614 20 21 17.7614 21 15C21 12.2386 18.7614 10 16 10C13.2386 10 11 12.2386 11 15C11 17.7614 13.2386 20 16 20Z"
      stroke="#01163E"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M7.97656 24.9218C8.72983 23.4408 9.87821 22.1971 11.2946 21.3284C12.711 20.4598 14.3401 20 16.0017 20C17.6632 20 19.2923 20.4598 20.7087 21.3284C22.1251 22.1971 23.2735 23.4407 24.0268 24.9217"
      stroke="#01163E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

import React, { useContext } from 'react'
import { Chevron } from '../../../assets/svg/chevron'

import { Link, LinkWithId, Notification } from '../styles'
import { Option, OptionTitle, ToggleSubMenu, SubOption, LiOption } from './styles'
import { TemplateContext } from '../../template/context'
import { Collapse } from '@material-ui/core'
import { useMenuOption } from './hooks'

export const MenuOption = ({ option, handleSubMenuToggle }) => {
  const [getActive] = useMenuOption()
  const { isSubMenuToggled, notifications } = useContext(TemplateContext)

  return (
    <>
      {option?.subItems?.length ? (
        <Option
          onClick={() => handleSubMenuToggle(option?.id)}
          isSubMenuToggled={isSubMenuToggled?.id === option?.id && isSubMenuToggled?.toggled}
        >
          <OptionTitle color={isSubMenuToggled?.id === option?.id && isSubMenuToggled?.toggled ? 'blue' : 'false'}>
            {option?.text}
          </OptionTitle>

          <ToggleSubMenu isSubMenuToggled={isSubMenuToggled?.id === option.id && isSubMenuToggled?.toggled}>
            <Chevron toggled={isSubMenuToggled?.id === option?.id && isSubMenuToggled?.toggled} />
          </ToggleSubMenu>
        </Option>
      ) : (
        <Option>
          <Link to={option?.route}>{option?.text}</Link>
          {notifications.length > 0 && option?.text === 'Notificações' && <Notification>{notifications.length}</Notification>}
        </Option>
      )}

      <Collapse in={isSubMenuToggled?.id === option?.id && isSubMenuToggled?.toggled} timeout="auto" unmountOnExit>
        <SubOption>
          {option?.subItems?.map((item, index) => (
            <LiOption active={getActive(item?.path)} key={index}>
              {item?.path.includes('#') ? (
                <LinkWithId href={item?.path}>{item?.text}</LinkWithId>
              ) : (
                <Link to={item?.path}>{item?.text}</Link>
              )}
            </LiOption>
          ))}
        </SubOption>
      </Collapse>
    </>
  )
}

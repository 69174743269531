import { useCallback, useState, useContext } from 'react'
import { showToast } from '@provi/provi-components'

import { hasAllowanceUpgradeInProgress } from '../../../../services/api'

import { TemplateContext } from '../../../../components/template/context'

import { LOCALSTORAGE_TOKENS } from '../../../../constants'

export const useCards = () => {
  const [isShowModal, setIsShowModal] = useState(false)
  const [modalActive, setModalActive] = useState(0)

  const { selectCrid, setIsLoading } = useContext(TemplateContext)

  const withdrawalAllowance = useCallback(async item => {
    try {
      setIsLoading(true)

      const { id: CreditRequestId } = selectCrid || {}

      if (!CreditRequestId) return

      const response = await hasAllowanceUpgradeInProgress(CreditRequestId)

      const { inProgress } = response.data

      if (inProgress) {
        setModalActive(3)
        setIsShowModal(true)

        return
      }

      localStorage.setItem(LOCALSTORAGE_TOKENS.loan_option_ids, JSON.stringify(item.LoanOptionsIds))
      localStorage.setItem(LOCALSTORAGE_TOKENS.invoice_active, item.id)

      setModalActive(0)
      setIsShowModal(true)
    } catch (error) {
      showToast('Erro, por favor tente novamente')
    } finally {
      setIsLoading(false)
    }
  }, [])

  const dismissModal = useCallback(() => {
    setIsShowModal(false)
  }, [])

  return { withdrawalAllowance, isShowModal, modalActive, dismissModal, setModalActive, setIsShowModal }
}

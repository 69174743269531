import React, { useState } from 'react'

import ItemInvoiceComponent from './ItemInvoiceComponent'
import ModalComponent from './ModalComponent'

export const InvoiceReview = ({ item, index }) => {
  const [isShowModal, setIsShowModal] = useState(false)

  return (
    <>
      <ItemInvoiceComponent
        index={index}
        item={item}
        buttonLabel="Declarar renda"
        setIsShowModal={setIsShowModal}
        status="rever"
      />

      {isShowModal && <ModalComponent item={item} setIsShowModal={setIsShowModal} />}
    </>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'

import { CloseIcon } from '../../../../assets/svg/close'
import { ReactComponent as WhatsappIcon } from '../../../../assets/svg/whatsapp.svg'

import * as S from './styles'

export const Modal = ({ modalRef, onSubmit, onClose, isLoading, showDoubtsFooter, children, title = '' }) => {
  const goToWhatsapp = () => {
    window.location.href = 'https://api.whatsapp.com/send?phone=5511957712414'
  }

  return (
    <S.StyledModal ref={modalRef}>
      <S.HeaderWrapper>
        <S.Title>{title}</S.Title>
        <S.IconContainer onClick={onClose}>
          <CloseIcon />
        </S.IconContainer>
      </S.HeaderWrapper>
      {children}
      <S.ModalFooter>
        {showDoubtsFooter ? (
          <>
            <Link to="/dashboard/ajuda/#Cancelamento">Acessar FAQ</Link>

            <S.ButtonsWrapper>
              <S.Button isLoading={isLoading} onClick={goToWhatsapp}>
                <WhatsappIcon /> Falar com atendimento
              </S.Button>
            </S.ButtonsWrapper>
          </>
        ) : (
          <S.ButtonsWrapper>
            <S.ButtonFirst isLoading={isLoading} onClick={() => onSubmit()} text="Confirmar desistência" />
          </S.ButtonsWrapper>
        )}
      </S.ModalFooter>
    </S.StyledModal>
  )
}

import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  max-width: 470px;
  /* height: 80px; */
  padding: 20px;
  border-radius: 6px;
  margin: 25px 35px;

  background-color: ${theme.colors.bluePrimaryDark};
  color: #fff;
  font-size: 24px;
  line-height: 34px;
  font-family: 'Montserrat', sans-serif;

  @media (max-width: 800px) {
    margin: 0;
    font-size: 16px;
    line-height: 26px;
  }
`

export const Link = styled.p`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  margin-top: 20px;
  margin-bottom: 0px;

  cursor: pointer;

  & > svg {
    margin-left: 10px;
  }

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 24px;
  }
`

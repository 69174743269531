import React, { useContext } from 'react'
import { IconDoubt } from '../../../../assets/svg/icon-doubt'
import * as S from './styles.js'
import Spinner from './Spinner'
import { TemplateCancellationContext } from '../../../../components/template-cancellation/context'
import { useTypePayment } from '../hooks'
import ModalMessage from '../modal/modalMsg'
import { history } from '../../../../navigation/history'

const DataBank = ({ selectCrid }) => {
  const { setCancellationData, cancellationData } = useContext(TemplateCancellationContext)
  const {
    typeOfAccount,
    onChangeTypeOfAccount,
    bank,
    onChangeBank,
    agency,
    onChangeAgency,
    account,
    onChangeAccount,
    goToNextStep,
    closeModal,
    modalRef,
    modalHeight,
    banks,
    valid,
    setValid,
    errMsg,
    setErrMsg,
    disabledButton
  } = useTypePayment({ selectCrid, setCancellationData, cancellationData })

  return (
    <S.Form>
      <S.SubTitleForm>
        Informe os dados bancários do titular do curso.
        <a data-tip="O reembolso só pode ser realizado na conta bancária do titular do curso. Se você não tem uma conta no seu nome, selecione a opção “Não tenho uma conta”.">
          <IconDoubt color="black" size="12" />
        </a>
        <S.Tooltip effect="solid" place="left" />
      </S.SubTitleForm>
      <S.LabelSelect>
        Tipo de conta
        <S.StyledSelect
          onChange={onChangeTypeOfAccount}
          options={[
            { value: 'CONTA_CORRENTE', label: 'Conta Corrente' },
            { value: 'CONTA_POUPANCA', label: 'Conta Poupança' }
          ]}
          value={typeOfAccount}
          width="359px"
          placeholder="Selecione o tipo de conta"
        />
      </S.LabelSelect>
      <S.LabelSelect>
        Banco
        <S.StyledSelect2 onChange={onChangeBank} options={banks} value={bank} width="359px" placeholder="Selecione seu banco" />
      </S.LabelSelect>
      <S.AgencyField>
        <S.LabelSelect>
          <S.InputField
            isRequired
            label="Agência"
            placeholder="0000"
            value={agency}
            name="agency"
            onChange={onChangeAgency}
            width="119px"
            mobileWidth="92.59px"
            maxLength={4}
          />
        </S.LabelSelect>
        <S.InputAccount>
          <S.InputField
            isRequired
            label="Conta com dígito"
            placeholder="0000000-0"
            value={account}
            name="account"
            onChange={onChangeAccount}
            width="200px"
            maxLength={12}
          />
        </S.InputAccount>
      </S.AgencyField>
      <S.DivButton>
        <S.ButtonAdvance
          buttonProps={{
            href: '',
            rel: 'noopener noreferrer',
            target: '_blank'
          }}
          color="primary"
          icon
          text="Avançar"
          width="127px"
          mobileWidth="100%"
          disabled={disabledButton}
          onClick={() => goToNextStep(selectCrid.id, setValid)}
        />
      </S.DivButton>
      <S.StyledPortalModal
        ref={modalRef}
        mobileHeight={modalHeight(errMsg).mobileHeight}
        desktopHeight={modalHeight(errMsg).desktopHeight}
      >
        {valid ? (
          <>
            {errMsg !== 'validado' ? (
              <ModalMessage errMsg={errMsg} setErrMsg={setErrMsg} closeModal={closeModal} />
            ) : (
              history.push('/cancelamento/resumo')
            )}
          </>
        ) : (
          <S.Validating>
            <Spinner />
            <span>Aguarde enquanto validamos seus dados bancários... ⏳</span>
          </S.Validating>
        )}
      </S.StyledPortalModal>
    </S.Form>
  )
}

export default DataBank

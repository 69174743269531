import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const Button = styled.button`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;

  border: 1px solid ${theme.colors.bluePrimary};
  border-radius: 5px;

  color: ${theme.colors.bluePrimary};
  background-color: #fff;

  padding: 5px 16px;

  cursor: pointer;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.95);
  }

  ${props =>
    props.isActive &&
    `
    background-color: #fceaea;
  `}

  ${props =>
    props.isFill &&
    `
    background-color: ${theme.colors.bluePrimary};
    border: none;
    color: #fff;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.95)
    }
  `}

  &:disabled {
    cursor: default;

    filter: brightness(0.9);
    border: 1px solid #cfcfcf;
    color: #414141;
  }
`

import React, { useContext } from 'react'
import { ButtonV3 } from '@provi/provi-components'

import { HeaderV2 } from '../../../components/HeaderV2'
import { Template } from '../../../components/template'
import { TemplateContext } from '../../../components/template/context'
import * as S from './styles'
import { useAlterFinancing } from './hooks'
import { ArrowLeft } from '../../../assets/svg/ArrowLeft'
import { TableInstallments } from './tables/tableInstallments'
import { Divider } from '@material-ui/core'
import { DescriptionText } from './components/description-text'
import { ConfirmationModal } from './components/confirmation-modal'
import { ExtraInfo } from './components/extra-info'

export const AlterFinancing = () => {
  const { isMobile } = useContext(TemplateContext)
  const {
    selectedOption,
    installments,
    isLoading,
    onSubmit,
    goBack,
    isLoadingSubmit,
    handleSelectOption,
    buttonRef,
    currentPaymentFlow,
    isReviewing,
    setIsReviewing,
    chosenInstallment,
    reviewText,
    isModalShowing,
    setIsModalShowing,
    sumPaymentValuePending,
    shouldGenerateContract,
    hasContract,
    fullySigned
  } = useAlterFinancing()

  if (isReviewing) {
    return (
      <Template>
        {isModalShowing && (
          <ConfirmationModal
            confirm={onSubmit}
            isLoadingSubmit={isLoadingSubmit}
            onDismiss={() => setIsModalShowing(false)}
            shouldGenerateContract={shouldGenerateContract}
            hasContract={hasContract}
            isThirdOption={chosenInstallment?.number === 3}
            fullySigned={fullySigned}
          />
        )}
        <HeaderV2 label="Simulações dos descontos" isMobile={isMobile}></HeaderV2>
        <DescriptionText
          title={`Você está alterando o seu financiamento para: ${chosenInstallment?.kind}`}
          description={reviewText || ''}
          isLoading={isLoading}
          isMobile={isMobile}
        />

        <ExtraInfo newPrice={chosenInstallment?.totalValue} oldPrice={sumPaymentValuePending || 0} />

        {chosenInstallment && (
          <TableInstallments
            selected={true}
            isMobile={isMobile}
            isLoading={isLoading}
            installments={chosenInstallment?.installments}
          />
        )}

        <S.ButtonWrapper>
          <S.Back
            disabled={isLoadingSubmit}
            onClick={
              isLoadingSubmit
                ? () => {}
                : () => {
                    setIsReviewing(false)
                    window.scrollTo(0, 0)
                  }
            }
          >
            Voltar
          </S.Back>
          <ButtonV3
            onClick={() => {
              setIsModalShowing(true)
            }}
            mobileWidth="80%"
            text="Aceitar alteração"
            icon={ArrowLeft}
            disabled={isLoading || !selectedOption}
          />
        </S.ButtonWrapper>
      </Template>
    )
  }

  return (
    <Template>
      <HeaderV2 label="Simulações dos descontos" isMobile={isMobile}></HeaderV2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <DescriptionText
          title="Fluxo de pagamento atual"
          description="Esse é o seu fluxo de pagamento atual. Você pode mantê-lo ou alterar para um dos fluxos  abaixo. Realize uma simulação, identifique a opção que caiba no seu bolso e fique em dia  com a Provi."
          isLoading={isLoading}
          isMobile={isMobile}
        />

        <ExtraInfo newPrice={sumPaymentValuePending} oldPrice={0} />
        <TableInstallments selected={true} isMobile={isMobile} isLoading={isLoading} installments={currentPaymentFlow} />
      </div>

      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <S.StyledLoadingDescription
            isLoading
            hasHeading
            containerHeight="250px"
            containerWidth={isMobile ? '100%' : '408px'}
          />
        </div>
      ) : (
        installments?.length && (
          <DescriptionText
            title="Fluxos de pagamento disponíveis"
            description="Com o desconto de regularização de atraso, veja como ficam as faturas de pagamento do seu financiamento"
            isLoading={isLoading}
            isMobile={isMobile}
          />
        )
      )}

      {installments?.map((installmentOption, index) => (
        <div key={index}>
          <S.Description
            as="p"
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
            >
              <input
                type="radio"
                name="option"
                value={installmentOption?.id}
                checked={selectedOption === installmentOption?.id}
                onChange={() => handleSelectOption(installmentOption?.id)}
                style={{
                  cursor: 'pointer'
                }}
              />
              <label
                htmlFor="option"
                onClick={() => handleSelectOption(installmentOption?.id)}
                style={{
                  cursor: 'pointer',
                  display: 'flex'
                }}
              >
                Opção {index + 1} - {installmentOption?.kind}
              </label>
            </div>
            <ExtraInfo newPrice={installmentOption?.totalValue} oldPrice={sumPaymentValuePending || 0} />
          </S.Description>
          {installmentOption?.installments && (
            <TableInstallments
              selected={selectedOption === installmentOption?.id}
              isMobile={isMobile}
              isLoading={isLoading}
              installments={installmentOption?.installments}
            />
          )}

          <Divider />
        </div>
      ))}

      <S.ButtonWrapper>
        <S.Back disabled={isLoadingSubmit} onClick={isLoadingSubmit ? () => {} : goBack}>
          Voltar
        </S.Back>
        <ButtonV3
          ref={buttonRef}
          onClick={onSubmit}
          mobileWidth="80%"
          text="Escolher opção"
          icon={ArrowLeft}
          disabled={isLoading || !selectedOption}
        />
      </S.ButtonWrapper>
    </Template>
  )
}

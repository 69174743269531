import { theme } from '@provi/provi-components'
import { Link as ReactRouterLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.isMenuToggled ? '240px' : '60px')};
  height: 100vh;
  transition: width 0.3s ease-in-out;

  position: fixed;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  inset: 0;

  cursor: default;
  z-index: 6;

  box-shadow: 0px 4px 24px rgba(0, 11, 60, 0.1);
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  background: ${theme.colors.white100};
  overflow: hidden auto;
`

export const ContentHeader = styled.div`
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: ${props => (props.isMenuToggled ? 'space-between' : 'center')};
  padding: 0 14px;

  > a {
    display: ${({ isMenuToggled }) => (isMenuToggled ? 'inherit' : 'none')};
    transition: display 3s;

    & > svg {
      max-width: 90px;
      height: auto;
    }
  }

  > button {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
`

export const MenuIconsOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 65px;
`

const iconOptionActiveStyles = css`
  background: ${theme.colors.blueSecundaryLight};
  border-radius: 10px;
`
export const IconOption = styled.div`
  display: ${({ isMenuDesktopOpened }) => (isMenuDesktopOpened ? 'none' : 'flex')};
  height: 48px;
  justify-content: center;
  align-items: center;
  margin: 0px;

  transition: display 0.3s ease-in-out;

  ${props => props.active && iconOptionActiveStyles}
`

const IconLinkStyles = css`
  width: 40px;
  height: 40px;

  transition: 0.2s ease;

  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background: rgb(241, 241, 241);
    border-radius: 10px;
  }
`
export const Link = styled(ReactRouterLink)`
  width: 100%;
  svg {
    width: 35px;
    height: 35px;
    font-weight: bold;
  }
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  text-decoration: none;
  ${props => props?.icon && IconLinkStyles};
  color: #414141;
  top: 5px;
`

export const LinkedInValidationAlert = styled.div`
  margin-left: 25px;
  background-color: ${theme.colors.pink900};
  border-radius: 50%;

  font-family: 'Montserrat', sans-serif;
  width: 20px;
  height: 20px;
  background-color: ${theme.colors.pink900};

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 14px;

  position: absolute;
`

import React, { useContext } from 'react'
import { Title, Button, SubTitle } from '@provi/provi-components'

import { TemplateDeclaration } from '../../../components/template-declaration'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'

import { ButtonOutline } from '../../../components/button-outline'
import { getPreviousMonthName } from '../../../utils/'

import { Container, ButtonsContainer } from './styles'

export const OthersIncomeTypes = () => {
  const { goToNextScreen } = useContext(TemplateDeclarationContext)

  return (
    <TemplateDeclaration subTitle="Outras rendas">
      <Container>
        <Title text={<>Você recebeu dinheiro de alguma outra fonte além do trabalho?</>} />

        <SubTitle
          text={`Considere qualquer valor que você recebeu no mês de ${getPreviousMonthName()} e que não tenha relação com seu trabalho.`}
        />
        <ButtonsContainer>
          <ButtonOutline text="Não recebi" onClick={() => goToNextScreen(false)} isWithoutIcon />
          <Button text="Recebi" onClick={() => goToNextScreen(true)} />
        </ButtonsContainer>
      </Container>
    </TemplateDeclaration>
  )
}

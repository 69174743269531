import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  padding-left: 154px;
  box-sizing: border-box;
  width: 100%;
  height: 75%;
  z-index: 2;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .subTitleContainer {
    p {
      margin: 0;
      padding: 0;
    }
  }

  ${theme.breakpoints.down('md')} {
    width: 94%;
    padding: 0 27px;
  }
`

export const ContainerOptions = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 38px;
  margin-top: 38px;

  ${theme.breakpoints.down('md')} {
    justify-content: space-between;
  }
`

export const FormContainer = styled.div`
  padding-bottom: 1px;

  ${theme.breakpoints.down('sm')} {
    padding-top: 0px;
    padding-bottom: 1px;
  }
`

export const Text = styled.div`
  font-family: CenturyGothicBold, sans-serif;
  color: ${theme.colors.blue600};
  font-size: 24px;
  line-height: 20px;
  ${theme.breakpoints.down('lg')} {
    font-size: 20px;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 18px;
  }
`

export const Title = styled.div`
  font-family: CenturyGothicBold, sans-serif;
  margin-top: ${theme.variables.spacer * 2}px;
  color: ${theme.colors.blue900};
  font-size: 28px;
  line-height: 25px;
  ${theme.breakpoints.down('lg')} {
    font-size: 24px;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 20px;
  }
`

export const SubTitle = styled.div`
  font-family: CenturyGothic, sans-serif;
  color: ${theme.colors.blue900};
  font-size: 18px;
  line-height: 26px;
  margin-top: 10px;

  @media (max-width: 440px) {
    br {
      display: none;
    }
  }
  ${theme.breakpoints.down('lg')} {
    font-size: 15px;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 13px;
  }
  font-weight: ${prop => (prop.isBold ? 'bold' : 'normal')};
`

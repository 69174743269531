import styled, { css, keyframes } from 'styled-components'

const fadeAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const positionHandler = {
  right: 'row',
  top: 'column-reverse'
}

const anchorPositionHandler = {
  right: css`
    align-items: center;
    justify-content: flex-start;
  `,
  top: css`
    align-items: flex-end;
    justify-content: center;
  `
}

export const Container = styled.div`
  display: flex;
  width: fit-content;

  align-items: center;
  justify-content: center;

  position: relative;

  flex-direction: ${({ position }) => positionHandler[position]};
`

export const PopoverAnchor = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};

  position: relative;
  width: 0;
  height: 0;
  overflow: visible;
  transition: 0.4s;
  animation-name: ${fadeAnimation};
  animation-duration: 0.8s;
  animation-iteration-count: 1;

  ${({ position }) => anchorPositionHandler[position]};
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;

  flex-direction: ${({ position }) => positionHandler[position]};
`

export const Popover = styled.div`
  background: #00198a;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  z-index: 9;

  width: 300px;
  max-width: 80vw;

  & > svg {
    cursor: pointer;
    margin-left: 10px;
    width: 60px;
  }
`

export const ArrowLeft = styled.div`
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;

  border-right: 15px solid #00198a;
`

export const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;

  border-top: 15px solid #00198a;
`

export const Text = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  font-weight: 500;
`

export const CrossIconWrapper = styled.div`
  cursor: pointer;
`

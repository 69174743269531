import React, { useContext, useEffect } from 'react'
import { Button, theme, LoadingProvi } from '@provi/provi-components'
import { history } from '../../../navigation/history'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { Container, Title, Bold, Text, Wrapper } from './style'

export const TermsNegative = () => {
  const { goToNextScreen, setDataModal, isLoading, setIsLoading } = useContext(TemplateDeclarationContext)

  useEffect(() => {
    setDataModal(null)
  }, [setDataModal])

  return (
    <>
      {isLoading && <LoadingProvi isLoadingDark={false} />}
      <Container>
        <Wrapper>
          <Title>
            Ficamos tristes com sua resposta{' '}
            <span role="img" aria-label="Panda">
              😕
            </span>
          </Title>
          <Text>
            <Bold>A Provi In Network depende de você</Bold>, falando a verdade, para poder impactar a vida de cada vez mais
            brasileiros.
          </Text>
          <Text>
            Você <Bold>quer ajudar a mudar a educação do Brasil</Bold> concordando com juramento anterior?
          </Text>

          <Button
            marginHorizontal={0}
            marginVertical={0}
            text="Avançar"
            onClick={() => {
              setIsLoading(true)
              goToNextScreen(true)
            }}
            textLink={{
              color: theme.colors.white100,
              text: 'Ainda não concordo',
              onClick: () => {
                setIsLoading(true)
                goToNextScreen(false, true)
                history.push('/dashboard/declaracoes-isa')
              }
            }}
          />
        </Wrapper>
      </Container>
    </>
  )
}

import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  padding-left: 154px;
  box-sizing: border-box;
  width: 100%;
  height: 75%;
  z-index: 2;

  ${theme.breakpoints.down('md')} {
    padding: 0 27px;
  }
`

export const Title = styled.h4`
  margin: 30px 0;
  color: ${theme.colors.blue900};
  font-size: 30px;
  line-height: 36px;
  font-family: CenturyGothicBold, sans-serif;
  ${theme.breakpoints.down('md')} {
    font-size: 24px;
    line-height: 30px;
  }
`

export const SubTitle = styled.h5`
  color: ${({ color }) => (color ? theme.colors.blue600 : theme.colors.blue900)};
  margin: 0;
  margin-top: 35px;
  padding: 0;

  font-family: CenturyGothicBold, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Text = styled.p`
  font-family: CenturyGothic, sans serif;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.blue900};

  margin: 0;
  padding: 0;
  margin-top: 8px;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Bold = styled.span`
  font-family: CenturyGothicBold, sans-serif;
`

export const ButtonsContainer = styled.div`
  margin: 0;
  padding: 0;
  margin-top: 81px;

  display: flex;

  div {
    margin: 0;
    padding: 0;
    margin-right: 24px;
  }

  ${theme.breakpoints.down('md')} {
    flex-direction: column;

    div + div {
      margin-top: 17px;
    }
  }

  ${theme.breakpoints.down('sm')} {
    padding-bottom: 20px;
    margin-top: 40px;
  }
`
export const TextValue = styled.p`
  font-family: ${({ bold }) => (bold ? 'CenturyGothicBold, sans-serif' : 'CenturyGothic, sans serif')};
  color: ${({ color }) => color};
  font-size: 18px;
  line-height: 24px;
  margin: 0px;
  font-weight: normal;

  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Row = styled.div`
  margin: 10px 0px 0px 0px;

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin: 25px 0px 10px 0px;
    `}
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`

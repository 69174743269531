import React from 'react'
import { currencyParser } from '../../../../utils'
import * as S from './styles'

const CardStarkbank = data => {
  const accountType = {
    CONTA_POUPANCA: 'Conta poupança',
    CONTA_CORRENTE: 'Conta corrente',
    savings: 'Conta poupança',
    checking: 'Conta corrente'
  }

  return (
    <S.CardRequestValueContainer>
      <S.CardRequestAccount>
        <span>Valor: </span> {currencyParser(data?.data.refundSummary.refundAmount, 'R$')}
      </S.CardRequestAccount>
      <S.CardRequestAccount>
        <span>Tipo de conta:</span> {accountType[data?.data.refundSummary.bankAccountDetails.account.accountType]}
      </S.CardRequestAccount>
      <S.CardRequestAccount>
        <span>Banco:</span> {data?.data.refundSummary.bankAccountDetails.financialInstitution.name}
      </S.CardRequestAccount>
      <S.CardRequestAccount>
        <span>Agência: </span> {data?.data.refundSummary.bankAccountDetails.account.agency}
      </S.CardRequestAccount>
      <S.CardRequestAccount>
        <span>Conta: </span> {data?.data.refundSummary.bankAccountDetails.account.accountNumber}
      </S.CardRequestAccount>
    </S.CardRequestValueContainer>
  )
}

export default CardStarkbank

import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import ErrorBoundary from './components/error-boundary'
import { TemplateProvider } from './components/template/provider'
import { TemplateDeclarationProvider } from './components/template-declaration/provider'
import { TemplateProviSignProvider } from './components/template-provisign/provider'
import { LinkedInProvider } from './components/LinkedInTooltip/provider'
import { Navigation } from './navigation'
import TypeForm from './components/TypeFormIntegration'

import './config'
import './style.css'
import { TemplateCancellationProvider } from './components/template-cancellation/provider'

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <ErrorBoundary>
        <TemplateProvider>
          <TemplateProviSignProvider>
            <TemplateDeclarationProvider>
              <TemplateCancellationProvider>
                <LinkedInProvider>
                  <TypeForm />
                  <Navigation />
                </LinkedInProvider>
              </TemplateCancellationProvider>
            </TemplateDeclarationProvider>
          </TemplateProviSignProvider>
        </TemplateProvider>
      </ErrorBoundary>
    </AppContainer>,
    document.getElementById('provi-root')
  )
}

render()

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./navigation', () => {
    render()
  })
}

import { useCallback, useEffect, useRef, useState, useContext } from 'react'
import { history } from '../../navigation/history'
import { getExtraInfoCrid } from '../../services/api'
import { setLogger } from '../../utils/logger'
import { TemplateContext } from '../template/context'

export const useTemplateCancellation = () => {
  const [cancellationData, setCancellationData] = useState({})
  const [canRequestCancellation, setCanRequestCancellation] = useState(true)
  const { selectCrid, setIsLoadingGlobal } = useContext(TemplateContext)

  const getExtraInfo = async () => {
    try {
      if (!window.location.href.includes('/cancelamento')) return
      setIsLoadingGlobal(true)

      if (!selectCrid) return

      const { data } = await getExtraInfoCrid(selectCrid?.id)
      setCanRequestCancellation(data.canRequestCancellation)
      setIsLoadingGlobal(false)
    } catch (error) {
      setLogger(error)
      setIsLoadingGlobal(false)
    }
  }

  useEffect(() => {
    getExtraInfo()
  }, [selectCrid])

  const modalRef = useRef()

  const openModal = useCallback(() => {
    modalRef.current.openModalFn()
  }, [modalRef])

  const closeModal = useCallback(() => {
    modalRef.current.closeModalFn()
  }, [modalRef])

  const goBackToSelectReason = useCallback(() => {
    return history.push('/cancelamento/motivo')
  }, [history])

  return {
    cancellationData,
    setCancellationData,
    openModal,
    closeModal,
    modalRef,
    goBackToSelectReason,
    canRequestCancellation
  }
}

import React from 'react'
import styled from 'styled-components'
import ImageNotFound from '../../assets/images/404.png'

export const Background = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  background-image: url(${({ background }) => background});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 65%;
`

export const NotFound = () => <Background background={ImageNotFound} />

import { useState, useEffect } from 'react'

export const useWorkIncome = (goToNextScreen, incomeStatementPath, setDataModal) => {
  const [selectedOption, setSelectedOption] = useState()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  useEffect(() => {
    setDataModal(null)
  }, [setDataModal])

  useEffect(() => {
    if (incomeStatementPath && incomeStatementPath.ReadWorkIncome && incomeStatementPath.ReadWorkIncome.choosedId) {
      const find =
        incomeStatementPath.ReadWorkIncome &&
        incomeStatementPath.ReadWorkIncome.options.find(item => item.id === incomeStatementPath.ReadWorkIncome.choosedId)

      if (find) {
        onSelectOption(find)
        setIsButtonDisabled(false)
      }
    }
  }, [incomeStatementPath])

  const onSelectOption = option => {
    setSelectedOption(option)
    setIsButtonDisabled(false)
  }

  const onSubmit = e => {
    e.preventDefault()
    if (selectedOption?.id) {
      goToNextScreen(selectedOption.id)
    }
  }

  return [selectedOption, onSelectOption, onSubmit, isButtonDisabled]
}

import { showToast } from '@provi/provi-components'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'

import { LOCALSTORAGE_TOKENS } from '../../../constants'
import { history } from '../../../navigation/history'
import { getSimulationUpgrade, upgradeNewAllowance } from '../../../services/api'
import { logger } from '../../../utils/logger'

export const useAlterAllowance = () => {
  const [collapsed, setCollapsed] = useState(true)
  const [newAllowances, setNewAllowances] = useState([])
  const [oldAllowances, setOldAllowances] = useState([])
  const [installments, setInstallments] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [screen, setScreen] = useState('allowance')
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [oldAllowancesValue, setOldAllowancesValue] = useState(0)
  const [newAllowancesValue, setNewAllowancesValue] = useState(0)
  const [extraInformation, setExtraInformationAboutUpgrade] = useState({ requireNewSignature: false })

  const modalRef = useRef()

  const openModal = useCallback(() => {
    if (screen === 'allowance') {
      setCollapsed(true)

      return setScreen('installment')
    }

    modalRef.current.openModalFn()
  }, [screen, setScreen, modalRef])

  const closeModal = useCallback(() => {
    modalRef.current.closeModalFn()
  }, [modalRef])

  const firstDateOfPayment = moment(newAllowances[0]?.date, 'DD/MM/YYYY')

  const day = firstDateOfPayment.date()
  const month = firstDateOfPayment.format('MMMM')

  useEffect(() => {
    const getSimulation = async () => {
      try {
        setIsLoading(true)
        const crid = JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKENS.crid))

        const { data } = await getSimulationUpgrade(crid.id)

        if (data.error) {
          showToast('Você não pode atualizar sua mesada.')
          return history.push('/dashboard/mesadas/abertas')
        }

        setOldAllowances(data.allowances)
        setNewAllowances(data.newAllowance)
        setInstallments(data.installments)
        setOldAllowancesValue(data.oldAllowanceValue)
        setNewAllowancesValue(data.allowanceUpgradeValue)
        setExtraInformationAboutUpgrade(data.extraInformation)
      } catch (error) {
        logger({ error: error })
        showToast('Você não pode atualizar sua mesada.')

        return history.push('/dashboard/mesadas/abertas')
      } finally {
        setIsLoading(false)
      }
    }

    getSimulation()
  }, [])

  const handleShowMore = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed])

  const goBack = useCallback(() => {
    if (screen === 'installment') {
      setCollapsed(true)

      return setScreen('allowance')
    }

    return history.push('/dashboard/mesadas/abertas')
  }, [screen])

  const onSubmit = useCallback(async () => {
    try {
      setIsLoadingSubmit(true)
      const crid = JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKENS.crid))

      const { data } = await upgradeNewAllowance(crid.id)

      if (data.error) {
        return showToast('Você não pode atualizar sua mesada.')
      }

      history.push('/provi-sign/alteracao-mesada-sucesso')
    } catch (error) {
      logger({ error: error })
      showToast('Você não pode atualizar sua mesada.')
    } finally {
      setIsLoadingSubmit(false)
    }
  }, [setIsLoading, screen])

  return {
    collapsed,
    handleShowMore,
    newAllowances,
    isLoading,
    onSubmit,
    installments,
    screen,
    goBack,
    isLoadingSubmit,
    openModal,
    closeModal,
    modalRef,
    oldAllowances,
    day,
    month,
    oldAllowancesValue,
    newAllowancesValue,
    extraInformation
  }
}

import { useState, useCallback } from 'react'
import { showToast } from '@provi/provi-components'

import { history } from '../../../navigation/history'

import { getResMedPurchaseAddress, updateResMedPurchaseAddress, resMedPurchaseResume } from '../../../services/api'

export const useAddressConfirmation = setIsLoading => {
  const [inputValues, setInputValues] = useState({})
  const [purchaseResumeData, setPurchaseResumeData] = useState({})

  const handleInputChange = useCallback(
    event => {
      setInputValues({ ...inputValues, [event.target.name]: event.target.value })
    },
    [inputValues]
  )

  const handleOnSubmit = useCallback(
    async event => {
      event.preventDefault()
      try {
        const crid = JSON.parse(localStorage.getItem('CRID'))

        const response = await updateResMedPurchaseAddress({ crid: crid.id, data: inputValues })
        if (response.status === 200) {
          history.push('/provi-pay/forma-pagamento')
        }
      } catch (error) {
        showToast('Erro, por favor tente novamente')
      }
    },
    [setIsLoading, inputValues]
  )

  const getAddressData = useCallback(async () => {
    setIsLoading(true)
    try {
      const crid = JSON.parse(localStorage.getItem('CRID'))
      const apiResponse = await getResMedPurchaseAddress(crid.id)
      const { zipcode, street, number, complement, state, city, district } = apiResponse.data

      setInputValues({ zipcode, street, number, complement, state, city, district })
    } catch (error) {
      showToast('Erro, por favor tente novamente')
    } finally {
      setIsLoading(false)
    }
  }, [setIsLoading])

  const getPurchaseResumeData = useCallback(async () => {
    setIsLoading(true)
    try {
      const crid = JSON.parse(localStorage.getItem('CRID'))
      const data = await resMedPurchaseResume(crid.id)
      setPurchaseResumeData(data)
    } catch (error) {
      showToast('Erro, por favor tente novamente')
    } finally {
      setIsLoading(false)
    }
  }, [setIsLoading])

  return {
    inputValues,
    purchaseResumeData,
    handleInputChange,
    handleOnSubmit,
    getAddressData,
    getPurchaseResumeData
  }
}

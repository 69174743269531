import React, { useContext } from 'react'
import { Button, LoadingProvi } from '@provi/provi-components'
import { TemplateContext } from '../../../components/template/context'

import * as S from './styles'

export const AlreadyHasCrid = () => {
  const { isMobile, isLoading } = useContext(TemplateContext)

  return (
    <>
      {isLoading && <LoadingProvi isLoadingDark />}
      <S.Container isMobile={isMobile}>
        <S.Wrapper>
          <S.ContainerTitle>
            <S.Title>Você já possui um contrato de ISA assinado com a Provi.</S.Title>
          </S.ContainerTitle>

          <S.Text>
            Infelizmente não podemos seguir com a sua solicitação por esse motivo. Se preferir, entre em contato com nossa
            equipe de suporte.
          </S.Text>

          <S.WrapperButton>
            <Button
              marginHorizontal={0}
              type="submit"
              text="Entrar em contato"
              onClick={() =>
                window
                  .open(
                    'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?',
                    '_blank'
                  )
                  .focus()
              }
            />
          </S.WrapperButton>
        </S.Wrapper>
      </S.Container>
    </>
  )
}

import React from 'react'

export const WhiteCheck = () => (
  <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.20825 1.62524L3.12492 5.7084L1.08325 3.66691"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

import React from 'react'
import * as S from './styles'

const SummaryNoRefund = () => {
  return (
    <>
      <S.CardRequestValueContainer>
        <S.CardNoRefund>
          <span>Você não tem valores a pagar ou receber</span>
        </S.CardNoRefund>
      </S.CardRequestValueContainer>
    </>
  )
}

export default SummaryNoRefund

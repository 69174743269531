import { useCallback, useEffect, useState } from 'react'
import { showToast } from '@provi/provi-components'
import { history } from '../../../navigation/history'
import { getFinancing, getExtraInfoCrid, getProfileData, getToken, getIncomeTaxUrl } from '../../../services/api'
import { LOCALSTORAGE_TOKENS } from '../../../constants'
import { identify } from '../../../utils/identify'
import { redirect } from './utils/redirect'

export const useSelectCrid = (setMenuActive, setIsBack, setSelectCrid, token, setTypeDash, setShowExtraInfo) => {
  const [data, setData] = useState([])
  const [user, setUser] = useState([])
  const [IRNotification, setIRNotification] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [creditRequestToUpgrade, setCreditRequestToUpgrade] = useState('')
  const incompleteStatus = ['incomplete', 'not_logged', 'approved', 'waiting_contract', 'waiting_signature']

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true)

      try {
        const response = await getFinancing(token)

        setData(response)

        const seeIncomeTax = response.filter(crid => crid?.seeIncomeTax)
        const foundCreditRequestToUpgrade = response.find(crid => crid.canUpgrade)

        if (seeIncomeTax.length) {
          const incomeTaxResponse = await getIncomeTaxUrl(response?.[0].id)
          setIRNotification(incomeTaxResponse?.urlIncomeTax)
        }

        setCreditRequestToUpgrade(foundCreditRequestToUpgrade)
      } catch (err) {
        showToast('Erro, por favor tente novamente')
      } finally {
        setIsLoading(false)
      }
    }
    getData()
    setMenuActive('select-crid')
    setIsBack(false)
  }, [setIsBack, setIsLoading, setMenuActive, token])

  useEffect(() => {
    const getUserName = async () => {
      try {
        const response = await getProfileData(token)
        setUser(response)
      } catch (err) {
        showToast('Erro, por favor tente novamente')
      }
    }
    getUserName()
  }, [])

  const redirectToContract = async crid => {
    setIsLoading(true)
    const response = await getToken(crid.id)

    const { token: tokenRedirect } = await response.data

    setIsLoading(false)
    return history.push(`/provi-sign/carregando/${tokenRedirect}`)
  }

  const onClickNotification = useCallback(() => {
    const route = '/provi-sign/alteracao-mesada'

    return selectCrid(creditRequestToUpgrade, false, route)
  }, [data, creditRequestToUpgrade])

  const selectCrid = async (crid, isDisabled, route) => {
    identify({
      CreditRequestId: crid.id,
      UserId: user.id,
      email: user.email
    })

    if (isDisabled) return

    if (crid.status === 'waiting_signature') return redirectToContract(crid)

    if (crid.redirect && (incompleteStatus.includes(crid.status) || crid.status === 'analysis')) {
      return (window.location.href = crid.redirect)
    }

    localStorage.setItem(LOCALSTORAGE_TOKENS.crid, JSON.stringify(crid))

    setSelectCrid(crid)
    setTypeDash(crid.productType)

    const { data: dataExtraInfo } = await getExtraInfoCrid(crid.id)
    setShowExtraInfo(dataExtraInfo.showDetailedParts)

    return redirect({ crid, dataExtraInfo, route })
  }

  return { data, selectCrid, incompleteStatus, user, isLoading, IRNotification, creditRequestToUpgrade, onClickNotification }
}

import { useState, useEffect } from 'react'
import Fuse from 'fuse.js'
import { getFAQ } from '../../../services/api'

var OPTIONS = {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['content.sub-title', 'content.texts']
}

export const useHelp = ({ setMenuActive, selectCrid }) => {
  const [originalQuestions, setOriginalQuestions] = useState([])
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    async function loadQuestions() {
      if (!selectCrid) return

      const questionsResponse = await getFAQ(selectCrid.id)

      setMenuActive('help')

      setQuestions(questionsResponse)
      setOriginalQuestions(questionsResponse)
    }
    loadQuestions()
  }, [selectCrid])

  const handleSearchValue = e => {
    if (e.target.value === '') {
      setQuestions(originalQuestions)
    } else {
      const fuse = new Fuse(originalQuestions, OPTIONS)

      const result = fuse.search(e.target.value)

      setQuestions(result.map(item => item.item))
    }
  }

  return [questions, handleSearchValue]
}

import { theme } from '@provi/provi-components'
import styled, { keyframes } from 'styled-components'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  margin-bottom: 40px;

  border: 8px solid ${theme.colors.bluePrimaryLight};
  border-left-color: ${theme.colors.bluePrimary};
  background: transparent;
  width: 72px;
  height: 72px;
  border-radius: 50%;
`

export default Spinner

import { useEffect, useState } from 'react'

const optionsIndication = [
  { id: 1, text: 1 },
  { id: 2, text: 2 },
  { id: 3, text: 3 },
  { id: 4, text: 4 },
  { id: 5, text: 5 },
  { id: 6, text: 6 },
  { id: 7, text: 7 },
  { id: 8, text: 8 },
  { id: 9, text: 9 },
  { id: 10, text: 10 }
]

export const useIndicationPartner = (goToNextScreen, incomeStatementPath) => {
  const [value, setValue] = useState('')
  const [selectedOption, setSelectedOption] = useState()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  useEffect(() => {
    if (incomeStatementPath && incomeStatementPath.readIndicationPartner) {
      setSelectedOption(incomeStatementPath?.choosedIndication?.rating)
      setValue(incomeStatementPath?.choosedIndication?.ratingComments)
    }
  }, [incomeStatementPath])

  const onSelectOption = option => {
    setSelectedOption(option.id)
    setIsButtonDisabled(false)
  }

  const setComments = valueComments => {
    setValue(valueComments)
  }

  const onSubmit = e => {
    e.preventDefault()
    if (selectedOption) {
      goToNextScreen({
        rating: selectedOption,
        ratingComments: value
      })
    }
  }

  return [setComments, selectedOption, onSelectOption, onSubmit, isButtonDisabled, value, optionsIndication]
}

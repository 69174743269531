import React from 'react'
import { Wrapper, ContainerLogin, Container, Title, SubTitle } from './styles'

export const RedirectToken = props => {
  return (
    <Wrapper>
      <ContainerLogin>
        <Container>
          <Title>Acesse seu email!</Title>
          <SubTitle>Enviamos para você um email com um novo link de acesso para a plataforma da Provi.</SubTitle>
        </Container>
      </ContainerLogin>
    </Wrapper>
  )
}

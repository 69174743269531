import React from 'react'
import { LoadingSkeleton } from '../../../../components/LoadingSkeleton'
import { currencyParser } from '../../../../utils'

import * as S from '../styles'

export const parseDiscount = (discountInPercentage, maximumFractionDigits) => {
  const discount = typeof discountInPercentage === 'string' ? parseInt(discountInPercentage) : discountInPercentage

  const formattedDiscount = new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits || 0
  }).format(discount / 100)

  if (isNaN(discount)) {
    throw new Error(`Can't parse price: ${discountInPercentage}`)
  }

  return formattedDiscount || '0'
}

export const ExtraInfo = ({ newPrice, oldPrice, label = 'Total' }) => {
  const discountPercentage = (1 - newPrice / oldPrice) * 100

  if (!newPrice) {
    return (
      <S.LoadingContainer>
        <LoadingSkeleton isLoading hasHeading containerHeight="20px" containerWidth="130px" />
      </S.LoadingContainer>
    )
  }

  return (
    <S.ExtraInfoWrapper>
      {discountPercentage > 0 ? (
        <S.ExtraInfoDiscount>
          <S.ExtraInfoDiscountedItem>
            <S.ExtraInfoItemPrice hasDiscount={true}>{currencyParser(oldPrice || 0)}</S.ExtraInfoItemPrice>
            <S.ExtraInfoItemPrice isVariant={true}>{parseDiscount(discountPercentage)} OFF</S.ExtraInfoItemPrice>
          </S.ExtraInfoDiscountedItem>
        </S.ExtraInfoDiscount>
      ) : null}

      <S.ExtraInfoFullPrice>
        <S.ExtraInfoPriceLabel>{label}:</S.ExtraInfoPriceLabel>
        <S.ExtraInfoPriceValue title={`Total final: ${currencyParser(newPrice || 0)}`}>
          {currencyParser(newPrice || 0)}
        </S.ExtraInfoPriceValue>
      </S.ExtraInfoFullPrice>
    </S.ExtraInfoWrapper>
  )
}

import React, { useContext } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import Button from '@material-ui/core/Button'
import { Modal, LoadingProvi } from '@provi/provi-components'
import { MaxWidth } from '../../../components/max-width'
import { TemplateProviSignContext } from '../../../components/template-provisign/context'
import { TemplateContext } from '../../../components/template/context'
import { IconDownload } from '../../../assets/svg/icon-download-outlined'
import { ArrowMore } from '../../../assets/svg/arrow-more'
import { IconCheck } from '../../../assets/svg/icon-check2'
import { IconFingerSigner } from '../../../assets/svg/icon-finger-signer'

import {
  Wrapper,
  ContainerPdf,
  SubContainerPdf,
  ContainerLoading,
  TextLoading,
  OpacityTrick,
  HackButtonGlobals,
  ContainHeaderButtons,
  SignerBottomSheet,
  HotClickableDragableArea,
  LayoutContent,
  SignerCallToAction,
  SignerBottomSheetContainer,
  Main
} from './styles'

import { useTermsContract } from './hooks'
import { FormFormik } from './form'
import { logger } from '../../../utils/logger'
import { PROXY_BASE_URL } from '../../../constants'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const TermsContract = () => {
  const { objectValidation, typeUser } = useContext(TemplateProviSignContext)
  const { setIsLoading, isMobile } = useContext(TemplateContext)

  const [
    onLoadSuccess,
    arrayPages,
    isShowModal,
    setIsShowModal,
    onSubmit,
    proviContractId,
    proviContractUrl,
    isLoadingIntern,
    openBottomSheetSigner,
    setOpenBottomSheetSigner,
    fingerSigned,
    onFingerSignToggle,
    loadProgress,
    setLoadProgress,
    documentRef
  ] = useTermsContract(setIsLoading, objectValidation, typeUser)

  const buttonSize = isMobile ? 'small' : 'medium'

  return (
    <>
      {isLoadingIntern && (
        <ContainerLoading>
          <TextLoading>{loadProgress.toFixed(0)}% Estamos gerando seu contrato</TextLoading>
          <LoadingProvi />
        </ContainerLoading>
      )}
      {isShowModal && (
        <Modal
          title={`Digite o seu ${objectValidation?.isCPF ? 'CPF' : 'CNPJ'} para concluir a sua assinatura`}
          onDismiss={() => setIsShowModal(false)}
        >
          <FormFormik
            typeUser={typeUser}
            proviContractId={proviContractId}
            objectValidation={objectValidation}
            onSubmit={onSubmit}
            setIsLoading={setIsLoading}
          />
        </Modal>
      )}
      <ContainHeaderButtons>
        {proviContractId && (
          <OpacityTrick hide={openBottomSheetSigner}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpenBottomSheetSigner(!openBottomSheetSigner)}
              size={buttonSize}
            >
              Assinar Contrato
            </Button>
          </OpacityTrick>
        )}

        <OpacityTrick hide={false}>
          <HackButtonGlobals>
            <Button
              variant="outlined"
              color="secondary"
              aria-label="Baixar Contrato"
              style={{ marginLeft: '16px' }}
              startIcon={!isMobile && <IconDownload />}
              size={buttonSize}
              onClick={() => {
                window.open(`https://${proviContractUrl}`, '_blank')
              }}
            >
              {isMobile ? <IconDownload /> : 'Baixar'}
            </Button>
          </HackButtonGlobals>
        </OpacityTrick>
      </ContainHeaderButtons>
      <Main>
        <MaxWidth style={{ height: '100%' }}>
          <Wrapper>
            <ContainerPdf>
              <SubContainerPdf>
                <Document
                  ref={documentRef}
                  scale={1}
                  onLoadSuccess={({ numPages }) => onLoadSuccess(numPages)}
                  file={proviContractUrl && `${PROXY_BASE_URL}/${proviContractUrl}`}
                  error="Ocorreu um erro ao carregar este contrato"
                  onLoadProgress={({ loaded, total }) => setLoadProgress((loaded / total) * 100)}
                  onLoadError={error => logger(error)}
                  onRenderError={error => logger(error)}
                >
                  {arrayPages &&
                    arrayPages.map((item, index) => (
                      <Page
                        width={isMobile ? window?.screen?.width : 800}
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        error="Ocorreu um erro ao carregar esta página"
                        noData="Ocorreu um erro ao carregar esta página"
                        loading="Carregando..."
                        onLoadError={error => logger(error)}
                      />
                    ))}
                </Document>
              </SubContainerPdf>
            </ContainerPdf>
          </Wrapper>
        </MaxWidth>
      </Main>

      <SignerBottomSheet isOpen={openBottomSheetSigner}>
        <SignerBottomSheetContainer>
          <HotClickableDragableArea
            onClick={() => {
              setOpenBottomSheetSigner(false)
              setTimeout(() => onFingerSignToggle(false), 500)
            }}
          >
            <ArrowMore />
          </HotClickableDragableArea>

          <LayoutContent>
            {!fingerSigned && (
              <SignerCallToAction
                onClick={() => onFingerSignToggle(!fingerSigned)}
                checked={fingerSigned}
                aria-label="Clique para concordar com as condições do contrato"
              >
                <IconFingerSigner />
                Clique para concordar com as condições do contrato
              </SignerCallToAction>
            )}

            {fingerSigned && (
              <SignerCallToAction
                onClick={() => onFingerSignToggle(!fingerSigned)}
                checked={fingerSigned}
                aria-label="Termos e condições aceitas"
              >
                <IconCheck /> Termos e condições aceitas
              </SignerCallToAction>
            )}

            <Button variant="contained" color="secondary" disabled={!fingerSigned} onClick={() => setIsShowModal(true)}>
              Assinar Contrato
            </Button>
          </LayoutContent>
        </SignerBottomSheetContainer>
      </SignerBottomSheet>
    </>
  )
}

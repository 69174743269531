import React from 'react'
import { IconBlockedAllowance } from '../../../../../assets/svg/blocked-allowance'
import * as S from '../../styles'

export const CardBlocked = ({ allowance, key }) => {
  return (
    <S.Card blocked key={key}>
      <S.CardHeader blocked>
        <S.MonthAllowance>Mesada de {allowance.formatDate}</S.MonthAllowance>
        <S.NumberAllowance>
          {allowance.number} de {allowance.amountOfItems}
        </S.NumberAllowance>
      </S.CardHeader>

      <S.WrapperCard>
        <S.WrapperItem>
          <p>Início da retirada</p>
          <p>{allowance.availableDate}</p>
        </S.WrapperItem>

        <S.WrapperItem>
          <p>Previsão de depósito</p>
          <p>{allowance.scheduledDate}</p>
        </S.WrapperItem>
      </S.WrapperCard>

      <S.FooterCard blocked>
        <S.ButtonFooterCard>
          {allowance.originalStatus && allowance.originalStatus === 'available' ? 'Retire a anterior primeiro' : 'Bloqueada'}
          <IconBlockedAllowance />
        </S.ButtonFooterCard>
      </S.FooterCard>
    </S.Card>
  )
}

export const activeStatus = [
  'Financiamento Ativo',
  'Financiamento Cancelado - Fatura disponível',
  'Financiamento ativo - Faturas atrasadas',
  'Financiamento concluído',
  'Financiamento Cancelado - Faturas atrasadas',
  'ISA Ativo - Fatura disponível',
  'ISA Ativo - Faturas atrasadas',
  'ISA cancelado - Fatura disponível',
  'ISA Default',
  'ISA Cancelado - Faturas atrasadas',
  'Financiamento Ativo',
  'Financiamento ativo - Fatura disponível'
]

export const redStatuses = [
  'Financiamento Cancelado',
  'Financiamento Cancelado - Faturas atrasadas',
  'Financiamento ativo - Faturas atrasadas',
  'ISA Ativo - Faturas atrasadas',
  'ISA Default',
  'ISA Cancelado - Faturas atrasadas',
  'ISA cancelado',
  'Compra Cancelada'
]

export const greenStatuses = [
  'Financiamento Ativo',
  'Financiamento concluído',
  'ISA Concluído',
  'ISA Ativo',
  'Compra Concluída'
]

export const ProviPayProductTypes = ['CreditCard', 'ProviPay', 'Boleto', 'Pix']

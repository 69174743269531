import React from 'react'
import { TemplateCancellationContext } from './context'
import { useTemplateCancellation } from './hooks'

export const TemplateCancellationProvider = props => {
  const { cancellationData, setCancellationData, openModal, closeModal, modalRef } = useTemplateCancellation()

  const value = { cancellationData, setCancellationData, openModal, closeModal, modalRef }

  return <TemplateCancellationContext.Provider value={value}>{props.children}</TemplateCancellationContext.Provider>
}

import React, { useContext } from 'react'
import { Title, InputMasks, Button, Label, Input } from '@provi/provi-components'
import InputAdornment from '@material-ui/core/InputAdornment'
import { history } from '../../../navigation/history'
import { TemplateProviSignContext } from '../../../components/template-provisign/context'
import { TemplateContext } from '../../../components/template/context'
import { IconLock } from '../../../assets/svg/icon-lock'
import { Template } from '../../../components/template'
import { useIntroGuarantor } from './hooks'

import { Wrapper, WrapperForm, WrapperInput } from './styles'

export const IntroGuarantor = () => {
  const { objectValidation, typeUser } = useContext(TemplateProviSignContext)
  const { setIsLoading } = useContext(TemplateContext)
  const [data, nextScreenToGo] = useIntroGuarantor(setIsLoading, typeUser, objectValidation)

  return (
    <Template isShowMenu>
      <Wrapper>
        <WrapperForm>
          <Title text={data && `Confirme os seus dados para ${data?.haveContract ? 'renovar' : 'assinar'} o contrato`} />
          <WrapperInput>
            <Label text="CPF" />
            <InputMasks
              mask="999.999.999-99"
              margin="normal"
              type="tel"
              value={data && data.guarantor && data.guarantor.cpf}
              InputProps={{
                disabled: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconLock />
                  </InputAdornment>
                )
              }}
              autoFocus
            />
          </WrapperInput>
          <WrapperInput>
            <Label text="Nome" />
            <Input
              margin="normal"
              value={data && data.guarantor && data.guarantor.fullName}
              InputProps={{
                disabled: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconLock />
                  </InputAdornment>
                )
              }}
              autoFocus
            />
          </WrapperInput>

          <WrapperInput>
            <Label text="Telefone" />
            <InputMasks
              mask="(99) 99999-9999"
              margin="normal"
              type="tel"
              value={data && data.guarantor && data.guarantor.phone}
              InputProps={{
                disabled: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconLock />
                  </InputAdornment>
                )
              }}
              autoFocus
            />
          </WrapperInput>

          <Button marginHorizontal={0} onClick={() => history.push(nextScreenToGo)} type="submit" text="Eu confirmo" />
        </WrapperForm>
      </Wrapper>
    </Template>
  )
}

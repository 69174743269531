import React from 'react'
import { ButtonV3 } from '@provi/provi-components'
import { BRCurrencyFormatter } from '../../utils/intl'
import ResMedModal from './ResMedModal'

import * as S from './styles'

const ResMedCard = ({ courseInfo, darkBtnClick, lightBtnClick, modalInfo, handleCloseModal }) => {
  const { courseName, subtotal, total } = courseInfo

  return (
    <>
      <S.Card>
        <S.Title>Oportunidade!</S.Title>
        <S.SmallText>
          Compre com poucos cliques o seu <strong>{courseName}</strong>
        </S.SmallText>
        <S.LargeText>
          <strong>De</strong> <strike>{BRCurrencyFormatter(subtotal)}</strike> <strong>por {BRCurrencyFormatter(total)}</strong>
        </S.LargeText>
        <S.BtnContainer>
          <ButtonV3 mobileWidth="100%" text="Quero Comprar" onClick={darkBtnClick} />
          <ButtonV3 mobileWidth="100%" text="Ver detalhes" color="light" onClick={lightBtnClick} />
        </S.BtnContainer>
      </S.Card>

      {modalInfo.show && <ResMedModal modalInfo={modalInfo} handleCloseModal={handleCloseModal} btnClick={darkBtnClick} />}
    </>
  )
}

export default ResMedCard

import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/browser'
import { JSONPARSER } from '../utils/logger'
import { identify } from '../utils/identify'
import { LOCALSTORAGE_TOKENS } from '../constants'

export const history = createBrowserHistory()

history.listen(() => {
  const CRID = JSONPARSER(localStorage.getItem(LOCALSTORAGE_TOKENS.crid))
  const email = localStorage.getItem(LOCALSTORAGE_TOKENS.email)
  const UserId = localStorage.getItem(LOCALSTORAGE_TOKENS.userId)
  Sentry.setContext('User', {
    CRID,
    email,
    UserId
  })

  Sentry.setContext('Screen', {
    path: window.location.pathname
  })

  identify({
    CreditRequestId: CRID?.id,
    email,
    UserId
  })

  ReactGA.pageview(window.location.pathname + window.location.search)
})

import { theme } from '@provi/provi-components'
import React, { useContext, useEffect, useState } from 'react'
import { TemplateContext } from '../template/context'
import { getFlag } from './getFlag'
import { TypeFormComponent } from './typeFormComponent'

export const TypeFormRendering = ({ id, blockedUrls, allowedUrls, typeFlag, tracking }) => {
  const { isMobile, selectCrid } = useContext(TemplateContext)
  const [show, setShow] = useState(false)
  const [registredUrl, setRegistredUrl] = useState({ url: '', shouldUpdate: false })
  const [open, setOpenEvent] = useState('load')

  useEffect(() => {
    getFlag({ setShow, selectCrid, blockedUrls, allowedUrls, typeFlag, id, registredUrl, setOpenEvent })
  }, [id, window.location.pathname, registredUrl, selectCrid])

  const onReady = () => {
    if (!registredUrl.shouldUpdate) {
      setRegistredUrl({ url: window.location.pathname, shouldUpdate: true })
    }
  }

  const onSubmit = () => {
    localStorage.setItem(`submittedTypeform#${id}`, 'true')
    setShow(false)
  }

  return {
    component: (
      <TypeFormComponent
        id={id}
        open={open}
        onReady={onReady}
        onSubmit={onSubmit}
        buttonColor={theme.colors.bluePrimary}
        buttonText="Feedback"
        tracking={tracking}
        isMobile={isMobile}
      />
    ),
    show
  }
}

export const typeFormPropsNps = {
  id: 'cz0ar8bc',
  blockedUrls: ['/provi-sign', '/token-invalido', '/token'],
  allowedUrls: null,
  typeFlag: 'TypeformFlag',
  tracking: {
    utm_source: 'Painel do Aluno',
    utm_medium: 'Side tab',
    utm_campaign: 'Pesquisa - Alunos',
    user_id: localStorage.getItem('user_id')
  }
}

export const typeFormPropsStudentsInterview = {
  id: 'XKxNnCOb',
  blockedUrls: null,
  allowedUrls: ['/dashboard/declaracoes/pendentes', '/dashboard/faturas/abertas', '/dashboard/faturas/pagas'],
  typeFlag: 'TypeformStudentsInterview',
  tracking: {
    user_id: localStorage.getItem('user_id')
  }
}

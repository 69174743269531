import React from 'react'
import * as S from './styles'
import { currencyParser } from '../../../../utils'

const SummaryPix = ({ data }) => {
  return (
    <S.CardRequestValueContainer>
      <S.CardRequestAccount>
        <span>Valor: </span> {currencyParser(data.refundAmount, 'R$ ')}
      </S.CardRequestAccount>
    </S.CardRequestValueContainer>
  )
}

export default SummaryPix

import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin-top: 10px;
  padding-left: 154px;
  box-sizing: border-box;
  width: 100%;
  max-width: 850px;
  z-index: 2;

  ${theme.breakpoints.down('md')} {
    height: 67%;
    padding: 0 24px;
  }
`

export const ContainerOptions = styled.div`
  display: flex;
  max-width: 640px;
  flex-wrap: wrap;
  margin-top: ${theme.variables.spacer * 2}px;
  margin-bottom: 38px;
  ${theme.breakpoints.down('md')} {
    justify-content: space-between;
  }
  @media (max-width: 630px) {
    justify-content: center;
  }
`

export const Option = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  margin: 4px;
  cursor: pointer;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'CenturyGothic', sans serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #b0afaf;

  ${prop =>
    prop.active
      ? css`
          background: ${theme.colors.bluePrimaryDark};
          border: none;
          font-family: 'CenturyGothicBold', sans serif;
          color: ${theme.colors.bluePrimary};
        `
      : css`
          border: 1px solid #cfcfcf;
          border-radius: 5px;
        `}
`

export const ButtonsContainer = styled.div`
  @media (max-width: 600px) {
    .MuiButton-root {
      margin-bottom: 20px;
    }
  }
`

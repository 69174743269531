import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

const textStyle = css`
  color: ${theme.colors.blue900};
  font-family: 'Montserrat', sans-serif;
`

export const SmallText = styled.p`
  ${textStyle}
  font-size: 18px;
  line-height: 24px;

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-width: 330px) {
    font-size: 12px;
  }
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;

  & > h3 {
    ${textStyle}
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  & > h4 {
    ${textStyle}
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    & > strong {
      margin-right: 5px;
    }
  }

  & > div {
    margin-bottom: 23px;

    & > p {
      ${textStyle}
      margin: 0;
      padding: 0;
      font-size: 16px;
      line-height: 20px;
    }
  }

  & > ul {
    padding-left: 18px;
    margin-bottom: 29px;

    & > li {
      ${textStyle}
      font-size: 14px;
      line-height: 20px;
    }
  }
`

export const AlignButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ButtonCloseModal = styled.p`
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.bluePrimary} !important;
  margin: 20px !important;
  cursor: pointer;
`

import React, { useRef, useCallback } from 'react'
import * as S from './styles'
import { ReactComponent as Dolar } from '../../../../assets/svg/currency-circle.svg'
import { ReactComponent as Clock } from '../../../../assets/svg/clock-counter.svg'
import { ReactComponent as Question } from '../../../../assets/svg/question.svg'
import { ReactComponent as Warning } from '../../../../assets/svg/warning-octagon.svg'
import { Modal } from '../Modal'
import { useRequests } from '../../hooks'
import CardPaymentOrder from './card-payment-order'
import CardStarkbank from './card-starkbank'
import CardWirecard from './card-wirecard'
import CardPix from './card-pix'
import { LoadingSkeleton } from '../../../../components/LoadingSkeleton'
import moment from 'moment'
import CardNoRefund from './card-no-refund'

const CardRequest = ({ cridInfo, isMobile }) => {
  const modalRef = useRef()
  const modalGiveup = useRef()

  const openModal = useCallback(() => {
    modalRef.current.openModalFn()
  }, [modalRef])

  const closeModal = useCallback(() => {
    modalRef.current.closeModalFn()
  }, [modalRef])

  const openModalGiveup = useCallback(() => {
    modalGiveup.current.openModalFn()
  }, [modalGiveup])

  const closeModalGiveup = useCallback(() => {
    modalGiveup.current.closeModalFn()
  }, [modalGiveup])

  const { objRefundType, onSubmit, cancellationData, isLoading, isLoadingCancellationData } = useRequests({
    cridInfo,
    closeModalGiveup
  })

  if (isLoadingCancellationData) {
    return <LoadingSkeleton isLoading hasHeading containerHeight="376px" containerWidth="704px" />
  }

  if (
    (!cancellationData && !isLoadingCancellationData) ||
    (!cancellationData?.cancellationRequest && !isLoadingCancellationData)
  ) {
    return <S.EmptyRequests>Você não tem nenhuma solicitação para acompanhar no momento.</S.EmptyRequests>
  }

  return (
    <>
      <S.CardRequestContainer>
        <S.CardRequestTitle>Acompanhamento da solicitação de cancelamento</S.CardRequestTitle>
        <S.CardRequestRow>
          <div>
            <strong>Curso:</strong> <span>{cridInfo?.course}</span>
          </div>
          <div>
            <strong>Instituição:</strong> <span>{cridInfo?.partner}</span>
          </div>
        </S.CardRequestRow>
        <S.CardRequestRow>
          <div>
            {isMobile ? <strong>Data do cancelamento:</strong> : <strong>Data do pedido de cancelamento: </strong>}
            <span>{moment(cancellationData?.cancellationRequest?.requestDate).format('DD/MM/YYYY')}</span>
          </div>
          <div>
            <strong>Motivo:</strong> <span>{cancellationData?.cancellationRequest?.comment}</span>
          </div>
        </S.CardRequestRow>
        {cancellationData?.cancellationRequest?.status !== 'canceled' && (
          <div>
            <S.CardRequestSubTitle>
              <Dolar />
              {objRefundType[cancellationData?.cancellationRequest?.refundType] || 'Reembolso'}
            </S.CardRequestSubTitle>
          </div>
        )}
        {!cancellationData?.cancellationRequest?.refundType && cancellationData?.cancellationRequest?.status !== 'canceled' && (
          <CardNoRefund />
        )}
        {cancellationData?.cancellationRequest?.refundType === 'starkbank' &&
          cancellationData?.cancellationRequest?.status !== 'canceled' && <CardStarkbank data={cancellationData} />}

        {cancellationData?.cancellationRequest?.refundType === 'wirecard' && cridInfo?.productType === 'Pix' &&
          cancellationData?.cancellationRequest?.status !== 'canceled' && <CardPix data={cancellationData} />}

        {cancellationData?.cancellationRequest?.refundType === 'wirecard' && cridInfo?.productType === 'CreditCard' &&
          cancellationData?.cancellationRequest?.status !== 'canceled' && <CardWirecard data={cancellationData} />}


        {cancellationData?.cancellationRequest?.refundType === 'ordem de pagamento' &&
          cancellationData?.cancellationRequest?.status !== 'canceled' && <CardPaymentOrder data={cancellationData} />}

        {cancellationData?.cancellationRequest?.status === 'canceled' ? (
          <S.CardRequestInfo>
            <Warning />

            <S.CardRequestInfoText>
              <div>
                <strong>Você desistiu de cancelar a compra do curso:</strong> isso significa que nenhuma multa ou reembolso
                serão aplicados.
              </div>
            </S.CardRequestInfoText>
          </S.CardRequestInfo>
        ) : (
          <>
            <S.CardRequestInfo>
              <Clock />

              <S.CardRequestInfoText>
                <div>
                  <strong>O seu cancelamento está em análise: </strong> A análise leva 3 dias úteis e, se você quiser, neste
                  período você pode optar por desistir do cancelamento.{' '}

                  {cancellationData?.cancellationRequest?.refundType &&
                    cancellationData?.cancellationRequest?.refundType === 'wirecard' && cridInfo?.ProductType === 'CreditCard' &&
                    'Após o período o valor estará disponível em 1 a 2 faturas após a solicitação.'}

                  {cancellationData?.cancellationRequest?.refundType && cridInfo?.ProductType === 'Pix' &&
                    cancellationData?.cancellationRequest?.refundType === 'wirecard' &&
                    'Após o período o valor estará disponível para saque em até 15 dias úteis.'}

                  {cancellationData?.cancellationRequest?.refundType &&
                    cancellationData?.cancellationRequest?.refundType !== 'wirecard' &&
                    'Após o período o valor estará disponível para saque em até 15 dias úteis.'}

                </div>
                <a onClick={openModalGiveup} data-test-id="give-up-cancellation">
                  Desistir do cancelamento
                </a>
              </S.CardRequestInfoText>
            </S.CardRequestInfo>
          </>
        )}
        <S.CardRequestCancellation onClick={openModal} data-test-id="modal-doubts">
          <Question />
          Dúvidas sobre o cancelamento
        </S.CardRequestCancellation>
      </S.CardRequestContainer>
      <Modal modalRef={modalRef} title="Dúvidas?" onClose={closeModal} showDoubtsFooter>
        <S.ModalBody>
          <p data-test-id="modal-doubts-body">
            Você pode consultar as principais dúvidas no nosso FAQ de cancelamento. Mas, se você ainda tiver alguma pergunta que
            não encontrou a resposta, você pode entrar em contato diretamente com o nosso atendimento.
          </p>
        </S.ModalBody>
      </Modal>
      <Modal
        modalRef={modalGiveup}
        title="Você vai continuar com a gente? 👀 "
        isLoading={isLoading}
        onClose={closeModalGiveup}
        onSubmit={onSubmit}
      >
        <S.ModalBody>
          <p data-test-id="give-up-cancellation-body">
            Você clicou para desistir do cancelamento. Isso significa que você irá continuar com o curso e nenhuma multa ou
            reembolso serão aplicados.
          </p>
        </S.ModalBody>
      </Modal>
    </>
  )
}

export default CardRequest

import React, { useContext, useEffect } from 'react'
import { Button, theme, Container } from '@provi/provi-components'
import { history } from '../../../navigation/history'
import { TemplateDeclaration } from '../../../components/template-declaration'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { Title, Text, Wrap } from './style'

export const SuccessCase = () => {
  const { goToNextScreen, setDataModal } = useContext(TemplateDeclarationContext)

  useEffect(() => {
    setDataModal(null)
  }, [setDataModal])

  return (
    <TemplateDeclaration>
      <Container>
        <Title>Finalizada!</Title>
        <Text>Sua declaração foi finalizada e agora vamos analisá-la com carinho!</Text>
        <Text>
          Estamos muito felizes que você já esteja conseguindo uma renda acima do mínimo. Temos tanto orgulho de fazer parte da
          sua história que queremos contá-la para mais pessoas.
        </Text>
        <Text>Topa virar um case de sucesso nas nossas plataformas?</Text>
        <Wrap>
          <Button
            marginHorizontal={0}
            marginVertical={0}
            text="Quero ser um case de sucesso"
            onClick={() => goToNextScreen(true)}
            textLink={{
              color: theme.colors.blue900,
              text: 'Não tenho interesse',
              onClick: () => {
                goToNextScreen(false, true)
                history.push('/contratos')
              }
            }}
          />
        </Wrap>
      </Container>
    </TemplateDeclaration>
  )
}

import React, { useState, useCallback } from 'react'

import { insertLinkedIn, validateLinkedInRegistration } from '../../services/api'
import { LinkedInContext } from './context'

export const LinkedInProvider = ({ children }) => {
  const [linkedInData, setLinkedInData] = useState(null)
  const [isLinkedInRegistered, setIsLinkedInRegistered] = useState(true)
  const [buttonCheckDisplay, setButtonCheckDisplay] = useState(false)

  const handleLinkedInInsertion = useCallback(async linkedInUrl => {
    const response = await insertLinkedIn({ linkedInUrl, createdInUserDashboard: true })
    setLinkedInData(response?.data?.linkedIn?.data)
    if (response.status === 200) {
      setButtonCheckDisplay(true)
      setIsLinkedInRegistered(true)
    }
  }, [])

  const handleValidationOfLinkedInRegister = useCallback(async () => {
    const response = await validateLinkedInRegistration()
    setLinkedInData(response?.data?.linkedIn)
    setIsLinkedInRegistered(response?.data?.registered)
  }, [])

  return (
    <LinkedInContext.Provider
      value={{
        linkedInData,
        isLinkedInRegistered,
        handleLinkedInInsertion,
        handleValidationOfLinkedInRegister,
        buttonCheckDisplay,
        setButtonCheckDisplay
      }}
    >
      {children}
    </LinkedInContext.Provider>
  )
}

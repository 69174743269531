import { ButtonV3, InputFieldV3, PortalModal, SelectorV3, theme } from '@provi/provi-components'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

export const Form = styled.div`
  border-top: 2px solid ${theme.colors.bluePrimaryLight};
  margin-top: 10.5px;
`
export const StyledSelect = styled(SelectorV3)`
  div {
    font-size: 14px !important;
  }
  > div {
    z-index: 9999;
  }
`
export const StyledSelect2 = styled(SelectorV3)`
  div {
    font-size: 14px !important;
  }
`

export const SubTitleForm = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;

  svg {
    margin-left: 8px;
  }
  letter-spacing: 0.015em;
  color: #000000;
  margin: 24.5px 0 0 0;
`

export const Tooltip = styled(ReactTooltip)`
  z-index: 99999999 !important;
  opacity: 1 !important;
  background-color: ${theme.colors.bluePrimaryDark} !important;
  color: #ffffff !important;
  padding: 12px 16px !important;
  width: 204px !important;
  height: 152px !important;
  border-radius: 8px !important;
  font-family: 'Montserrat', sans-serif !important;
  &.place-left {
    &:after {
      border-left-color: ${theme.colors.bluePrimaryDark} !important;
      border-left-style: solid !important;
      border-left-width: 9px !important;
    }
  }
`

export const LabelSelect = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.015em;
  margin: 32px 0 0 0;
`

export const InputAccount = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.015em;
  margin: 32px 0 0 40px;
  @media (max-width: 800px) {
    margin: 32px 0 0 31.12px;
  }
`

export const AgencyField = styled.div`
  display: flex;
  label > p {
    font-family: 'Montserrat', sans-serif;
  }
`

export const DivButton = styled.div`
  margin-top: 48px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const StyledPortalModal = styled(PortalModal)`
  border: 2px solid ${theme.colors.bluePrimaryLight};
  border-radius: 8px;
  height: ${({ desktopHeight }) => desktopHeight};
  @media (max-width: 800px) {
    width: 312px;
    height: ${({ mobileHeight }) => mobileHeight};
  }

  @media (max-width: 330px) {
    width: 312px;
    padding-top: 30px;
    height: ${({ mobileHeight }) => (mobileHeight === '352px' ? '450px' : mobileHeight)};
  }
`
export const ButtonAdvance = styled(ButtonV3)`
  button span {
    font-size: 16px !important;
  }
`

export const InputField = styled(InputFieldV3)`
  font-size: 14px !important;
`

export const Validating = styled.div`
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #000000;
  }
`

export const CloseModal = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 464px;
  position: fixed;
  button {
    border: none;
    background: transparent;
    cursor: pointer;
  }
  @media (max-width: 800px) {
    width: 276px;
  }
  @media (max-width: 375px) {
    width: 250px;
  }
  @media (max-width: 345px) {
    width: 210px;
    top: 10px;
  }
`

import React from 'react'
import { Redirect, Route, Switch, Router } from 'react-router-dom'
import { ToastComponent } from '@provi/provi-components'
import 'react-toastify/dist/ReactToastify.css'
import { ROUTES } from './routes'
import { history } from './history'

export const Navigation = () => {
  return (
    <Router history={history}>
      <Switch>
        {ROUTES.map(item => (
          <Route key={item.path} path={item.path} exact component={item.component} />
        ))}
        <Redirect to="/inicio" exact />
      </Switch>
      <ToastComponent />
    </Router>
  )
}

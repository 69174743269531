import { theme } from '@provi/provi-components'
import styled from 'styled-components'

export const ButtonCallToAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;

  width: 211px;
  height: 48px;

  background: ${theme.colors.bluePrimary};
  border-radius: 4px;

  margin-top: 25px;

  > a {
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.015em;

    color: #ffffff;

    flex: none;
    order: 0;
    flex-grow: 0;
  }
`

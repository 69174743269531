import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'
import { Link as ReactRouterLink } from 'react-router-dom'

export const Top = styled.div`
  display: flex;
  align-items: center;
`

export const MenuOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const NavTitle = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  align-items: center;
  align-self: stretch;
  flex-grow: 0;
  padding: 0 16px;
  margin-top: 35px;

  > h4 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #414141;
  }
`

const IconLinkStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  transition: 0.2s ease;

  :hover {
    background: rgb(241, 241, 241);
    border-radius: 10px;
  }
`
export const Link = styled(ReactRouterLink)`
  position: relative;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  text-decoration: none;
  ${props => props?.icon && IconLinkStyles};
  color: #414141;
`

export const LinkWithId = styled.a`
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  text-decoration: none;
  ${props => props?.icon && IconLinkStyles};
  color: #414141;
`

export const LogoutContainer = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  align-items: center;
  align-self: stretch;
  flex-grow: 0;
  padding: 0 16px;
  margin-top: 35px;

  > a {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: ${theme.colors.pink900};
    cursor: pointer;
    text-decoration: none;
  }
`

const notificationCollapseStyle = css`
  position: absolute;
  right: 0;
  top: -2px;
`

const notificationMobileStyle = css`
  position: absolute;
  right: -4px;
  bottom: -2px;
`

export const Notification = styled.div`
  ${({ isIcon }) => isIcon && notificationCollapseStyle}

  ${({ isMobile }) => isMobile && notificationMobileStyle}

  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;

  border-radius: 50%;
  background: #de114b;

  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 14px;
`

import React from 'react'
import { ContainerHeader, Title, Progress, WrapperItens, ColorBold } from './style'

export const Header = ({ current }) => {
  return (
    <ContainerHeader>
      <WrapperItens>
        <Title>
          Declaração <ColorBold>{current}%</ColorBold> concluída
        </Title>
      </WrapperItens>
      <Progress value={current} total={100} />
    </ContainerHeader>
  )
}

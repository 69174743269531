import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  padding-left: 155px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: 802px;
  z-index: 2;

  ${theme.breakpoints.down('md')} {
    padding: 0 27px;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 80px;

  div {
    margin: 0;
    margin-right: 24px;
  }

  @media (max-width: 600px) {
    margin-top: 150px;
    .MuiButton-root {
      width: 224px;
    }
  }

  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    div + div {
      margin-top: 16px;
    }
  }

  @media (max-width: 350px) {
    margin-top: 50px;
  }
`

import React from 'react'
import { CollapseSection } from '../../../../components/CollapseV2'

export const Question = ({ itemProps, index, items }) => {
  return (
    <CollapseSection
      active={-1 === index}
      isLast={items.length - 1 === index}
      key={itemProps.title}
      title={itemProps.subTitle}
      content={itemProps.texts}
    />
  )
}

import React from 'react'

export const Question2 = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
      stroke="#575757"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 12C8.41421 12 8.75 11.6642 8.75 11.25C8.75 10.8358 8.41421 10.5 8 10.5C7.58579 10.5 7.25 10.8358 7.25 11.25C7.25 11.6642 7.58579 12 8 12Z"
      fill="#575757"
    />
    <path
      d="M8 9V8.5C8.34611 8.5 8.68446 8.39736 8.97224 8.20507C9.26003 8.01278 9.48433 7.73947 9.61678 7.4197C9.74924 7.09993 9.78389 6.74806 9.71637 6.40859C9.64885 6.06913 9.48217 5.75731 9.23743 5.51256C8.99269 5.26782 8.68087 5.10115 8.3414 5.03363C8.00194 4.9661 7.65007 5.00076 7.3303 5.13321C7.01053 5.26566 6.73722 5.48997 6.54493 5.77775C6.35264 6.06554 6.25 6.40388 6.25 6.75"
      stroke="#575757"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

import React, { useContext } from 'react'
import moment from 'moment'

import { LoadingSkeleton } from '../../../components/LoadingSkeleton'
import { SelectCridNotification } from '../../../components/SelectCridNotification'

import { TemplateContext } from '../../../components/template/context'
import { useSelectCrid } from './hooks'
import {
  Container,
  Header,
  TitleHeader,
  Title,
  WrapperCrids,
  BoxCrid,
  StatusCrid,
  ContentCrid,
  BoxLogo,
  ContainerCrid,
  BoxLoading
} from './styles'
import { greenStatuses, redStatuses } from './utils/constants'

export const SelectCridV2 = () => {
  const { setMenuActive, setIsBack, setSelectCrid, token, setTypeDash, setShowExtraInfo, isMobile } = useContext(
    TemplateContext
  )

  const {
    data,
    selectCrid,
    incompleteStatus,
    user,
    isLoading,
    IRNotification,
    creditRequestToUpgrade,
    onClickNotification
  } = useSelectCrid(setMenuActive, setIsBack, setSelectCrid, token, setTypeDash, setShowExtraInfo)

  const isDisabled = item => {
    return ['Financiamento Cancelado', 'ISA cancelado', 'Compra Cancelada'].includes(item.resumeStatus)
  }

  const CridStatus = ({ resumeStatus }) => {
    if (redStatuses.includes(resumeStatus)) return <StatusCrid color="#FEACAC">{resumeStatus}</StatusCrid>

    if (greenStatuses.includes(resumeStatus)) return <StatusCrid color="#D9F0C5">{resumeStatus}</StatusCrid>

    return <StatusCrid color="#FFEFAD">{resumeStatus}</StatusCrid>
  }

  const hasHover = crid => {
    return (
      (crid.redirect && (incompleteStatus.includes(crid.status) || crid.status === 'analysis')) ||
      !(incompleteStatus.includes(crid.status) || crid.status === 'analysis')
    )
  }

  return (
    <Container>
      <Header>
        <TitleHeader>
          <LoadingSkeleton isLoading={isLoading} hasHeading containerHeight="30px" containerWidth={isMobile ? '70%' : '33%'}>
            {`Olá${user && user.firstName ? ', ' + user.firstName + ' ' + user.lastName : ''} ;)`}
          </LoadingSkeleton>
        </TitleHeader>
      </Header>

      {(IRNotification || !!creditRequestToUpgrade) && (
        <SelectCridNotification
          onClick={onClickNotification}
          type={IRNotification ? 'IR' : 'AllowanceUpgrade'}
          downloadUrl={IRNotification}
          value={creditRequestToUpgrade?.upgradeValue}
        />
      )}

      <Title>Meus Produtos</Title>
      <WrapperCrids>
        {isLoading ? (
          <>
            <BoxLoading>
              <LoadingSkeleton isLoading={isLoading} hasHeading containerHeight="100%" containerWidth="100%" />
            </BoxLoading>
            <BoxLoading>
              <LoadingSkeleton isLoading={isLoading} hasHeading containerHeight="100%" containerWidth="100%" />
            </BoxLoading>
            <BoxLoading>
              <LoadingSkeleton isLoading={isLoading} hasHeading containerHeight="100%" containerWidth="100%" />
            </BoxLoading>
          </>
        ) : (
          data.map(item => {
            return (
              <BoxCrid
                key={item.id}
                hover={isDisabled(item) ? false : hasHover(item)}
                isBlocked={isDisabled(item)}
                onClick={() => selectCrid(item, isDisabled(item))}
              >
                <ContainerCrid>
                  <BoxLogo>
                    <img
                      src={`https://avatars.dicebear.com/api/initials/${item.partner}.svg?b=%23000b3a&w=80&h=80&backgroundColorLevel=600&fontSize=30&r=8&bold=true`}
                    />
                  </BoxLogo>
                  <ContentCrid>
                    <p>{item.partner}</p>
                    <h3>{item.course}</h3>
                    <span>{moment(item.createdAt).format('DD/MM/YYYY')}</span>
                  </ContentCrid>
                </ContainerCrid>
                <CridStatus resumeStatus={item.resumeStatus} />
              </BoxCrid>
            )
          })
        )}
      </WrapperCrids>
    </Container>
  )
}

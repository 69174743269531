import React from 'react'

export const CloseIcon = ({ size = false, color = 'black' }) => (
  <svg
    width={size ? size : '16'}
    height={size ? size : '16'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.7071 0.292891C1.31658 -0.0976327 0.683414 -0.0976311 0.292891 0.292894C-0.0976324 0.683419 -0.0976307 1.31658 0.292894 1.70711L1.7071 0.292891ZM14.2929 15.707C14.6834 16.0976 15.3166 16.0976 15.7071 15.707C16.0976 15.3165 16.0976 14.6834 15.7071 14.2928L14.2929 15.707ZM15.7071 1.70705C16.0976 1.31652 16.0976 0.683357 15.7071 0.292832C15.3166 -0.0976924 14.6834 -0.0976917 14.2929 0.292832L15.7071 1.70705ZM0.292894 14.2928C-0.0976303 14.6834 -0.0976306 15.3165 0.292894 15.707C0.683418 16.0976 1.31658 16.0976 1.70711 15.707L0.292894 14.2928ZM0.292894 1.70711L7.29288 8.70706L8.70709 7.29285L1.7071 0.292891L0.292894 1.70711ZM7.29288 8.70706L14.2929 15.707L15.7071 14.2928L8.70709 7.29285L7.29288 8.70706ZM8.70709 8.70706L15.7071 1.70705L14.2929 0.292832L7.29288 7.29285L8.70709 8.70706ZM7.29288 7.29285L0.292894 14.2928L1.70711 15.707L8.70709 8.70706L7.29288 7.29285Z"
      fill={color}
    />
  </svg>
)

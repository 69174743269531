import React from 'react'
import { HeaderContainer, TitleHeader, ButtonHeader, TextButton } from './styles'

const Button = ({ buttonLabel, to }) => {
  return (
    <ButtonHeader to={to}>
      <TextButton>{buttonLabel}</TextButton>
    </ButtonHeader>
  )
}

export const HeaderV2 = ({ label, buttonLabel, displayButton = false, isMobile, to, showBorder = true }) => {
  return (
    <HeaderContainer showBorder={showBorder}>
      <TitleHeader>{label}</TitleHeader>
      {displayButton && !isMobile && <Button buttonLabel={buttonLabel} to={to} />}
    </HeaderContainer>
  )
}

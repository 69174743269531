import moment from 'moment'
import * as Sentry from '@sentry/browser'
import { setupFirebase } from './services/firebase'
import { identifyFromStoredIdentity } from './utils/identify'
import { gaTrackingId } from './constants'
import ReactGA from 'react-ga'
import { initGTM } from './services/initGTM'

const { REACT_APP_API_ENV } = process.env

Sentry.init({
  dsn: 'https://fd35933272194285990bb729fdda03d2:103cbcf731c74269b10455c69c404453@sentry.io/2717568',
  environment: REACT_APP_API_ENV
})

// initial setup firebase
setupFirebase(REACT_APP_API_ENV)

initGTM(REACT_APP_API_ENV)

moment.updateLocale('pt-br', require('moment/locale/pt-br'))

ReactGA.initialize({
  trackingId: gaTrackingId,
  useExistingGa: true,
  debug: false
})
identifyFromStoredIdentity()

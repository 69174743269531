/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { showToast } from '@provi/provi-components'
import { history } from '../../../navigation/history'
import { LOCALSTORAGE_TOKENS } from '../../../constants'
import { sendCode, checkCode, validateSigner } from '../../../services/provi-sign'

const CODE_COUNT = 4

export const useCodeValidator = (setIsLoading, typeUser, setObjectValidation) => {
  const inputRefs = []
  const [counter, setCounter] = useState(0)
  const [phone, setPhone] = useState()
  const [code, setCode] = useState(new Array(CODE_COUNT).fill(undefined))

  useEffect(() => {
    let interval = null
    if (counter > 0) {
      interval = setInterval(() => {
        setCounter(counter => counter - 1)
      }, 1000)
    } else if (counter === 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [counter])

  const getValidateSigner = async () => {
    const type = localStorage.getItem(LOCALSTORAGE_TOKENS.type)
    const tokenUser = localStorage.getItem(LOCALSTORAGE_TOKENS.token)
    const tokenProviSign = localStorage.getItem(LOCALSTORAGE_TOKENS.token_provi_sign)
    const token = type === 'user' ? tokenUser : tokenProviSign
    const crid = JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKENS.crid))
    const { data } = await validateSigner(token, crid, typeUser)
    setObjectValidation(data)
  }

  useEffect(() => {
    inputRefs[0].focus()
    const getData = async () => {
      try {
        setIsLoading(true)
        setCounter(30)
        const response = await sendCode(typeUser)
        if (response && response.data && response.data.token) {
          history.push('/provi-sign/termos-contrato')
          return
        }

        setPhone(response.data.phone)
      } catch (err) {
        if (err.response) {
          showToast(err.response.data.message)
          return
        }
        showToast()
      } finally {
        setIsLoading(false)
      }
    }
    if (typeUser) {
      getValidateSigner()
      getData()
    }
  }, [typeUser])

  const setCodeNumber = async (codeNumber, position) => {
    if (position < code.length) {
      const newCode = code

      newCode[position] = codeNumber
      setCode(newCode)
    }

    const checkArr = code.filter(c => c === undefined || c === '')
    if (!checkArr.length) {
      setIsLoading(true)
      try {
        const data = code.join('')
        await checkCode({ data: data }, typeUser)
        history.push('/provi-sign/termos-contrato')
      } catch (error) {
        showToast('Código de verificação inválido')
        setIsLoading(false)
      }
      setIsLoading(false)
    }
  }

  const addInput = ref => {
    inputRefs.push(ref)
  }

  const onKeyDown = (e, position) => {
    if (e.keyCode === 8 && inputRefs[position].value === '' && position !== 0) {
      inputRefs[position - 1].focus()
    } else {
      e.target.select()
    }
  }

  const setNextFocus = (codeNumber, position) => {
    if (codeNumber !== '' && position < CODE_COUNT - 1) {
      inputRefs[position + 1].focus()
      inputRefs[position + 1].select()
    }
  }

  const sendVerification = async () => {
    setIsLoading(true)
    try {
      await sendCode(typeUser)
      setCounter(30)
      showToast('Código reenviado :)')
    } catch (err) {
      if (err.response) {
        showToast(err.response.data.error.message)
        return false
      }
      showToast()
    } finally {
      setIsLoading(false)
    }
  }

  return [code, setCodeNumber, addInput, setNextFocus, onKeyDown, sendVerification, counter, phone]
}

import React from 'react'

import { Row } from '../components/row'
import * as S from '../styles'
import { LoadingSkeleton } from '../../../../components/LoadingSkeleton'

export const TableAllowance = ({ collapsed, newAllowances, isLoading, isMobile }) => {
  const newAllowancesCollapsed = collapsed ? newAllowances.filter((_newAllowance, index) => index <= 5) : newAllowances

  return (
    <>
      {isLoading ? (
        <LoadingSkeleton isLoading hasHeading containerHeight="264px" containerWidth={isMobile ? '100%' : '408px'} />
      ) : (
        <S.TableContainer height={38 * newAllowancesCollapsed.length + 34} collapsed={collapsed}>
          <S.HeaderWrapper>
            <S.HeaderText showRadius position="left">
              Mesada
            </S.HeaderText>
            <S.HeaderText>Valor a receber</S.HeaderText>
            <S.HeaderText showRadius position="right">
              Quando
            </S.HeaderText>
          </S.HeaderWrapper>
          {newAllowancesCollapsed.map((row, index) => (
            <Row length={newAllowancesCollapsed.length} index={index} row={row} key={index} text="Mesada" />
          ))}
        </S.TableContainer>
      )}
    </>
  )
}

import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 800px) {
    padding: 0 5px;
  }
`

export const GrayText = styled.p`
  font-family: 'Montserrat', sans-serif;
  color: ${theme.colors.greyPrimary};
  font-size: 18px;
  line-height: 24px;

  @media (max-width: 425px) {
    font-size: 14px;
  }

  @media (max-width: 330px) {
    font-size: 12px;
  }
`

import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'

export const Title = styled.h3`
  margin: 0;
  color: ${theme.colors.blue900};
  font-weight: 600;
  font-size: 20px;

  margin-bottom: 18px;
`

export const BankAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 30px 0;

  border-bottom: 1px solid #cfcfcf;
`

export const InlineWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  max-width: 340px;
  width: 100%;
  margin-bottom: 15px;

  ${theme.breakpoints.down('sm')} {
    justify-content: space-between;
    max-width: none;

    & > div + div {
      width: fit-content;
    }
  }
`

export const Subtitle = styled.div`
  color: ${theme.colors.black100};
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
`
const activeStyle = css`
  color: ${theme.colors.bluePrimaryDark};
  border-color: ${theme.colors.bluePrimary};
  background: ${theme.colors.bluePrimaryLight};
  cursor: pointer;
`

export const AccountTypeButton = styled.div`
  border: 2px solid ${theme.colors.bluePrimaryLight};
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  font-weight: 600;
  padding: 15px 35px;
  transition: 0.4s;

  color: ${theme.colors.bluePrimary};
  border-color: ${theme.colors.bluePrimaryLight};
  background: #ffffff;

  &:hover {
    ${({ isEnabledToSelect }) => isEnabledToSelect && activeStyle}
  }

  &:first-child {
    margin-right: 15px;
  }

  ${({ isActive }) => isActive && activeStyle}

  ${theme.breakpoints.down('sm')} {
    font-size: 14px;
    line-height: 12px;
    padding: 15px 27px;
  }
`

export const BankSelectWrapper = styled.div`
  margin-bottom: 15px;
`

export const SaveOrCancelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;

  @media (max-width: 800px) {
    width: 100%;
    flex-direction: column-reverse;
    justify-content: center;
  }
`

export const CancelEditionButton = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.bluePrimary};
  font-size: 16px;
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;

  & > svg {
    margin-right: 10px;
  }

  @media (max-width: 800px) {
    margin: 20px 0;

    & > svg {
      display: none;
    }
  }
`

export const InformationCard = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  background: ${theme.colors.bluePrimaryLight};
  width: 95%;
  max-width: 400px;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;

  margin: 20px 0 40px 0;

  & > svg {
    margin-right: 10px;
  }
`

export const ErrorCard = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  background: #fff;
  width: 95%;
  max-width: 400px;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  border: 2px solid ${theme.colors.pink900};

  margin: 20px 0 40px 0;

  & > svg {
    margin-right: 10px;
  }
`

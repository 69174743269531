import React from 'react'
import { IconLocked } from '../../../../assets/svg/icon-locked'
import { currencyParser } from '../../../../utils'
import { ItemInvoiceLocked, Left, Footer, TextTitle, TextSimple, Right, Status, Header, WrapperLocked } from './styles'

export const CardLocked = ({ item }) => {
  return (
    <ItemInvoiceLocked disabled minHeight={100}>
      <Header disabled>
        <Left>
          <TextTitle>{currencyParser(item.price || 0, 'R$')}</TextTitle>
          <TextSimple>
            Vencimento: <br /> {item.date}
          </TextSimple>
        </Left>
        <WrapperLocked>
          <IconLocked />
        </WrapperLocked>
        <Right>
          <TextSimple>Fatura fechada</TextSimple>
          <Status color="#bcbcbc" />
        </Right>
      </Header>
      <Footer disabled>
        <Left></Left>
        <Right>
          <TextSimple>{item.isUpfront ? 'Entrada' : `${item.number}/${item.amountOfItems}`}</TextSimple>
        </Right>
      </Footer>
    </ItemInvoiceLocked>
  )
}
